<div class="settings container-sm d-flex justify-content-center align-items-center">
  <div class="d-flex justify-content-between align-items-center w-35">
    <span>{{ 'jewishSettings.short' | translate }}</span>
    <mat-slide-toggle (change)="user.settings.sefiratHaomer = !user.settings.sefiratHaomer"
                      [checked]="user.settings.sefiratHaomer">
    </mat-slide-toggle>
    <span>{{ 'jewishSettings.long' | translate }}</span>
  </div>
</div>

<mat-card-subtitle class="mt-3 font-italic text-center">{{ 'jewishSettings.overridden' | translate }}</mat-card-subtitle>
<mat-card-subtitle class="mt-3 font-italic text-center">{{ 'jewishSettings.available' | translate }}</mat-card-subtitle>


<mat-list>
  <mat-list-item><span class="font-italic format-token">{{ 'jewishSettings.short' | translate }}</span>&nbsp;- כ"ג בעומר</mat-list-item>
  <mat-list-item><span class="font-italic format-token">{{ 'jewishSettings.long' | translate }}</span>&nbsp;- היום כ"ג יום שהם שלושה שבועת ושני ימים
    בעומר</mat-list-item>
</mat-list>
