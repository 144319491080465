<div class="container dialog-box"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane">
  <mat-card>
    <mat-card-header class="edit-record-header"
                     [style.direction]="direction">
      <mat-card-title>{{ 'dashboard.widgetProperties.specific.table.advanced.edit' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'dashboard.widgetProperties.specific.table.advanced.subtitle' | translate }}</mat-card-subtitle>
    </mat-card-header>

    <!--    Enabled-->
    <hr class="hr-text" [attr.data-content]="'dashboard.widgetProperties.specific.table.advanced.titleOnly' | translate">
    <div class="property container-sm d-flex justify-content-around align-items-center mt-1 mx-2 pb-1">
      <mat-checkbox (change)="setAll($event.checked, 'enabled')"
                    [checked]="enabled"
                    [indeterminate]="someSet('enabled')"
                    labelPosition="before"
                    class="menu-item-checkbox">
        <span class="menu-item mr-4">
          {{ 'dashboard.widgetProperties.specific.table.advanced.enabled' | translate }}
        </span>
      </mat-checkbox>

      <!--      Title only-->
      <mat-checkbox (change)="setAll($event.checked, 'titleOnly')"
                    [checked]="titleOnly"
                    [indeterminate]="someSet('titleOnly')"
                    labelPosition="before"
                    class="menu-item-checkbox">
        <span class="menu-item mr-4">
          {{ 'dashboard.widgetProperties.specific.table.titleOnly' | translate }}
        </span>
      </mat-checkbox>
    </div>

    <!--    Rounding options-->
    <hr class="hr-text" [attr.data-content]="'dashboard.widgetProperties.specific.table.advanced.rounding' | translate">
    <div [class.disabled]="someSet('titleOnly')"
         [style.direction]="direction"
         [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
         class="property container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1 w-100">
      <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.table.advanced.minutes' | translate }}</span>
      <mat-form-field appearance="standard" class="option-font w-50"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-select [(value)]="roundMin" (selectionChange)="setProperty('roundMin')">
          <mat-option *ngFor="let min of minutes" [value]="min"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
            {{ min }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div [class.disabled]="someSet('titleOnly')"
         [style.direction]="direction"
         [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
         class="property container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
      <mat-radio-group [(ngModel)]="roundOption" (ngModelChange)="setProperty('roundOption')" class="w-100 d-flex justify-content-between">
        <mat-radio-button [value]="roundingOptions.Nearest">
          {{ 'dashboard.widgetProperties.specific.table.advanced.nearest' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="roundingOptions.Previous">
          {{ 'dashboard.widgetProperties.specific.table.advanced.previous' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="roundingOptions.Next">
          {{ 'dashboard.widgetProperties.specific.table.advanced.next' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!--    Date of calculation-->
    <hr class="hr-text" [attr.data-content]="'dashboard.widgetProperties.specific.table.advanced.calc' | translate">
    <div [class.disabled]="someSet('titleOnly')"
         [style.direction]="direction"
         [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
         class="property container-sm d-flex justify-content-between align-items-center mt-2 mx-2 pb-1">
      <mat-radio-group [(ngModel)]="calcOption" (ngModelChange)="setProperty('calcOption')" class="w-100 d-flex justify-content-around mt-2">
        <mat-radio-button [value]="calcOptions.Daily">
          {{ 'dashboard.widgetProperties.specific.table.advanced.daily' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="calcOptions.Weekly" (change)="getCurrentWeekTimes()">
          {{ 'dashboard.widgetProperties.specific.table.advanced.weekly' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div [ngClass]="!someSet('titleOnly') && calcOption === calcOptions.Weekly ? 'animate__fadeIn' : 'd-none'"
         class="animate__animated">
      <div [class.disabled]="someSet('titleOnly') || calcOption === calcOptions.Daily" class="d-flex justify-content-between">
        <!--        Week definition-->
        <div class="d-flex flex-column">
          <mat-form-field appearance="standard" class="option-font"
                          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
            <mat-label class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.table.advanced.weekDefinitions.title' | translate }}</mat-label>
            <mat-select [(value)]="weekDefinition" (selectionChange)="setProperty('weekDefinition')">
              <mat-select-trigger>
                &nbsp;{{ 'dashboard.widgetProperties.specific.table.advanced.weekDefinitions.' + weekDefinition | translate }}
              </mat-select-trigger>
              <mat-option *ngFor="let _ of (obj.keys(weekDefinitions).length / 2) | times; let i = index"
                          [value]="weekDefinitions[weekDefinitions[i]]"
                          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                {{ 'dashboard.widgetProperties.specific.table.advanced.weekDefinitions.' + weekDefinitions[weekDefinitions[i]] | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!--        Day of change-->
        <div class="d-flex flex-column ml-4">
          <mat-form-field appearance="standard" class="option-font"
                          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
            <mat-label class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.title' | translate }}</mat-label>
            <mat-select [(value)]="dayOfChange" (selectionChange)="setProperty('dayOfChange')">
              <mat-select-trigger>
                &nbsp;{{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.' + dayOfChange | translate }}
              </mat-select-trigger>
              <mat-option *ngFor="let _ of (obj.keys(days).length / 2) | times; let i = index"
                          [value]="days[days[i]]"
                          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                {{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.' + days[days[i]] | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!--      Decisive day-->
      <div [class.disabled]="someSet('titleOnly') || calcOption === calcOptions.Daily || weekDefinition === undefined"
           [style.direction]="direction"
           [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
           class="property container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
        <mat-radio-group [(ngModel)]="decisiveDay" (ngModelChange)="setProperty('decisiveDay')" class="w-100 d-flex justify-content-between">
          <mat-radio-button [value]="decisiveDays.Earliest">
            {{ 'dashboard.widgetProperties.specific.table.advanced.earliest' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="decisiveDays.Latest">
            {{ 'dashboard.widgetProperties.specific.table.advanced.latest' | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="decisiveDays.Specific">
            {{ 'dashboard.widgetProperties.specific.table.advanced.specific' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <!--    Specific day-->
    <div  [ngClass]="!someSet('titleOnly')
                     && calcOption === calcOptions.Weekly
                     && decisiveDay === decisiveDays.Specific
                     && weekDefinition !== undefined
                       ? 'animate__fadeIn'
                       : 'd-none'"
          class="animate__animated">
      <div [style.direction]="direction"
           [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
           class="property container-sm d-flex justify-content-center align-items-center mt-1 mx-2 pb-1 w-100">
        <mat-form-field appearance="standard" class="option-font"
                        [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          <mat-label class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.table.advanced.specific' | translate }}</mat-label>
          <mat-select [(value)]="specificDay" (selectionChange)="setProperty('specificDay')">
            <mat-select-trigger>
              &nbsp;{{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.' + specificDay | translate }}
            </mat-select-trigger>
            <mat-option *ngFor="let day of definitions[weekDefinition]"
                        [value]="days[days[day]]"
                        [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
              {{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.' + days[days[day]] | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!--    Buttons-->
    <div class="row justify-content-center">
      <div class="mt-4">
        <button mat-raised-button color="primary" class="mr-5" [mat-dialog-close]="records">{{ 'buttons.ok' | translate}}</button>
        <button mat-raised-button color="accent" [mat-dialog-close]="null">{{ 'buttons.cancel' | translate}}</button>
      </div>
    </div>
  </mat-card>
</div>
