import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, HostListener } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { getZmanimJson } from "kosher-zmanim";
import { Subscription, Observable } from "rxjs";
import { UserDto } from "../_dto";
import { LocalStorage, Minhags } from "../_enums";
import { ComponentCanDeactivate } from "../_guards";
import { UserInformation, WidgetHelper, hasPermission, DateHelper } from "../_helpers";
import { widgetToWidgetState, KosherZmanimObject, Screen } from "../_models";
import { PermissionTypes } from "../_models/identity";
import { SettingsComponent } from "../_modules/settings/settings/settings.component";
import { ApiService, SnackbarService, EditService, WidgetService, AccountService, HalachicTimesService, DateService, ContentApiService, SettingsService } from "../_services";
import { setLoadedWidgets } from "../store/widget";
// @ts-ignore
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit, ComponentCanDeactivate {
  public window = window;
  public _currentScreen: Screen;
  get currentScreen(): Screen {
    return this._currentScreen;
  }

  set currentScreen(screen: Screen) {
    this._currentScreen = screen;
    this.store.dispatch(
      setLoadedWidgets({
        widgets: screen.widgets.map(
          widget => (
            structuredClone(
              widgetToWidgetState(widget)
            )
          )
        )
      })
    );
  }

  public isAuthenticated: boolean;
  public isInIframe = false;
  public editMode = false;
  public sidenavOpened: boolean;

  private timeout: Timeout;
  private isMouseHidden = false;
  private inactivityTime = 1000 * 60 * 5;
  private hasModeratorPermissions: boolean;
  private isOrganizationOpen: boolean;
  private subscriptions: Subscription[] = [];
  isMember: boolean;

  constructor(
    public api: ApiService,
    private snackbar: SnackbarService,
    private userInfo: UserInformation,
    private translate: TranslateService,
    private editService: EditService,
    private widgetService: WidgetService,
    private accountService: AccountService,
    private halachicTimesService: HalachicTimesService,
    private dateService: DateService,
    private dialog: MatDialog,
    private contentApiService: ContentApiService,
    private settingsService: SettingsService,
    private store: Store
  ) {
  }

  @HostListener('window:beforeunload')
  public canDeactivate(): Observable<boolean> | boolean {
    return !this.currentScreen.dirty;
  }

  /**
   * Handles Esc shortcut to toggle Edit mode.
   */
  @HostListener('document:keydown.Escape', ['$event'])
  public handleEscapeKeyboardEvent(): void {
    WidgetHelper.clearSelectedStyle();
    this.editMode = !this.editMode;
    this.editService.setEditMode(this.editMode);
  }

  /**
   * Handles Tab shortcut to open Settings menu.
   */
  @HostListener('document:keydown.Tab', ['$event'])
  public handleTabKeyboardEvent(): void {
    if (!this.hasModeratorPermissions && !this.isOrganizationOpen && !this.isMember) return;
    WidgetHelper.clearSelectedStyle();
    this.toggleSettingsWindow();
  }

  /**
   * Handles Ctrl+S shortcut to save current screen.
   * @param {KeyboardEvent} event - keyboard event in order to determine which buttons were pressed.
   */
  @HostListener('document:keydown.control.s', ['$event'])
  public handleCtrlSEvent(event: KeyboardEvent): void {
    if (!this.hasModeratorPermissions || !this.editMode) return;
    event.preventDefault(); // stops page saving

    const widgets = [...this.currentScreen.widgets.map(widget => ({ ...widget, contentSource: null, effectSource: null, contentChangedSource: null, resizeStopSource: null }))];
    const screen = { ...this.currentScreen, widgets };

    this.currentScreen.dirty = false;
    this.contentApiService.updateScreen(screen).subscribe(
      () => this.snackbar.success(this.translate.instant('snackbar.screenUpdated')),
      error => {
        console.log(error);
        this.snackbar.error(error.error.message, 'Ok', 5000);
      }
    );

  }

  public ngOnInit(): void {
    this.setHalachicOptionsByTime();

    const subscription = this.editService.editMode$.subscribe(mode => {
      this.editMode = mode;
    });
    this.registerSubscription(subscription);

    this.setUserInfo();
    this.loadScreen();
  }

  public ngAfterViewInit(): void {
    document.body.addEventListener('mousemove', () => this.magicMouse(this.inactivityTime));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Toggles settings dialogue window, and reloads on closure.
   * @private
   */
  private toggleSettingsWindow(): void {
    if (!!this.settingsService.settingsDialogWindowRef) {
      this.settingsService.settingsDialogWindowRef.close();
      return;
    }

    this.settingsService.settingsDialogWindowRef = this.dialog.open(SettingsComponent);
    this.settingsService.settingsDialogWindowRef.afterClosed()
      .subscribe(updated => {
        this.settingsService.clearSettingsDialogWindowReference();
        if (updated) {
          setTimeout(() => location.reload(), 3000);
          // TODO implement reload without page refreshing
        }
      });
  }

  /**
   * Hides the mouse cursor after inactivity and returns it on mouse move.
   * @param {number} inactivityTime - to hide the cursor
   * @private
   */
  private magicMouse(inactivityTime: number): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      if (!this.isMouseHidden) {
        document.querySelector('body').style.cursor = 'none';
        this.isMouseHidden = true;
      }
    }, inactivityTime);

    if (this.isMouseHidden) {
      document.querySelector('body').style.cursor = 'auto';
      this.isMouseHidden = false;
    }
  }

  /**
   * Sets the user information for the first time.
   * @private
   */
  private setUserInfo(): void {
    if (localStorage.getItem(LocalStorage.UniqueDeviceId) === null) {
      this.userInfo.set();
    }
    this.api.uniqueDeviceId = localStorage.getItem(LocalStorage.UniqueDeviceId);
  }

  /**
   * Retrieves current screen from server and combines with the changes made in the edit mode.
   * @private
   */
  private loadScreen(): void {
    const subscription = this.widgetService.getCombinedScreen().subscribe((screen: Screen) => {
      this.currentScreen = screen;
      const token = localStorage.getItem(LocalStorage.Token);
      this.contentApiService.createHubConnection(token, screen.id);
    });

    this.registerSubscription(subscription);
  }

  /**
   * Retrieves a user from cache or server and sets user options a.
   * @param {Date} date - selected date.
   * @private
   */
  private setLocationAndMinhagOptions(date: Date): void {
    const subscription = this.accountService.getUser().subscribe((user: UserDto) => {

      console.log('user (dto):', user);
      const { times, candleLight, minhag, location: { latitude, longitude, timeZoneId, description, elevation }, role, isOrganizationOpen: isOrganizationOpen } = user;
      this.hasModeratorPermissions = hasPermission(PermissionTypes.ManageWidgets)
      this.isMember = hasPermission(PermissionTypes.ChangeNusach);
      this.isOrganizationOpen = isOrganizationOpen;

      this.halachicTimesService.minhag = minhag as Minhags;
      this.halachicTimesService.candleLightTime = candleLight;
      this.halachicTimesService.times = times;

      this.halachicTimesService.options = {
        complexZmanim: true,
        date: DateHelper.getDateInISO8601Format(date),
        locationName: description,
        elevation,
        latitude,
        longitude,
        timeZoneId
      };

      this.halachicTimesService.halachicTimes = getZmanimJson(this.halachicTimesService.options).Zmanim as KosherZmanimObject;

      const tomorrow = new Date(date);
      tomorrow.setDate(date.getDate() + 1);
      const tomorrowOptions = {
        ...this.halachicTimesService.options,
        date: DateHelper.getDateInISO8601Format(tomorrow)
      };

      const todayZmanim = getZmanimJson(this.halachicTimesService.options).Zmanim as KosherZmanimObject;
      const tomorrowZmanim = getZmanimJson(tomorrowOptions).Zmanim as KosherZmanimObject;
      this.halachicTimesService.halachicTimesTwoDays = [todayZmanim, tomorrowZmanim];
    });
    this.registerSubscription(subscription);
  }

  /**
   * Listens to date change in order to set user options.
   * @private
   */
  private setHalachicOptionsByTime(): void {
    const subscription = this.dateService.dateChanged$
      .subscribe(date => this.setLocationAndMinhagOptions(date));

    this.registerSubscription(subscription);
  }

  /**
   * Adds subscription to the current component subscription array.
   * @param {Subscription} subscription - subscription to register.
   * @private
   */
  private registerSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  //   /**
  //  * Calculates a dayLink in order to change date by specified change time.
  //  * @param {Date} date
  //  * @param {KosherZmanimOptionDto[]} times
  //  * @param {ChangeTimes} changeTimeKey
  //  * @private
  //  * @return {number}
  //  */
  //    private getDayLink(date: Date, times: KosherZmanimOptionDto[], changeTimeKey: ChangeTimes = ChangeTimes.Tzais): number {
  //     const changeTimeOption = times.find(time => time.key === changeTimeKey)?.option;
  //     return date > new Date(this.halachicTimesService.halachicTimes[changeTimeOption]) ? 1 : 0;
  //   }
}
