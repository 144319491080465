<div #component
     (mousedown)="component.style.cursor = 'grabbing'"
     (mouseup)="component.style.cursor = 'grab'"
     class="component m-2">
  <div>
    <div class="component-icon">
      <img [src]="widgetIcon?.path">
    </div>
    <div class="component-name">{{ 'dashboard.widgetsTab.' + widgetIcon?.name | translate }}</div>
  </div>
</div>
