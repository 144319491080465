import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as UserActions from "./user.actions";
import { AccountService } from "src/app/_services/account.service";


@Injectable()
export class UserEffects {
  loadUserPermissions$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadUser),
    switchMap(action => this.userService.getUser().pipe(
      map(userDto => UserActions.loadUserPermissions({ role: userDto.role })),
      catchError(error => of(UserActions.loadUserFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private userService: AccountService
  ) { }
}
