import {
  Calendar,
  Daf,
  getZmanimJson,
  HebrewDateFormatter,
  JewishCalendar,
  JewishDate,
  Options,
  YerushalmiYomiCalculator,
  YomiCalculator
} from 'kosher-zmanim';
import * as  moment from 'moment';
import { KosherZmanimOptionDto } from '../_dto/kosher-zmanim-option-dto';
import { UserDto } from '../_dto/user-dto';
import { ChangeTimes } from '../_enums/change-times';

import { Languages } from '../_enums/languages';
import { Minhags } from '../_enums/minhags';
import { TimeFormat } from '../_enums/time-format';
import { DayDescriptionSettings, JewishSettings } from '../_models/jewish';
import { KosherZmanimKey, KosherZmanimObject, KosherZmanimObjectTwoDays } from '../_models/kosher-zmanim-object';
import { Widget } from '../_models/widget';
import { HalachicTimesService } from '../_services/halachic-times.service';

function memoize(fn) {
  const cache = new Map();
  return function (...args) {
    // Round all dates so we cache only per minute
    for (let i = 0; i < args.length; i++) {
      if (args[i] instanceof Date) {
        args[i] = new Date(args[i].setSeconds(0, 0));
      }
    }

    const key = JSON.stringify(args);
    // Check if result is already cached
    // If so, return it
    if (cache.has(key)) {
      return cache.get(key);
    }
    // Otherwise, calculate the result
    const result = fn.apply(this, args);
    // Remove the oldest entry if the cache is full
    if (cache.size >= 1000) {
      const oldestKey = cache.keys().next().value;
      cache.delete(oldestKey);
    }
    // Cache the result and return it
    cache.set(key, result);
    return result;
  }
}

const getZmanimJsonMemoized = memoize(getZmanimJson);

function Memoize(target, property, descriptor) {
  const originalMethod = descriptor.value;
  descriptor.value = memoize(originalMethod);
  return descriptor;
}
export class HalachicTimesConfig {
  options: Options;
  formatter: HebrewDateFormatter;
  calendar: JewishCalendar;
  candleLight: number;
  minhag: Minhags;
  jewishDate: JewishDate;
  language: Languages;
  date: Date;
  settings: JewishSettings;
  times: KosherZmanimOptionDto[];
  timeFormat: TimeFormat;
  studies: string[];
  haftara: string;
  haftaraCaption: boolean;
  avot: string;
  hoshana: string;
  dayLink: number;

  static createInstance(widget: Widget, user: UserDto, date: Date, halachicTimesService: HalachicTimesService): HalachicTimesConfig {
    const dayDescription: DayDescriptionSettings = { shabbosMevorchim: user.settings?.dayDescription.shabbosMevorchim };

    const settings: JewishSettings = {
      hebrewDate: [widget.content.jewish.hebrewDateDay, widget.content.jewish.hebrewDateMonth, widget.content.jewish.hebrewDateYear],
      gregorianDate: widget.content.jewish.gregorianDate,
      parasha: widget.content.jewish.parasha,
      dayOfWeek: widget.content.jewish.dayOfWeek,
      sefiratHaomer: widget.content.jewish.sefiratHaomer,
      dafYomi: widget.content.jewish.dafYomi,
      shabbosEnd: {
        isMinutes: user.settings?.shabbosEnd.isMinutes,
        minutes: user.settings?.shabbosEnd.minutes,
        option: user.settings?.shabbosEnd.option
      },
      fastEnd: {
        isMinutes: user.settings?.fastEnd.isMinutes,
        minutes: user.settings?.fastEnd.minutes,
        option: user.settings?.fastEnd.option
      },
      moladOnShabbos: user.settings?.moladOnShabbos,
      kiddushLevanahEndBetweenMoldos: user.settings?.kiddushLevanahEndBetweenMoldos,
      dayDescription,
      moridHatal: user.settings?.moridHatal,
    };

    const formatter = new HebrewDateFormatter();
    const isHebrew = widget.language === Languages.Hebrew;
    formatter.setHebrewFormat(isHebrew);
    formatter.setLongWeekFormat(true);

    const omerPrefix = user.minhag === Minhags.Ashkenaz ? 'ב' : 'ל';
    formatter.setHebrewOmerPrefix(omerPrefix);

    const calendar = new JewishCalendar(date);

    calendar.setInIsrael(user.inEretzIsrael);
    calendar.setUseModernHolidays(user.settings?.dayDescription.zioni);


    const config: HalachicTimesConfig = {
      options: halachicTimesService.options,
      candleLight: halachicTimesService.candleLightTime,
      formatter,
      calendar,
      jewishDate: new JewishDate(date),
      language: widget.language,
      date,
      settings,
      times: user.times,
      minhag: user.minhag,
      timeFormat: widget.content.jewish.timeFormat,
      studies: [],
      haftara: '',
      haftaraCaption: user.settings?.haftara.caption,
      avot: '',
      hoshana: '',
      dayLink: this.getLinkDay(date, user.times, widget.changeTimeKey, halachicTimesService),
    };

    return config;
  }

  static getLinkDay(
    date: Date,
    times: KosherZmanimOptionDto[],
    changeTimeKey: ChangeTimes = ChangeTimes.Tzais,
    halachicTimesService: HalachicTimesService
  ): number {
    if (changeTimeKey === ChangeTimes.Midnight) {
      return 0;
    }
    const changeTimeOption = times.find(
      (time) => time.key === changeTimeKey
    )?.option;
    return date > new Date(halachicTimesService.halachicTimes[changeTimeOption])
      ? 1
      : 0;;
  }
}

const WEEKS = [
  '',
  ' שהם שבוע אחד',
  ' שהם שני שבועות',
  ' שהם שלושה שבועות',
  ' שהם ארבעה שבועות',
  ' שהם חמישה שבועות',
  ' שהם שישה שבועות',
  ' שהם שבעה שבועות'
];

const DAYS_REMAINDER = [
  '',
  ' ויום אחד',
  ' ושני ימים',
  ' ושלושה ימים',
  ' וארבעה ימים',
  ' וחמישה ימים',
  ' ושישה ימים'
];

export class JewishHelper {

  public static dateFormats: string[] = [
    'MMM DD, YYYY',
    'MMMM DD, YYYY',
    'DD MMMM YYYY',
    'D MMMM YYYY',
    'DD/MM/YYYY',
    'DD/MM/YY',
    'MM/DD/YYYY',
    'MM/DD/YY',
    'DD-MM-YYYY',
    'DD-MM-YY',
    'MM-DD-YYYY',
    'MM-DD-YY',
    'MMM-DD-YYYY',
    'DD-MMM-YYYY',
    'YYYY-MM-DD',
    'DD.MM.YYYY',
    'DD.MM.YY',
    'MM.DD.YYYY',
    'MM.DD.YY',
    ', MMMM D',
    'YYYY'
  ];

  private static _halachicConfig = {
    parasha: JewishHelper.parasha,
    hebrewDate: JewishHelper.hebrewDate,
    gregorianDate: JewishHelper.gregorianDate,
    dayOfWeek: JewishHelper.dayOfWeek,
    candleLight: JewishHelper.candleLight,
    sunset: JewishHelper.sunset,
    sunrise: JewishHelper.sunrise,
    shabbosEnd: JewishHelper.shabbosEnd,
    rabeinuTam: JewishHelper.rabeinuTam,
    sefiratHaomer: JewishHelper.haomer,
    veaneinu: JewishHelper.veaneinu,
    fastEnd: JewishHelper.fastEnd,
    dafYerushalmi: JewishHelper.dafYerushalmi,
    molad: JewishHelper.molad,
    haftara: JewishHelper.haftara,
    nachem: JewishHelper.nachem,
    rambam3: JewishHelper.rambam3,
    rambam1: JewishHelper.rambam1,
    rambamSeferHamitzvos: JewishHelper.rambamSeferHamitzvos,
    tehilimYomi: JewishHelper.tehilim,
    tania: JewishHelper.tania,
    chumash: JewishHelper.chumash,
    pirkeiAvot: JewishHelper.pirkeiAvot
  };

  private static _halachicTimes: KosherZmanimObject;
  private static _halachicTimesTwoDays: KosherZmanimObjectTwoDays;

  /**
   * Getter for the private _halachicConfig field.
   */
  public static get halachicConfig() {
    return this._halachicConfig;
  }

  /**
   * Getter for the private _halachicTimes field.
   */
  public static get halachicTimes(): KosherZmanimObject {
    return this._halachicTimes;
  }

  /**
   * Setter for the private _halachicTimes field.
   */
  public static set halachicTimes(value: KosherZmanimObject) {
    this._halachicTimes = value;
  }

  /**
   * Getter for the private _halachicTimesTwoDays field.
   */
  public static get halachicTimesTwoDays(): KosherZmanimObjectTwoDays {
    return this._halachicTimesTwoDays;
  }

  /**
   * Setter for the private _halachicTimesTwoDays field.
   */
  public static set halachicTimesTwoDays(value: KosherZmanimObjectTwoDays) {
    this._halachicTimesTwoDays = value;
  }

  /**
   * Checks whether it is Haomer on current/selected date and returns the correct title.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static haomer(config: HalachicTimesConfig): string {
    const { formatter, language, calendar, settings: { sefiratHaomer }, minhag, dayLink } = config;

    const modifiedCalendar = JewishHelper.getJewishDateByDayLink(calendar, dayLink);

    const omerDay = modifiedCalendar.getDayOfOmer();

    let longOmer = '';
    if (omerDay == 1 && sefiratHaomer) {
      const omerPrefix = minhag === Minhags.Ashkenaz ? 'בעומר' : 'לעומר';
      return longOmer += 'היום ' + JewishHelper.getLongDay(omerDay) + ' ' + omerPrefix;
    }
    if (omerDay > 1 && sefiratHaomer) {
      longOmer += 'היום ' + JewishHelper.getLongDay(omerDay) + JewishHelper.getLongOmer(omerDay, minhag);
    }
    return language === Languages.Hebrew && sefiratHaomer ? longOmer : formatter.formatOmer(modifiedCalendar);
  }

  /**
   * Returns the Parasha for the closest Shabbat.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static parasha(config: HalachicTimesConfig): string {
    const { calendar, language, formatter, date, settings: { parasha }, dayLink } = config;

    const clonedDate = new Date(date);
    if (dayLink > 0) {
      clonedDate.setDate(clonedDate.getDate() + dayLink);
    }

    JewishHelper.setShabbos(clonedDate);
    const shabbosCalendar = new JewishCalendar(clonedDate);
    shabbosCalendar.setInIsrael(calendar.getInIsrael());
    const currentParasha = formatter.formatParsha(shabbosCalendar);
    const specialParasha = ' - ' + formatter.formatSpecialParsha(shabbosCalendar);

    if (!currentParasha) {

      const yomTovOnShabbos = language === Languages.Hebrew ? 'בשבת יום טוב' : 'Yom Tov on Shabbos';
      const cholHamoedOnShabbos = language === Languages.Hebrew ? 'בשבת חול המועד' : 'Chol Hamoed on Shabbos';
      const yomTovOrCholHamoed = shabbosCalendar.isCholHamoed() ? cholHamoedOnShabbos : yomTovOnShabbos;
      return date.getDay() !== 6 ? yomTovOrCholHamoed : '';
    }

    const resultParsha = specialParasha.trim() !== '-' ? currentParasha + specialParasha : currentParasha
    const caption = language === Languages.Hebrew ? 'פרשת ' : 'Parashat ';

    return parasha ? caption + resultParsha : resultParsha;
  }

  /**
   * Returns the Daf Hayomi Hayerushalmi for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static dafYerushalmi(config: HalachicTimesConfig): string {
    const { formatter, calendar, dayLink } = config;
    const daf: Daf = YerushalmiYomiCalculator.getDafYomiYerushalmi(JewishHelper.getJewishDateByDayLink(calendar, dayLink));
    return 'דף יומי ירושלמי: ' + formatter.formatDafYomiYerushalmi(daf);
  }

  /**
   * Returns the current/selected Hebrew date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static hebrewDate(config: HalachicTimesConfig): string {
    const { formatter, jewishDate, settings: { hebrewDate }, dayLink } = config;

    let date = formatter.format(JewishHelper.getJewishDateByDayLink(jewishDate, dayLink)).split(' ');

    if (date.length === 4) {  // on Jewish leap year
      date = [date[0], `${date[1]} ${date[2]}`, date[3]];
    }

    return date.filter((item, index, arr) => hebrewDate[index] ? arr[index] : null).join(' ');
  }

  /**
   * Returns the current/selected Gregorian date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static gregorianDate(config: HalachicTimesConfig): string {
    const { date, settings: { gregorianDate } } = config;
    return moment(date).format(gregorianDate);
  }

  /**
   * Returns the current/selected day of week.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static dayOfWeek(config: HalachicTimesConfig): string {
    const { formatter, jewishDate, dayLink, language, settings: { dayOfWeek } } = config;
    let day = formatter.formatDayOfWeek(JewishHelper.getJewishDateByDayLink(jewishDate, dayLink));
    if (language !== Languages.Hebrew && dayLink === 1) {
      day = formatter.formatDayOfWeek(jewishDate);
    }
    // translate day of week to english
    if (language === Languages.English) {
      switch (day) {
        case 'יום ראשון':
          day = 'Sunday';
          break;
        case 'יום שני':
          day = 'Monday';
          break;
        case 'יום שלישי':
          day = 'Tuesday';
          break;
        case 'יום רביעי':
          day = 'Wednesday';
          break;
        case 'יום חמישי':
          day = 'Thursday';
          break;
        case 'יום שישי':
          day = 'Friday';
          break;
        case 'שבת':
          day = 'Shabbos';
          break;
      }
    }
    const dayTitle = language === Languages.Hebrew ? 'יום' : '';
    const nightTitle = language === Languages.Hebrew ? 'ליל' : '';
    // if (day == "שבת"){
    //   return dayLink === 1 ? `${nightTitle} ${day}`.replace('ליל ראשון', 'מוצ"ש') :day
    // }
    return dayLink === 1 ? `${nightTitle} ${day}`.replace('ליל ראשון', 'מוצ"ש') : dayOfWeek ? `${dayTitle} ${day}` : day;
  }

  /**
   * Returns the sunset time for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static sunset(config: HalachicTimesConfig): string {
    const { language, options, times, timeFormat, dayLink } = config;

    const sunsetTimeDto = times.find((time: KosherZmanimOptionDto) => time.key === 'sunset');
    const sunsetTime = JewishHelper.getSpecifiedTime(sunsetTimeDto, dayLink);

    let formattedTime = JewishHelper.getFormattedLocaleTime(sunsetTime, options.timeZoneId, timeFormat);
    formattedTime = JewishHelper.removeAmPmInHebrew(formattedTime, language);

    return language === Languages.Hebrew ? `${sunsetTimeDto.he}: ${formattedTime}` : `${sunsetTimeDto.en}: ${formattedTime}`;
  }

  /**
   * Returns the sunrise time for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static sunrise(config: HalachicTimesConfig): string {
    const { language, options, times, timeFormat, dayLink } = config;

    const sunriseTimeDto = times.find((time: KosherZmanimOptionDto) => time.key === 'sunrise');
    const sunriseTime = JewishHelper.getSpecifiedTime(sunriseTimeDto, dayLink);
    let formattedTime = JewishHelper.getFormattedLocaleTime(sunriseTime, options.timeZoneId, timeFormat);
    formattedTime = JewishHelper.removeAmPmInHebrew(formattedTime, language);

    return language === Languages.Hebrew ? `${sunriseTimeDto.he}: ${formattedTime}` : `${sunriseTimeDto.en}: ${formattedTime}`;
  }



  /**
   * Returns the candlelight time for the nearest Shabbat/Chag.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static candleLight(config: HalachicTimesConfig): string {
    const { date, language, candleLight, options, times, timeFormat, calendar, dayLink } = config;

    // if it's Shaboos && tomorrow don't YomTov
    if ((calendar.getDayOfWeek() === 7 && !calendar.isTomorrowShabbosOrYomTov())) {
      return
    }
    // if it's YomTov && tomorrow don't YomTov
    if ((calendar.getDayOfWeek() !== 7 && calendar.isYomTovAssurBemelacha() && !calendar.isTomorrowShabbosOrYomTov())) {
      return
    }
    const yomTovThisWeek = JewishHelper.isYomTovThisWeek(date, calendar);

    let candleLightTime,
      candleLightTitle,
      candleLightAndShabbosEndTitle,
      candleLightAndShabbosEndHebrewTitle,
      candleLightHebrewTitle: string;

    if (calendar.getDayOfWeek() === 7 && !calendar.isYomTov() && calendar.isTomorrowShabbosOrYomTov()) {
      // !calendar.isAssurBemelacha()
      // Today is Shabbos AND tomorrow is Yom Tov.
      const formattedOptions: Options = {
        ...options,
        date: calendar.getDate().toJSDate()
      };
      candleLightTitle = 'Holiday Candle Light';
      candleLightHebrewTitle = 'הד״נ עיו״ט';
      candleLightTime = JewishHelper.getYomTovRelatedTime('candleLight', formattedOptions, times, timeFormat);
      return

    } else if (!yomTovThisWeek ||
      (yomTovThisWeek.equals(calendar) && !calendar.isTomorrowShabbosOrYomTov()) ||
      (yomTovThisWeek.equals(calendar) && calendar.getDayOfWeek() === 6)
    ) {
      // There is no Yom Tov this week OR it is Yom Tov today and no Yom Tov tomorrow.
      candleLightTitle = 'Sh. Candle Light';
      candleLightHebrewTitle = 'הדלקת נרות';
      const isCandleLight = true;

      candleLightTime = JewishHelper.getShabbosRelatedTime('candleLight', date, options, timeFormat, times, isCandleLight);

      // calendar.isAssurBemelacha()
      if (calendar.getDayOfWeek() === 7 && calendar.isTomorrowShabbosOrYomTov()) {
        // There is Shabbos && Yom Tov today AND it Yom Tov tomorrow.
        candleLightAndShabbosEndTitle = 'Shabbos End & Candle Light';
        candleLightAndShabbosEndHebrewTitle = 'מוצ״ש והד״נ';

        const tomorrow = new Date(date);
        tomorrow.setDate(date.getDate() + 1);
        const formattedOptions: Options = {
          ...options,
          date: tomorrow
        };
        let formattedTime = JewishHelper.getShabbosRelatedTime('shabbosEnd', date, options, timeFormat, times);
        formattedTime = JewishHelper.removeAmPmInHebrew(formattedTime, language);
        return language === Languages.Hebrew ? `${candleLightAndShabbosEndHebrewTitle}: ${formattedTime}` : `${candleLightAndShabbosEndTitle}: ${formattedTime}`;
      }
    } else if (calendar.isYomTov() && yomTovThisWeek.equals(calendar) && calendar.isTomorrowShabbosOrYomTov() && calendar.getDayOfWeek() !== 6) {
      // calendar.isAssurBemelacha
      // There is Yom Tov today AND tomorrow.
      // candleLightTitle = 'Holiday Candle Light';
      // candleLightHebrewTitle = 'זמן הדל"נ חג';
      candleLightTitle = 'Chag End & Candle Light';
      candleLightHebrewTitle = 'מוצ״ח והד״נ';
      const tomorrow = new Date(date);
      tomorrow.setDate(date.getDate() + 1);
      const formattedOptions: Options = {
        ...options,
        date: tomorrow
      };

      let formattedTime = JewishHelper.getShabbosRelatedTime('shabbosEnd', date, options, timeFormat, times);
      formattedTime = JewishHelper.removeAmPmInHebrew(formattedTime, language);
      return language === Languages.Hebrew ? `${candleLightHebrewTitle}: ${formattedTime}` : `${candleLightTitle}: ${formattedTime}`;
    } else {
      // At least one Yom Tov day this week and there's a weekday today.
      const formattedOptions: Options = {
        ...options,
        date: yomTovThisWeek.getDate().toJSDate()
      };

      const yomTovDate = new JewishCalendar(yomTovThisWeek.getDate().toJSDate());

      if (yomTovDate.getYomTovIndex() === JewishCalendar.YOM_KIPPUR) {
        candleLightAndShabbosEndTitle = 'Yom Kippur Candle Light';
        candleLightAndShabbosEndHebrewTitle = 'הד״נ עיו״כ';
      } else {
        candleLightTitle = 'Holiday Candle Light';
        candleLightHebrewTitle = 'הד״נ עיו״ט';
      }

      candleLightTime = JewishHelper.getYomTovRelatedTime('candleLight', formattedOptions, times, timeFormat);
    }
    candleLightTime = JewishHelper.removeAmPmInHebrew(candleLightTime, language);

    return language === Languages.Hebrew ? `${candleLightHebrewTitle}: ${candleLightTime}` : `${candleLightTitle}: ${candleLightTime}`;
  }

  /**
   * Returns the Shabbos end time for the nearest Shabbat/Chag.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static shabbosEnd(config: HalachicTimesConfig): string {

    const { date, language, options, times, timeFormat, calendar } = config;

    const yomTovThisWeek = JewishHelper.isYomTovThisWeek(date, calendar);

    if (calendar.getDayOfWeek() === 7 && calendar.isTomorrowShabbosOrYomTov() || calendar.isYomTov() && calendar.isTomorrowShabbosOrYomTov()) {
      return
    }
    let shabbosEndTime,
      shabbosTitle,
      shabbosHebrewTitle: string;

    if (!yomTovThisWeek) {
      shabbosTitle = 'Shabbos ends';
      shabbosHebrewTitle = 'מוצאי שבת';
      // shabbosEndTime = JewishHelper.getShabbosRelatedTime(shabbosEnd, options, date, times, timeFormat);
      shabbosEndTime = JewishHelper.getShabbosRelatedTime('shabbosEnd', date, options, timeFormat, times);

    } else {
      if (calendar.isTomorrowShabbosOrYomTov()) {
        return
      }
      const formattedOptions: Options = {
        ...options,
        date: yomTovThisWeek.getDate().toJSDate()
      };
      const yomTovDate = new JewishCalendar(yomTovThisWeek.getDate().toJSDate());
      if (yomTovDate.getYomTovIndex() === JewishCalendar.YOM_KIPPUR) {
        shabbosTitle = 'Yom Kippur ends';
        shabbosHebrewTitle = 'מוצאי יו״כ';
      } else {
        shabbosTitle = 'Holiday ends';
        shabbosHebrewTitle = 'מוצאי חג';
      }
      shabbosEndTime = JewishHelper.getYomTovRelatedTime('shabbosEnd', formattedOptions, times, timeFormat);
      // shabbosEndTime = JewishHelper.getYomTovRelatedTime(shabbosEnd, formattedOptions, times, timeFormat);

    }

    shabbosEndTime = JewishHelper.removeAmPmInHebrew(shabbosEndTime, language);

    return language === Languages.Hebrew ? `${shabbosHebrewTitle}: ${shabbosEndTime}` : `${shabbosTitle}: ${shabbosEndTime}`;
  }


  /**
   * Returns the Rabeinu Tam time for the nearest Shabbat/Chag.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static rabeinuTam(config: HalachicTimesConfig): string {
    const { date, language, options, times, timeFormat, calendar, dayLink } = config;

    const modifiedCalendar = JewishHelper.getJewishDateByDayLink(calendar, dayLink);
    const yomTovThisWeek = JewishHelper.isYomTovThisWeek(date, modifiedCalendar);

    let rabeinuTamTime,
      rabeinuTamTitle,
      rabeinuTamHebrewTitle: string;

    if (!yomTovThisWeek) {
      rabeinuTamTitle = 'Sh. ends Rabeinu Tam';
      rabeinuTamHebrewTitle = 'מוצ"ש ר"ת';
      rabeinuTamTime = JewishHelper.getShabbosRelatedTime('rabenuTam', date, options, timeFormat, times);

    } else {
      const formattedOptions: Options = {
        ...options,
        date: yomTovThisWeek.getDate().toJSDate()
      };

      const yomTovDate = new JewishCalendar(yomTovThisWeek.getDate().toJSDate());
      if (yomTovDate.getYomTovIndex() === JewishCalendar.YOM_KIPPUR) {
        rabeinuTamTitle = 'Yom Kippur ends Rabeinu Tam';
        rabeinuTamHebrewTitle = 'יו"כ ר"ת';
      } else if (modifiedCalendar.isTomorrowShabbosOrYomTov()) {
        rabeinuTamTitle = 'Sh. ends Rabeinu Tam & Candle Light'
        rabeinuTamHebrewTitle = 'ר״ת והד״נ'
      } else {
        rabeinuTamTitle = 'Holiday ends Rabeinu Tam';
        rabeinuTamHebrewTitle = 'מוצ״ח ר״ת';
      }
      rabeinuTamTime = JewishHelper.getYomTovRelatedTime('rabenuTam', formattedOptions, times, timeFormat);
    }

    rabeinuTamTime = JewishHelper.removeAmPmInHebrew(rabeinuTamTime, language);

    return language === Languages.Hebrew ? `${rabeinuTamHebrewTitle}: ${rabeinuTamTime}` : `${rabeinuTamTitle}: ${rabeinuTamTime}`;
  }

  /**
   * Returns the fast end time for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static fastEnd(config: HalachicTimesConfig): string {

    const { calendar, language, options, times, timeFormat, dayLink } = config;

    if (calendar.getJewishMonth() === JewishCalendar.TISHREI && calendar.getJewishDayOfMonth() === 10) return null;

    const modifiedOptions: Options = {
      ...options,
      date: JewishHelper.getGregorianDateByDayLink(new Date(options.date as string), dayLink)
    };

    const fastEndTimeDto = times.find((time: KosherZmanimOptionDto) => time.key === 'fastEnd');
    const fastEndTime = JewishHelper.getSpecifiedTime(fastEndTimeDto);

    let formattedTime = JewishHelper.getFormattedLocaleTime(fastEndTime, modifiedOptions.timeZoneId, timeFormat);
    formattedTime = JewishHelper.removeAmPmInHebrew(formattedTime, language);

    return calendar.isTaanis()
      ? language === Languages.Hebrew ? `${fastEndTimeDto.he}: ${formattedTime}` : `${fastEndTimeDto.en}: ${formattedTime}`
      : null;
  }

  /**
   * Checks whether it is Veaneinu in the prayer on current/selected date and returns the correct title.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static veaneinu(config: HalachicTimesConfig): string {
    const { calendar, language, dayLink } = config;

    const modifiedCalendar = JewishHelper.getJewishDateByDayLink(calendar, dayLink);

    if (language === Languages.Hebrew) {
      return modifiedCalendar.isTaanis() && !modifiedCalendar.isAssurBemelacha() ? 'עננו' : null;
    } else {
      return modifiedCalendar.isTaanis() && !modifiedCalendar.isAssurBemelacha() ? 'Aneinu' : null;
    }
  }

  /**
   * Returns the Molad time for the nearest Rosh Chodesh.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static molad(config: HalachicTimesConfig): string {
    const { calendar, formatter, language, settings: { moladOnShabbos } } = config;

    const clonedCalendar = calendar.clone();
    clonedCalendar.forward(Calendar.MONTH, 1);

    const molad = clonedCalendar.getMolad();
    const moladMonth = formatter.format(clonedCalendar).split(' ')[1];
    const moladDay = formatter.formatDayOfWeek(molad);
    const moladTime = molad.getMoladHours() + ':' + molad.getMoladMinutes();

    const formattedMolad = language === Languages.Hebrew
      ? `מולד חודש ${moladMonth} יום ${moladDay} ${moladTime} ${molad.getMoladChalakim()} חל'`
      : `Molad ${moladMonth} ${moladDay} ${moladTime} & ${molad.getMoladChalakim()} chalakim`;

    if (moladOnShabbos && calendar.isShabbosMevorchim()) {
      return formattedMolad;
    } else if (!moladOnShabbos && JewishHelper.isBetweenShabbosMevorchimAndMolad(calendar, molad)) {
      return formattedMolad;
    }

    return null;
  }

  /**
   * Checks whether it is Nachem in the prayer on current/selected date and returns the correct title.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static nachem(config: HalachicTimesConfig): string {
    const { language, calendar, dayLink } = config;

    const modifiedCalendar = JewishHelper.getJewishDateByDayLink(calendar, dayLink);

    const nachemTitle = language === Languages.Hebrew ? 'נחם בתפילת מנחה' : 'Nachem at Mincha';
    const is10BeAvOnSunday = modifiedCalendar.getJewishMonth() === JewishCalendar.AV &&
      modifiedCalendar.getJewishDayOfMonth() === 10 &&
      modifiedCalendar.getDayOfWeek() === 1

    if (modifiedCalendar.getDayOfWeek() !== 7 && (JewishHelper.is9BeAv(modifiedCalendar) || is10BeAvOnSunday)) {
      return nachemTitle;
    }

    return null;
  }

  /**
   * Returns the Haftara for the nearest Shabbos/Chag.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static haftara(config: HalachicTimesConfig): string {
    const { haftara, haftaraCaption, language } = config;

    const haftaraCaptionEn = haftaraCaption && !!haftara ? 'Haftara: ' : '';
    const haftaraCaptionHe = haftaraCaption && !!haftara ? 'הפטרה: ' : '';

    return language === Languages.Hebrew ? `${haftaraCaptionHe}${haftara}` : `${haftaraCaptionEn}${haftara}`;
  }

  /**
   * Returns the Rambam (3 halachot) for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static rambam3(config: HalachicTimesConfig): string {
    const { language, studies } = config;
    return language === Languages.Hebrew ? `רמב"ם ג' פרקים: ${studies?.['5']}` : `Rambam 3 Chapters: ${studies?.['5']}`;
  }

  /**
   * Returns the Rambam (1 halacha) for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static rambam1(config: HalachicTimesConfig): string {
    const { language, studies } = config;

    return language === Languages.Hebrew ? `רמב"ם פרק א': ${studies?.['4']}` : `Rambam 1 Chapter: ${studies?.['4']}`;
  }

  /**
   * Returns the Rambam Sefer Hamitzvos for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static rambamSeferHamitzvos(config: HalachicTimesConfig): string {
    const { language, studies } = config;
    return language === Languages.Hebrew ? `ספר המצוות: ${studies?.['3']}` : `Sefer Hamitzvos: ${studies?.['3']}`;
  }

  /**
   * Returns the Tehilim for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static tehilim(config: HalachicTimesConfig): string {
    const { language, studies } = config;
    return language === Languages.Hebrew ? `תהילים: ${studies?.['0']}` : `Tehilim Yomi: ${studies?.['0']}`;
  }

  /**
   * Returns the Tania for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static tania(config: HalachicTimesConfig): string {
    const { language, studies } = config;
    return language === Languages.Hebrew ? `תניא: ${studies?.['1']}` : `Tania: ${studies?.['1']}`;
  }

  /**
   * Returns the Chumash for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static chumash(config: HalachicTimesConfig): string {
    const { language, studies } = config;
    const chumash = studies?.['2'].replace('חומש,', '');
    return language === Languages.Hebrew ? `חומש: ${chumash}` : `Chumash: ${chumash}`;
  }

  /**
   * Returns the Pirkei Avot for the current/selected date.
   * @param {HalachicTimesConfig} config - halachic times config.
   */
  public static pirkeiAvot(config: HalachicTimesConfig): string {
    const avot = config.avot;
    return avot ? avot[0] : null;
  }

  /* ---------------------------------------------------- Helper methods ------------------------------------------------------------------------ */

  private static getLongOmer(omerDay: number, minhag: Minhags): string {
    const days = omerDay > 10 ? ' יום' : ' ימים';
    const weeks = WEEKS[Math.floor(omerDay / 7)];
    const remainder = omerDay > 7 ? DAYS_REMAINDER[omerDay % 7] : '';
    const omerPrefix = minhag === Minhags.Ashkenaz ? 'ב' : 'ל';

    return [Minhags.Ashkenaz, Minhags.Sfarad, Minhags.Chabad].includes(minhag)
      ? days + weeks + remainder + ` ${omerPrefix}עומר`
      : days + ` ${omerPrefix}עומר` + weeks + remainder;
  }

  private static setShabbos(date: Date): void {
    const diff = 6 - date.getDay();
    date.setDate(date.getDate() + diff);
  }

  private static setErevShabbos(date: Date): void {
    const day = date.getDay();
    const diff = day === 6 ? 6 : 5 - day;

    date.setDate(date.getDate() + diff);
  }

  private static getSpecifiedTime(timeDto: KosherZmanimOptionDto, dayLink: number = null): Date {

    return dayLink !== null
      ? new Date(JewishHelper.halachicTimesTwoDays[dayLink][timeDto.option])
      : new Date(JewishHelper.halachicTimes[timeDto.option]);
  }

  private static getFormattedLocaleTime(date: Date, timeZone: string, timeFormat: TimeFormat): string {
    return date.toLocaleTimeString('en-GB', {
      timeZone,
      hour12: timeFormat === TimeFormat._12H,
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  private static isPurim(calendar: JewishCalendar, options: Options): boolean {
    const date = calendar.getJewishDayOfMonth();
    const month = calendar.getJewishMonth();
    return !options.locationName.includes('Jerusalem')
      && ((!calendar.isJewishLeapYear() && month === JewishDate.ADAR && date === 14)
        || (calendar.isJewishLeapYear() && month === JewishDate.ADAR_II && date === 14))
      || options.locationName.includes('Jerusalem')
      && ((!calendar.isJewishLeapYear() && month === JewishDate.ADAR && date === 15)
        || (calendar.isJewishLeapYear() && month === JewishDate.ADAR_II && date === 15));
  }

  @Memoize
  public static getShabbosRelatedTime<T = string>(key: string, date: Date, options: Options, timeFormat: TimeFormat, times: KosherZmanimOptionDto[], isCandlelight = false, returnType: 'string' | 'date' = 'string'): T {

    const selectedDate = new Date(date);
    if (isCandlelight) {
      JewishHelper.setErevShabbos(selectedDate);
    } else {
      JewishHelper.setShabbos(selectedDate);
    }

    const formattedOptions: Options = {
      ...options,
      date: selectedDate
    };

    const halachicTimes: KosherZmanimObject = getZmanimJsonMemoized(formattedOptions).Zmanim as KosherZmanimObject;
    const option = times.find((timeDto: KosherZmanimOptionDto) => timeDto.key === key).option;

    const time = JewishHelper.getTimeOfShabosByKey(option, times, halachicTimes);
    const localTimeZoneString = JewishHelper.getFormattedLocaleTime(time, formattedOptions.timeZoneId, timeFormat)
    if (returnType === 'string') {
      return localTimeZoneString as unknown as T;;
    } else if (returnType === 'date') {
      const localTimeZoneStringSplited = localTimeZoneString.split(':');
      time.setHours(+localTimeZoneStringSplited[0], +localTimeZoneStringSplited[1]);
      return time as unknown as T;
    }
  }

  public static getTodaysRelatedTime<T = string>(key: string, formattedOptions: Options, times: KosherZmanimOptionDto[], timeFormat: TimeFormat, returnType: 'string' | 'date' = 'string'): T {
    const halachicTimes: KosherZmanimObject = getZmanimJsonMemoized(formattedOptions).Zmanim as KosherZmanimObject;
    const option = times.find((timeDto: KosherZmanimOptionDto) => timeDto.key === key).option;

    const time = JewishHelper.getTimeOfShabosByKey(option, times, halachicTimes);

    const localTimeZoneString = JewishHelper.getFormattedLocaleTime(time, formattedOptions.timeZoneId, timeFormat)
    if (returnType === 'string') {
      return localTimeZoneString as unknown as T;;
    }
    else if (returnType === 'date') {
      const localTimeZoneStringSplited = localTimeZoneString.split(':');
      time.setHours(+localTimeZoneStringSplited[0], +localTimeZoneStringSplited[1]);
      return time as unknown as T;
    }
  }


  private static getYomTovRelatedTime(key: string,
    formattedOptions: Options,
    times: KosherZmanimOptionDto[],
    timeFormat: TimeFormat): string {

    if (key === 'candleLight') {
      const date = new Date(formattedOptions.date as string);
      date.setDate(date.getDate() - 1);
      formattedOptions = {
        ...formattedOptions,
        date: new Date(date)
      }
    }

    const halachicTimes: KosherZmanimObject = getZmanimJsonMemoized(formattedOptions).Zmanim as KosherZmanimObject;
    const option = times.find((timeDto: KosherZmanimOptionDto) => timeDto.key === key).option;

    const time = JewishHelper.getTimeOfShabosByKey(option, times, halachicTimes);

    return JewishHelper.getFormattedLocaleTime(time, formattedOptions.timeZoneId, timeFormat);
  }


  private static inTheMiddleOf(currentDate: JewishDate, dateA: JewishDate, dateB: JewishDate): boolean {
    return currentDate.compareTo(dateA) >= 0 && currentDate.compareTo(dateB) <= 0;
  }

  private static isLeapYear(year: number): boolean {
    return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
  }

  public static isJewishLeapYear(year: number): boolean {
    const leapYears = [3, 6, 8, 11, 14, 17, 0];
    let y = leapYears.includes(year % 19);
    return y
  }

  private static is9BeAv(calendar: JewishCalendar): boolean {
    return calendar.getJewishMonth() === JewishCalendar.AV && calendar.getJewishDayOfMonth() === 9;
  }

  private static isHoshanaRaba(calendar: JewishCalendar): boolean {
    return calendar.getJewishMonth() === JewishCalendar.TISHREI && calendar.getJewishDayOfMonth() === 21;
  }

  private static isBetweenShabbosMevorchimAndMolad(calendar: JewishCalendar, molad: JewishDate): boolean {
    return calendar.compareTo(JewishHelper.getShabbosMevorchim(calendar)) >= 0 && calendar.compareTo(molad) <= 0;
  }

  private static getShabbosMevorchim(calendar: JewishCalendar): JewishDate {
    const diff = calendar.getDaysInJewishMonth() - calendar.getJewishDayOfMonth();
    const clonedCalendar = calendar.clone();
    clonedCalendar.forward(Calendar.DATE, diff + 1);

    while (clonedCalendar.getDayOfWeek() !== 7) {
      clonedCalendar.back();
    }

    return clonedCalendar;
  }

  private static getNextRoshChodesh(calendar: JewishCalendar, formatter: HebrewDateFormatter, language: Languages): string {
    const diff = calendar.getDaysInJewishMonth() - calendar.getJewishDayOfMonth();
    const clonedCalendar = calendar.clone();
    clonedCalendar.forward(Calendar.DATE, diff);

    if (clonedCalendar.getJewishDayOfMonth() === 29) {
      clonedCalendar.forward(Calendar.DATE, 1);
    }

    const dayOfWeek = formatter.formatDayOfWeek(clonedCalendar);
    if (clonedCalendar.getJewishDayOfMonth() === 1) {
      return language === Languages.Hebrew ? `ביום ${dayOfWeek}` : `on ${dayOfWeek}`;
    }

    clonedCalendar.forward(Calendar.DATE, 1);
    const secondDay = formatter.formatDayOfWeek(clonedCalendar);
    return language === Languages.Hebrew ? `ביום ${dayOfWeek} וביום ${secondDay}` : `on ${dayOfWeek} & ${secondDay}`;
  }

  public static getJewishDateByDayLink(date: JewishDate, dayLink): JewishCalendar {
    const clonedDate = date.clone();

    if (dayLink > 0) {
      clonedDate.forward(Calendar.DATE, dayLink);
      return new JewishCalendar(clonedDate.getDate());
    }

    return new JewishCalendar(date.getDate());
  }

  public static getGregorianDateByDayLink(date: Date, dayLink): Date {
    return dayLink > 0 ? new Date(date.setDate(date.getDate() + 1)) : date;
  }

  private static removeAmPmInHebrew(value: string, language: Languages): string {
    return language === Languages.Hebrew ? value.replace(/\s(?:AM|PM|am|pm)/, '') : value;
  }

  private static isYomTovThisWeek(date: Date, calendar: JewishCalendar): false | JewishCalendar {
    if (JewishHelper.isYomTov(calendar)) {
      return calendar;
    }

    const jewishDate = calendar.clone();
    const diff = 6 - date.getDay();

    for (let i = 1; i <= diff; i++) {
      jewishDate.forward(Calendar.DATE, 1);
      const testedDate = new JewishCalendar(jewishDate.getJewishYear(), jewishDate.getJewishMonth(), jewishDate.getJewishDayOfMonth());
      if (JewishHelper.isYomTov(testedDate)) {
        return testedDate;
      }
    }
    return false;
  }

  private static isYomTov(calendar: JewishCalendar): boolean {
    return calendar.isAssurBemelacha() && calendar.getDayOfWeek() !== 7;
  }

  private static getTimeOfShabosByKey(option: KosherZmanimKey, times: KosherZmanimOptionDto[], halachicTimes: KosherZmanimObject): Date {
    let time: Date;

    switch (option) {
      case 'Minutes_15':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, -15);
        break;
      case 'Minutes_18':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, -18);
        break;
      case 'Minutes_20':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, -20);
        break;
      case 'Minutes_22':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, -22);
        break;
      case 'Minutes_24':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, -24);
        break;
      case 'Minutes_30':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, -30);
        break;
      case 'Minutes_40':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, -40);
        break;
      case 'Minutes18':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, 18);
        break;
      case 'Minutes20':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, 20);
        break;
      case 'Minutes35':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, 35);
        break;
      case 'Minutes40':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, 40);
        break;
      case 'Minutes45':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, 45);
        break;
      case 'Minutes60':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, 60);
        break;
      case 'Minutes72':
        time = JewishHelper.getTimeBySunset(times, halachicTimes, 72);
        break;
      case 'TchilasZmanKidushLevana7Days':
        time = new Date(halachicTimes['TchilasZmanKidushLevana7Days']);
        break;
      // rabenuTam
      case 'Tzais72':
        time = new Date(halachicTimes['Tzais72']);
        break;
      case 'Tzais16Point1Degrees':
        time = new Date(halachicTimes['Tzais16Point1Degrees']);
        break;
      case 'Tzais72Zmanis':
        time = new Date(halachicTimes['Tzais72Zmanis']);
        break;
      // fastEnd
      case 'TzaisGeonim3Point65Degrees':
        time = new Date(halachicTimes['TzaisGeonim3Point65Degrees']);
        break;
      case 'TzaisGeonim3Point676Degrees':
        time = new Date(halachicTimes['TzaisGeonim3Point676Degrees']);
        break;
      case 'TzaisGeonim3Point7Degrees':
        time = new Date(halachicTimes['TzaisGeonim3Point7Degrees']);
        break;
      case 'TzaisGeonim3Point8Degrees':
        time = new Date(halachicTimes['TzaisGeonim3Point8Degrees']);
        break;
      case 'TzaisGeonim4Point37Degrees':
        time = new Date(halachicTimes['TzaisGeonim4Point37Degrees']);
        break;
      case 'TzaisGeonim4Point61Degrees':
        time = new Date(halachicTimes['TzaisGeonim4Point61Degrees']);
        break;
      case 'TzaisGeonim4Point8Degrees':
        time = new Date(halachicTimes['TzaisGeonim4Point8Degrees']);
        break;
      case 'TzaisGeonim5Point88Degrees':
        time = new Date(halachicTimes['TzaisGeonim5Point88Degrees']);
        break;
      case 'TzaisGeonim5Point95Degrees':
        time = new Date(halachicTimes['TzaisGeonim5Point95Degrees']);
        break;
      case 'TzaisGeonim6Point45Degrees':
        time = new Date(halachicTimes['TzaisGeonim6Point45Degrees']);
        break;
      case 'TzaisGeonim7Point083Degrees':
        time = new Date(halachicTimes['TzaisGeonim7Point083Degrees']);
        break;
      case 'TzaisGeonim7Point67Degrees':
        time = new Date(halachicTimes['TzaisGeonim7Point67Degrees']);
        break;
      case 'TzaisGeonim8Point5Degrees':
        time = new Date(halachicTimes['TzaisGeonim8Point5Degrees']);
        break;
      case 'TzaisGeonim9Point3Degrees':
        time = new Date(halachicTimes['TzaisGeonim9Point3Degrees']);
        break;
      case 'TzaisGeonim9Point75Degrees':
        time = new Date(halachicTimes['TzaisGeonim9Point75Degrees']);
        break;
      case 'Tzais16Point1Degrees':
        time = new Date(halachicTimes['Tzais16Point1Degrees']);
        break;
      case 'Tzais18Degrees':
        time = new Date(halachicTimes['Tzais18Degrees']);
        break;
      case 'Tzais26Degrees':
        time = new Date(halachicTimes['Tzais26Degrees']);
        break;
      default:
        time = new Date(halachicTimes['Sunset']);
        break;
    }

    return time;
  }

  private static getLongDay(omerDay): String {
    switch (omerDay) {
      case 1:
        omerDay = 'יום אחד';
        break;
      case 2:
        omerDay = 'שני';
        break;
      case 3:
        omerDay = 'שלשה';
        break;
      case 4:
        omerDay = 'ארבעה';
        break;
      case 5:
        omerDay = 'חמשה';
        break;
      case 6:
        omerDay = 'ששה';
        break;
      case 7:
        omerDay = 'שבעה';
        break;
      case 8:
        omerDay = 'שמונה';
        break;
      case 9:
        omerDay = 'תשעה';
        break;
      case 10:
        omerDay = 'עשרה';
        break;
      case 11:
        omerDay = 'אחד עשר';
        break;
      case 12:
        omerDay = 'שנים עשר';
        break;
      case 13:
        omerDay = 'שלשה עשר';
        break;
      case 14:
        omerDay = 'ארבעה עשר';
        break;
      case 15:
        omerDay = 'חמשה עשר';
        break;
      case 16:
        omerDay = 'ששה עשר';
        break;
      case 17:
        omerDay = 'שבעה עשר';
        break;
      case 18:
        omerDay = 'שמונה עשר';
        break;
      case 19:
        omerDay = 'תשעה עשר';
        break;
      case 20:
        omerDay = 'עשרים';
        break;

        omerDay = 'אחד ועשרים';
        break;
      case 22:
        omerDay = 'שנים ועשרים';
        break;
      case 23:
        omerDay = 'שלושה ועשרים';
        break;
      case 24:
        omerDay = 'ארבעה ועשרים';
        break;
      case 25:
        omerDay = 'חמשה ועשרים';
        break;
      case 26:
        omerDay = 'ששה ועשרים';
        break;
      case 27:
        omerDay = 'שבעה ועשרים';
        break;
      case 28:
        omerDay = 'שמונה ועשרים';
        break;
      case 29:
        omerDay = 'תשעה ועשרים';
        break;
      case 30:
        omerDay = 'שלושים';
        break;
      case 31:
        omerDay = 'אחד ושלושים';
        break;
      case 32:
        omerDay = 'שנים ושלושים';
        break;
      case 33:
        omerDay = 'שלושה ושלושים';
        break;
      case 34:
        omerDay = 'ארבעה ושלושים';
        break;
      case 35:
        omerDay = 'חמשה ושלושים';
        break;
      case 36:
        omerDay = 'ששה ושלושים';
        break;
      case 37:
        omerDay = 'שבעה ושלושים';
        break;
      case 38:
        omerDay = 'שמונה ושלושים';
        break;
      case 39:
        omerDay = 'תשעה ושלושים';
        break;
      case 40:
        omerDay = 'ארבעים';
        break;
      case 41:
        omerDay = 'אחד וארבעים';
        break;
      case 42:
        omerDay = 'שנים וארבעים';
        break;
      case 43:
        omerDay = 'שלושה וארבעים';
        break;
      case 44:
        omerDay = 'ארבעה וארבעים';
        break;
      case 45:
        omerDay = 'חמשה וארבעים';
        break;
      case 46:
        omerDay = 'ששה וארבעים';
        break;
      case 47:
        omerDay = 'שבעה וארבעים';
        break;
      case 48:
        omerDay = 'שמונה וארבעים';
        break;
      case 49:
        omerDay = 'תשעה וארבעים';
        break;
    }
    return omerDay
  }
  private static getTimeBySunset(times: KosherZmanimOptionDto[], halachicTimes: KosherZmanimObject, minutes: number): Date {
    const sunsetTimeDto = times.find((timeDto: KosherZmanimOptionDto) => timeDto.key === 'sunset');

    const time = new Date(halachicTimes[sunsetTimeDto.option]);
    time.setMinutes(time.getMinutes() + minutes);
    return time;
  }
}

