import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { UserDto } from '../../../_dto/user-dto';
import { Languages } from '../../../_enums/languages';
import { AccountHelper } from '../../../_helpers/account.helper';
import { User } from '../../../_models/identity/user';
import { AccountService } from '../../../_services/account.service';
import { ContentApiService } from '../../../_services/content-api.service';
import { EditService } from '../../../_services/edit.service';
import { LocalStorage } from '../../../_enums/local-storage';
import { SettingsService } from '../../../_services/settings.service';
import { SnackbarService } from '../../../_services/snackbar.service';
import { WidgetService } from '../../../_services/widget.service';
import { SettingsComponent } from '../../settings/settings/settings.component';
import { hasPermission } from 'src/app/_helpers/roleToPermissionTranslator';
import { PermissionTypes } from 'src/app/_models/identity/PermissionTypes';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit {
  public direction = 'ltr';
  public editMode: boolean;
  public isAdmin = false;
  public hasModeratorPermissions = false;
  public isOrganisationOpen = false;
  public username: string;
  public email: string;
  public userTitle = 'User: ';
  public emailTitle = 'Email: ';
  public organisationTitle = 'Organisation: ';
  public organisationName = '';

  constructor(
    private dialog: MatDialog,
    private contentApiService: ContentApiService,
    private accountService: AccountService,
    private snackBarService: SnackbarService,
    private translate: TranslateService,
    private editService: EditService,
    private widgetService: WidgetService,
    private settingsService: SettingsService
  ) {
    translate.setDefaultLang(Languages.English);
    const language = localStorage.getItem(LocalStorage.Language) || Languages.English;
    translate.use(language);
  }

  public ngOnInit(): void {
    this.selectLanguage();

    this.accountService.currentUser$
      .subscribe((user: User) => {
        this.isAdmin = AccountHelper.isAdmin();
        this.username = user?.username || '';
        this.email = user?.email || '';
      });

    this.accountService.getUser().subscribe((user: UserDto) => {
      if (user) {
        this.isOrganisationOpen = user.isOrganizationOpen;
        this.hasModeratorPermissions = hasPermission(PermissionTypes.ManageWidgets)
        this.organisationName = user?.organisationName || '';
      }
    });

    this.editService.editMode$.subscribe(mode => this.editMode = mode);
  }

  /**
   * Toggles the Edit mode.
   */
  public toggleEditMode(): void {
    this.editMode = !this.editMode;
    this.editService.setEditMode(this.editMode);
  }

  /**
   * Logs out, emits empty screen and clears all the caches.
   */
  public logout(): void {
    this.accountService.logout();
    this.widgetService.updateScreen(null);
    this.accountService.clearUsersCache();
    this.accountService.clearUserCache();
    this.contentApiService.clearOrganisationsCache();
    this.widgetService.clearScreensCache();
  }

  /**
   * Opens settings dialogue window, and reloads on closure.
   * @private
   */
  public openSettings(): void {
    if (!!this.settingsService.settingsDialogWindowRef) return;

    this.settingsService.settingsDialogWindowRef = this.dialog.open(SettingsComponent);
    this.settingsService.settingsDialogWindowRef.afterClosed()
      .subscribe(updated => {
        this.settingsService.clearSettingsDialogWindowReference();
        if (updated) {
          location.reload();
          // TODO implement reload without page refreshing
          // this.accountService.clearUserCache();
          // this.accountService.getUser().subscribe();
          // this.widgetService.updateScreen(this.screen);
        }
      });
  }

  /**
   * Determines current language and sets titles and the text direction.
   * @private
   */
  private selectLanguage(): void {
    if (this.translate.currentLang === Languages.Hebrew) {
      this.direction = 'rtl';
      this.userTitle = 'משתמש: ';
      this.emailTitle = 'דוא\"ל: ';
      this.organisationTitle = 'ארגון: '
    } else {
      this.direction = 'ltr';
      this.userTitle = this.translate.currentLang === Languages.English ? 'User: ' : 'Пользователь: ';
      this.emailTitle = this.translate.currentLang === Languages.English ? 'Email: ' : 'Эл. почта: ';
      this.organisationTitle = this.translate.currentLang === Languages.English ? 'Organisation: ' : 'Организация: ';
    }

    this.translate.onLangChange.subscribe(({ lang }) => {
      if (lang === Languages.Hebrew) {
        this.direction = 'rtl';
        this.userTitle = 'משתמש: ';
        this.emailTitle = 'דוא\"ל: ';
      } else {
        this.direction = 'ltr';
        this.userTitle = lang === Languages.English ? 'User: ' : 'Пользователь: ';
        this.emailTitle = this.translate.currentLang === Languages.English ? 'Email: ' : 'Эл. почта: ';
      }
    });
  }
}
