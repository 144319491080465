import { Effect, EffectTypes, ScrollDirections } from '../_models/effect';
import { Widget } from '../_models/widget';

export class ScrollHelper {

  /**
   * Determines whether the scroll is needed for auto effect purpose (if the content width is greater than the wrapper width).
   * @param {HTMLElement} wrapperElement - HTML element to be checked.
   * @param {ScrollDirections} direction - scroll direction (enum).
   * @param {boolean} singleItemCheck - whether to check scroll requirement by single element or element scrollWidth, default is false.
   */
  public static isScrollRequired(wrapperElement: HTMLElement, direction: ScrollDirections, singleItemCheck: boolean = false): boolean {
    const singleItemElement = wrapperElement.children[0].children[0] as HTMLDivElement;

    return !singleItemCheck
      ? [ScrollDirections.Left, ScrollDirections.Right].includes(direction)
        ? wrapperElement.scrollWidth > wrapperElement.clientWidth
        : wrapperElement.scrollWidth > wrapperElement.clientHeight
      : [ScrollDirections.Left, ScrollDirections.Right].includes(direction)
        ? singleItemElement.clientWidth > wrapperElement.clientWidth
        : singleItemElement.clientHeight > wrapperElement.clientHeight;
  }

  /**
   * Duplicates HTML element
   * @param {number} itemsPerView Number of viewable items
   * @param {HTMLElement} scrollElement Element to duplicate
   * @param {HTMLElement} parentElement Parent element to attach the clone
   * @param {string} justifyContent [optional] To set CSS justify-content property
   */
  public static duplicateHtmlElement(
    itemsPerView: number,
    scrollElement: HTMLElement,
    parentElement: HTMLElement,
    justifyContent: string = 'center'
  ): void {
    for (let i = 0; i < itemsPerView * 2 + 2; i++) {
      if (i > 20) break;
      this.appendNewClone(scrollElement, parentElement, justifyContent);
    }
  }

  /**
   * Determines whether there was a change in an HTML element in order to run the callback
   * @param {HTMLElement} element HTML element in question
   * @param {() => void} callback Callback function to run on change
   */
  public static runMutationObserver(element: HTMLElement, callback: () => void): void {
    const config = { attributes: true, childList: true, subtree: true };

    const mutationObserver = new MutationObserver((mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          callback();
          break;
        }
      }
    });
    mutationObserver.observe(element, config);
  }

  /**
   * Appends a new clone
   * @param {HTMLElement} scrollElement Element to duplicate
   * @param {HTMLElement} parentElement Parent element to attach the clone
   * @param {string} justifyContent To set CSS justify-content property
   * @private
   */
  private static appendNewClone(
    scrollElement: HTMLElement,
    parentElement: HTMLElement,
    justifyContent: string = 'center'
  ): void {
    const newClone = scrollElement.cloneNode(true) as HTMLDivElement;
    newClone.classList.add('jewish-item-clone');
    if (justifyContent) {
      this.justify(newClone, justifyContent);
    }
    parentElement.appendChild(newClone);
  }

  /**
   * Determines whether justify-content or text-align CSS property should be set
   * @param {HTMLElement} element HTML element in question
   * @param {string} justifyContent The property value
   * @private
   */
  private static justify(element: HTMLElement, justifyContent: string): void {
    if (justifyContent !== 'justify') {
      element.style.justifyContent = justifyContent;
    } else {
      element.style.textAlign = 'justify';
    }
  }

  static isHorizontal(scrollDirection: ScrollDirections): boolean {
    return scrollDirection === ScrollDirections.Left || scrollDirection === ScrollDirections.Right;
  }

  static isVertical(scrollDirection: ScrollDirections): boolean {
    return scrollDirection === ScrollDirections.Up || scrollDirection === ScrollDirections.Down;
  }

  /**
   * Scrolls the widget if the needed it will stop the widget from scrolling if not.
   * @returns {[number, number]} An array with the number of items per view at the first index and the scroll speed at the second index.
   */
  static scrollIfShould = (scrollElement: HTMLElement, parentElement: HTMLElement, widget: Widget): [number, number] => {
    // Check if should be scrolled.
    return this.shouldScroll(widget.effect, scrollElement, parentElement)
      ? this.scroll(scrollElement, parentElement, widget)
      : this.doNotScroll();
  }

  /**
   * Determines if the widget should be scrolled based on the values of this.widget.effect.autoEffect and this.widget.effect.type.
   * @returns {boolean} - `true` if the widget should be scrolled, `false` otherwise.
   */
  static shouldScroll(effect: Effect, scrollElement: HTMLElement, parentElement: HTMLElement): boolean {
    // Return true if the widget should be scrolled
    return (effect.autoEffect === false && effect.type === EffectTypes.Scroll)
      || (effect.autoEffect === true && this.shouldScrollAutoEffect(effect.scrollDirection, scrollElement, parentElement));
  }

  /**
   * Determines if the widget should be scrolled when this.widget.effect.autoEffect is true.
   * @returns {boolean} - `true` if the widget should be scrolled, `false` otherwise.
   */
  static shouldScrollAutoEffect = (scrollDirection: ScrollDirections, scrollElement: HTMLElement, parentElement: HTMLElement): boolean => {
    /*
     * Return false if the height of the first child element of the parent is less than the height of the single item element.
     * We only want to scroll the widget if the height of the single item is bigger than the height of the parent.
     * This is because when the widget is already scrolling, it duplicates the items.
     * We need to check the height of a single item, rather than just the scrolled items, to avoid this duplication.
     */

    if (this.isVertical(scrollDirection)) {
      return parentElement.offsetHeight > 0 && parentElement.offsetHeight > scrollElement.offsetHeight;
    } else {
      return parentElement.offsetHeight > 0 && parentElement.offsetHeight > scrollElement.offsetHeight;
    }
  }

  /**
   * Scrolls the widget.
   * @param {HTMLElement} scrollElement The scrolling element.
   * @param {HTMLElement} parentElement The element reference.
   * @param {Widget} widget An object containing the `scrollDirection` property.
   * @returns {[number, number]} An array with the number of items per view at the first index and the scroll speed at the second index.
   */
  static scroll = (scrollElement: HTMLElement, parentElement: HTMLElement, widget: Widget): [number, number] => {
    return [this.calculateItemsPerView(scrollElement, parentElement, widget), this.changeScrollSpeed(scrollElement, widget)];
  }

  /**
   * Stops the widget from scrolling.
   * @returns {[number, number]} An array with the number of items per view set to 1 and the scroll speed set to 0.
   */
  static doNotScroll = (): [number, number] => [1, 0];

  /**
   * Calculates the number of items that should be displayed per view based on the dimensions of the scrolling element
   * and the parent container element.
   * @returns {void}
   * @private
   */
  static calculateItemsPerView = (scrollElement: HTMLElement, parentElement: HTMLElement, widget: Widget): number => {
    // Calculate the number of items that should be displayed per view based on the scroll direction
    switch (widget.effect.scrollDirection) {
      case ScrollDirections.Up:
      case ScrollDirections.Down:
        const containerElementHeight = scrollElement.offsetHeight;
        const singleScrolledItemHeight = parentElement.offsetHeight;
        return (containerElementHeight && singleScrolledItemHeight) ? this.calculateIterationsToFillContainer(containerElementHeight, singleScrolledItemHeight) + 1 : 1;
      case ScrollDirections.Left:
      case ScrollDirections.Right:
        const containerElementWidth = scrollElement.clientWidth;
        const singleScrolledItemWidth = parentElement.scrollWidth;
        return (containerElementWidth && singleScrolledItemWidth) ? this.calculateIterationsToFillContainer(containerElementWidth, singleScrolledItemWidth) + 1 : 1;
      default:
        return 3;
    }
  }

  static calculateIterationsToFillContainer(singleScrolledItemSize: number, containerElementSize: number): number {
    return Math.ceil((singleScrolledItemSize) / (containerElementSize) * 2);
  }

  /**
   * Adjusts the scroll speed based on the dimensions of the scrolling element and the widget scroll speed.
   */
  static changeScrollSpeed(scrollElement: HTMLElement, widget: Widget): number {
    const scrollSpeed = widget.effect.scrollSpeed;
    const isHorizontal = [ScrollDirections.Left, ScrollDirections.Right].includes(widget.effect.scrollDirection);

    const scrollSpeedAdjustment = this.calculateScrollSpeed(scrollElement, isHorizontal, scrollSpeed);

    return Math.round(scrollSpeedAdjustment * 10) / 10;
  }

  /**
   * Calculates a scroll speed based on an element and widget scroll speed.
   * @param element The element being scrolled.
   * @param isHorizontal A flag indicating whether the scroll direction is horizontal or vertical.
   * @param scrollSpeed The scroll speed of the widget.
   * @returns The scroll speed.
   */
  static calculateScrollSpeed(singleItem: HTMLElement, isHorizontal: boolean, scrollSpeed: number): number {
    return isHorizontal
      ? (((singleItem.offsetWidth / 100) / scrollSpeed) || 3) * 20
      : (((singleItem.offsetHeight / 100) / scrollSpeed) || 3) * 20;
  }
}
