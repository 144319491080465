import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';

import packageJosn from '../package.json';

document.title = `Beezee Systems ${packageJosn.version}`;

const tracker = new Tracker({
  projectKey: "ABrHT5bhViMWrk4aZlbZ",
  captureExceptions: true,
  verbose: true,
  __debug__: true,
  __DISABLE_SECURE_MODE: true,
});
console.log(packageJosn.version);
tracker.start({});
tracker.use(trackerAssist({
  confirmText: 'Can you please give me access?',
}));

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function scheduleReload() {
  const now = new Date();
  const nextMidnight = new Date();
  nextMidnight.setHours(24, 0, 0, 0);

  const timeUntilMidnight = nextMidnight.getTime() - now.getTime();

  setTimeout(() => {
    location.reload();
    setInterval(() => {
      location.reload();
    }, 24 * 60 * 60 * 1000);
  }, timeUntilMidnight);
}

scheduleReload();
