<div class="left-panel" [style.direction]="direction">
  <div [title]="isAdmin ? 'ADMIN' : userTitle + username + '&#13;' + emailTitle + email + '&#13;' + organisationTitle + organisationName"
       class="menu panel-item">
    <mat-icon>menu</mat-icon>
  </div>
  <ul>
    <li class="panel-item" *ngIf="hasModeratorPermissions || isOrganisationOpen"
        title="{{ 'menu.settings' | translate }}">
      <mat-icon (click)="openSettings()">settings</mat-icon>
    </li>
    <li class="panel-item" *ngIf="hasModeratorPermissions"
        title="{{ (!editMode ? 'menu.edit' : 'menu.stopEdit') | translate }}">
      <mat-icon (click)="toggleEditMode()">
        {{ !editMode ? 'edit' : 'edit_off' }}
      </mat-icon>
    </li>
    <li (click)="logout()"
        class="panel-item"
        title="{{ 'menu.logout' | translate }}">
      <mat-icon>logout</mat-icon>
    </li>
  </ul>
</div>
