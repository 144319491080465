<div class="settings container-sm d-flex justify-content-center align-items-center mx-2">
  <div class="w-50 d-flex justify-content-between align-items-center">
    <span>{{ 'jewishSettings.min' | translate }}</span>
    <mat-slide-toggle (change)="user.settings.shabbosEnd.isMinutes = !user.settings.shabbosEnd.isMinutes"
                      [checked]="!user.settings.shabbosEnd.isMinutes">
    </mat-slide-toggle>
    <span>{{ 'jewishSettings.deg' | translate }}</span>
  </div>
</div>

<div class="settings container-sm d-flex justify-content-between align-items-center">
  <mat-form-field [class.disabled]="!user.settings.shabbosEnd.isMinutes"
                  [style.direction]="direction"
                  [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-label>{{ 'jewishSettings.minutesAfter' | translate }}</mat-label>
    <mat-select [(value)]="user.settings.shabbosEnd.minutes">
      <mat-option *ngFor="let time of times" [value]="time">{{ time }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field [class.disabled]="user.settings.shabbosEnd.isMinutes"
                  [style.direction]="direction"
                  [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-label>{{ 'jewishSettings.selectOption' | translate }}</mat-label>
    <mat-select [(value)]="user.settings.shabbosEnd.option">
      <mat-option *ngFor="let timeOption of tzaisTimes"
                  [value]="timeOption.option"
                  [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        {{ direction === 'rtl' ? timeOption.he : timeOption.en }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
