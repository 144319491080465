import { PermissionTypes } from '../_models/identity/PermissionTypes';
import { hasPermission } from './roleToPermissionTranslator';

export class AccountHelper {

  /**
   * Checks whether the current user is admin.
   * @param {string} token - user's token.
   */
  public static isAdmin(): boolean {
    return hasPermission(PermissionTypes.Admin)
  }
}
