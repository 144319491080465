import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input, HostListener } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { WidgetTypes, Languages } from "src/app/_enums";
import { WidgetHelper, TranslateHelper } from "src/app/_helpers";
import { Widget, WidgetIcon, Content, EffectTypes, Screen, Effect } from "src/app/_models";
import { ConfirmationComponent } from "src/app/_modules/admin/admin-dashboard/user-list/confirmation/confirmation.component";
import { WidgetService, SnackbarService } from "src/app/_services";

@Component({
  selector: 'app-widget-settings',
  templateUrl: './widget-settings.component.html',
  styleUrls: ['./widget-settings.component.css']
})
export class WidgetSettingsComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;
  @Input() nav: NgbNav;
  @Input() editMode = false;

  public direction: Direction = 'ltr';
  public widgetIcon$: Observable<WidgetIcon>;
  public widgets = WidgetHelper.widgets;
  public disabledFontWidgetTypes = [WidgetTypes.Text, WidgetTypes.Clock];
  public disabledFontWidgetTypes1 = [WidgetTypes.Text];
  public isTextByDate: boolean = true;
  public widgetWithEffects = [WidgetTypes.Text, WidgetTypes.Jewish, WidgetTypes.Table];

  constructor(
    private widgetService: WidgetService,
    private dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private snackbar: SnackbarService,
  ) {
    for (const widget in this.widgets) {
      if (this.widgets.hasOwnProperty(widget)) {
        this.registerSvgImage(this.widgets[widget].icon);
      }
    }
    iconRegistry.addSvgIcon(
      'usa',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/united-states.svg'));
    iconRegistry.addSvgIcon(
      'israel',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/israel.svg'));
  }

  /**
   * Retrieves the filename from the URL.
   * @param {string} url - a URL to retrieve a filename from.
   * @private
   * @static
   */
  private static getFilenameFromUrl(url: string): string {
    if (!url) {
      return;
    }
    const arr = url.split('/');
    return arr[arr.length - 1];
  }

  public ngOnInit(): void {
    this.setTranslation();

    this.widgetIcon$ = this.widgetService.widgetIconDropped$;
  }

  /**
   * Handles the Del shortcut to delete the selected widget.
   * @param {KeyboardEvent} event - a keyboard event to determine whether Del button was pressed.
   */
  @HostListener('document:keydown.Delete', ['$event'])
  handleDeleteKeyboardEvent(event: KeyboardEvent) {
    if (!this.editMode || (event.target as HTMLElement).className === 'angular-editor-textarea') return;
    this.removeWidget();
  }

  /**
   * Deletes the widget and the files related to it.
   */
  public removeWidget(): void {
    const filesToRemove: string[] = [];
    const dialogRef: MatDialogRef<ConfirmationComponent> = this.dialog.open(ConfirmationComponent);

    dialogRef.componentInstance.config = {
      direction: 'ltr',
      message: this.translate.instant('snackbar.deleteWidget')
    };

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed === '') {

        if ([WidgetTypes.Media, WidgetTypes.PDF, WidgetTypes.Music].includes(this.widget.type)) {
          filesToRemove.push(WidgetSettingsComponent.getFilenameFromUrl(this.widget.background.imageUrl));
          this.widget.content.media?.items?.forEach(item => filesToRemove.push(WidgetSettingsComponent.getFilenameFromUrl(item?.url)));
          filesToRemove.push(WidgetSettingsComponent.getFilenameFromUrl(this.widget.content.pdf?.url));
        }

        this.screen.filesToRemove.push(...filesToRemove.filter(item => item));
        this.screen.widgets = this.screen.widgets.filter(widget => widget.id !== this.widget.id);
        this.widgetService.updateScreen(this.screen);
        this.nav.select('widgets');
      }
    });
  }

  /**
   * Duplicates the widget.
   */
  public duplicateWidget(): void {
    this.screen.dirty = true;
    let widgetDuplicate: Widget = { ...this.widget, contentSource: null, effectSource: null, contentChangedSource: null, resizeStopSource: null };
    widgetDuplicate = JSON.parse(JSON.stringify(widgetDuplicate));
    widgetDuplicate.contentSource = new Subject<Content>();
    widgetDuplicate.effectSource = new BehaviorSubject<Effect>({ type: EffectTypes.None });
    widgetDuplicate.contentChangedSource = new Subject<void>();
    widgetDuplicate.resizeStopSource = new Subject<IResizeEvent>();
    widgetDuplicate.id = WidgetHelper.getNewWidgetId(this.screen);
    widgetDuplicate.position.top = this.widget.position.top + 5;
    widgetDuplicate.position.left = this.widget.position.left + 5;
    this.screen.widgets.push(widgetDuplicate);
    this.widgetService.updateScreen(this.screen);
    this.snackbar.success(this.translate.instant('snackbar.widgetDuplicated'), this.translate.instant('buttons.ok'));
  }

  hideWidget(widget: Widget) {
    widget.isHidden = true;
  }

  showWidget(widget: Widget) {
    widget.isHidden = false;
  }

  toggleWidgetVisibility() {
    if (this.widget.isHidden === true) {
      this.showWidget(this.widget);
    } else {
      this.hideWidget(this.widget);
    }
  }


  /**
   * Registers the SVG image of specified filename.
   * @param {string} name - an image filename to register.
   * @private
   */
  private registerSvgImage(name: string): void {
    this.iconRegistry.addSvgIcon(
      name,
      this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/${name}.svg`));
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
