<div class="container" [style.direction]="direction">
  <mat-card-subtitle translate>generalSettings.subtitle</mat-card-subtitle>

  <mat-list>
    <mat-list-item>
      <div class="list-line">
        <div translate>generalSettings.language</div>

        <div>
          <mat-button-toggle-group #languagesGroup="matButtonToggleGroup">
            <mat-button-toggle
              (change)="language = $event.value"
              [checked]="language === languages.English"
              class="toggle-button"
              title="{{ 'generalSettings.en' | translate }}"
              value="en">
              <mat-icon svgIcon="usa"></mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
              (change)="language = $event.value"
              [checked]="language === languages.Hebrew"
              title="{{ 'generalSettings.he' | translate }}"
              class="toggle-button"
              value="he">
              <mat-icon svgIcon="israel"></mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
              (change)="language = $event.value"
              [checked]="language === languages.Russian"
              title="{{ 'generalSettings.ru' | translate }}"
              class="toggle-button"
              value="ru">
              <mat-icon svgIcon="russia"></mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="!isAdmin">
      <div class="list-line">
        <div translate>generalSettings.timeFormat</div>
        <div class="w-25 d-flex justify-content-between align-items-center">
          <span>{{ 'generalSettings.12h' | translate }}</span>
          <mat-slide-toggle (change)="toggleTimeFormat($event.checked)"
                            [checked]="user.timeFormat === timeFormat._24H">
          </mat-slide-toggle>
          <span>{{ 'generalSettings.24h' | translate }}</span>
        </div>
      </div>
    </mat-list-item>
    <div class="message" translate>generalSettings.message</div>
  </mat-list>
</div>

<div *ngIf="!isAdmin" mat-dialog-actions class="d-flex justify-content-center w-100 position-absolute actions" [style.direction]="direction">
  <button (click)="save()"
          color="primary"
          mat-raised-button
          matDialogClose
          type="submit">
    {{ 'buttons.ok' | translate }}
  </button>
</div>
