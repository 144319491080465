import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgbCalendar, NgbCalendarHebrew, NgbDatepickerI18n, NgbDatepickerI18nHebrew } from "@ng-bootstrap/ng-bootstrap";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { GridsterModule } from "angular2gridster";
import { FileUploadModule } from "ng2-file-upload";
import { NumericInputDirective } from "src/app/_directives/numeric-input/numeric-input.directive";
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxFilesizeModule } from 'ngx-filesize';
import { FilterPipe, UntitleCasePipe, JewishContentPipe, TableTypePipe, DurationPipe } from "src/app/_pipes";
import { UserEffects } from "src/app/store/user";
import { widgetsReducer } from "src/app/store/widget";
import { SharedModule } from "../shared/shared.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UploadComponent } from "./dashboard/upload/upload.component";
import { WidgetIconComponent } from "./dashboard/widget-icon/widget-icon.component";
import { BackgroundComponent } from "./dashboard/widget-settings/tabs/background/background.component";
import { EffectsComponent } from "./dashboard/widget-settings/tabs/effects/effects.component";
import { FontComponent } from "./dashboard/widget-settings/tabs/font/font.component";
import { GeneralComponent } from "./dashboard/widget-settings/tabs/general/general.component";
import { BlinkComponent } from "./dashboard/widget-settings/tabs/schedulers/blink/blink.component";
import { DateRangeComponent } from "./dashboard/widget-settings/tabs/schedulers/date-range/date-range.component";
import { DynamicTimeRangeComponent } from "./dashboard/widget-settings/tabs/schedulers/dynamic-time-range/dynamic-time-range.component";
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HolidaysComponent } from "./dashboard/widget-settings/tabs/schedulers/holidays/holidays.component";
import { SchedulerComponent } from "./dashboard/widget-settings/tabs/schedulers/scheduler.component";
import { TimeRangeComponent } from "./dashboard/widget-settings/tabs/schedulers/time-range/time-range.component";
import { WeekdaysComponent } from "./dashboard/widget-settings/tabs/schedulers/weekdays/weekdays.component";
import { AnalogClockDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/analog-clock-dashboard/analog-clock-dashboard.component";
import { DigitalClockDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/digital-clock-dashboard/digital-clock-dashboard.component";
import { JewishContentComponent } from "./dashboard/widget-settings/tabs/specific-widget/jewish-dashboard/jewish-content/jewish-content.component";
import { JewishDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/jewish-dashboard/jewish-dashboard.component";
import { MediaContentComponent } from "./dashboard/widget-settings/tabs/specific-widget/media-dashboard/media-content/media-content.component";
import { MediaDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/media-dashboard/media-dashboard.component";
import { PdfDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/pdf-dashboard/pdf-dashboard.component";
import { RssDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/rss-dashboard/rss-dashboard.component";
import { AlertContentComponent } from "./dashboard/widget-settings/tabs/specific-widget/table-dashboard/alert-dashboard/alert-content/alert-content.component";
import { AlertDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/table-dashboard/alert-dashboard/alert-dashboard.component";
import { TableContentComponent } from "./dashboard/widget-settings/tabs/specific-widget/table-dashboard/table-content/table-content.component";
import { TableRecordOptionsMultipleComponent } from "./dashboard/widget-settings/tabs/specific-widget/table-dashboard/table-content/table-record-options-multiple/table-record-options-multiple.component";
import { TableRecordOptionsComponent } from "./dashboard/widget-settings/tabs/specific-widget/table-dashboard/table-content/table-record-options/table-record-options.component";
import { TableDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/table-dashboard/table-dashboard.component";
import { AddTextByDateComponent } from "./dashboard/widget-settings/tabs/specific-widget/text-dashboard/add-text-by-date/add-text-by-date.component";
import { AddTextComponent } from "./dashboard/widget-settings/tabs/specific-widget/text-dashboard/add-text/add-text.component";
import { TextContentComponent } from "./dashboard/widget-settings/tabs/specific-widget/text-dashboard/text-content/text-content.component";
import { TextDashboardComponent } from "./dashboard/widget-settings/tabs/specific-widget/text-dashboard/text-dashboard.component";
import { UpdateTextByDateComponentComponent } from "./dashboard/widget-settings/tabs/specific-widget/text-dashboard/update-text-by-date-component/update-text-by-date-component.component";
import { WidgetSettingsComponent } from "./dashboard/widget-settings/widget-settings.component";
import { WidgetsPanelComponent } from "./dashboard/widgets-panel/widgets-panel.component";
import { EditRoutingModule } from "./edit-routing.module";
@NgModule({
  declarations: [
    DashboardComponent,
    WidgetIconComponent,
    UploadComponent,
    WidgetSettingsComponent,
    JewishDashboardComponent,
    JewishContentComponent,
    FilterPipe,
    TableDashboardComponent,
    TableContentComponent,
    TextContentComponent,
    UpdateTextByDateComponentComponent,
    AddTextComponent,
    AnalogClockDashboardComponent,
    DigitalClockDashboardComponent,
    MediaDashboardComponent,
    MediaContentComponent,
    PdfDashboardComponent,
    RssDashboardComponent,
    WidgetsPanelComponent,
    SchedulerComponent,
    WeekdaysComponent,
    TimeRangeComponent,
    DateRangeComponent,
    DynamicTimeRangeComponent,
    HolidaysComponent,
    AddTextByDateComponent,
    TextDashboardComponent,
    UntitleCasePipe,
    JewishContentPipe,
    TableRecordOptionsComponent,
    TableRecordOptionsMultipleComponent,
    AlertDashboardComponent,
    AlertContentComponent,
    TableTypePipe,
    BlinkComponent,
    NumericInputDirective,
    DurationPipe,
    EffectsComponent,
    GeneralComponent,
    BackgroundComponent,
    FontComponent
  ],
  exports: [
    DashboardComponent,
    UntitleCasePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    EditRoutingModule,
    SharedModule,
    DragDropModule,
    ColorPickerModule,
    FileUploadModule,
    NgxMaterialTimepickerModule,
    NgImageSliderModule,
    NgxFilesizeModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MatPaginatorModule,
    GridsterModule.forRoot(),
    StoreModule.forFeature('widgets', widgetsReducer),
    EffectsModule.forFeature([UserEffects]),
  ],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarHebrew },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nHebrew }
  ]
})
export class EditModule {
}
