import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog, private translate: TranslateService) {
  }

  /**
   * Opens a success snackbar.
   * @param {string} message - snackbar message.
   * @param {string} action - snackbar action name, 'Ok' by default.
   * @param {number} duration - show snackbar in milliseconds, 3000 by default.
   */
  public success(message: string, action = this.translate.instant('buttons.ok'), duration = 3000): void {
    this.openSnackbar('success-snackbar', message, action, duration, false);
  }

  /**
   * Opens an error snackbar.
   * @param {string} message - snackbar message.
   * @param {string} action - snackbar action name, 'Ok' by default.
   * @param {number} duration - show snackbar in milliseconds, 3000 by default.
   * @param {boolean} hasError - whether there is an error, false by default.
   * @param {ComponentType<any>} component - generic component to open in a modal window on error.
   */
  public error(message: string, action = 'Ok', duration = 3000, hasError = false, component?: ComponentType<any>): void {
    this.openSnackbar('error-snackbar', message, action, duration, hasError, component);
  }

  /**
   * Opens an informational snackbar.
   * @param {string} message - snackbar message.
   * @param {string} action - snackbar action name, 'Ok' by default.
   * @param {number} duration - show snackbar in milliseconds, 3000 by default.
   */
  public info(message: string, action = this.translate.instant('buttons.ok'), duration = 3000): MatSnackBarRef<any> {
    return this.openSnackbar('info-snackbar', message, action, duration, false);
  }

  /**
   * Opens a snackbar according to specified parameters. Opens a required modal window on error.
   * @param {string} className - CSS class to style the snackbar.
   * @param {string} message - snackbar message.
   * @param {string} action - snackbar action name.
   * @param {number} duration - show snackbar in milliseconds.
   * @param {boolean} hasError - whether there is an error.
   * @param {ComponentType<any>} component - generic component to open in a modal window on error.
   * @private
   */
  private openSnackbar(className: string,
                       message: string,
                       action: string,
                       duration: number,
                       hasError: boolean,
                       component?: ComponentType<any>): MatSnackBarRef<any> {

    const snackBarRef = this.snackBar.open(message, action, {
      duration,
      verticalPosition: 'top',
      politeness: 'assertive',
      panelClass: [className]
    });

    if (hasError) {
      snackBarRef.afterDismissed().subscribe(() => this.dialog.open(component));
    }

    return snackBarRef;
  }
}
