import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unpad'
})
export class UnpadPipe implements PipeTransform {

  /**
   * Removes a zero before the time.
   * @param {string} value - time to 'unpad'.
   * @param {boolean} padZero - whether the time must include the leading zero.
   * @return {string} - formatted time.
   */
  public transform(value: string, padZero: boolean): string {
    if (value.indexOf(':') === -1) return value;

    if (!padZero) {
      const timeArr = value.split(':');
      if (timeArr[1][1] == '0') {
        timeArr[1] = timeArr[1].trim().substring(1);
        return timeArr.join(':').replace(':', ': ');
      }
      else return value;
    }
    return value;
  }
}
