import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {
  private hubUrl = environment.hubUrl;
  private hubConnection: HubConnection;
  private onlineUsersSource = new BehaviorSubject<string[]>([]);
  private userConnectedSource = new BehaviorSubject<string>(null);
  private userDisconnectedSource = new BehaviorSubject<string>(null);

  public onlineUsers$ = this.onlineUsersSource.asObservable();
  public userConnected$ = this.userConnectedSource.asObservable();
  public userDisconnected$ = this.userDisconnectedSource.asObservable();

  /**
   * Creates the PresenceHub connection.
   * @param {string} token - user's token for authorisation purpose.
   */
  public createHubConnection(token: string): void {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubUrl + 'presence', { accessTokenFactory: () => token })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start()
      .catch(error => console.log(error));

    this.hubConnection.on('UserIsOnline', username => this.userConnectedSource.next(username));
    this.hubConnection.on('UserIsOffline', username => this.userDisconnectedSource.next(username));
    this.hubConnection.on('GetOnlineUsers', (usernames: string[]) => this.onlineUsersSource.next(usernames));
  }

  /**
   * Stops the UpdateHub connection.
   */
  public stopHubConnection(): void {
    if (this.hubConnection) {
      this.hubConnection.stop().catch(error => console.log(error));
    }
  }
}
