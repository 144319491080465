import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget, BackgroundTypes, Screen } from "src/app/_models";
import { WidgetService } from "src/app/_services";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.css']
})
export class BackgroundComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;

  public resourcesUrl = environment.resourcesUrl;
  public direction: Direction = 'ltr';
  public backgroundTypes = BackgroundTypes;
  public isTransparent: boolean;

  constructor(private widgetService: WidgetService,
              private translate: TranslateService,
              private translateHelper: TranslateHelper) { }

  public ngOnInit(): void {
    this.setTranslation();

    if (this.widget) {
      this.isTransparent = this.widget.background.type === BackgroundTypes.Transparent;
    }
  }

  /**
   * Toggles background - colour/image.
   * @param {boolean} checked - whether the background should be set to image.
   */
  public toggleBackground(checked: boolean): void {
    this.widget.background.type = checked
      ? BackgroundTypes.Image
      : BackgroundTypes.Color;

    this.widgetService.updateScreen(this.screen);
  }

  /**
   * Toggles the background image stretch property.
   * @param {boolean} checked - whether the background image should be stretched.
   */
  public toggleStretched(checked: boolean): void {
    this.widget.background.stretched = checked;
    this.widgetService.updateScreen(this.screen);
  }

  /**
   * Toggle widget background transparency.
   * @param {boolean} checked - whether the widget background should be set transparent.
   */
  public toggleTransparent(checked: boolean): void {
    this.isTransparent = checked;
    this.widget.background.type = this.isTransparent
      ? BackgroundTypes.Transparent
      : this.widget.background.imageUrl
        ? BackgroundTypes.Image
        : BackgroundTypes.Color;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
