import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SettingsComponent } from '../_modules/settings/settings/settings.component';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _settingsDialogWindowRef: MatDialogRef<SettingsComponent>;

  public get settingsDialogWindowRef(): MatDialogRef<SettingsComponent> {
    return this._settingsDialogWindowRef;
  }

  public set settingsDialogWindowRef(value: MatDialogRef<SettingsComponent>) {
    this._settingsDialogWindowRef = value;
  }

  public clearSettingsDialogWindowReference(): void {
    this._settingsDialogWindowRef = null;
  }
}
