<div class="container position-relative"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane">
  <button [title]="'buttons.close' | translate" class="close-btn" [mat-dialog-close]="false" mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
  <div class="row">
    <mat-card class="w-100">
      <mat-card-header class="title-block"
                       [style.direction]="direction"
                       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-card-title>{{ 'dashboard.widgetProperties.specific.table.alert.title' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'dashboard.widgetProperties.specific.table.alert.subtitle' | translate }}</mat-card-subtitle>
      </mat-card-header>

      <div class="d-flex box">
        <div class="col-3">
          <div class="w-100 h-100 d-flex flex-column justify-content-around align-items-center gridster-items">
            <div *ngFor="let item of availableKeysList"
                 (drop)="dropGridsterItem(item)"
                 (dblclick)="dropGridsterItem(item)"
                 [h]="1"
                 [w]="1"
                 class="z-index-1"
                 ngxGridsterItemPrototype>
              <mat-chip color="primary" selected>
                {{ 'dashboard.widgetProperties.specific.table.alert.' + item.key | translate }}
              </mat-chip>
            </div>
          </div>
        </div>

        <div class="col-1 border-left-dotted"></div>

        <div class="col-8">
          <ngx-gridster [options]="gridsterOptions" class="w-100 h-100 alert-gridster">
            <ngx-gridster-item *ngFor="let item of widget.content.table.alertKeys"
                               (change)="adjustGridsterItemPosition($event)"
                               [(x)]="item.x"
                               [(y)]="item.y"
                               [w]="item.w"
                               [h]="item.h"
                               class="d-flex justify-content-center align-items-center">
              <mat-chip color="primary" selected class="gridster-item">
                <div>{{ 'dashboard.widgetProperties.specific.table.alert.' + item.key | translate }}</div>
                <mat-icon (click)="removeGridsterItem(item)"
                          [title]="'dashboard.widgetProperties.specific.table.alert.remove' | translate"
                          color="accent"
                          class="remove-item">
                  remove_circle
                </mat-icon>
              </mat-chip>
            </ngx-gridster-item>
          </ngx-gridster>
        </div>
      </div>

    </mat-card>

  </div>

  <div class="row justify-content-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <div class="mt-4">
      <button mat-raised-button color="primary" class="mx-5" [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
    </div>
  </div>
</div>

