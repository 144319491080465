import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount, tap } from 'rxjs/operators';
import { EithanApiParametersDto } from '../_dto/eithan-api-parameters-dto';
import { ApiDto, StudiesApiDto } from '../_dto/api-dto';
import { Languages } from '../_enums/languages';
import { Minhags } from '../_enums/minhags';

export class DayInfoResponse {
  /** @description The daily portion of the Zohar */
  zohar_yomi: string;
  /** @description The daily portion of the Tanya */
  tanya?: string;
  /** @description one chapter of the Mishneh Torah each day */
  rambam_1_pereq?: string;
  /** @description three chapters of the Mishneh Torah each day */
  rambam_3_pereq?: string;
  /** @description The daily portion of Tehilim */
  tehilim_yomi?: string;
  /** @description The daily portion of the Chumash with */
  chumash?: string;
  /** @description The daily page of the Talmud Bavli */
  daf_yomi_babli?: string;
  /** @description The daily page of the Talmud Yerushalmi */
  daf_yomi_yerushalmi?: string;
  /** @description Whether or not Tehilim is recited on Shabbat Maquve */
  tehilim_on_shabbath_maquve?: string;
  /** @description The two chapters of Tehilim recited twice a month */
  tehilim_2_per_month?: string;
  /** @description Whether or not the Amud Yomi of Toldot Aharon is recited */
  amood_yomi_tol_aharon?: string;
  /** @description The daily page Ahalacha */
  daf_yomi_bahalacha?: string;
  /** @description The Shabbat reading of Pirke Avot */
  pirke_avot?: string;
  /** @description The time of candle lighting */
  candles_light?: string;
  /** @description The time of sunset */
  sunset?: string;
  /** @description The time of Shabbat end */
  shabbat_end?: string;
  /** @description The time of Rabenu Tam */
  rabenu_tam?: string;
  /** @description The time of Tzom end */
  tzom_end?: string;
  /** @description Alerts for Eruv Tabshilin */
  eruv_tabshilin?: string;
  /** @description The Haftara reading for Shabbat */
  haftara?: string;
  /** @description The weekly Torah portion */
  parasha?: string;
  /** @description A brief description of the day */
  day_description?: string;
  /** @description Alerts for Hamelech */
  hamelech?: string;
  /** @description Alerts for Hoshana */
  hoshana?: string;
  /** @description Alerts for Barchi Nafshi */
  barchi_nafshi?: string;
  /** @description Alerts for not reading Tachanun because of Brit Milah in the shul */
  tachanun_brit?: string;
  /** @description Alerts for Ledavid */
  ledavid?: string;
  /** @description Alerts for Vanenu */
  vanenu?: string;
  /** @description The Sefirat Haomer reading for the day */
  sefirat_haomer?: string;
  /** @description Masiv haruah or morid hatal */
  masiv_haruah?: string;
  /** @description Veten Tal Umatar or Veten Beracha */
  veten_tal_umatar?: string;
  /** @description Alerts for Ata Yatzarta */
  ata_yatzarta?: string;
  /** @description Days of Rosh Chodesh */
  rosh_chodesh_days?: string;
  /** @description Alerts for Shabbat Mevarechin */
  shabbat_mevarechin?: string;
  /** @description Rosh Chodesh Yaale */
  yaale?: string;
  /** @description The time to begin the Kiddush Levanah third day of Molad */
  begin_kiddush_3?: string;
  /** @description The time to begin the Kiddush Levanah seventh day of Molad */
  begin_kiddush_7?: string;
  /** @description The time to end the Kiddush Levanah */
  end_kiddush?: string;
  /** @description The time of the next Molad */
  next_molad?: string;
  /** @description Alerts for Al Hanisim */
  al_hanisim?: string;
  /** @description Alerts for not reading Tachanun */
  tachanun?: string;
  /** @description The Hebrew date for the day */
  hebrew_date?: string;
  /** @description The Rambam's Sefer HaMitzvos reading for the day */
  rambam_sefer_hamitzvos?: string;
  /** @description The georgian date for the day */
  civil_date?: string;


  static getByKey(key: string, dayInfo: DayInfoResponse): string {
    switch (key) {
      case 'parasha':
        return dayInfo.parasha;
      case 'hebrewDate':
        return dayInfo.hebrew_date;
      case 'gregorianDate':
        return dayInfo.civil_date;
      // case 'dayOfWeek':
      //   // TODO: return dayInfo.dayOfWeek;
      //   return dayInfo.none;
      case 'dayDescription':
        return dayInfo.day_description;
      case 'candleLight':
        return dayInfo.candles_light;
      case 'dafYomi':
        return dayInfo.daf_yomi_babli;
      case 'dafYerushalmi':
        return dayInfo.daf_yomi_yerushalmi;
      case 'sunset':
        return dayInfo.sunset;
      // case 'sunrise':
      //   return dayInfo.none;
      case 'shabbosEnd':
        return dayInfo.shabbat_end;
      case 'rabeinuTam':
        return dayInfo.rabenu_tam;
      case 'yaaleVeyavo':
        return dayInfo.yaale;
      case 'alHanisim':
        return dayInfo.al_hanisim;
      case 'hamelechHakadosh':
        return dayInfo.hamelech;
      case 'tachanun':
        return dayInfo.tachanun;
      case 'sefiratHaomer':
        return dayInfo.sefirat_haomer;
      case 'veaneinu':
        return dayInfo.vanenu;
      case 'atoYatzarto':
        return dayInfo.ata_yatzarta;
      case 'fastEnd':
        return dayInfo.tzom_end;
      case 'eruvTavshilin':
        return dayInfo.eruv_tabshilin;
      case 'ledavid':
        return dayInfo.ledavid;
      case 'vetenTal':
        return dayInfo.veten_tal_umatar;
      case 'moridHageshem':
        return dayInfo.masiv_haruah;
      case 'molad':
        return dayInfo.next_molad;
      case 'kiddushLevanahEnd':
        return dayInfo.end_kiddush;
      case 'kiddushLevanah7':
        return dayInfo.begin_kiddush_7;
      case 'kiddushLevanah3':
        return dayInfo.begin_kiddush_3;
      case 'haftara':
        return dayInfo.haftara;
      case 'hoshana':
        return dayInfo.hoshana;
      case 'nachem':
        return dayInfo.barchi_nafshi;
      case 'rambam3':
        return dayInfo.rambam_3_pereq;
      case 'rambam1':
        return dayInfo.rambam_1_pereq;
      case 'rambamSeferHamitzvos':
        return dayInfo.rambam_sefer_hamitzvos;
      case 'tehilimYomi':
        return dayInfo.tehilim_yomi;
      case 'tania':
        return dayInfo.tanya;
      case 'chumash':
        return dayInfo.chumash;
      case 'pirkeiAvot':
        return dayInfo.pirke_avot;
      default:
        return undefined;
    }
  }
}

export interface FetchDayParams {
  Chool: boolean;
  TextLanguage: 1 | 2;
  Longitude?: number;
  Latitude?: number;
  Altitude?: number;
  ConsiderAltitudeOnSunset?: boolean;
  TimeZone?: -12 | -11 | -10 | -9 | -8 | -7 | -6 | -5 | -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  Region?: "Region_Israel" | "US" | "Canada" | "Europe" | "Brazil" | "Mexico" | "Else";
  LocName?: string;
  CandleLight?: 15 | 18 | 20 | 22 | 24 | 30 | 40;
  Lyear?: number;
  Lmonth?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  Lday?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31;
  isZioni: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EithanApiService {
  private studiesUrl = 'https://eitan.ws/Json3/Limudim.aspx?days=1&';
  private haftarotUrl = 'https://eitan.ws/Json3/Haftarot.aspx?days=1&';
  private pirkeiAvotUrl = 'https://eitan.ws/Json3/PirqueAvot60.aspx?PirkeEngStr=Pirkei%20Avot&days=1&';
  private hoshanosUrl = 'https://eitan.ws/Json3/PrayChanges.aspx?days=1&';
  private dayInfo = 'https://eitan.ws/Json3/ComboInfoNew.aspx';

  private studies$: Observable<StudiesApiDto>;
  private haftarot$: Observable<ApiDto>;
  private avot$: Observable<ApiDto>;
  private hoshanos$: Observable<StudiesApiDto>;

  constructor(private http: HttpClient) { }

  dayInfoCache: { [key: string]: DayInfoResponse } = {};

  /**
   * Fetches day info and caches it.
   * @param detchDayParams - The parameters to fetch the day info with.
   */
  public fetchDayInfo(detchDayParams: FetchDayParams): Observable<DayInfoResponse> {
    const { Lyear: year, Lmonth: month, Lday: day, Chool: hool, TextLanguage: textLanguage, isZioni } = detchDayParams;
    const key = `${year}-${month}-${day}-${hool}-${textLanguage}-${isZioni}`;
    if (this.dayInfoCache[key]) {
      return new Observable<DayInfoResponse>(observer => {
        observer.next(this.dayInfoCache[key]);
        observer.complete();
      });
    }
    return this.http
      .get<DayInfoResponse>(`${this.dayInfo}?Lyear=${year}&Lmonth=${month}&Lday=${day}&Chool=${hool}&textLanguage=${textLanguage}&zionic=${isZioni}`).pipe(
        tap(dayInfo => this.dayInfoCache[key] = dayInfo)
      );
  }


  /**
   * Fetches special studies and caches it.
   * @param {EithanApiParametersDto} parameters - parameters
   * @param {boolean} nocache - whether to not use cache, by default false.
   */
  public fetchStudies(parameters: EithanApiParametersDto, nocache = false): Observable<StudiesApiDto> {
    if (!this.studies$ || nocache) {
      const { year, month, day, inEretzIsrael, language } = parameters;

      const isChool = inEretzIsrael ? 'False' : 'True';
      const lang = language === Languages.Hebrew ? '1' : '0';

      this.studies$ = this.http
        .get<StudiesApiDto>(`${this.studiesUrl}Lyear=${year}&Lmonth=${month}&Lday=${day}&Chool=${isChool}&TextLanguage=${lang}`)
        .pipe(
          publishReplay(1),
          refCount()
        );
    }
    return this.studies$;
  }

  /**
   * Fetches Hoshanos and caches it.
   * @param {EithanApiParametersDto} parameters - parameters
   * @param {boolean} nocache - whether to not use cache, by default false.
   */
  public fetchHoshanos(parameters: EithanApiParametersDto, nocache = false): Observable<StudiesApiDto> {
    if (!this.hoshanos$ || nocache) {
      const { year, month, day, inEretzIsrael, language } = parameters;

      const isChool = inEretzIsrael ? 'False' : 'True';
      const lang = language === Languages.Hebrew ? '1' : '0';

      this.hoshanos$ = this.http
        .get<StudiesApiDto>(`${this.hoshanosUrl}Hyear=${year}&Hmonth=${month}&Hday=${day}&Chool=${isChool}&TextLanguage=${lang}`)
        .pipe(
          publishReplay(1),
          refCount()
        );
    }
    return this.hoshanos$;
  }

  /**
   * Fetches Haftarot and caches it.
   * @param {EithanApiParametersDto} parameters - parameters
   * @param {boolean} nocache - whether to not use cache, by default false.
   */
  public fetchHaftarot(parameters: EithanApiParametersDto, nocache = false): Observable<ApiDto> {
    if (!this.haftarot$ || nocache) {
      const { year, month, day, inEretzIsrael, language, minhag } = parameters;

      const isChool = inEretzIsrael ? 'False' : 'True';
      const lang = language === Languages.Hebrew ? '1' : '0';

      this.haftarot$ = this.http
        .get<ApiDto>(`${this.haftarotUrl}Hyear=${year}&Hmonth=${month}&Hday=${day}&Chool=${isChool}&SelMinhag=${minhag}&TextLanguage=${lang}`)
        .pipe(
          publishReplay(1),
          refCount()
        );
    }
    return this.haftarot$;
  }

  /**
   * Fetches Pirkei Avot and caches it.
   * @param {EithanApiParametersDto} parameters - parameters
   * @param {boolean} nocache - whether to not use cache, by default false.
   */
  public fetchPirkeiAvot(parameters: EithanApiParametersDto, nocache = false): Observable<ApiDto> {
    if (!this.avot$ || nocache) {
      const { year, month, day, inEretzIsrael, language, minhag } = parameters;

      const isChool = inEretzIsrael ? 'False' : 'True';
      const lang = language === Languages.Hebrew ? '1' : '0';
      const ashkenazi = [Minhags.Ashkenaz, Minhags.Sfarad, Minhags.Chabad].includes(minhag as Minhags) ? 'True' : 'False';

      this.avot$ = this.http
        .get<ApiDto>(`${this.pirkeiAvotUrl}Lyear=${year}&Lmonth=${month}&Lday=${day}&Chool=${isChool}&Ashkanzi=${ashkenazi}&TextLanguage=${lang}`)
        .pipe(
          publishReplay(1),
          refCount()
        );
    }
    return this.avot$;
  }
}
