import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditService {
  private editModeSource = new Subject<boolean>();
  private portraitOrientationSource = new Subject<boolean>();

  public editMode$ = this.editModeSource.asObservable();
  public portraitOrientation$ = this.portraitOrientationSource.asObservable();

  /**
   * Toggles the edit mode.
   * @param {boolean} enabled - whether the edit mode is enabled.
   */
  public setEditMode(enabled: boolean): void {
    this.editModeSource.next(enabled);
  }

  /**
   * Toggles the landscape/portrait orientation.
   * @param {boolean} portraitEnabled - whether the edit mode is enabled.
   */
  public setPortraitOrientation(portraitEnabled: boolean): void {
    this.portraitOrientationSource.next(portraitEnabled);
  }
}
