import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { SchedulerService } from "../scheduler.service";
import { Scheduler } from "src/app/_models";

@Component({
  selector: 'app-time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.css']
})
export class TimeRangeComponent implements OnInit, OnDestroy {
  @Input() scheduler: Scheduler;

  @ViewChild('timeStart') timeStart: ElementRef<HTMLInputElement>;
  @ViewChild('timeEnd') timeEnd: ElementRef<HTMLInputElement>;

  public timeRange: UntypedFormGroup;
  public direction: Direction = 'ltr';

  private clearSubscription: Subscription;
  private updateSubscription: Subscription;

  constructor(
    private schedulerService: SchedulerService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnInit(): void {
    this.setTranslation();

    this.initTimeRange(this.scheduler);
    this.clearSubscription = this.schedulerService.schedulersCleared$.subscribe(() => this.clearTimeRange());
    this.updateSubscription = this.schedulerService.schedulersUpdated$.subscribe((scheduler: Scheduler) => {
      this.timeRange.reset();
      this.initTimeRange(scheduler);
    });
  }

  public ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
    this.updateSubscription.unsubscribe();
  }

  /**
   * Sets the time range 'start' & 'end' times.
   */
  public setTime(): void {
    if (!this.scheduler) {
      this.scheduler = {} as Scheduler;
    }

    if (this.timeStart.nativeElement.value && this.timeEnd.nativeElement.value) {
      if (!this.scheduler.timeRange) {
        this.scheduler.timeRange = { start: null, end: null };
      }
      this.scheduler.timeRange.start = this.timeStart.nativeElement.value;
      this.scheduler.timeRange.end = this.timeEnd.nativeElement.value;
    }
  }

  /**
   * Clears the time range scheduler.
   */
  public clearTimeRange(): void {
    this.scheduler.timeRange = null;
  }

  /**
   * Initialises the time range form.
   * @param {Scheduler} scheduler - the Scheduler object.
   * @private
   */
  private initTimeRange(scheduler: Scheduler): void {
    this.timeRange = new UntypedFormGroup({
      startTime: new UntypedFormControl(scheduler?.timeRange?.start, [this.timeRangeValidator.bind(this)]),
      endTime: new UntypedFormControl(scheduler?.timeRange?.end, [this.timeRangeValidator.bind(this)])
    });
  }

  /**
   * Validates the time range 'start' time is less than the 'end' time.
   * @private
   */
  private timeRangeValidator() {
    if (this.timeRange?.controls['startTime']?.value >= this.timeRange?.controls['endTime']?.value) {
      return { timesNotConsequent: true };
    }

    return null;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
