export enum MinhagHaftara {
  Ashkenazi,
  Sefaradi,
  Teimani,
  Chabad
}

export const MinhagApi = {
  0: 'Askenazim',
  1: 'Sefaradim',
  2: 'Temanim',
  3: 'Chabadim'
};
