import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UserDto } from '../../../../_dto/user-dto';
import { Languages } from '../../../../_enums/languages';
import { LocalStorage } from '../../../../_enums/local-storage';
import { TimeFormat } from '../../../../_enums/time-format';
import { AccountHelper } from '../../../../_helpers/account.helper';
import { DateHelper } from '../../../../_helpers/date.helper';
import { User } from '../../../../_models/identity/user';
import { AccountService } from '../../../../_services/account.service';
import { SnackbarService } from '../../../../_services/snackbar.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css']
})
export class GeneralSettingsComponent implements OnInit {
  @Input() user: UserDto;

  public timeFormat = TimeFormat;
  public direction = 'ltr';
  public date: string;
  public isAdmin = false;
  public languages = Languages;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private translate: TranslateService,
    private accountService: AccountService,
    private snackbar: SnackbarService
  ) {
    iconRegistry.addSvgIcon(
      'usa',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/united-states.svg'));
    iconRegistry.addSvgIcon(
      'israel',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/israel.svg'));
    iconRegistry.addSvgIcon(
      'russia',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/russia.svg'));

    translate.setDefaultLang(Languages.English);
  }

  /**
   * Gets the app language.
   */
  public get language(): string {
    return localStorage.getItem(LocalStorage.Language) || this.translate.currentLang;
  }

  /**
   * Sets the app language.
   * @param {Languages} language - a language to set.
   */
  public set language(language: string) {
    localStorage.setItem(LocalStorage.Language, language);
    this.translate.use(language);
    this.direction = language === Languages.Hebrew ? 'rtl' : 'ltr';
  }

  public ngOnInit(): void {
    const language = localStorage.getItem(LocalStorage.Language) || Languages.English;
    this.translate.use(language);
    this.direction = language === Languages.Hebrew ? 'rtl' : 'ltr';
    this.date = DateHelper.getDateInISO8601Format(new Date());

    this.accountService.currentUser$
      .subscribe((user: User) => this.isAdmin = AccountHelper.isAdmin());
  }

  /**
   * Toggles user's time format - 12H/24H.
   * @param checked
   */
  public toggleTimeFormat(checked: boolean): void {
    this.user.timeFormat = checked ? TimeFormat._24H : TimeFormat._12H;
  }

  /**
   * Updates user profile & shows a snackbar.
   */
  public save(): void {
    this.accountService.updateProfile(this.user).subscribe(
      () => {
        console.log(this.user);
        this.snackbar.success(this.translate.instant('snackbar.profileUpdated'), this.translate.instant('buttons.ok'));
      },
      error => {
        console.log(error);
        this.snackbar.error(error.error.Message, this.translate.instant('buttons.ok'));
      }
    );
  }
}
