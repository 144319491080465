import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { NavigationExtras, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatusCodes } from '../_enums/http-status-codes';
import { SnackbarService } from '../_services/snackbar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private snackbar: SnackbarService) {}

  /**
   * Shows appropriate snackbar depending on error status code.
   * @param {HttpRequest<unknown>} request - HTTP request.
   * @param {HttpHandler} next - HTTP handler.
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error) {
          switch (error.status) {
            case HttpStatusCodes.BadRequest:
              if (error.error.errors) {
                throw error.error;
              } else {
                this.snackbar.error(error.error.message, 'Ok');
              }
              break;
            case HttpStatusCodes.Unauthorized:
              this.snackbar.error(error.error.message, 'Ok');
              this.router.navigate(['account', 'login']);
              break;
            case HttpStatusCodes.NotFound:
              this.router.navigateByUrl('/not-found');
              this.snackbar.error(error.error.message, 'Ok');
              break;
            case HttpStatusCodes.ServerError:
              const navigationExtras: NavigationExtras = { state: { error: error.error } };
              this.router.navigateByUrl('/server-error', navigationExtras);
              const message = 'Errors are the path to the dark side. Errors lead to anger. Anger leads to hate. Hate leads to career change';
              this.snackbar.error(message, 'Ok', 10000);
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
