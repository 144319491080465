import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../../../_enums/languages';
import { TranslateHelper } from '../../../../_helpers/translate.helper';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  public direction = 'ltr';

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) { }

  public ngOnInit(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
