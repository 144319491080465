import { Direction } from "@angular/cdk/bidi";
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDropList } from "@angular/cdk/drag-drop";
import { Component, OnInit, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget } from "src/app/_models";
import { AppState } from "src/app/store/app.state";
import { changeJewishKeys } from "src/app/store/widget";

@Component({
  selector: 'app-jewish-content',
  templateUrl: './jewish-content.component.html',
  styleUrls: ['./jewish-content.component.css']
})
export class JewishContentComponent implements OnInit {
  @Input() widget: Widget;
  @Input() contentToChoose: string[];
  @Input() chosenContent: string[];
  public tempChosenContent: string[] = [];

  public direction: Direction = 'ltr';
  public searchValue = '';

  public categories = {
    calendar: ['hebrewDate', 'gregorianDate', 'dayOfWeek'],
    times: ['sunset', 'sunrise', 'fastEnd', 'candleLight', 'shabbosEnd', 'rabeinuTam'],
    prayerChanges: ['yaaleVeyavo', 'atoYatzarto', 'alHanisim', 'hamelechHakadosh', 'tachanun', 'vetenTal', 'moridHageshem', 'nachem', 'veaneinu',
      'ledavid'],
    special: ['dayDescription', 'hoshana', 'sefiratHaomer', 'eruvTavshilin', 'kiddushLevanahEnd', 'kiddushLevanah7', 'kiddushLevanah3', 'molad'],
    studies: ['parasha', 'haftara', 'dafYomi', 'dafYerushalmi', 'pirkeiAvot', 'rambam3', 'rambam1', 'rambamSeferHamitzvos', 'tehilimYomi',
      'tania', 'chumash']
  };

  constructor(private translate: TranslateService, private translateHelper: TranslateHelper, private store: Store<AppState>) {
    translate.setDefaultLang(Languages.English);
  }

  public ngOnInit(): void {
    this.setTranslation();

    if (this.widget.content.jewish.keys.length > 0) {
      this.chosenContent = [...this.widget.content.jewish.keys];
      this.tempChosenContent = [...this.chosenContent];
      this.widget.contentSource?.next(this.widget.content.jewish.keys);
    }
  }

  /**
   * Transfers a Jewish key on drop.
   * @param {CdkDragDrop<string[]>} event - to determine which container the item is dropped onto.
   */
  public dropItem(event: CdkDragDrop<string[]>) {
    event.container.element.nativeElement.classList.remove('placeholder-border');

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const containerBefore: string[] = [...event.container.data];
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      const containerAfter: string[] = [...event.container.data];
      const droppedItem = containerAfter.filter(item => !containerBefore.includes(item))[0];

      if (this.chosenContent.includes(droppedItem)) {
        const indexItemToRemove = this.contentToChoose.indexOf(droppedItem);
        if (indexItemToRemove !== -1) {
          this.contentToChoose.splice(indexItemToRemove, 1);
        }
      } else {
        this.contentToChoose.push(droppedItem);
      }
    }
    this.tempChosenContent = [...this.tempChosenContent];
  }

  /**
   * Sets the 'placeholder-border' on mouse hover over the drop zone.
   * @param {CdkDropList} container - a drop container to drop to.
   */
  public onDragEnter(container: CdkDropList): void {
    container.element.nativeElement.classList.add('placeholder-border');
  }

  /**
   * Removes the 'placeholder-border' on mouse exit the drop zone.
   * @param {CdkDropList} container - a drop container to drop to.
   */
  public onDragExit(container: CdkDropList): void {
    container.element.nativeElement.classList.remove('placeholder-border');
  }

  /**
   * Transfers a Jewish key.
   * @param {string} currentItem - a Jewish key to transfer.
   */
  public transferItem(currentItem: string): void {
    if (this.contentToChoose.includes(currentItem)) {
      const indexItemToRemove = this.contentToChoose.indexOf(currentItem);
      this.contentToChoose.splice(indexItemToRemove, 1);
      this.tempChosenContent.push(currentItem);
    } else {
      const indexItemToRemove = this.tempChosenContent.indexOf(currentItem);
      this.tempChosenContent.splice(indexItemToRemove, 1);
      this.contentToChoose.push(currentItem);
    }
    this.tempChosenContent = [...this.tempChosenContent];
  }

  /**
   * Transfers a Jewish key back.
   * @param {string} currentItem - a Jewish key to transfer.
   */
  public transferBack(currentItem: string): void {
    if (this.tempChosenContent.includes(currentItem)) {
      const indexItemToRemove = this.tempChosenContent.indexOf(currentItem);
      this.tempChosenContent.splice(indexItemToRemove, 1);
      this.contentToChoose.push(currentItem);
    } else {
      const indexItemToRemove = this.contentToChoose.indexOf(currentItem);
      this.contentToChoose.splice(indexItemToRemove, 1);
      this.tempChosenContent.push(currentItem);
    }
    this.tempChosenContent = [...this.tempChosenContent];
  }

  /**
   * Clears the chosen content.
   */
  public clearAll(): void {
    this.contentToChoose = [...this.contentToChoose, ...this.tempChosenContent];
    this.tempChosenContent = [];
  }

  /**
   * Transfers the whole category to be chosen.
   * @param {string[]} category - a category to transfer.
   */
  public transferCategory(category: string[]): void {
    for (const item of category) {
      if (this.contentToChoose.includes(item)) {
        this.transferItem(item);
      }
    }
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }

  updateKeys(newKeys: string[]): void {
    this.widget.content.jewish.keys = newKeys;
    this.widget.contentChangedSource.next();
    this.store.dispatch(changeJewishKeys({ widgetId: this.widget.id, jewishKeys: newKeys }));
  }

  public applyChanges(): true {
    this.chosenContent = [...this.tempChosenContent];
    this.updateKeys([...this.chosenContent]);
    return true;
  }

  public discardChanges(): false {
    this.tempChosenContent = [...this.chosenContent];
    return false;
  }

}
