import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input
} from '@angular/core';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ScrollHelper } from '../../_helpers/scroll.helper';

@Directive({
  selector: '[appFitText]'
})
export class FitTextDirective implements AfterViewInit {
  @Input() test: boolean;
  @Input() width = true;
  @Input() height = false;

  constructor(private el: ElementRef<HTMLDivElement>) {
  }

  public ngAfterViewInit(): void {
    const element: HTMLElement = this.el.nativeElement;

    timer(0, 80).pipe(
      take(5)
    ).subscribe(() => this.fit(element));

    ScrollHelper.runMutationObserver(element, () => this.fit(element));
  }

  /**
   * Decreases/increases the content font to fit the HTML element size.
   * @param {HTMLElement} el - HTML element to fit to.
   */
  public fit(el: HTMLElement): void {
    let currentSize = parseInt(window.getComputedStyle(el).getPropertyValue('font-size'), 10);
    if (this.test) {
      console.log('font size:', currentSize);
    }

    let i = 0;

    if (this.width) {
      while (el.clientWidth < el.scrollWidth && i < 1000) {
        i++;
        el.style.fontSize = currentSize-- + 'px';
      }

      i = 0;
      while (el.clientWidth > el.scrollWidth && i < 1000) {
        i++;
        el.style.fontSize = currentSize++ + 'px';
      }
    }

    if (this.height) {
      while (el.clientHeight < el.scrollHeight && i < 1000) {
        i++;
        el.style.fontSize = currentSize-- + 'px';
      }

      i = 0;
      while (el.clientHeight > el.scrollHeight && i < 1000) {
        i++;
        el.style.fontSize = currentSize++ + 'px';
      }
    }

    if (this.test) {
      console.log('after textFit:');
      console.log(this.el.nativeElement);
      console.log('client width: ', this.el.nativeElement.clientWidth, 'scroll width:', this.el.nativeElement.scrollWidth);
      console.log('client height: ', this.el.nativeElement.clientHeight, 'scroll height:', this.el.nativeElement.scrollHeight);
    }
  }
}
