<!--  Enable slider-->
<div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3">
  <mat-slide-toggle (change)="toggleSchedulers($event.checked)"
                    [checked]="schedulersEnabled"
                    labelPosition="before"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="mx-3">{{ 'dashboard.widgetProperties.scheduler.enable' | translate }}</span>
  </mat-slide-toggle>
</div>

<mat-accordion #accordion="matAccordion" [multi]="true">
  <!--Days of week-->
  <mat-expansion-panel [expanded]="scheduler.daysOfWeek ? true : false" [disabled]="!schedulersEnabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="settings-title">{{ 'dashboard.widgetProperties.scheduler.days.title' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3"
         [class.disabled]="!schedulersEnabled">
      <app-weekdays [scheduler]="scheduler"></app-weekdays>
    </div>
  </mat-expansion-panel>

  <!--Time range-->
  <mat-expansion-panel [expanded]="scheduler.timeRange ? true : false" [disabled]="!schedulersEnabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="settings-title">{{ 'dashboard.widgetProperties.scheduler.timeRange.title' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3 w-100"
         [class.disabled]="!schedulersEnabled">
      <app-time-range [scheduler]="scheduler"></app-time-range>
    </div>
  </mat-expansion-panel>

  <!--Date range-->
  <mat-expansion-panel [expanded]="scheduler.dateRange || scheduler.hebrewRange ? true : false" [disabled]="!schedulersEnabled" class="date-range">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="settings-title">{{ 'dashboard.widgetProperties.scheduler.dateRange.title' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3"
         [class.disabled]="!schedulersEnabled">
      <app-date-range [scheduler]="scheduler"></app-date-range>
    </div>
  </mat-expansion-panel>

  <!--Dynamic time range-->
  <mat-expansion-panel [expanded]="scheduler.dynamicTimeRange ? true : false" [disabled]="!schedulersEnabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="settings-title">{{ 'dashboard.widgetProperties.scheduler.dynamicTimeRange.title' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3"
         [class.disabled]="!schedulersEnabled">
      <app-dynamic-time-range [scheduler]="scheduler"></app-dynamic-time-range>
    </div>
  </mat-expansion-panel>

  <!--Holidays-->
  <mat-expansion-panel [expanded]="holidaysEnabled" [disabled]="!schedulersEnabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="settings-title">{{ 'dashboard.widgetProperties.scheduler.holidays.title' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3 w-100"
         [class.disabled]="!schedulersEnabled">
      <app-holidays [scheduler]="scheduler"></app-holidays>
    </div>
  </mat-expansion-panel>

  <!--Blink-->
  <mat-expansion-panel [expanded]="scheduler?.blink?.enabled" [disabled]="!schedulersEnabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="settings-title">{{ 'dashboard.widgetProperties.scheduler.blink.title' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3 w-100"
         [class.disabled]="!schedulersEnabled">
      <app-blink [scheduler]="scheduler"></app-blink>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<!--Empty-->
<div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3"
     [class.disabled]="!schedulersEnabled">
  <div class="w-75 d-flex justify-content-between align-items-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span>{{ 'dashboard.widgetProperties.scheduler.hide' | translate }}</span>
    <mat-slide-toggle (change)="scheduler.ifEmpty = $event.checked" [checked]="scheduler.ifEmpty"></mat-slide-toggle>
  </div>
</div>
