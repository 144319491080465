import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { CheckboxStatus, Languages } from "src/app/_enums";
import { TranslateHelper, SchedulerHelper } from "src/app/_helpers";
import { Holidays, Holiday, Scheduler } from "src/app/_models";
import { SchedulerService } from "../scheduler.service";

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css']
})
export class HolidaysComponent implements OnInit, OnDestroy {
  @Input() scheduler: Scheduler;
  @Input() trigger: boolean;

  @Output() refreshPipe = new EventEmitter<void>();

  public status = CheckboxStatus;

  public isCollapsedErevShabbos = true;
  public isCollapsedHanukkah = true;

  public holidaysFirstColumn: Holidays;
  public holidaysSecondColumn: Holidays;

  public direction: Direction = 'ltr';

  private clearSubscription: Subscription;
  private updateSubscription: Subscription;

  constructor(
    private schedulerService: SchedulerService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnInit(): void {
    this.setTranslation();
    this.prepareHolidaysColumns(this.scheduler);
    this.clearSubscription = this.schedulerService.schedulersCleared$.subscribe(() => this.clearHolidays());
    this.updateSubscription = this.schedulerService.schedulersUpdated$.subscribe((scheduler: Scheduler) => this.prepareHolidaysColumns(scheduler));
  }

  public ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
    this.updateSubscription.unsubscribe();
  }

  /**
   * Sets the specified special day status.
   * @param {Holidays} day - a Holiday object.
   */
  public onChange(day: Holiday): void {
    day.status = day.status === CheckboxStatus.Indeterminate ? CheckboxStatus.Checked : day.status + 1;
    this.scheduler.holidays = [...this.holidaysFirstColumn, ...this.holidaysSecondColumn];

    if (day.id === 'erev_shabbat') {
      this.isCollapsedErevShabbos = day.status !== CheckboxStatus.Checked;
      if (day.status !== CheckboxStatus.Checked) {
        this.scheduler.holidays[10].erevShabbosStartTime = null;
      }
    }

    if (day.id === 'hanukkah') {
      this.isCollapsedHanukkah = day.status !== CheckboxStatus.Checked;
    }

    this.refreshPipe.emit();
  }

  /**
   * Toggles the specified Hanukkah candle.
   * @param {number} candle - a candle number.
   */
  public toggleCandle(candle: number): void {
    this.scheduler.holidays[11].hanukkahCandles[candle] = !this.scheduler.holidays[11].hanukkahCandles[candle];
  }

  /**
   * Clears the special days scheduler & collapses the holidays tab.
   */
  public clearHolidays(): void {
    this.scheduler.holidays = this.returnToIndeterminate(this.scheduler.holidays);
    this.holidaysFirstColumn = this.returnToIndeterminate(this.holidaysFirstColumn);
    this.holidaysSecondColumn = this.returnToIndeterminate(this.holidaysSecondColumn);
    this.schedulerService.collapseHolidays();
  }

  /**
   * Returns all the special days' scheduler to the indeterminate status.
   * @param {Holidays} holidays - a special days list.
   * @private
   */
  private returnToIndeterminate(holidays: Holidays): Holidays {
    return holidays.map(day => {
      const { en, he, id } = day;
      return {
        en,
        he,
        id,
        status: CheckboxStatus.Indeterminate,
        hanukkahCandles: id === 'hanukkah' ? [true, true, true, true, true, true, true, true] : null,
        erevShabbosStartTime: id === 'erev_shabbat' ? '12:00' : null
      }
    });
  }

  /**
   * Prepares to display the special days columns.
   * @param {Scheduler} scheduler - the Scheduler object.
   * @private
   */
  private prepareHolidaysColumns(scheduler: Scheduler): void {
    scheduler.holidays ??= SchedulerHelper.holidays;
    const { holidays } = scheduler;
    this.holidaysFirstColumn = [...holidays].slice(0, holidays.length / 2);
    this.holidaysSecondColumn = [...holidays].slice(holidays.length / 2);
    this.isCollapsedHanukkah = scheduler.holidays[11].status !== CheckboxStatus.Checked;
    this.isCollapsedErevShabbos = scheduler.holidays[10].status !== CheckboxStatus.Checked;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
