import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/identity/user';
import { AccountService } from '../_services/account.service';
import { AppState } from '../store/app.state';
import { Store } from '@ngrx/store';
import { loadUserPermissions } from '../store/user/user.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private accountService: AccountService, private router: Router, private store: Store<AppState>) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,): Observable<boolean> {
    return this.accountService.currentUser$.pipe(
      map((user: User) => {
        if (user) return true;
        this.store.dispatch(loadUserPermissions({ role: null }))
        this.router.navigate(['account/login'], { queryParams: { returnUrl: state.url } })
          .catch(error => console.log(error));
      })
    );
  }
}
