<div class="scheduler">
<!--  <mat-slide-toggle (change)="toggleAllDays($event.checked)"-->
<!--                    [checked]="daysChecked"-->
<!--                    labelPosition="before"-->
<!--                    class="mb-3"-->
<!--                    [style.direction]="direction"-->
<!--                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">-->
<!--    <span class="toggle-days mx-2">{{ 'dashboard.widgetProperties.scheduler.days.selectClear' | translate }}</span>-->
<!--  </mat-slide-toggle>-->

  <mat-button-toggle-group multiple [style.font-size]="direction === 'ltr' ? '0.8rem' : 'inherit'"
                           [style.direction]="direction"
                           [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-button-toggle *ngFor="let _ of 7 | times; let i = index"
                       [checked]="!scheduler?.daysOfWeek || scheduler?.daysOfWeek[i]"
                       (change)="toggleDay(i)">
      {{ 'dashboard.widgetProperties.scheduler.days.' + i.toString() | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>

</div>
