import { Injectable } from '@angular/core';

import { Devices } from '../_enums';
import { Resolution } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  private stylesResolution = new Resolution(1920, 1080);

  /**
   * Checks whether the application opened through iframe.
   * @returns {boolean}
   */
  public get isInIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  public get resolution() {
    return this.stylesResolution;
  }

  public set resolution(value) {
    const resolution = (value as unknown as string).split('x');
    const width = parseInt(resolution[0], 10);
    const height = parseInt(resolution[1], 10);
    this.stylesResolution = new Resolution(width, height) || this.stylesResolution;
  }

  public getResponsiveFontSize(fontSize: number): string {
    const windowWidth = window.innerWidth;
    let responsiveFontSize: string;

    if (windowWidth === this.stylesResolution.width) {
      return 'inherit';
    }

    if (this.stylesResolution.width === Devices.Display4K) {
      if (windowWidth <= Devices.Mini) {
        responsiveFontSize = fontSize / 8 + 'px';
      } else if (windowWidth <= Devices.Smartphone) {
        responsiveFontSize = fontSize / 6 + 'px';
      } else {
        responsiveFontSize = windowWidth > Devices.TabletLarge ? fontSize / 2 + 'px' : fontSize / 3.8 + 'px';
      }
      return responsiveFontSize;
    }

    if (this.stylesResolution.width === Devices.TabletLarge) {
      return fontSize + 'px';
    }

    if (windowWidth >= Devices.Display4K) {
      responsiveFontSize = fontSize * 2 + 'px';
    } else if (windowWidth <= Devices.Mini) {
      responsiveFontSize = fontSize / 5 + 'px';
    } else if (windowWidth <= Devices.Smartphone) {
      responsiveFontSize = fontSize / 3 + 'px';
    } else {
        responsiveFontSize = (windowWidth - 100) > Devices.Tablet ? fontSize / 1.5 + 'px' : fontSize / 2 + 'px';
    }
    return responsiveFontSize;
  }
}
