import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDto } from '../../../../../_dto/user-dto';
import { Languages } from '../../../../../_enums/languages';
import { TranslateHelper } from '../../../../../_helpers/translate.helper';

@Component({
  selector: 'app-candle-light',
  templateUrl: './candle-light.component.html',
  styleUrls: ['./candle-light.component.css']
})
export class CandleLightComponent implements OnInit {
  @Input() user: UserDto;
  public direction = 'ltr';
  public candleLightTimes = [15, 18, 20, 22, 24, 30, 40];

  constructor(
    private translate: TranslateService,
    private translateHelper/**
   * Subscribes to language change and define the page direction.
   * @private
   */: TranslateHelper
  ) { }

  public ngOnInit(): void {
    this.setTranslationConfig();
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslationConfig(): void {
    this.translate.onLangChange.subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
