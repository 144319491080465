import { Pipe, PipeTransform } from '@angular/core';
import { Languages } from '../_enums/languages';
import { TableRecord } from '../_models/table';
import { HalachicTimesService } from '../_services/halachic-times.service';

@Pipe({
  name: 'halachic',
  pure: true
  // pure: false
})
export class HalachicPipe implements PipeTransform {

  constructor(private halachicService: HalachicTimesService) {
  }

  /**
   * Returns halachic time name according to the record key and chosen language.
   * @param {TableRecord} value - current table record.
   * @param {Languages} language - chosen language.
   */
  public transform(value: TableRecord, language: Languages): string {
    if (!value) return;

    if (value.custom) {
      return value.custom;
    }

    const record = this.halachicService.times?.find(time => time.key === value.key);
    return record ? record[language] : null;
  }
}
