<table [dataSource]="dataSource" [style.direction]="direction" class="mat-elevation-z8" mat-table>

  <!-- Shortcut column -->
  <ng-container matColumnDef="keyCombination">
    <th *matHeaderCellDef [class.pr-5]="direction === 'rtl'" [style.text-align]="direction === 'rtl' ? 'right' : 'left'" mat-header-cell>
      {{ 'settings.shortcuts.keyCombination' | translate }}
    </th>
    <td *matCellDef="let shortcut" [class.pr-5]="direction === 'rtl'" [style.text-align]="direction === 'rtl' ? 'right' : 'left'" mat-cell>
      {{ shortcut.keyCombination }}
    </td>
  </ng-container>

  <!-- Purpose column -->
  <ng-container matColumnDef="purpose">
    <th *matHeaderCellDef [style.text-align]="direction === 'rtl' ? 'right' : 'left'" mat-header-cell>
      {{ 'settings.shortcuts.purpose' | translate }}
    </th>
    <td *matCellDef="let shortcut" [style.text-align]="direction === 'rtl' ? 'right' : 'left'" mat-cell>
      {{ 'settings.shortcuts.' + shortcut.purposeKey | translate }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
