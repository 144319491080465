import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { Subscription, timer } from "rxjs";
import { switchMap } from "rxjs/operators";
import { EffectTypes } from "src/app/_models";
import { ApiService } from "src/app/_services";

@Component({
  selector: 'app-rss-viewer',
  templateUrl: './rss-viewer.component.html',
  styleUrls: ['./rss-viewer.component.css']
})
export class RssViewerComponent implements OnDestroy, OnInit {
  @Input() effect: EffectTypes;
  @Input() rssUrl: string | null;

  public items: (string | SafeHtml)[] = [];
  private xmlSubscription: Subscription;
  rssType: 'html' | 'xml' = 'xml';

  constructor(
    private sanitizer: DomSanitizer,
    private api: ApiService
  ) {
  }

  private isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }

  public ngOnInit(): void {
    this.setupRSSFeed();
  }

  private setupRSSFeed(): void {
    if (!this.rssUrl || !this.isValidUrl(this.rssUrl)) {
      console.error('Invalid RSS URL:', this.rssUrl);
      return;
    }
    const split: string[] = RssViewerComponent.parseUrl(this.rssUrl);
    const xmlUrl: string = split[0];
    const refreshRate: number = +(split[1] || 60) * 60 * 1000;
    const toDelete: string = split[2] || '';

    this.xmlSubscription = timer(0, refreshRate)
      .pipe(switchMap(() => this.api.getRss(xmlUrl)))
      .subscribe(
        (xml: string) => {
          this.clearItemsIfNeeded();
          const visibleText = this.getVisibleText(xml, toDelete);
          this.items.push(visibleText);
        },
        (error) => {
          console.error('Error fetching RSS feed:', error);
          // Consider adding user feedback here, such as a message indicating the error.
        });
  }

  public ngOnDestroy(): void {
    this.xmlSubscription.unsubscribe();
  }

  /**
   * Splits URL by given separator
   * @param {string} url
   * @param {string} separator
   * @returns {string[]}
   * @private
   */
  private static parseUrl(url: string, separator = '||'): string[] {
    return url.split(separator);
  }

  /**
   * Returns text to be displayed from XML due to given parameters
   * @param {string} xml XML to be parsed
   * @param {string} toDelete Not to display
   * @returns {string | SafeHtml}
   * @private
   */
  private getVisibleText(xml: string, toDelete: string): string | SafeHtml {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');
    const rssArr: string[] = [];
    const descArr: Element[] = Array.from(xmlDoc.getElementsByTagName('description'));

    const deleteRegExp = new RegExp(toDelete, 'ig');

    descArr.forEach((node: Element) => rssArr.push(node.textContent || node.innerHTML));
    let joinedText = rssArr.join(' ');

    if (this.rssType === 'html') {
      // Sanitize and return HTML content safely
      return this.sanitizer.bypassSecurityTrustHtml(joinedText);
    } else {
      // Handle as plain text, stripping HTML and unwanted parts
      return joinedText
        .replace(/(<([^>]+)>)/ig, '')
        .replace(deleteRegExp, '')
        .replace(/&#176;/g, '°');
    }
  }

  private clearItemsIfNeeded(): void {
    if (this.effect === EffectTypes.Scroll) {
      // Logic to remove old items
      while (this.items.length > 200) {
        this.items.shift(); // Removes the first item
      }
    }
  }
}
