import { Pipe, PipeTransform } from '@angular/core';
import { TimeFormat } from '../_enums/time-format';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  /**
   * Formats the output time according to chosen preferences.
   * @param {string} time - chosen time.
   * @param {boolean} isUpper - whether the am/pm is capitalised.
   * @param {boolean} padZero - whether there is a need to pad the output time with zero: 1:35 vs 01:35.
   * @param {boolean} isAmPm - whether there is a need to display am/pm.
   * @param {boolean} displaySeconds - whether to display time seconds.
   * @private
   */
  private static format(time: string, isUpper: boolean, padZero: boolean, isAmPm: boolean, displaySeconds: boolean): string {
    let result = isUpper
      ? (padZero ? TimeFormatPipe.padZero(time.toUpperCase()) : time.toUpperCase())
      : (padZero ? TimeFormatPipe.padZero(time.toLowerCase()) : time.toLowerCase());

    if (displaySeconds) {
      if (result.toLowerCase().includes('m')) {
        const index = result.indexOf('m') - 2;
        let resultArr = result.split('');
        resultArr.splice(index, 0, ':00')
        result = resultArr.join('');
      } else {
        result += ':00';
      }
    }

    if (!isAmPm) {
      return result.replace('am', '').replace('pm', '').trim();
    }

    return result;
  }

  /**
   * Pads the output time with zero.
   * @param {string} time - chosen time.
   * @private
   */
  private static padZero(time: string): string {
    const timeArr = time.split(':');
    timeArr[0] = timeArr[0].padStart(2, '0');
    return timeArr.join(':');
  }

  /**
   * Defines whether the chosen time is in 12H format.
   * @param {string} time - chosen time.
   * @private
   */
  private static is12(time: string): boolean {
    return time.toLowerCase().includes('am') || time.toLowerCase().includes('pm');
  }

  /**
   * Transforms the time to 24H format.
   * @param {string} time - chosen time.
   * @private
   */
  private static getIn24(time: string): string {
    const h = parseInt(time, 10);
    const m = time.split(':')[1].split(' ')[0];
    return h + 12 + ':' + m;
  }

  /**
   * Transforms the time to 12H format.
   * @param {string} time - chosen time.
   * @param {boolean} isAmPm - whether there is a need to display am/pm.
   * @private
   */
  private static getIn12(time: string, isAmPm: boolean): string {
    let h = parseInt(time, 10);
    const m = time.split(':')[1];
    if (isAmPm) {
      return h >= 12 ? h - 12 + ':' + m + ' pm' : h + ':' + m + ' am';
    }
    return h >= 12 ? h - 12 + ':' + m : h + ':' + m;
  }

  /**
   * Formats the input time according to chosen parameters.
   * @param {string} time - chosen time.
   * @param {TimeFormat} format - time format (12H or 24H).
   * @param {boolean} isAmPm - whether there is a need to display am/pm.
   * @param {boolean} isUpper - whether the am/pm is capitalised.
   * @param {boolean} padZero - whether there is a need to pad the output time with zero: 1:35 vs 01:35.
   * @param {boolean} displaySeconds - whether to display time seconds.
   */
  public transform(time: string, format: TimeFormat, isAmPm: boolean, isUpper: boolean, padZero: boolean, displaySeconds: boolean): string {
    if (!time) return;

    if (format === TimeFormat._24H && !(TimeFormatPipe.is12(time)) || format === TimeFormat._12H && TimeFormatPipe.is12(time)) {
      return TimeFormatPipe.format(time, isUpper, padZero, isAmPm, displaySeconds);
    }

    if (format === TimeFormat._24H) {
      const result = time.toLowerCase().includes('am') ? time.split(' ')[0] : TimeFormatPipe.getIn24(time);
      return TimeFormatPipe.format(result, isUpper, padZero, isAmPm, displaySeconds);
    }

    const result = TimeFormatPipe.getIn12(time, isAmPm);
    return TimeFormatPipe.format(result, isUpper, padZero, isAmPm, displaySeconds);
  }
}
