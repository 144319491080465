import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, AfterViewInit, Input } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Languages, LocalStorage } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Screen, Widget } from "src/app/_models";
import { WidgetService } from "src/app/_services";
import { WidgetsState, ViewMode, changeViewByDateMode } from "src/app/store/widget";
import { AddTextByDateComponent } from "./add-text-by-date/add-text-by-date.component";
import { TextContentComponent } from "./text-content/text-content.component";

@Component({
  selector: "app-text-dashboard",
  templateUrl: "./text-dashboard.component.html",
  styleUrls: ["./text-dashboard.component.css"],
})
export class TextDashboardComponent implements OnInit, AfterViewInit {
  @Input() widget: Widget;
  @Input() screen: Screen;
  public selectByDate = '';
  public isHebrewRange: boolean;
  public toppings: UntypedFormGroup;
  public languages = Languages;
  public htmlContent: string;
  public direction: Direction = "ltr";
  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "15rem",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultFontSize: "3",
    outline: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["strikeThrough", "subscript", "superscript", "heading"],
      [
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "removeFormat",
        "insertHorizontalRule",
        "toggleEditorMode",
      ],
    ],
  };
  private textContentRef: MatDialogRef<TextContentComponent>;
  private newRowRef: MatDialogRef<AddTextByDateComponent>;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private widgetService: WidgetService,
    public fb: UntypedFormBuilder,
    private store: Store<{ widgets: WidgetsState }>
  ) { }
  public ngOnInit(): void {
    this.setTranslation();
    this.initShowDate();
    this.widgetService.widgetSelected$.subscribe((widget: Widget) => {
      this.changeTextBackgroundColor(widget.textBackgroundColor)
    });
  }

  /**
   * Gets the current app language from the Local storage.
   */
  public get language(): string {
    return localStorage.getItem(LocalStorage.Language) || this.translate.currentLang;
  }


  switchSelectedValue(event: MatRadioChange) {
    let viewMode: ViewMode;
    switch (event.value) {
      case 'isShowAllYear':
        viewMode = ViewMode.AllYear;
        break;
      case 'isShowMonth':
        viewMode = ViewMode.AllMonth
        break;
      case 'isShowWeek':
        viewMode = ViewMode.AllWeek;
        break;
      case 'isShowDay':
        viewMode = ViewMode.AllDay;
        break;
    }
    this.store.dispatch(changeViewByDateMode({ widgetId: this.widget.id, viewMode: viewMode }));

    this.resetSelectedValues();
    this.selectValue(event.value);
  }

  private resetSelectedValues(): void {
    const { text } = this.widget.content;
    text.isShowAllYear = false;
    text.isShowMonth = false;
    text.isShowWeek = false;
    text.isShowDay = false;
  }

  private selectValue(selectedValue: string): void {
    this.widget.content.text[selectedValue] = true;
  }

  public ngAfterViewInit(): void {
    const buttonTitles = [
      { en: 'Undo', he: 'לבטל', ru: 'Отменить', title: '' },
      { en: 'Redo', he: 'לעשות שוב', ru: 'Сделать вновь', title: '' },
      { en: 'Bold', he: 'מודגש', ru: 'Жирный', title: '' },
      { en: 'Italic', he: 'נטוי', ru: 'Курсив', title: '' },
      { en: 'Underline', he: 'קו תחתון', ru: 'Подчеркнуть', title: '' },
      { en: 'Justify Left', he: 'ליישר שמאלה', ru: 'Выравнять налево', title: '' },
      { en: 'Justify Center', he: 'ליישר במרכז', ru: 'Выравнять по центру', title: '' },
      { en: 'Justify Right', he: 'ליישר ימינה', ru: 'Выравнять направо', title: '' },
      { en: 'Justify Full', he: 'ליישר משני צדדים', ru: 'Выравнять полностью', title: '' },
      { en: 'Indent', he: 'הזחה', ru: 'Отступ', title: '' },
      { en: 'Outdent', he: 'הזחה החוצה', ru: 'Отступ наружу', title: '' },
      { en: 'Unordered List', he: 'רשימה לא מסודרת', ru: 'Неупорядоченный список', title: '' },
      { en: 'Ordered List', he: 'רשימה מסודרת', ru: 'Упорядоченный список', title: '' },
      { en: 'Text Color', he: 'צבע טקסט', ru: 'Цвет текста', title: '' },
      { en: 'Background Color', he: 'צבע רקע', ru: 'Фоновый цвет', title: '' }
    ];

    setTimeout(() => {
      this.changeTextBackgroundColor(this.widget.textBackgroundColor);

      buttonTitles.forEach((btnTitle) => {
        const button: HTMLElement = document.querySelector(
          `.angular-editor-button[title="${btnTitle.en}"]`
        );
        button && (btnTitle.title = button.title = btnTitle[this.translate.currentLang]);
      });

      this.translate.onLangChange.subscribe(({ lang }) => {
        buttonTitles.forEach((btnTitle) => {
          const button: HTMLElement = document.querySelector(
            `.angular-editor-button[title="${btnTitle.title}"]`
          );
          btnTitle.title = button.title = btnTitle[lang];
        });
      });
    }, 1500);
  }

  /**
   * Changes the text editor background to contrast the font colour.
   * @param {string} colour - a chosen editor background colour.
   */
  public changeTextBackgroundColor(colour: string): void {
    const textEditor: HTMLDivElement = document.querySelector(
      ".angular-editor-textarea"
    );
    if (!textEditor) return;
    textEditor.style.cssText = `
      height: auto;
      min-height: 15rem;
      outline: none;
      background-color: ${colour} !important
    `;
  }

  /**
   * Opens table content modal window. Passes data to TableContentComponent.
   */
  public openContentModal(): void {
    this.textContentRef = this.dialog.open(TextContentComponent, {
      hasBackdrop: false,
    });

    this.textContentRef.componentInstance.widget = this.widget;

    this.textContentRef
      .afterClosed()
      .subscribe(() => (this.textContentRef = null));
  }
  /**
   * Opens table content modal window. Passes data to TableContentComponent.
   */
  public openNewRowModal(): void {
    this.newRowRef = this.dialog.open(AddTextByDateComponent, {
      hasBackdrop: false,
      height: '80vh',
      width: '80vw',
    });
    this.newRowRef.componentInstance.config = {
      message: 'dsa',
      direction: this.direction,
      widget: this.widget
    };

    this.newRowRef
      .afterClosed()
      .subscribe(() => {
        const year = this.newRowRef.componentInstance.config.date.year;
        const month = this.newRowRef.componentInstance.config.date.month;
        const day = this.newRowRef.componentInstance.config.date.day;
        this.widget.content.text.byDate.push({
          dateDay: day.toString(),
          dateMonth: month.toString(),
          hebrewDate: new NgbDate(year, month, day),
          isDateHhebre: false,
          text: this.newRowRef.componentInstance.config.htmlContent,
          text1: "",
          text2: "",
          text3: "",
          text4: "",
          text5: "",
          text6: ""
        });
        this.textContentRef = null
      });
  }
  /**
   * Marks screen "dirty" for purpose of page leaving warning.
   */
  public markScreenDirty(): void {
    this.screen.dirty = true;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.editorConfig.placeholder =
      this.translate.currentLang === Languages.Hebrew
        ? "הזן טקסט כאן..."
        : "Enter text here...";
    this.translate.onLangChange.subscribe(({ lang }) => {
      this.direction = lang === Languages.Hebrew ? "rtl" : "ltr";
      this.editorConfig.placeholder =
        this.direction === "rtl" ? "הזן טקסט כאן..." : "Enter text here...";
    });
    this.direction = this.translateHelper.direction;
  }

  private initShowDate(): void {
    this.selectByDate = ['isShowAllYear', 'isShowMonth', 'isShowWeek', 'isShowDay']
      .find(value => this.widget.content.text[value]);
  }


  changeScrollDirection() { }
}
