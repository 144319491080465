import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Widget } from '../../_models/widget';

@Component({
  selector: 'app-web-viewer',
  templateUrl: './web-viewer.component.html',
  styleUrls: ['./web-viewer.component.css']
})
export class WebViewerComponent implements OnInit {
  @Input() widget: Widget;
  public safeUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  public ngOnInit(): void {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.widget.content.web.url);
  }
}
