import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TimesDto } from '../../../_dto/times-dto';
import { UserDto } from '../../../_dto/user-dto';
import { Languages } from '../../../_enums/languages';
import { AccountHelper } from '../../../_helpers/account.helper';
import { AccountService } from '../../../_services/account.service';
import { User } from '../../../_models/identity/user';
import { ValuesService } from '../../../_services/values.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  @Input() tabIndex = 0;

  public user$: Observable<UserDto>;
  public isAdmin = false;
  public times: TimesDto[];

  constructor(
    private valuesService: ValuesService,
    private translate: TranslateService,
    private accountService: AccountService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    translate.setDefaultLang(Languages.English);
    iconRegistry.addSvgIcon(
      'menorah',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/menorah.svg'));
  }

  public ngOnInit(): void {
    this.verifyIfAdmin();
    this.getTimes();
    this.user$ = this.accountService.getUser();
  }

  /**
   * Checks whether the current user is admin.
   * @private
   */
  private verifyIfAdmin(): void {
    this.accountService.currentUser$.subscribe((user: User) => this.isAdmin = AccountHelper.isAdmin());
  }

  /**
   * Gets halachic time objects related to the current user.
   * @private
   */
  private getTimes(): void {
    this.valuesService.getTimes().subscribe((times: TimesDto[]) => this.times = times);
  }
}
