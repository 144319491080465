import { Pipe, PipeTransform } from "@angular/core";

/**
 * A custom pipe that formats a list of strings as a string with a separator between them.
 * Escapes any HTML special characters in the strings.
 */
@Pipe({
  name: "separator",
})
export class SeparatorPipe implements PipeTransform {
  /**
   * Formats a list of strings as a string with a separator between them.
   * Escapes any HTML special characters in the strings.
   *
   * @param value - The list of strings to be formatted.
   * @param separator - The separator to be used between the strings.
   * @param separatorAtEnd - Whether to add the separator at the end of the formatted string.
   * @returns The formatted string.
   */
  transform(
    value: string[],
    separator: string,
    separatorAtEnd: boolean = false
  ): string {
    if (value.length === 0) return "";

    return (
      value.join(` ${separator} `) + (separatorAtEnd ? ` ${separator} ` : "")
    );
  }
}
