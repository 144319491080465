<div class="settings container-sm d-flex justify-content-center align-items-center">
  <mat-form-field [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-label>{{ 'jewishSettings.format' | translate }}</mat-label>
    <mat-select [(value)]="user.settings.gregorianDate">
      <mat-option *ngFor="let format of dateFormats" [value]="format">{{ format }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<mat-card-subtitle class="mt-3 font-italic text-center">{{ 'jewishSettings.overridden' | translate }}</mat-card-subtitle>

<mat-list class="explanation">
  <mat-list-item><span class="font-italic format-token">M</span>&nbsp;- 1 2 ... 12</mat-list-item>
  <mat-list-item><span class="font-italic format-token">MM</span>&nbsp;- 01 02 ... 12</mat-list-item>
  <mat-list-item><span class="font-italic format-token">MMM</span>&nbsp;- Jan Feb ... Dec</mat-list-item>
  <mat-list-item><span class="font-italic format-token">MMMM</span>&nbsp;- January February ... December</mat-list-item>
  <mat-list-item><span class="font-italic format-token">D</span>&nbsp;- 1 2 ... 31</mat-list-item>
  <mat-list-item><span class="font-italic format-token">DD</span>&nbsp;- 01 02 ... 31</mat-list-item>
  <mat-list-item><span class="font-italic format-token">YY</span>&nbsp;- 84 85 ... 21</mat-list-item>
  <mat-list-item><span class="font-italic format-token">YYYY</span>&nbsp;- 1984 1985 ... 2021</mat-list-item>
</mat-list>
