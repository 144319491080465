import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-be-patient',
  templateUrl: './be-patient.component.html',
  styleUrls: ['./be-patient.component.css']
})
export class BePatientComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
