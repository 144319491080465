import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshPipeService {
  private refreshSource = new BehaviorSubject<void>(null);

  public refresh$: Observable<void> = this.refreshSource.asObservable();

  public refreshPipe(): void {
    this.refreshSource.next();
  }
}
