<div [class.ru-width]="language === languages.Russian">

<!--  Multiple lines-->
  <div class="settings container-sm d-flex justify-content-center align-items-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-slide-toggle (change)="widget.content.jewish.multipleLines = !widget.content.jewish.multipleLines"
                      (change)="widget.content.jewish.multipleLines === true &&  widget.content.jewish.multipleLinesRemoveLines === false? widget.content.jewish.smallTextAuto = true : ''  "
                      (change)="widget.content.jewish.multipleLines === false ? widget.content.jewish.multipleLinesRemoveLines =false : ''  "
                      (change)="widget.content.jewish.multipleLines === false ? widget.content.jewish.smallTextAuto =false : ''  "
                      (change)="makeTextSmallOrBreakLines()"
                      [checked]="widget.content.jewish.multipleLines"
                      (change)="changeScrollDirection()"
                      [style.direction]="'ltr'"
                      labelPosition="before">
      <span class="mx-3">{{ 'dashboard.widgetProperties.specific.jewish.multiple' | translate }}</span>
    </mat-slide-toggle>
  </div>

  <!--  multipleLines remove lines-->
  <div *ngIf="widget.content.jewish.multipleLines">
    <div class="settings container-sm d-flex justify-content-center align-items-center"
        [style.direction]="direction"
        [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-slide-toggle (change)="widget.content.jewish.multipleLinesRemoveLines= !widget.content.jewish.multipleLinesRemoveLines"
                        (change)="widget.content.jewish.multipleLines === true &&  widget.content.jewish.multipleLinesRemoveLines === false? widget.content.jewish.smallTextAuto = true : ''  "
                        (change)="widget.content.jewish.multipleLinesRemoveLines === true ? widget.content.jewish.smallTextAuto = false : '' "
                        (change)="makeTextSmallOrBreakLines()"
                        [checked]="widget.content.jewish.multipleLinesRemoveLines"
                        [style.direction]="'ltr'"
                        labelPosition="before">
                        <span class="mx-3">{{ 'dashboard.widgetProperties.specific.jewish.multipleLinesRemoveLines' | translate }}</span>
      </mat-slide-toggle>
    </div>
  </div>

  <!--  Small text auto-->
  <div *ngIf="widget.content.jewish.multipleLines">
    <div class="settings container-sm d-flex justify-content-center align-items-center"
        [style.direction]="direction"
        [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-slide-toggle (change)="widget.content.jewish.smallTextAuto = !widget.content.jewish.smallTextAuto"
                        (change)="widget.content.jewish.multipleLines === true &&  widget.content.jewish.smallTextAuto === false? widget.content.jewish.multipleLinesRemoveLines = true : ''  "
                        (change)="widget.content.jewish.smallTextAuto === true ? widget.content.jewish.multipleLinesRemoveLines = false : '' "
                        (change)="makeTextSmallOrBreakLines()"
                        [checked]="widget.content.jewish.smallTextAuto"
                        [style.direction]="'ltr'"
                        labelPosition="before">
        <span class="mx-3">{{ 'dashboard.widgetProperties.specific.jewish.smallTextAuto' | translate }}</span>
      </mat-slide-toggle>
    </div>
  </div>

<!--  Choose content-->
  <div class="settings container-sm d-flex justify-content-center align-items-center">
    <button mat-raised-button (click)="openContentModal()"
            [style.direction]="direction"
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-icon>check_box</mat-icon>
      {{ 'dashboard.widgetProperties.specific.jewish.choose' | translate }}
    </button>
  </div>

<!-- Separator -->
  <div class="settings container-sm d-flex justify-content-between align-items-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.jewish.separator' | translate }}</span>
    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                    appearance="standard"
                    class="select-font w-50">
      <mat-label>{{ 'dashboard.widgetProperties.specific.jewish.separatorPlaceholder' | translate }}</mat-label>
      <input [(ngModel)]="widget.separator"
             matInput
             type="text"
             (keydown.delete)="preventPropagation($event)"
             (input)="changeSeparator()">
    </mat-form-field>
  </div>

<!--  Time format-->
  <div class="settings container-sm d-flex justify-content-between"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.jewish.timeFormat' | translate }}</span>
    <mat-radio-group [(ngModel)]="widget.content.jewish.timeFormat" (change)="onTimeFormatChange($event)">
      <mat-radio-button [value]="timeFormat._12H" [ngClass]="language === languages.Russian ? 'ml-3' : 'mx-3'">
        {{ 'dashboard.widgetProperties.specific.jewish.12h' | translate }}
      </mat-radio-button>
      <mat-radio-button [value]="timeFormat._24H">
        {{ 'dashboard.widgetProperties.specific.jewish.24h' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <!--  Zero padding-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span>{{ 'dashboard.widgetProperties.specific.jewish.zero' | translate }}</span>
    <mat-slide-toggle (change)="widget.content.jewish.padZero = !widget.content.jewish.padZero; markScreenDirty()"
                      [checked]="widget.content.jewish.padZero">
    </mat-slide-toggle>
  </div>

<!--  Hebrew date-->
  <div class="settings container-sm d-flex justify-content-between align-items-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title">{{ 'dashboard.widgetProperties.specific.jewish.hebrewDate' | translate }}</span>
    <mat-button-toggle-group multiple [class.ml-2]="language === languages.Russian">
      <mat-button-toggle (change)="widget.content.jewish.hebrewDateDay = !widget.content.jewish.hebrewDateDay"
                         [checked]="widget.content.jewish.hebrewDateDay">
        {{ 'dashboard.widgetProperties.specific.jewish.day' | translate }}
      </mat-button-toggle>
      <mat-button-toggle (change)="widget.content.jewish.hebrewDateMonth = !widget.content.jewish.hebrewDateMonth"
                         [checked]="widget.content.jewish.hebrewDateMonth">
        {{ 'dashboard.widgetProperties.specific.jewish.month' | translate }}
      </mat-button-toggle>
      <mat-button-toggle (change)="widget.content.jewish.hebrewDateYear = !widget.content.jewish.hebrewDateYear"
                         [checked]="widget.content.jewish.hebrewDateYear">
        {{ 'dashboard.widgetProperties.specific.jewish.year' | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

<!--  Gregorian date-->
  <div class="settings container-sm d-flex justify-content-between align-items-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title">{{ 'dashboard.widgetProperties.specific.jewish.gregorianDate' | translate }}</span>
    <mat-form-field [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.specific.jewish.format' | translate }}</mat-label>
      <mat-select [(value)]="widget.content.jewish.gregorianDate">
        <mat-option *ngFor="let format of dateFormats" [value]="format"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          {{ format }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

<!--  Parasha-->
  <div class="settings container-sm d-flex justify-content-between align-items-center parasha"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title">{{ 'dashboard.widgetProperties.specific.jewish.parasha' | translate }}</span>
    <mat-slide-toggle (change)="widget.content.jewish.parasha = !widget.content.jewish.parasha"
                      [checked]="widget.content.jewish.parasha"
                      [style.direction]="'ltr'">
      <span class="mx-3">{{ 'dashboard.widgetProperties.specific.jewish.caption' | translate }}</span>
    </mat-slide-toggle>
  </div>

  <!-- Day -->
  <div class="settings container-sm d-flex justify-content-between align-items-center parasha"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title">{{ 'dashboard.widgetProperties.specific.jewish.day' | translate }}</span>
    <mat-slide-toggle (change)="widget.content.jewish.dayOfWeek = !widget.content.jewish.dayOfWeek"
                      [checked]="widget.content.jewish.dayOfWeek"
                      [style.direction]="'ltr'">
      <span class="mx-3">{{ 'dashboard.widgetProperties.specific.jewish.caption' | translate }}</span>
    </mat-slide-toggle>
  </div>

<!--  Daf Hayomi-->
  <div class="settings container-sm d-flex justify-content-between align-items-center parasha"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title">{{ 'dashboard.widgetProperties.specific.jewish.daf' | translate }}</span>
    <mat-slide-toggle (change)="widget.content.jewish.dafYomi = !widget.content.jewish.dafYomi"
                      [checked]="widget.content.jewish.dafYomi"
                      [style.direction]="'ltr'">
      <span class="mx-3">{{ 'dashboard.widgetProperties.specific.jewish.caption' | translate }}</span>
    </mat-slide-toggle>
  </div>

<!--  Sefirat Haomer-->
  <div class="settings container-sm d-flex justify-content-between align-items-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title">{{ 'dashboard.widgetProperties.specific.jewish.omer' | translate }}</span>
    <div class="w-50 d-flex justify-content-between align-items-center" [style.direction]="'ltr'">
      <span>{{ 'dashboard.widgetProperties.specific.jewish.short' | translate }}</span>
      <mat-slide-toggle (change)="widget.content.jewish.sefiratHaomer = !widget.content.jewish.sefiratHaomer"
                        [checked]="widget.content.jewish.sefiratHaomer">
      </mat-slide-toggle>
      <span>{{ 'dashboard.widgetProperties.specific.jewish.long' | translate }}</span>
    </div>
  </div>
</div>
