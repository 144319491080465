import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { KosherZmanimOptionDto } from '../../../../_dto/kosher-zmanim-option-dto';
import { TimesDto } from '../../../../_dto/times-dto';
import { Languages } from '../../../../_enums/languages';
import { Direction, TranslateHelper } from '../../../../_helpers/translate.helper';
import { AccountService } from '../../../../_services/account.service';
import { SnackbarService } from '../../../../_services/snackbar.service';
import { ValuesService } from '../../../../_services/values.service';

@Component({
  selector: 'app-dynamic-times',
  templateUrl: './zmanim.component.html',
  styleUrls: ['./zmanim.component.css']
})
export class ZmanimComponent implements OnInit {
  @Input() times: TimesDto[];

  public direction: Direction = 'ltr';
  public userTimes: KosherZmanimOptionDto[];
  public activeTabIndex = 0;

  constructor(
    private valuesService: ValuesService,
    private accountService: AccountService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnInit(): void {
    this.setTranslationConfiguration();
    this.getUserTimes();
  }

  public save(): void {
    this.accountService.updateUserTimes(this.userTimes).subscribe(
      () => {
        this.snackbar.success(this.translate.instant('snackbar.times'), this.translate.instant('buttons.ok'));
        window.location.reload();
      },
      error => {
        console.log(error);
        this.snackbar.error(error.error.Message, 'Ok', 5000);
      }
    );
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslationConfiguration(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }

  /**
   * Gets the halachic time objects related to the current user.
   * @private
   */
  private getUserTimes(): void {
    this.accountService.getUserTimes().subscribe(times => this.userTimes = times);
  }
}
