import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbCalendar, NgbCalendarHebrew, NgbDatepickerI18n, NgbDatepickerI18nHebrew, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { TimesPipe } from '../../_pipes/times.pipe';
import { HeaderComponent } from './header/header.component';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { BePatientComponent } from './be-patient/be-patient.component';
import { ValueTextPipe } from 'src/app/_pipes/value-text.pipe';
import { ValueTextByMonthPipe } from 'src/app/_pipes/value-text-by-month.pipe';
import { ValueTextByWeekPipe } from 'src/app/_pipes/value-text-by-week.pipe';
import { ValueTextByYearPipe } from 'src/app/_pipes/value-text-by-year.pipe';
import { DateSelectorComponent } from 'src/app/date-selector/date-selector.component';
import { FormatHebrewDatePipe } from 'src/app/_pipes/format-hebrew-date.pipe';
import { FormsModule } from '@angular/forms';
import { ValueTextByPipe } from 'src/app/_pipes/value-text-by.pipe';
import { ByPassSecurityPipe } from 'src/app/_pipes/by-pass-security.pipe';
import { RemoveInlineStylesPipe } from 'src/app/_pipes/remove-inline-styles.pipe';
import { ValueTextByDayPipe } from 'src/app/_pipes/value-text-by-day.pipe';

const matModules = [
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatMenuModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatToolbarModule,
  MatIconModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatDividerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatListModule,
  MatButtonToggleModule,
  MatSidenavModule,
  MatSliderModule,
  MatChipsModule,
  MatRadioModule,
  MatBadgeModule,
  MatExpansionModule
];

@NgModule({
  declarations: [
    MenuBarComponent,
    HeaderComponent,
    BePatientComponent,
    DateSelectorComponent,
    TimesPipe,
    ValueTextPipe,
    ValueTextByDayPipe,
    FormatHebrewDatePipe,

    ValueTextByMonthPipe,
    ValueTextByWeekPipe,
    ValueTextByYearPipe,
    ValueTextByPipe,
    ByPassSecurityPipe,
    RemoveInlineStylesPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ...matModules,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient]
      }
    }),
    GooglePlaceModule
  ],
  exports: [
    ...matModules,
    TranslateModule,
    GooglePlaceModule,
    NgbModule,
    MenuBarComponent,
    HeaderComponent,
    TimesPipe,
    ValueTextPipe,
    ValueTextByDayPipe,
    ValueTextByMonthPipe,
    ValueTextByWeekPipe,
    ValueTextByYearPipe,
    ByPassSecurityPipe,
    ValueTextByPipe,
    FormatHebrewDatePipe,
    RemoveInlineStylesPipe,
    DateSelectorComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: NgbCalendar, useClass: NgbCalendarHebrew },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nHebrew }
  ]
})
export class SharedModule { }
