import { Pipe, PipeTransform } from "@angular/core";
import {
  NgbCalendarHebrew,
  NgbDate,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { ChangeTimes } from "../_enums/change-times";
import { JewishHelper } from "../_helpers/jewish.helper";
import { TextByDateEntry } from "../_models/TextByDateEntry";
import { Widget } from "../_models/widget";
import { HalachicTimesService } from "../_services/halachic-times.service";

@Pipe({
  name: "valueTextByMonth",
})
export class ValueTextByMonthPipe implements PipeTransform {
  public dayLink = 0;
  public jewishDateDayLink: any;
  public hebrewDate: NgbDateStruct;
  public textValue = [];
  public isSeeLineTexts = [];
  private jewishCalendar = new NgbCalendarHebrew();
  public textValueAfter = [];
  index;
  constructor(private halachicTimesService: HalachicTimesService) { }

  transform(value: TextByDateEntry[], selDate: Date, widget: Widget): string[] {
    this.textValueAfter = [];
    for (let i = 0; i < value.length; i++) {
      this.index = i;

      const selectedDate = new Date(selDate);
      this.dayLink = this.getDayLink(selectedDate, widget.changeTimeKey);
      const dayLinkDate = ValueTextByMonthPipe.getGregorianDateByDayLink(
        selectedDate,
        this.dayLink
      );
      const ngbDate = new NgbDate(
        dayLinkDate.getFullYear(),
        dayLinkDate.getMonth() + 1,
        dayLinkDate.getDate()
      );
      this.hebrewDate = this.jewishCalendar.fromGregorian(ngbDate);
      let ishLeapYear = JewishHelper.isJewishLeapYear(this.hebrewDate.year);
      this.jewishDateDayLink = this.hebrewDate;
      if (widget.content.text.isDateHhebre == false) {
        // If normal year (not passed)
        if (
          ishLeapYear != true ||
          (ishLeapYear == true && this.jewishDateDayLink.month < 6)
        ) {
          //If a leap year
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == this.jewishDateDayLink.month
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }
        }
        if (ishLeapYear == true && this.jewishDateDayLink.month >= 6) {
          // NISAN
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == 7 &&
            this.jewishDateDayLink.month == 8
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }

          // IAAR
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == 8 &&
            this.jewishDateDayLink.month == 9
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }
          // SIVAN
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == 9 &&
            this.jewishDateDayLink.month == 10
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }
          // TAMUZ
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == 10 &&
            this.jewishDateDayLink.month == 11
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }
          // AV
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == 11 &&
            this.jewishDateDayLink.month == 12
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }
          // ADAR A'
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == 14 &&
            this.jewishDateDayLink.month == 6
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }
          // ADAR B'
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == 13 &&
            this.jewishDateDayLink.month == 7
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }
          // ELUL
          if (
            this.jewishCalendar.fromGregorian(value[this.index].hebrewDate).month == 12 &&
            this.jewishDateDayLink.month == 13
          ) {
            value[this.index].text !== "" && this.textValueAfter.push(value[this.index].text);
          }
        }
      }
    }
    return this.textValueAfter;
  }
  private getDayLink(
    date: Date,
    changeTimeKey: ChangeTimes = ChangeTimes.Tzais
  ): number {
    const changeTimeOption = this.halachicTimesService.times.find(
      (time) => time.key === changeTimeKey
    )?.option;
    return date >
      new Date(this.halachicTimesService.halachicTimes[changeTimeOption])
      ? 1
      : 0;
  }

  public static getGregorianDateByDayLink(date: Date, dayLink: number): Date {
    return new Date(date.setDate(date.getDate() + dayLink));
  }
}
