import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, publishReplay, refCount, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { LoginDto } from '../_dto/login-dto';
import { RegisterDto } from '../_dto/register-dto';
import { ResetPasswordDto } from '../_dto/reset-password-dto';
import { KosherZmanimOptionDto } from '../_dto/kosher-zmanim-option-dto';
import { UserDto } from '../_dto/user-dto';
import { LocalStorage } from '../_enums/local-storage';
import { AccountHelper } from '../_helpers/account.helper';
import { User } from '../_models/identity/user';
import { BaseApiService } from './base-api-service';
import { ContentApiService } from './content-api.service';
import { PresenceService } from './presence.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseApiService {
  private apiUrl = environment.apiUrl;
  private currentUserSource = new ReplaySubject<User>(1);
  private users$: Observable<UserDto[]>;
  private user$: Observable<UserDto>;
  private userTimes$: Observable<KosherZmanimOptionDto[]>;

  public currentUser$ = this.currentUserSource.asObservable();

  constructor(private http: HttpClient,
              private router: Router,
              private presence: PresenceService,
              private contentApiService: ContentApiService) {
    super();
  }

  /**
   * Fetches the current user account details from a server.
   * @param {string} token - JWT token.
   */
  public loadCurrentUser(token: string): Observable<void> {
    if (!token) {
      this.currentUserSource.next(null);
      return of(null);
    }

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${token}`);

    return this.http.get<User>(this.apiUrl + 'account', { headers }).pipe(
      map((user: User) => {
          if (user) {
            console.log('user (auth):', user);
            this.setUserInLocalStorageAndInService(user);
            if (AccountHelper.isAdmin()) {
              this.router.navigate(['admin']).catch(error => console.log(error));
            }
          }
        }
      )
    );
  }

  /**
   * Fetches the list of users from a server and caches it.
   */
  public getUsers(): Observable<UserDto[]> {
    if (!this.users$) {
      this.users$ = this.http.get<UserDto[]>(this.apiUrl + 'account/users', { headers: AccountService.authorizationHeader })
        .pipe(
          publishReplay(1),
          refCount()
        );
    }

    return this.users$;
  }

  /**
   * Fetches the current user (DTO) from a server and caches it.
   */
  public getUser(): Observable<UserDto> {
    if (!this.user$) {
      this.user$ = this.http.get<UserDto>(this.apiUrl + 'account/currentuser', { headers: AccountService.authorizationHeader })
        .pipe(
          publishReplay(1),
          refCount()
        );
    }

    return this.user$;
  }

  /**
   * Fetches the list of times for a current user from a server and caches it.
   */
  public getUserTimes(): Observable<KosherZmanimOptionDto[]> {
    // return this.http.get<TimeDto[]>(this.apiUrl + 'account/usertimes', { headers: AccountService.authorizationHeader });
    if (!this.userTimes$) {
      this.userTimes$ = this.http.get<KosherZmanimOptionDto[]>(this.apiUrl + 'account/usertimes', { headers: AccountService.authorizationHeader })
        .pipe(
          publishReplay(1),
          refCount()
        );
    }

    return this.userTimes$;
  }

  /**
   * Updates the times for a current user on server.
   * @param {KosherZmanimOptionDto[]} times - updated times.
   */
  public updateUserTimes(times: KosherZmanimOptionDto[]): Observable<KosherZmanimOptionDto[]> {
    this.clearUserTimesCache();
    return this.http.post<KosherZmanimOptionDto[]>(this.apiUrl + 'account/usertimes', times, { headers: AccountService.authorizationHeader });
  }

  /**
   * Logs in.
   * @param {LoginDto} loginDto - login credentials.
   */
  public login(loginDto: LoginDto): Observable<User> {
    return this.http.post<User>(this.apiUrl + 'account/login', loginDto).pipe(
      tap((user: User) => {
        if (user) {
          this.setUserInLocalStorageAndInService(user);
          this.presence.createHubConnection(user.token);
        }
      })
    );
  }

  /**
   * Registers a new user.
   * @param {RegisterDto} registerDto - register credentials.
   */
  public register(registerDto: RegisterDto): Observable<User> {
    return this.http.post<User>(this.apiUrl + 'account/register', registerDto);
  }

  /**
   * Removes user details from the Local Storage and emits null as a user observable.
   */
  public logout(): void {
    this.presence.stopHubConnection();
    if (!AccountHelper.isAdmin()) {
      this.contentApiService.stopHubConnection();
    }
    localStorage.removeItem(LocalStorage.Token);
    this.currentUserSource.next(null);
    this.router.navigate(['account', 'login']);
  }

  /**
   * Checks at registration whether an email exists.
   * @param {string} email - an email to check.
   */
  public checkEmailExists(email: string): Observable<boolean> {
    return this.http.get<boolean>(this.apiUrl + 'account/emailexists?email=' + email);
  }

  /**
   * Checks at registration whether a username exists.
   * @param {string} username - a username to check.
   */
  public checkUsernameExists(username: string): Observable<boolean> {
    return this.http.get<boolean>(this.apiUrl + 'account/usernameexists?username=' + username);
  }

  /**
   * Updates the user.
   * @param {UserDto} userDto - user details.
   */
  public update(userDto: UserDto): Observable<UserDto> {
    this.clearUserCache();
    return this.http.put<UserDto>(this.apiUrl + 'account/', userDto, { headers: AccountService.authorizationHeader });
  }

  /**
   * Updates the user profile.
   * @param {UserDto} userDto - user details.
   */
  public updateProfile(userDto: UserDto): Observable<UserDto> {
    return this.http.put<UserDto>(this.apiUrl + 'account/profile', userDto, { headers: AccountService.authorizationHeader });
  }

  /**
   * Deletes user by ID.
   * @param {number} id - user ID.
   */
  public deleteUser(id: string): Observable<UserDto> {
    return this.http.delete<UserDto>(this.apiUrl + 'account/' + id, { headers: AccountService.authorizationHeader });
  }

  /**
   * Deletes selected users by their IDs.
   * @param {string[]} ids - slected user IDs.
   */
  public deleteUsers(ids: string[]): void {
    ids.forEach(id => this.http.delete(this.apiUrl + 'account/' + id, { headers: AccountService.authorizationHeader }).subscribe());
  }

  /**
   * Resets a user password.
   * @param {ResetPasswordDto} model - reset password credentials.
   */
  public resetPassword(model: ResetPasswordDto): Observable<any> {
    return this.http.post(this.apiUrl + 'account/resetPassword', model, { headers: AccountService.authorizationHeader });
  }

  /**
   * Clears the users cache.
   */
  public clearUsersCache(): void {
    this.users$ = null;
  }

  /**
   * Clears the user cache.
   */
  public clearUserCache(): void {
    this.user$ = null;
  }

  /**
   * Clears the user times cache.
   * @private
   */
  private clearUserTimesCache(): void {
    this.userTimes$ = null;
  }

  /**
   * Sets a user into the Local Storage and emits it as observable.
   * @param {User} user - user account details.
   * @private
   */
  private setUserInLocalStorageAndInService(user: User): void {
    localStorage.setItem(LocalStorage.Token, user.token);
    this.currentUserSource.next(user);
  }
}
