<div
  class="container position-relative"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <mat-card mat-dialog-content [style.direction]="direction">
    <button
      [title]="'buttons.close' | translate"
      class="close-btn"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon>close</mat-icon>
    </button>

    <mat-card-header >
      <mat-card-subtitle translate>{{
        "dashboard.widgetProperties.specific.textByDate.newText" | translate
      }}</mat-card-subtitle>
    </mat-card-header>

    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <div class="control-pair">
        <div
          *ngIf="widget.content.text.isDateHhebre == true"
          style="display: flex; justify-content: center"
        >
          <div
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="contoning-select-date-box"
          >
            <h4>בחירת חודש לועזי</h4>
            <mat-form-field appearance="fill">
              <mat-label
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                >חודש לועזי</mat-label
              >
              <mat-select
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                [formControl]="dateControlMonts"
                required
              >
                <!-- <mat-option>--</mat-option> -->
                <mat-option *ngFor="let mon of montsDate" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"  [value]="mon">
                  {{ mon.name }}
                </mat-option>
              </mat-select>
              <mat-hint>{{ dateControlMonts.value?.num }}</mat-hint>
            </mat-form-field>
          </div>

          <div
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="contoning-select-date-box"
          >
            <h4>בחירת יום</h4>
            <mat-form-field appearance="fill">
              <mat-label
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                >יום</mat-label
              >
              <mat-select
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                [formControl]="dateControlDays"
                required
              >
                <!-- <mat-option>--</mat-option> -->
                <mat-option *ngFor="let day of daysDate" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"  [value]="day">
                  {{ day.name }}
                </mat-option>
              </mat-select>
              <!-- <mat-error *ngIf="controlDays.hasError('required')"
                >Please choose a date</mat-error -->
              >
              <mat-hint>{{ controlDays.value?.name }}</mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div
          *ngIf="widget.content.text.isDateHhebre == false"
          style="display: flex; justify-content: center"
        >
          <div
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="contoning-select-date-box"
          >
            <!-- <h4 [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
              בחירת חודש עברי
            </h4> -->
            <mat-form-field appearance="fill" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            >
              <mat-label
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
              >חודש עברי</mat-label>
              <mat-select
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                [formControl]="controlMonts"
                required              >
                <!-- <mat-option>--</mat-option> -->
                <mat-option *ngFor="let mon of monts" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"  [value]="mon">
                  {{ mon.name }}
                </mat-option>
              </mat-select>
              <!-- Please choose a month -->
              <mat-error *ngIf="controlMonts.hasError('required')">בחר חודש</mat-error>
            </mat-form-field>
          </div>
          <div class="contoning-select-date-box">
            <!-- <h4 [style.text-align]="direction === 'rtl' ? 'right' : 'left'">בחירת יום עברי</h4> -->
            <mat-form-field
              appearance="fill"
              [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            >
              <mat-label
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                >יום</mat-label
              >
              <mat-select
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                [formControl]="controlDays"
                required
              >
                <!-- <mat-option>--</mat-option> -->
                <mat-option *ngFor="let day of days" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"  [value]="day">
                  {{ day.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlDays.hasError('required')">בחר יום</mat-error>

            </mat-form-field>
          </div>
        </div>
      </div>

      <!--      text-->
      <div style="flex-wrap: wrap">
        <!-- <div > -->
        <div class="flex-item">
          <mat-form-field
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="mat-form-field-contind-input"
          >
            <mat-label
              ><sup class="required">*</sup
              >{{
                "dashboard.widgetProperties.specific.textByDate.text"
                  | translate
              }}</mat-label
            >
            <input matInput formControlName="text" type="text" />
          </mat-form-field>
        </div>

        <!--      text1      -->
        <div class="flex-item">
          <mat-form-field
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="mat-form-field-contind-input"
          >
            <mat-label>{{
              "dashboard.widgetProperties.specific.textByDate.text" | translate
            }}</mat-label>
            <input matInput formControlName="text1" type="text" />
          </mat-form-field>
        </div>

        <!--      text2      -->
        <div class="flex-item">
          <mat-form-field
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="mat-form-field-contind-input"
          >
            <mat-label>{{
              "dashboard.widgetProperties.specific.textByDate.text" | translate
            }}</mat-label>
            <input matInput formControlName="text2" type="text" />
          </mat-form-field>
        </div>
        <!-- </div> -->
        <!--      text3      -->
        <div class="flex-item">
          <mat-form-field
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="mat-form-field-contind-input"
          >
            <mat-label>{{
              "dashboard.widgetProperties.specific.textByDate.text" | translate
            }}</mat-label>
            <input matInput formControlName="text3" type="text" />
          </mat-form-field>
        </div>

        <!--      text4      -->
        <div class="flex-item">
          <mat-form-field
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="mat-form-field-contind-input"
          >
            <mat-label>{{
              "dashboard.widgetProperties.specific.textByDate.text" | translate
            }}</mat-label>
            <input matInput formControlName="text4" type="text" />
          </mat-form-field>
        </div>

        <!--      text5      -->
        <div class="flex-item">
          <mat-form-field
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="mat-form-field-contind-input"
          >
            <mat-label>{{
              "dashboard.widgetProperties.specific.textByDate.text" | translate
            }}</mat-label>
            <input matInput formControlName="text5" type="text" />
          </mat-form-field>
        </div>

        <!-- text6 -->
        <div class="flex-item">
          <mat-form-field
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="mat-form-field-contind-input"
          >
            <mat-label>{{
              "dashboard.widgetProperties.specific.textByDate.text" | translate
            }}</mat-label>
            <input matInput formControlName="text6" type="text" />
          </mat-form-field>
        </div>
      </div>

      <!-- <div mat-dialog-actions class="actions"> -->
      </form>
    </mat-card>
    <div mat-dialog-actions class="actions" [ngClass]="direction === 'rtl' ? 'btns-he' : 'btns-hn'"
     >
      <button
        color="accent"
        mat-raised-button
        [mat-dialog-close]="false"
        type="button"
      >
        {{ "buttons.cancel" | translate }}
      </button>
      <button
      [disabled]="!controlMonts.value || !controlDays.value || !controlMonts.value && !controlDays.value"
        color="primary"
        mat-raised-button
        [mat-dialog-close]="true"
        type="submit"
        (click)="onSubmit()"
      >
        {{ "dashboard.widgetProperties.specific.textByDate.add" | translate }}
      </button>
    </div>
</div>
