import { Component, OnInit, AfterViewInit, OnChanges, Input, ViewChild, ElementRef, SimpleChanges } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { getZmanimJson, JewishCalendar } from "kosher-zmanim";
import { Observable, timer, Subscription, merge } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { UserDto, KosherZmanimOptionDto } from "src/app/_dto";
import { Languages, RoundingOptions, AlignItems, Highlight, TimeFormat, ChangeTimes } from "src/app/_enums";
import { JewishHelper, SchedulerHelper } from "src/app/_helpers";
import { Widget, EffectTypes, HorizontalAlign, ScrollDirections, TableRecords, KosherZmanimObject, KosherZmanimObjectTwoDays, VerticalAlign, TableRecord } from "src/app/_models";
import { FilterTableRecordsPipe } from "src/app/_pipes";
import { HalachicTimesService, DateService, RefreshPipeService, WidgetService } from "src/app/_services";
import { AlertService } from "../alert-viewer/alert.service";

export class ResizeObservable extends Observable<ResizeObserverEntry[]> {
  // Constructor that creates an Observable that listens for resize events on the provided element
  constructor(element: HTMLElement) {
    super(subscriber => {
      // Create a ResizeObserver instance that sends resize events to the subscriber
      const resizeObserver = new ResizeObserver(entries => {
        subscriber.next(entries);
      });

      // Observe the provided element for resize events
      resizeObserver.observe(element);

      // Return a function that unsubscribes from the resize events
      return function unsubscribe() {
        resizeObserver.unobserve(element);
        resizeObserver.disconnect();
      }
    });
  }
}

enum WidthClassName {
  Right = 'right-width',
  Left = 'left-width'
}

@Component({
  selector: 'app-table-viewer',
  templateUrl: './table-viewer.component.html',
  styleUrls: ['./table-viewer.component.css']
})
export class TableViewerComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() user: UserDto;
  private _widget: Widget;
  @Input() set widget(widget: Widget) {
    this._widget = widget;
  }

  get widget(): Widget {
    return this._widget;
  }

  @Input() effect: EffectTypes;
  @Input() highlightColor: string;

  @ViewChild('container') scrollElement: ElementRef<HTMLElement>;
  @ViewChild('wrapper') parentElement: ElementRef<HTMLElement>;
  @ViewChild(FilterTableRecordsPipe) filterTableRecordsPipe: FilterTableRecordsPipe;

  public get horizontalAlignCenter(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Center;
  }

  get isVertical(): boolean {
    return (
      this.widget.effect.scrollDirection === ScrollDirections.Up ||
      this.widget.effect.scrollDirection === ScrollDirections.Down
    );
  }

  public DEFAULT_HIGHLIGHT_COLOUR = '#ff8000';

  public effectTypes = EffectTypes;
  public scrollDirections = ScrollDirections;
  public languages = Languages;
  public align = HorizontalAlign;
  public widthClassName = WidthClassName;
  public itemsPerView: number;
  public rows: TableRecords = [];
  public viewItems: TableRecords = [];
  public date: Date;
  public dayLink = 0;
  public dayLinkForPipe = 0;
  public dayLinkForPipe$: Observable<number>;
  public countTemp = 0
  public dateTemp: Date = new Date()
  public dateForMerge: Date = new Date();
  public refreshCount = 0;
  public shabbosEnd: Date;
  public times: KosherZmanimOptionDto[];

  private halachicTimes: KosherZmanimObject;
  public halachicTimesTwoDays: KosherZmanimObjectTwoDays;
  public speed: number = 2.9;

  private initialDate: Date;
  public mergedDate$: Observable<Date>;

  private timeChanged$ = timer(0, 180 * 1000).pipe(
    map(() => {
      const date = new Date()
      if (date.getHours() == 0 && date.getMinutes() == 0) {
        this.dateService.setDate(new Date())
      }

      return date
    }),
    shareReplay()
  );
  private dateSubscription: Subscription;
  static this: any;

  constructor(
    private halachicTimesService: HalachicTimesService,
    private dateService: DateService,
    private elementRef: ElementRef,
    private refreshService: RefreshPipeService,
    private alertService: AlertService,
    public widgetService: WidgetService,
    public sanitizer: DomSanitizer
  ) {
    this.mergedDate$ = merge(this.timeChanged$, dateService.dateChanged$)

    this.halachicTimes = halachicTimesService.halachicTimes;

    this.halachicTimesTwoDays = halachicTimesService.halachicTimesTwoDays;
    this.times = halachicTimesService.times;
  }

  public ngOnInit(): void {
    this.initialDate = new Date();
    this.initialDate.setSeconds(0, 0);

    this.dayLink = this.getDayLink(new Date(), this.widget.changeTimeKey);

    this.listenToPipeRefresh();
    this.listenToDateChange();

    this.fillRecordsTime();
    this.markNextRecord(this.date);

    setInterval(() => {
      this.fillRecordsTime();
      this.markNextRecord(this.date);
    }, 5_000);
  }

  private get scrollElement1(): HTMLElement {
    return this?.scrollElement?.nativeElement;
  }

  private get parentElement1(): HTMLElement {
    return this?.parentElement?.nativeElement;
  }

  public get horizontalAlignLeft(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Left;
  }

  public get horizontalAlignRight(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Right;
  }

  public get verticalAlignTop(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Top;
  }

  public get verticalAlignCenter(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Center;
  }

  public get verticalAlignBottom(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Bottom;
  }

  public ngOnDestroy(): void {
    this.dateSubscription.unsubscribe();
  }

  /**
   * Returns record's fixed time in 24H format.
   * @param {string} time - record's fixed time.
   * @private
   */
  private static getIn24(time: string): string {
    const h = parseInt(time, 10);
    const m = time.split(':')[1].split(' ')[0];

    return time.toLowerCase().includes('a')
      ? h + ':' + m
      : h + 12 + ':' + m;
  }

  /**
   * Rounds minutes according to selected option.
   * @param {number} roundMin - how many minutes to round.
   * @param {RoundingOptions} option - rounding option: nearest, next or closest (enum value).
   * @param min - minutes before rounding.
   * @private
   */
  public static roundTime(roundMin: number, option: RoundingOptions, min: number): number {
    if (roundMin === 0) {
      return min;
    }

    let resultMin = min;

    switch (option) {
      case RoundingOptions.Previous:
        resultMin = Math.floor(min / roundMin) * roundMin;
        break;
      case RoundingOptions.Next:
        resultMin = Math.floor((min + roundMin) / roundMin) * roundMin;
        break;
      case RoundingOptions.Nearest:
        resultMin = min % roundMin > roundMin / 2
          ? Math.floor((min + roundMin) / roundMin) * roundMin
          : Math.floor(min / roundMin) * roundMin;
        break;
    }

    return resultMin;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.object) {
      console.log('object has changed: ', changes.object.currentValue);
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    if (!this.scrollElement || this.widget.content.table.singleLine) return;
    this.widget.effectSource.subscribe(() => {
      if (!this.widget.content.table.singleLine) {
        this.horizntalScrollStarter();
      }
    });
  }

  private async horizntalScrollStarter() {
    const verticalAlign = this.widget.font.verticalAlign;
    if (verticalAlign) {
      this.elementRef.nativeElement.parentElement.style.alignItems = AlignItems[verticalAlign];
    }

    const scrollElement = this.scrollElement.nativeElement;
    scrollElement.style.height = 'auto';
    this.viewItems = this.rows;

    if (this.widget.effect.scrollDirection === ScrollDirections.Up || this.widget.effect.scrollDirection === ScrollDirections.Down) {
      if (this.widget.effect.type === EffectTypes.BlurredSwap || this.widget.effect.type === EffectTypes.FastSwap) {
        // this.swap();
      } else {
        this.viewItems = this.rows;
      }
    } else {
      this.viewItems = this.rows;
    }

    while (this.parentElement1.clientWidth === 0) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }

  /**
   * Fills each record time field.
   * @private
   */
  private fillRecordsTime(): void {
    this.widget.content.table.records.forEach((record: TableRecord) => {
      record.time = this.getRecordTime(record);
    });
  }

  /**
   * Returns record's formatted time for highlight purpose.
   * @param {TableRecord} record - current table record.
   * @private
   */
  private getRecordTime(record: TableRecord): string {
    if (record.isFixed) {
      if (record.fixedTime.toLowerCase().includes('m')) {
        return TableViewerComponent.getIn24(record.fixedTime).padStart(5, '0') + ':00';
      }
      return record.fixedTime + ':00';
    }

    const currentTime = this.times?.find(time => time.key === record.key);
    const date = this.halachicTimes[currentTime?.option];
    const fullDate = new Date(date);
    fullDate.setMinutes(fullDate.getMinutes() + record.addMinutes);

    return fullDate.toLocaleTimeString('en-US', {
      timeZone: this.halachicTimesService.options.timeZoneId,
      hour12: false
    });
  }

  /**
   * Calculates and returns current week Shabbos end time according to chosen date.
   */
  public getShabbosEndTime(): Date {
    const sunsetTimeDto = this.halachicTimesService.times.find((timeDto: KosherZmanimOptionDto) => timeDto.key === 'sunset');

    if (this.date.getDay() === 6) {
      this.halachicTimesService.date = this.date;
    }
    const halachicTimes = getZmanimJson(this.halachicTimesService.options).Zmanim;

    const { isMinutes, minutes, option } = this.user.settings?.shabbosEnd;

    let time: Date;
    if (isMinutes) {
      time = new Date(halachicTimes[sunsetTimeDto.option]);
      time.setMinutes(time.getMinutes() + minutes);
    } else {
      time = new Date(halachicTimes[option]);
    }

    return time;
  }

  /**
   * Marks the nearest (by time) record to in order to highlight it.
   * @private
   */
  private async markNextRecord(selectDate: Date): Promise<void> {
    const { highlight, tableHolidaysHighlightConditions, tableDaysHighlightConditions, records } = this.widget.content.table;

    records.forEach(record => record.highlighted = false);

    if (highlight === Highlight.Never) return;

    if (highlight === Highlight.Condition) {
      const date = new Date(selectDate);
      const candleLightTime = JewishHelper.getShabbosRelatedTime<Date>('candleLight', date, this.halachicTimesService.options, TimeFormat._24H, this.user.times, false, 'date');
      const shabbosEndTime = JewishHelper.getShabbosRelatedTime<Date>('shabbosEnd', date, this.halachicTimesService.options, TimeFormat._24H, this.user.times, false, 'date');
      const isConditionallyHighlighted = await SchedulerHelper.isHolidayVisible(tableHolidaysHighlightConditions, this.getDayLink(date, this.widget.changeTimeKey),
        new JewishCalendar(date), date, candleLightTime, shabbosEndTime) && SchedulerHelper.isDayVisible({ daysOfWeek: tableDaysHighlightConditions }, date);

      if (!isConditionallyHighlighted) {
        this.alertService.emitAlertRecord(null, this.widget.id);
        return;
      };
    }

    const sortedRecords = !this.widget.content.table.alertLayout
      ? [...records]
        .filter(record => record.displayed)
        .sort((a, b) => a.time > b.time ? 1 : -1)
      : [...records]
        .sort((a, b) => a.time > b.time ? 1 : -1);


    const date = new Date(selectDate);
    date.setSeconds(0, 0);

    const selectedDate = this.initialDate.getTime() === date.getTime() ? new Date() : new Date(selectDate);

    const dayLink = this.getDayLink(selectedDate, this.widget.changeTimeKey);

    const calendar = new JewishCalendar(selectedDate);

    for (const record of sortedRecords) {
      const { recordDaysHighlightConditions, recordHolidaysHighlightConditions } = record;
      const candleLightTime = JewishHelper.getShabbosRelatedTime<Date>('candleLight', selectedDate, this.halachicTimesService.options, TimeFormat._24H, this.user.times, false, 'date');
      const shabbosEndTime = JewishHelper.getShabbosRelatedTime<Date>('shabbosEnd', selectedDate, this.halachicTimesService.options, TimeFormat._24H, this.user.times, false, 'date');
      const recordConditionallyHighlighted = await SchedulerHelper.isHolidayVisible(recordHolidaysHighlightConditions, dayLink, calendar, selectedDate, candleLightTime, shabbosEndTime)
        && SchedulerHelper.isDayVisible({ daysOfWeek: recordDaysHighlightConditions }, selectedDate);

      if (!recordConditionallyHighlighted) {
        record.highlighted = false;
        break;
      }

      const date = new Date();

      if (record.time) {
        const timeArr = record.time?.split(':');
        date.setHours(+timeArr[0], +timeArr[1], +timeArr[2]);
      }

      if (date.getTime() > selectedDate.getTime()) {
        record.highlighted = true;
        this.alertService.emitAlertRecord(record, this.widget.id);
        break;
      }
    }
  }

  /**
   * Calculates a dayLink in order to change date by specified change time.
   * @param {Date} date
   * @param {ChangeTimes} changeTimeKey
   * @private
   * @return {number}
   */
  public getDayLink(date: Date, changeTimeKey: ChangeTimes = ChangeTimes.Tzais): number {
    const changeTimeOption = this.times.find(time => time.key === changeTimeKey)?.option;
    const timeZoneOptions: Intl.DateTimeFormatOptions = { timeZone: this.halachicTimesService.options.timeZoneId };
    const changeTimeDate = new Date(this.halachicTimesService.halachicTimes[changeTimeOption]).toLocaleString('en-US', timeZoneOptions);
    // const temp =  date > new Date(changeTimeDate) ? 1 : 0;
    this.dayLinkForPipe = date > new Date(changeTimeDate) ? 1 : 0;
    return this.dayLinkForPipe
  }


  /**
   * Listens to date change in the date calendar and calculates the day link.
   * @private
   */
  private listenToDateChange(): void {
    this.dateSubscription = merge(this.timeChanged$, this.dateService.dateChanged$)
      .subscribe((date: Date) => {
        this.halachicTimesService.clearCurrentWeekTimes();
        this.halachicTimes = this.halachicTimesService.halachicTimes;
        this.halachicTimesTwoDays = this.halachicTimesService.halachicTimesTwoDays;
        this.date = date;
        this.dayLink = this.getDayLink(date, this.widget.changeTimeKey);

        this.shabbosEnd = new Date(this.getShabbosEndTime().toLocaleString('en-US', {
          timeZone: this.halachicTimesService.options.timeZoneId
        }));
      });
  }

  /**
   * Triggers the refresh pipe.
   * @private
   */
  private listenToPipeRefresh(): void {
    this.refreshService.refresh$.subscribe(() => this.refreshCount++);
  }
}
