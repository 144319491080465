import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DarkModeService } from 'angular-dark-mode';

import { Languages } from '../../../_enums/languages';
import { LocalStorage } from '../../../_enums/local-storage';
import { AccountHelper } from '../../../_helpers/account.helper';
import { User } from '../../../_models/identity/user';
import { Screen } from '../../../_models/screen';
import { AccountService } from '../../../_services/account.service';
import { ContentApiService } from '../../../_services/content-api.service';
import { DateService } from '../../../_services/date.service';
import { EditService } from '../../../_services/edit.service';
import { SettingsService } from '../../../_services/settings.service';
import { SnackbarService } from '../../../_services/snackbar.service';
import { WidgetService } from '../../../_services/widget.service';
import { DateSelectorComponent } from '../../../date-selector/date-selector.component';
import { SettingsComponent } from '../../settings/settings/settings.component';
import { BePatientComponent } from '../be-patient/be-patient.component';
import { WidgetHelper } from '../../../_helpers/widget.helper';
import { AppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';
import { PermissionTypes } from 'src/app/_models/identity/PermissionTypes';
import { map } from 'rxjs/operators';
import { loadUser } from 'src/app/store/user/user.actions';
import { selectUserPermissions } from 'src/app/store/user/user.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public PermissionTypes: typeof PermissionTypes = PermissionTypes;
  public isAdmin = false;
  public screen: Screen;
  public username = '';
  public direction = 'ltr';
  public darkMode$: Observable<boolean> = this.darkModeService.darkMode$;
  public window = window;
  public userPermissions$: Observable<PermissionTypes[]> = this.store.select(selectUserPermissions);

  private selectedDate: Date;
  private userSubscription: Subscription;
  private screenSubscription: Subscription;
  private dateSubscription: Subscription;
  private dateSelectorRef: MatDialogRef<DateSelectorComponent>;

  constructor(
    private editService: EditService,
    private accountService: AccountService,
    private widgetService: WidgetService,
    private contentApiService: ContentApiService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private dateService: DateService,
    private darkModeService: DarkModeService,
    private settingsService: SettingsService,
    private store: Store<AppState>
  ) {
    accountService.getUser().toPromise().then(user => {
      this.store.dispatch(loadUser({ role: user.role }));
    });
  }

  hasManageScreenPermission: boolean = false;
  private permissionsSubscription: Subscription;

  public hasPermissionTo(permission: PermissionTypes): Observable<boolean> {
    return this.userPermissions$.pipe(
      map(permissions => permissions.includes(permission))
    );
  }

  public ngOnInit(): void {
    this.permissionsSubscription = this.hasPermissionTo(PermissionTypes.ManageWidgets).subscribe(hasPermission => {
      this.hasManageScreenPermission = hasPermission;
    });
    this.setTranslation();

    this.userSubscription = this.accountService.currentUser$
      .subscribe((user: User) => {
        this.isAdmin = AccountHelper.isAdmin();
        this.username = user?.username || '';
      });

    this.screenSubscription = this.widgetService.screenUpdated$
      .subscribe((screen: Screen) => this.screen = screen);

    this.dateSubscription = this.dateService.dateChanged$
      .subscribe(date => {
        this.selectedDate = new Date(date);
      });
  }

  public ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.screenSubscription.unsubscribe();
    this.dateSubscription.unsubscribe();
    this.permissionsSubscription.unsubscribe();
  }

  /**
   * Handles Ctrl+Tab shortcut to open/close a datepicker.
   * @param {KeyboardEvent} event - KeyboardEvent to prevent the default.
   */
  @HostListener('document:keydown.control.shift.d', ['$event'])
  public handleCtrlTabKeyboardEvent(event: KeyboardEvent): void {
    event.preventDefault();
    this.toggleDatePicker();
  }

  /**
   * Toggles dark/light mode.
   */
  public onDarkModeToggle(): void {
    this.darkModeService.toggle();
  }

  /**
   * Toggles date picker dialogue window.
   */
  public toggleDatePicker(): void {
    if (!!this.dateSelectorRef) {
      this.dateSelectorRef.close()
      this.dateSelectorRef = null;
      return;
    }

    this.dateSelectorRef = this.dialog.open(DateSelectorComponent, {
      hasBackdrop: false,
      position: {
        top: '5rem',
        right: '3rem'
      }
    });
    this.dateSelectorRef.componentInstance.selectedDate = this.selectedDate;
    this.dateSelectorRef.componentInstance.mode = 'app-wide';

    this.dateSelectorRef.afterClosed().subscribe((date: string) => {
      this.dateSelectorRef = null;
      if (!date) {
        this.dateService.setDate(new Date());
      }
    });
  }

  /**
   * Exits edit mode.
   */
  public stopEdit(): void {
    WidgetHelper.clearSelectedStyle()
    this.editService.setEditMode(false);
  }

  /**
   * Saves current screen.
   */
  public save(): void {
    if (!this.screen) return;

    const widgets = [...this.screen.widgets.map(widget => ({ ...widget, contentSource: null, effectSource: null, contentChangedSource: null, resizeStopSource: null }))];
    const screen = { ...this.screen, widgets };

    this.screen.dirty = false;
    this.contentApiService.updateScreen(screen).subscribe(
      () => this.snackbar.success(this.translate.instant('snackbar.screenUpdated')),
      error => {
        console.log(error);
        this.snackbar.error(error.error.message, 'Ok', 5000);
      }
    );

  }

  /**
   * Saves current screen & exits edit mode.
   */
  public saveAndExit(): void {
    this.save();
    this.stopEdit();
  }

  /**
   * Logs out & clears cache.
   */
  public logout(): void {
    this.accountService.logout();
    this.accountService.clearUsersCache();
    this.accountService.clearUserCache();
    this.contentApiService.clearOrganisationsCache();
    this.widgetService.clearScreensCache();
  }

  /**
   * Opens settings dialogue window, and reloads on closure.
   * @param {boolean} tabIndex - index of a specific settings tab to be open.
   */
  public openSettings(tabIndex = 0): void {
    if (!!this.settingsService.settingsDialogWindowRef) return;

    this.settingsService.settingsDialogWindowRef = this.dialog.open(SettingsComponent);
    this.settingsService.settingsDialogWindowRef.componentInstance.tabIndex = tabIndex;
    this.settingsService.settingsDialogWindowRef.afterClosed()
      .subscribe(updated => {
        this.settingsService.clearSettingsDialogWindowReference();
        if (updated) {
          location.reload();
          // TODO implement reload without page refreshing
          // this.accountService.clearUserCache();
          // this.accountService.getUser().subscribe();
          // this.widgetService.updateScreen(this.screen);
        }
      });
  }

  /**
   * Opens a 'Be Patient' modal window.
   */
  public openBePatientPopup(): void {
    this.dialog.open(BePatientComponent);
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    const language = localStorage.getItem(LocalStorage.Language) || Languages.English;
    this.translate.use(language);
    this.direction = language === Languages.Hebrew ? 'rtl' : 'ltr';
  }
}
