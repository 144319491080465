import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { TimeFormat, Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Screen, Widget } from "src/app/_models";

@Component({
  selector: 'app-digital-clock-dashboard',
  templateUrl: './digital-clock-dashboard.component.html',
  styleUrls: ['./digital-clock-dashboard.component.css']
})
export class DigitalClockDashboardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;

  public timeFormat = TimeFormat;
  public uppercaseToggleDisabled = false;
  public direction: Direction = 'ltr';

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper) {
  }

  public ngOnInit(): void {
    this.setTranslation();
  }

  /**
   * Sets the time format. A generic function for different format parameters.
   * @param {string} control - specifies the format parameter to set.
   * @param {boolean | TimeFormat} event - boolean or TimeFormat depending on the format parameter.
   */
  public setFormat(control: string, event: boolean | TimeFormat): void {
    const digital = this.widget.content.digital.format;
    switch (control) {
      case 'timeFormat':
        if (event === TimeFormat._12H) {
          this.widget.content.digital.format = this.widget.content.digital.format.replace(/H/g, 'h');
        } else {
          this.widget.content.digital.format = this.widget.content.digital.format.replace(/h/g, 'H');
          if (digital[digital.length - 1] === 'a') {
            this.widget.content.digital.format = this.widget.content.digital.format.slice(0, -2);
          }
          this.uppercaseToggleDisabled = true;
        }
        break;
      case 'amPm':
        if (event) {
          this.widget.content.digital.format += ' a';
          this.uppercaseToggleDisabled = false;
        } else {
          this.widget.content.digital.format = this.widget.content.digital.format.slice(0, -2);
          this.uppercaseToggleDisabled = true;
        }
        break;
      case 'seconds':
        if (event) {
          this.widget.content.digital.format = this.widget.content.digital.format.replace(':mm', ':mm:ss');
        } else {
          this.widget.content.digital.format = this.widget.content.digital.format.replace(':ss', '');
        }
        break;
      case  'zero':
        if (event) {
          this.widget.content.digital.format = this.widget.content.digital.format.replace(/^h/, 'hh');
          this.widget.content.digital.format = this.widget.content.digital.format.replace(/^H/, 'HH');
        } else {
          this.widget.content.digital.format = this.widget.content.digital.format.replace('hh', 'h');
          this.widget.content.digital.format = this.widget.content.digital.format.replace('HH', 'H');
        }
        break;
    }
  }

  /**
   * Toggles the AM/PM case.
   * @param {boolean} checked - whether the AM/PM should be set to lowercase.
   */
  public toggleCase(checked: boolean): void {
    this.widget.content.digital.uppercase = !checked;
  }

  /**
   * Marks screen "dirty" for purpose of page leaving warning.
   */
  public markScreenDirty(): void {
    this.screen.dirty = true;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
