import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Prevents the input of digits larger than the defined maximum.
 */
@Directive({
  selector: '[appNumericInput]'
})
export class NumericInputDirective {

  @Input() maxValue = 9999;
  @Input() minValue = 0;

  constructor(private elementRef: ElementRef<HTMLInputElement>) { }

  /**
   * Handles the keydown event to prevent the input of digits larger than the defined maximum.
   * @param {KeyboardEvent} event - a keyboard event to determine the button that was pressed.
   */
  @HostListener('keydown', ['$event']) handleInputKeyDown(event: KeyboardEvent) {
    if (+event.key < this.minValue || +event.key > this.maxValue || ['-', '+'].includes(event.key)) {
      event.preventDefault();
    } else if (['Backspace', 'Delete', 'End', 'Home', 'ArrowRight', 'ArrowLeft'].includes(event.key)) {
      // Allows the navigation buttons.
      return;
    }
  }

  /**
   * Handles the input event in order to prevent numbers larger than the defined maximum (there's necessity for both handlers).
   */
  @HostListener('input') handleInputChange() {
    if (+this.elementRef.nativeElement.value > this.maxValue) {
      this.elementRef.nativeElement.value = this.maxValue.toString();
    }
  }
}
