import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { Widget, BackgroundTypes, MediaItem, Screen } from "src/app/_models";
import { WidgetService, ContentApiService, SnackbarService, MediaService } from "src/app/_services";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  @Input() endpoint = 'upload';
  @Input() allowedFileTypes = ['image', 'video', 'pdf', 'audio'];
  @Input() title;
  @Input() screen: Screen;
  @Input() widget: Widget;

  @Output() uploadFinished = new EventEmitter<string>();

  public uploader: FileUploader;

  public hasBaseDropZoneOver = false;
  public acceptedFileTypes = 'image/png, image/jpeg, video/mp4, video/mpeg, video/wbm, audio/mpeg';

  private duration: number;

  constructor(
    public widgetService: WidgetService,
    private contentApiService: ContentApiService,
    private snackbar: SnackbarService,
    private mediaService: MediaService,
  ) { }

  public ngOnInit(): void {
    if (this.endpoint === 'pdf') {
      this.acceptedFileTypes = 'application/pdf';
    }
    this.initialiseUploader();
  }

  /**
   * Checks & limits the file size.
   * @param {File} file - a file to be checked.
   */
  public async checkFileSize([file]: File[]): Promise<void> {
    this.duration = await this.mediaService.getMediaDuration(file);
    const error = await this.mediaService.checkFileSize([file]);
    if (error) {
      this.snackbar.error(error);
      this.uploader.cancelAll();
    }
  }


  /**
   * Checks whether a file is over the drop base.
   * @param {boolean} event - whether the file is over a drop zone.
   */
  public fileOverBase(event: boolean): void {
    this.hasBaseDropZoneOver = event;
  }

  /**
   * Initialises the File uploader.
   * @private
   */
  private initialiseUploader(): void {
    this.uploader = this.mediaService.initialiseUploader(this.endpoint, this.allowedFileTypes, this.screen);

    this.uploader.onSuccessItem = async (fileItem: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (this.screen && this.endpoint === 'screen') {
        this.screen.background.imageUrl = response;
        this.screen.background.type = BackgroundTypes.Image;
        this.widgetService.updateScreen(this.screen);
      } else if (this.screen && this.widget && this.endpoint === 'widget') {
        this.widget.background.imageUrl = response;
        this.widget.background.type = BackgroundTypes.Image;
        this.widgetService.updateScreen(this.screen);
      } else if (this.screen && this.widget && this.endpoint === 'upload') {
        const { name, type, size } = fileItem.file;
        setTimeout(() => {
          const duration = Math.round(this.duration);
          const item: MediaItem = {
            serverName: response,
            name,
            type,
            size,
            duration: !isNaN(duration) ? duration : 0,
            url: environment.resourcesUrl + response,
            enabled: true
          };
          this.widget.content.media.items.push(item);
          this.widgetService.updateScreen(this.screen);
        }, 1000);
      } else if (this.screen && this.widget && this.endpoint === 'pdf') {
        this.uploadFinished.emit(response);
        this.widget.content.pdf.url = environment.resourcesUrl + response;
        this.widget.content.pdf.name = fileItem.file.name;
        this.widget.content.pdf.size = fileItem.file.size;
        this.widget.contentChangedSource.next();
        this.widgetService.updateScreen(this.screen);
        this.contentApiService.updateScreen(this.screen).subscribe({
          error: err => console.log(err)
        });
      }
    };

    this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      console.error('ERROR');
      console.log('FILE ITEM:', item);
      console.log('RESPONSE:', response);
      console.log('UPLOAD STATUS:', status);
      // TODO implement upload image error handling
    };
  }
}
