<div>
  <div class="settings container-sm d-flex justify-content-center align-items-center w-75">
    <span style="white-space: nowrap" class="mx-3">{{
      "dashboard.widgetProperties.specific.textByDate.textByDate" | translate
      }}</span>
    <mat-slide-toggle (change)="
        widget.content.text.isPermanent = !widget.content.text.isPermanent;
        markScreenDirty()
      " [checked]="widget.content.text.isPermanent" [style.direction]="'ltr'">
    </mat-slide-toggle>
    <span class="mx-3">{{
      "dashboard.widgetProperties.specific.textByDate.text" | translate
      }}</span>
  </div>
</div>
<div *ngIf="widget.content.text.isPermanent == false">
  <div>
    <div class="settings container-sm d-flex justify-content-center align-items-center" [style.direction]="direction"
      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-slide-toggle style="max-width:fit-content;"
        (change)="widget.content.table.multipleLines = !widget.content.table.multipleLines"
        (change)="widget.content.table.multipleLines === true"
        (change)="widget.content.table.multipleLines === false"
        (change)="widget.content.table.multipleLines === false"
        [checked]="widget.content.table.multipleLines" (change)="changeScrollDirection()" [style.direction]="'ltr'"
        labelPosition="before">
        <span class="mx-3">{{ 'dashboard.widgetProperties.specific.table.multiple' | translate }}</span>
      </mat-slide-toggle>
    </div>
  </div>

  <div>
    <div class="settings container-sm d-flex flex-column mx-2" [style.direction]="direction"
      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <div class="m-b" [style.direction]="direction"><span
          class="mr-mx-3">{{"dashboard.widgetProperties.specific.textByDate.viewBy" | translate}}</span></div>
      <mat-radio-group [style.font-size]="language === languages.Russian ? '0.8rem' : ''"
        class="w-100 d-flex justify-content-between" [(ngModel)]="selectByDate"
        (change)="switchSelectedValue($event); markScreenDirty()">
        <mat-radio-button value="isShowAllYear" [checked]="widget.content.text.isShowAllYear">
          <span class="mr-mx-3">{{
            "dashboard.widgetProperties.specific.textByDate.year" | translate
            }}</span> </mat-radio-button>
        <mat-radio-button value="isShowMonth" [checked]="widget.content.text.isShowMonth">
          <span class="mr-mx-3">{{
            "dashboard.widgetProperties.specific.textByDate.month" | translate
            }}</span> </mat-radio-button>
        <mat-radio-button value="isShowWeek" [checked]="widget.content.text.isShowWeek">
          <span class="mr-mx-3">{{
            "dashboard.widgetProperties.specific.textByDate.week" | translate
            }}</span> </mat-radio-button>
        <mat-radio-button value="isShowDay" [checked]="widget.content.text.isShowDay">
          <span class="mr-mx-3">{{
            "dashboard.widgetProperties.specific.textByDate.day" | translate
            }}</span> </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div [class.disabled]="true"
    class="settings container-sm d-flex justify-content-center align-items-center w-75">
    <span class="mx-3">
      {{ "dashboard.widgetProperties.specific.textByDate.hebrewDate" | translate }}
    </span>
    <mat-slide-toggle (change)="
        widget.content.text.isDateHhebre = !widget.content.text.isDateHhebre
      " [class.disabled]="widget.content.text.isPermanent" [checked]="widget.content.text.isDateHhebre"
      [style.direction]="'ltr'">
    </mat-slide-toggle>
    <span class="mx-3">{{
      "dashboard.widgetProperties.specific.textByDate.loazDate" | translate
      }}</span>
  </div>

  <div
    class="settings container-sm d-flex justify-content-center align-items-center">
    <button mat-raised-button (click)="openContentModal()" [disabled]="widget.content.text.isPermanent"
      [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-icon>list</mat-icon>
      {{
      "dashboard.widgetProperties.specific.textByDate.textByDate" | translate
      }}
    </button>
  </div>
</div>

<div *ngIf="widget.content.text.isPermanent == true">
  <div [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'" class="hint">
    {{ "dashboard.widgetProperties.specific.text.subtitle" | translate }}
  </div>

  <div [style]="{
    '--text-editor-textarea': direction === 'rtl' ? 'rtl' : 'ltr'
  }" class="settings text-editor container-sm d-flex justify-content-center align-items-center pr-4">
    <angular-editor [(ngModel)]="widget.content.text.permanent" (ngModelChange)="screen.dirty = true"
      [config]="editorConfig">
    </angular-editor>
  </div>

  <div class="settings container-sm d-flex justify-content-between align-items-center mx-2 mt-4"
    [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title mr-3">{{
      "dashboard.widgetProperties.specific.text.background" | translate
      }}</span>
    <div [(colorPicker)]="widget.textBackgroundColor" [style.background]="widget.textBackgroundColor || '#ffffff'"
      [style.right]="direction === 'rtl' ? '' : '1rem'" [style.left]="direction === 'rtl' ? '1rem' : ''" [title]="
      'dashboard.widgetProperties.specific.text.colourPickerTitle' | translate
    " (colorPickerChange)="
    changeTextBackgroundColor(widget.textBackgroundColor)
    " [cpOutputFormat]="'hex'" [cpAlphaChannel]="'disabled'" [cpOKButton]="true"
      [cpOKButtonText]="'buttons.ok' | translate" cpOKButtonClass="btn btn-outline-primary" [cpCancelButton]="true"
      cpCancelButtonClass="btn btn-outline-danger" [cpCancelButtonText]="'buttons.reset' | translate"
      class="colour-picker"></div>
  </div>
</div>
