<!--Horizontal scroll & the rest of effects-->
<div [class.d-flex]="isHorizontalScroll()" [ngClass]="{'width-oll-div': isHorizontalScroll()}">

  <ng-container *ngIf="widget.content.text.isPermanent; else byDate">
    <ng-container *ngIf="isHorizontalScroll()">
      <app-horizontal-scroll [widget]="widget" [htmlContent]="content" [shouldScroll]="scrollSetting()"
        [scrollDirection]="widget.effect.scrollDirection" [speed]="speed" [date]="date"></app-horizontal-scroll>
    </ng-container>
    <ng-container *ngIf="!isHorizontalScroll()">
      <app-vertical-scroll [widget]="widget" [htmlContent]="content" [shouldScroll]="scrollSetting()"
        [scrollDirection]="widget.effect.scrollDirection" [speed]="speed" [date]="date"></app-vertical-scroll>
    </ng-container>
  </ng-container>
</div>

<ng-template #byDate>
  <div #container *ngIf="widget.language === languages.Hebrew" [ngClass]="{
    item_right: widget.font.horizontalAlign == 'right',
    item_center: widget.font.horizontalAlign == 'center',
    item_left: widget.font.horizontalAlign == 'left',
    item_justify: widget.font.horizontalAlign == 'justify'
  }">
    <ng-container
      *ngIf="((widget.content.text.byDate | valueTextBy: (mergedDate$ | async):widget) | async)?.length > 0">
      <ng-container *ngIf="isHorizontalScroll() || !widget.content.table.multipleLines">
        <app-horizontal-scroll [widget]="widget"
          [multiHtmlContent]="widget.content.text.byDate | valueTextBy: (mergedDate$ | async) : widget | async"
          [shouldScroll]="scrollSetting()" [scrollDirection]="widget.effect.scrollDirection" [speed]="speed"
          [date]="date"></app-horizontal-scroll>
      </ng-container>
      <ng-container *ngIf="!isHorizontalScroll() && widget.content.table.multipleLines">
        <app-vertical-scroll [widget]="widget"
          [multiHtmlContent]="widget.content.text.byDate | valueTextBy: (mergedDate$ | async) : widget | async"
          [shouldScroll]="scrollSetting()" [scrollDirection]="widget.effect.scrollDirection" [speed]="speed"
          [date]="date"></app-vertical-scroll>
      </ng-container>
    </ng-container>
  </div>

  <div #container *ngIf="widget.language != languages.Hebrew" [ngClass]="{
    item_left: widget.font.horizontalAlign == 'left',
    item_right: widget.font.horizontalAlign == 'right',
    item_center: widget.font.horizontalAlign == 'center',
    item_justify: widget.font.horizontalAlign == 'justify'
  }">
    <div *ngIf="(widgetState | async).viewMode === ViewMode.AllYear">
      <div *ngFor="let value of widget.content.text.byDate | valueTextByYear: (mergedDate$ | async):widget">
      </div>
    </div>
    <div *ngIf="(widgetState | async).viewMode === ViewMode.AllMonth">
      <div *ngFor="let value of widget.content.text.byDate  | valueTextByMonth: (mergedDate$ | async):widget">
        {{value}}
      </div>
    </div>
    <div *ngIf="(widgetState | async).viewMode === ViewMode.AllWeek">
      <div *ngFor="let value of widget.content.text.byDate | valueTextByWeek: (mergedDate$ | async):widget">
        {{value}}
      </div>
    </div>
    <div *ngIf="(widgetState | async).viewMode === ViewMode.AllDay">
      <div *ngFor="let value of widget.content.text.byDate">
        <div *ngFor="let val of value | valueText: (dateService.dateChanged$ | async):widget">
          {{ val }}
        </div>
      </div>
    </div>
    <!-- <ng-container
      *ngIf="((widget.content.text.byDate | valueTextBy: (mergedDate$ | async):widget) | async)?.length > 0">
      <ng-container *ngIf="isHorizontalScroll()">
        <app-horizontal-scroll [widget]="widget"
          [multiHtmlContent]="widget.content.text.byDate | valueTextBy: (mergedDate$ | async) : widget | async"
          [shouldScroll]="scrollSetting()" [scrollDirection]="widget.effect.scrollDirection" [speed]="speed"
          [date]="date"></app-horizontal-scroll>
      </ng-container>
      <ng-container *ngIf="!isHorizontalScroll()">
        <app-vertical-scroll [widget]="widget"
          [multiHtmlContent]="widget.content.text.byDate | valueTextBy: (mergedDate$ | async) : widget | async"
          [shouldScroll]="scrollSetting()" [scrollDirection]="widget.effect.scrollDirection" [speed]="speed"
          [date]="date"></app-vertical-scroll>
      </ng-container>
    </ng-container> -->
  </div>
</ng-template>
