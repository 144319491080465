<app-menu-bar *ngIf="!isInIframe"></app-menu-bar>

<div *ngIf="!window.localStorage.getItem('token')" class="loading-spinner">
  <app-loading-spinner></app-loading-spinner>
</div>

<app-header *ngIf="editMode"></app-header>

<app-screen *ngIf="window.localStorage.getItem('token')"
            (sidenavOpened)="sidenavOpened = $event"
            [screen]="currentScreen">
</app-screen>
