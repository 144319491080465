import { Pipe, PipeTransform } from '@angular/core';
import { TableRecords } from '../_models/table';

@Pipe({
  name: 'refresh',
  pure: true
})
export class RefreshPipe implements PipeTransform {

  /**
   * Returns a copy of the table records. The purpose of the Refresh pipe is to enable the next pipe e.g. Filter Table Records pipe.
   * @param {TableRecords} records - list of records to copy.
   * @param {number} refreshCounter - incrementing of the refreshCounter triggers the Refresh pipe.
   * @param {boolean} deep - defines whether the copy will be deep or shallow. Default is shallow.
   */
  public transform(records: TableRecords, refreshCounter: number, deep = false): TableRecords {
    if (records == null) {
      return null;
    }

    return deep ? JSON.parse(JSON.stringify(records)) : [...records];
  }
}
