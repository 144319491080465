<div class="slider" #slider>
  <img *ngIf="mediaContent.items.length === 1 && mediaContent.items[0]?.type.includes('image'); else multipleSlides"
    [src]="mediaContent.items[0].url" [alt]="mediaContent.items[0].name"
    [class]="mediaContent.stretched ? 'stretched' : 'non-stretched'">

  <ng-template #multipleSlides>
    <ng-container *ngFor="let slide of mediaContent.items">
      <div #slides (animationend)="onSlideEnded($event)" *ngIf="slide.enabled" class="slide">

        <ng-container *ngIf="slide.type.includes('image')">
          <div *ngFor="let slide of mediaContent.items; let i = index" class="slide"
            [ngClass]="{'fade-in': i === currentImage, 'fade-out': currentImage !== i}">
            <img [src]="slide.url" [class]="mediaContent.stretched ? 'stretched' : 'non-stretched'">
          </div>
        </ng-container>

        <video (ended)="onSlideEnded($event)" *ngIf="slide.type.includes('video')" [muted]="true">
          <source [src]="slide.url" [type]="slide.type">
          Sorry, your browser doesn't support embedded videos.
        </video>

        <audio (ended)="onSlideEnded($event)" *ngIf="slide.type.includes('audio')" [muted]="false">
          <source [src]="slide.url" [type]="slide.type">
          Sorry, your browser doesn't support embedded audios.
        </audio>
      </div>
    </ng-container>

  </ng-template>
</div>
