import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { WeekDefinitions, Days, weekDefinitions, RoundingOptions, CalcOptions, DecisiveDayOption, Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Position, Scheduler, Screen, TableRecord } from "src/app/_models";
import { HalachicTimesService, RefreshPipeService } from "src/app/_services";

@Component({
  selector: 'app-table-row-options',
  templateUrl: './table-record-options.component.html',
  styleUrls: ['./table-record-options.component.css']
})
export class TableRecordOptionsComponent implements OnInit, AfterViewInit {
  @Input() record: TableRecord;
  @Input() screen: Screen;
  @Input() label: string;
  @Input() settingsWindowPosition: Position;

  @ViewChild('window') windowRef: ElementRef<HTMLDivElement>;

  public direction: Direction = 'ltr';
  public minutes = [0, 1, 5, 10, 15, 20, 30, 60];
  public weekDefinitions = WeekDefinitions;
  public days = Days;

  public definitions = weekDefinitions;
  public obj = Object;
  public roundingOptions = RoundingOptions;
  public calcOptions = CalcOptions;

  public decisiveDay = DecisiveDayOption;
  public schedulerMockDisplay: Scheduler;

  public schedulerMockHighlight: Scheduler;

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private halachicTimesService: HalachicTimesService,
    private refreshService: RefreshPipeService
  ) {}

  public ngOnInit(): void {
    this.setTranslation();

    const { recordHolidaysDisplayConditions, recordDaysDisplayConditions, recordHolidaysHighlightConditions, recordDaysHighlightConditions } = this.record;

    this.schedulerMockDisplay = {
      holidays: recordHolidaysDisplayConditions,
      daysOfWeek: recordDaysDisplayConditions
    };

    this.schedulerMockHighlight = {
      holidays: recordHolidaysHighlightConditions,
      daysOfWeek: recordDaysHighlightConditions
    };


    if (this.record.calcOption === CalcOptions.Weekly) {
      this.getCurrentWeekTimes();
    }
  }

  public ngAfterViewInit(): void {
    this.calculateWindowPosition();
  }

  /**
   * Sets halachic times for current week.
   */
  public getCurrentWeekTimes(): void {
    this.halachicTimesService.getCurrentWeekTimes();
  }

  /**
   * Triggers the Refresh pipe.
   */
  public refreshPipe(): void {
    this.refreshService.refreshPipe();
  }

  /**
   * Marks screen "dirty" for purpose of page leaving warning.
   */
  public markScreenDirty(): void {
    if (this.screen) this.screen.dirty = true;
  }

  /**
   * Calculates dialogue window coordinates.
   */
  public calculateWindowPosition(): void {
    const { x, y } = this.windowRef.nativeElement.getBoundingClientRect();
    this.settingsWindowPosition = { x, y };
  }


  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
