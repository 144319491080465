import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Languages, ChangeTimes, ChangeTimeKeys, WidgetTypes } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget } from "src/app/_models";

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  @Input() widget: Widget;
  public direction: Direction = 'ltr';
  public languages = Languages;
  public changeTimes: ChangeTimes[] = ChangeTimeKeys;
  public changeTimeWidgetTypes = [WidgetTypes.Jewish, WidgetTypes.Table, WidgetTypes.YahrzeitTable];
  public changeTimeWidgetTypesOfTextByDate = [WidgetTypes.Text];

  constructor(private translate: TranslateService,
              private translateHelper: TranslateHelper) { }

  public ngOnInit(): void {
    this.setTranslation();
  }

  /**
   * Defines the widget language.
   * @param {Languages} language - a language to define (enum).
   */
  public selectWidgetLanguage(language: Languages): void {
    this.widget.language = language;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
