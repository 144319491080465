<ng-container *ngIf="widget.widgetIcon.type !== widgetTypes.Text">
  <!--Font colour-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
    [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span>{{ 'dashboard.general.colour' | translate }}</span>
    <div [(colorPicker)]="widget.font.color" [style.background]="widget.font.color || '#000000'"
      [style.right]="direction === 'rtl' ? '' : '1rem'" [style.left]="direction === 'rtl' ? '1rem' : ''"
      [cpOKButton]="true" [cpOKButtonText]="'buttons.ok' | translate" cpOKButtonClass="btn btn-outline-primary"
      [cpCancelButton]="true" cpCancelButtonClass="btn btn-outline-danger"
      [cpCancelButtonText]="'buttons.reset' | translate" class="colour-picker">
    </div>
  </div>

  <!--Font family-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1"
    [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span>{{ 'dashboard.widgetProperties.font.family' | translate }}</span>
    <mat-form-field appearance="standard" class="select-font w-50">
      <mat-select [(value)]="widget.font.family" [style.font-family]="widget.font.family" [style.direction]="'ltr'">
        <mat-option *ngFor="let font of fonts" [value]="font" [style.font-family]="font">
          {{ font }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!--Font size-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1"
    [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.font.size' | translate }}</span>
    <mat-slider min="1" max="80" step="1" [(value)]="widget.font.size" thumbLabel (input)="changeTextSize($event)"></mat-slider>
    <!-- <mat-slider min="1" max="80" step="1" [(value)]="widget.font.size" (valueChange)="changeTestSize($event)" thumbLabel></mat-slider> -->

  </div>

  <!--Line height-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1"
    [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.font.lineHeight' | translate }}</span>
    <mat-slider min="60" max="200" step="1" [(value)]="widget.font.lineHeight" thumbLabel></mat-slider>
  </div>

  <!--Font style-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1"
    [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.font.style.title' | translate }}</span>
    <mat-form-field appearance="standard" class="select-font w-50">
      <mat-select [(value)]="widget.font.style" [style.font-weight]="widget.font.style === fStyle.Bold ? 'bold' : ''"
        [style.text-decoration]="widget.font.style === fStyle.Underline ? 'underline' : ''"
        [style.font-style]="widget.font.style === fStyle.Italic ? 'italic' : ''" [style.direction]="direction"
        [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-option *ngFor="let fontStyle of fontStyles" [value]="fontStyle.id"
          [style.font-weight]="fontStyle.id === fStyle.Bold ? 'bold' : ''"
          [style.text-decoration]="fontStyle.id === fStyle.Underline ? 'underline' : ''"
          [style.font-style]="fontStyle.id === fStyle.Italic ? 'italic' : ''"
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          {{ 'dashboard.widgetProperties.font.style.' + fontStyle.name.toLowerCase() | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>

<!--Horizontal alignment-->
<div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 py-3"
  [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.font.alignment.title' | translate }}</span>
  <mat-button-toggle-group>
    <mat-button-toggle [checked]="widget.font.horizontalAlign === horizontalAlign.Left"
      (change)="widget.font.horizontalAlign = horizontalAlign.Left"
      [title]="'dashboard.widgetProperties.font.alignment.left' | translate">
      <mat-icon>format_align_left</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [checked]="widget.font.horizontalAlign === horizontalAlign.Center"
      (change)="widget.font.horizontalAlign = horizontalAlign.Center"
      [title]="'dashboard.widgetProperties.font.alignment.center' | translate">
      <mat-icon>format_align_center</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [checked]="widget.font.horizontalAlign === horizontalAlign.Right"
      (change)="widget.font.horizontalAlign = horizontalAlign.Right"
      [title]="'dashboard.widgetProperties.font.alignment.right' | translate">
      <mat-icon>format_align_right</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [checked]="widget.font.horizontalAlign === horizontalAlign.Justify"
      (change)="widget.font.horizontalAlign = horizontalAlign.Justify"
      [title]="'dashboard.widgetProperties.font.alignment.justify' | translate">
      <mat-icon>format_align_justify</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<!--Vertical alignment-->
<!-- <div *ngIf="widget.type !== widgetTypes.Text || (widget.type == widgetTypes.Text && !widget.content.text.isPermanent)" -->
<div *ngIf="widget.type !== widgetTypes.Text"
  class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 py-3"
  [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.font.vertical.title' | translate }}</span>
  <mat-button-toggle-group>
    <mat-button-toggle [checked]="widget.font.verticalAlign === verticalAlign.Top"
      (change)="widget.font.verticalAlign = verticalAlign.Top"
      [title]="'dashboard.widgetProperties.font.vertical.top' | translate">
      <mat-icon>vertical_align_top</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [checked]="widget.font.verticalAlign === verticalAlign.Center"
      (change)="widget.font.verticalAlign = verticalAlign.Center"
      [title]="'dashboard.widgetProperties.font.vertical.middle' | translate">
      <mat-icon>vertical_align_center</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle [checked]="widget.font.verticalAlign === verticalAlign.Bottom"
      (change)="widget.font.verticalAlign = verticalAlign.Bottom"
      [title]="'dashboard.widgetProperties.font.vertical.bottom' | translate">
      <mat-icon>vertical_align_bottom</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
