import { Component, OnInit, Input } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { Store } from "@ngrx/store";
import { Screen, Widget } from "src/app/_models";
import { AppState } from "src/app/store/app.state";

@Component({
  selector: 'app-rss-dashboard',
  templateUrl: './rss-dashboard.component.html',
  styleUrls: ['./rss-dashboard.component.css']
})
export class RssDashboardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;

  public fontSizes = ['small', 'medium', 'large'];

  constructor(
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.widget
    // Get the RSS URL from state
    // Get the RSS options from state

  }
  
  public updateRssUrl() {
    // Dispatch an action or call a service to update the RSS URL in state
  }

  public updateFontSize(event: MatSelectChange): void {
    // Extract the new font size from the event object
    const newFontSize = event.value;
    this.widget.content.rss.fontSize = newFontSize;
    // TODO: RSS backend object fix

    // Perform desired operations with the new font size, such as
    // dispatching an action to update the state in store or updating a service
    // Example:
    // this.widgetContentService.setRssFontSize(newFontSize);
    
    // If you just need to save this value and you have a method to do so, you can call it here
    // or directly use 'widget.content.rss.fontSize' that got updated with ngModel
    
    // If you need to explicitly set widget.content.rss.fontSize you can do it, but usually it's unnecessary due to ngModel two-way binding
    // this.widget.content.rss.fontSize = newFontSize;
    
    // Additional logic if needed...
  }

  public updateNumberOfItems() {
    // Dispatch an action or call a service to update the number of items to show in state
  }

  // Add any other methods you need to handle changes and updates
}