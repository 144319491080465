import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { WeekDefinitions, Days, weekDefinitions, RoundingOptions, CalcOptions, DecisiveDayOption, Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { TableRecords } from "src/app/_models";
import { HalachicTimesService } from "src/app/_services";

@Component({
  selector: 'app-table-row-options-multiple',
  templateUrl: './table-record-options-multiple.component.html',
  styleUrls: ['./table-record-options-multiple.component.css']
})
export class TableRecordOptionsMultipleComponent implements OnInit {
  @Input() records: TableRecords;

  public direction: Direction = 'ltr';
  public minutes = [0, 1, 5, 10, 15, 20, 30, 60];
  public weekDefinitions = WeekDefinitions;
  public days = Days;
  public definitions = weekDefinitions;

  public obj = Object;
  public roundingOptions = RoundingOptions;
  public calcOptions = CalcOptions;
  public decisiveDays = DecisiveDayOption;

  public enabled = true;
  public titleOnly = false;
  public roundMin: number;
  public roundOption: RoundingOptions;
  public calcOption: CalcOptions;
  public weekDefinition: WeekDefinitions;
  public dayOfChange: Days;
  public decisiveDay: DecisiveDayOption;
  public specificDay: Days;

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private halachicTimesService: HalachicTimesService
  ) { }

  public ngOnInit(): void {
    this.setTranslation();
  }

  public someSet(property: string): boolean {
    if (!this.records) return false;

    return this.records.filter(record => record[property]).length > 0 && !this[property];
  }

  public setAll(value: boolean, property: string): void {
    this[property] = value;
    if (!this.records) return;

    this.records.forEach(record => record[property] = value);
  }

  public setProperty(property: string): void {
    this.records.forEach(record => record[property] = this[property]);
  }

  /**
   * Sets halachic times for current week.
   */
  public getCurrentWeekTimes(): void {
    this.halachicTimesService.getCurrentWeekTimes();
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
