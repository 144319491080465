import { Pipe, PipeTransform } from '@angular/core';
import { JewishCalendar } from 'kosher-zmanim';
import { SchedulerHelper } from '../_helpers/scheduler.helper';
import { TableRecords } from '../_models/table';
import { HalachicTimesService } from '../_services/halachic-times.service';

@Pipe({
  pure: true,
  name: 'filterTableRecords'
})
export class FilterTableRecordsPipe implements PipeTransform {

  constructor(private halachicTimesService: HalachicTimesService) {
  }

  /**
   * Filters table records according to the specified scheduler.
   * @async
   * @param {TableRecords} records - table records to filter.
   * @param {Date} date - selected date.
   * @param {number} dayLink - determines whether the date was changed according the specified change time.
   * @param {Date} shabbosEnd - Shabbos end time.
   * @param {boolean} inEretzIsrael - whether in Eretz Israel.
   */
  public async transform(records: TableRecords, date: Date, dayLink: number, shabbosEnd: Date, inEretzIsrael: boolean): Promise<TableRecords> {

    const calendar = new JewishCalendar(date);
    calendar.setInIsrael(inEretzIsrael);
    const result: TableRecords = [];

    for (const record of records) {
      const displayed = await SchedulerHelper.isHolidayVisible(record.recordHolidaysDisplayConditions, dayLink, calendar, date,
          this.getCandleLightTime(), shabbosEnd) &&
        SchedulerHelper.isDayVisible({ daysOfWeek: record.recordDaysDisplayConditions }, date);

      if (displayed) {
        record.displayed = true;
        result.push(record);
      }
    }

    return result;
  }

  /**
   * Calculates the candlelight time according to the sunset halachic time.
   * @private
   */
  private getCandleLightTime(): Date {
    const candleLightMin = this.halachicTimesService.candleLightTime;
    const sunsetStr = new Date(this.halachicTimesService.halachicTimes['Sunset']).toLocaleString('en-US', {
      timeZone: this.halachicTimesService.options.timeZoneId
    });
    const candleLightTime = new Date(sunsetStr);
    candleLightTime.setMinutes(candleLightTime.getMinutes() - candleLightMin);

    return candleLightTime;
  }
}
