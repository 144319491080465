import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-test-error',
  templateUrl: './test-error.component.html',
  styleUrls: ['./test-error.component.css']
})
export class TestErrorComponent {
  public validationErrors: any[] = [];

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public get404Error() {
    this.http.get(this.apiUrl + 'users/doesnotexist').subscribe(
      response => console.log(response),
      error => console.log(error)
    );
  }

  public get500Error() {
    this.http.get(this.apiUrl + 'buggy/servererror').subscribe(
      response => console.log(response),
      error => console.log(error)
    );
  }

  public get400Error() {
    this.http.get(this.apiUrl + 'buggy/badrequest').subscribe(
      response => console.log(response),
      error => console.log(error)
    );
  }

  public get400ValidationError() {
    this.http.get(this.apiUrl + 'buggy/badrequest/five').subscribe(
      response => console.log(response),
      error => {
        console.log(error);
        this.validationErrors = error.errors;
      }
    );
  }
}
