<div class="d-flex justify-content-between scheduler" [formGroup]="timeRange"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <mat-form-field class="w-32">
    <mat-label>{{ 'dashboard.widgetProperties.scheduler.timeRange.start' | translate }}</mat-label>
    <input #timeStart (input)="setTime()" [value]="scheduler?.timeRange?.start" formControlName="startTime" type="time" matInput>
  </mat-form-field>
  <mat-form-field class="w-32">
    <mat-label>{{ 'dashboard.widgetProperties.scheduler.timeRange.end' | translate }}</mat-label>
    <input #timeEnd (input)="setTime()" [value]="scheduler?.timeRange?.end" formControlName="endTime" type="time" matInput>
  </mat-form-field>
  <button (click)="clearTimeRange()"
          [title]="'buttons.clear' | translate"
          class="clear-button"
          mat-icon-button>
    <mat-icon matSuffix>clear</mat-icon>
  </button>
</div>
<mat-error *ngIf="timeRange?.controls['endTime'].dirty && timeRange?.controls['endTime'].invalid ||
      timeRange?.controls['startTime'].dirty && timeRange?.controls['startTime'].invalid">
  {{ 'dashboard.widgetProperties.scheduler.timeRange.error' | translate }}
</mat-error>
