<div class="scheduler">
  <div class="time-select"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-form-field appearance="standard" class="select-font">
      <mat-select (selectionChange)="setTime($event.value)"
                  [value]="scheduler.dynamicTimeRange?.time"
                  [placeholder]="'dashboard.widgetProperties.scheduler.dynamicTimeRange.select' | translate"
                  [style.direction]="direction"
                  [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-option *ngFor="let time of times" [value]="time.option"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          <span class="select-font">{{ direction === 'rtl' ? time.he : time.en }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button (click)="clearDynamicTime()"
            [title]="'buttons.clear' | translate"
            class="clear-button"
            mat-icon-button>
      <mat-icon matSuffix>clear</mat-icon>
    </button>
  </div>

  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="d-flex justify-content-around align-items-baseline ml-3">
      <mat-form-field appearance="standard"
                      class="select-font w-35"
                      [style.direction]="direction"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-label>{{ 'dashboard.widgetProperties.scheduler.dynamicTimeRange.add' | translate }}</mat-label>
        <input #addition matInput type="number" step="1" min="-180" max="180" [value]="scheduler.dynamicTimeRange?.addition?.toString()"
               (change)="setAddition(+addition.value)">
      </mat-form-field>

      <mat-form-field appearance="standard"
                      class="select-font w-35"
                      [style.direction]="direction"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-label>{{ 'dashboard.widgetProperties.scheduler.dynamicTimeRange.duration' | translate }}</mat-label>
        <input #duration matInput step="1" type="time" min="00:00:00" max="03:00:00" [value]="scheduler.dynamicTimeRange?.duration || '00:00:00'"
               (change)="setDuration(duration.value)">
      </mat-form-field>
    </div>
  </div>
</div>
