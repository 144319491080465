<div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <div class="flex-grow-1">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.media.interval' | translate }}</span>
  </div>
  <div class="d-flex justify-content-around flex-grow-2">
    <mat-form-field appearance="standard" class="select-font">
      <input (change)="markScreenDirty()" [(ngModel)]="widget.content.media.interval" matInput max="180" min="0" step="1" type="number">
    </mat-form-field>
  </div>
</div>

<div class="settings container-sm d-flex justify-content-between align-items-center mt-4 mx-2 pb-4"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span>{{ 'dashboard.widgetProperties.specific.media.stretch' | translate }}</span>
  <mat-slide-toggle (change)="toggleStretched($event.checked); markScreenDirty()"
                    [checked]="widget.content.media.stretched">
  </mat-slide-toggle>
</div>

<div class="settings container-sm d-flex justify-content-center align-items-center">
  <button mat-raised-button (click)="openContentModal()"
          [style.direction]="direction"
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-icon>check_box</mat-icon>
    {{ 'dashboard.widgetProperties.specific.media.content' | translate }}
  </button>
</div>
