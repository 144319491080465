import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget } from "src/app/_models";
import { DateService } from "src/app/_services";

@Component({
  selector: 'app-add-text-by-date',
  templateUrl: './add-text-by-date.component.html',
  styleUrls: ['./add-text-by-date.component.css'],
})
export class AddTextByDateComponent implements OnInit {
  @Input() config: {
    message: string,
    direction: Direction,
    widget: Widget,
    date?: { year: number, month: number, day: number},
    htmlContent?: string,
  };
  selectedDateChange(date: Date) {
    this.config.date = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}
  }

  get date(): Date {
    return this.config.date ? new Date(this.config.date.year, this.config.date.month - 1, this.config.date.day) : null;
  }

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "auto",
    minHeight: "15rem",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "",
    defaultFontSize: "3",
    outline: false,
    sanitize: false,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      ["strikeThrough", "subscript", "superscript", "heading"],
      [
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "removeFormat",
        "insertHorizontalRule",
        "toggleEditorMode",
      ],
    ],
  };

  constructor(private dialogRef: MatDialogRef<AddTextByDateComponent>, private translate: TranslateService, private translateHelper: TranslateHelper, public dateService: DateService) { }

  ngOnInit(): void {
    this.setTranslation();

  }

  model: NgbDateStruct;


  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.editorConfig.placeholder =
      this.translate.currentLang === Languages.Hebrew
        ? "הזן טקסט כאן..."
        : "Enter text here...";
    this.translate.onLangChange.subscribe(({ lang }) => {
      this.config.direction = lang === Languages.Hebrew ? "rtl" : "ltr";
      this.editorConfig.placeholder =
        this.config.direction === "rtl" ? "הזן טקסט כאן..." : "Enter text here...";
    });
    this.config.direction = this.translateHelper.direction;
  }

  public ngAfterViewInit(): void {
    const buttonTitles = [
      { en: 'Undo', he: 'לבטל', ru: 'Отменить', title: '' },
      { en: 'Redo', he: 'לעשות שוב', ru: 'Сделать вновь', title: '' },
      { en: 'Bold', he: 'מודגש', ru: 'Жирный', title: '' },
      { en: 'Italic', he: 'נטוי', ru: 'Курсив', title: '' },
      { en: 'Underline', he: 'קו תחתון', ru: 'Подчеркнуть', title: '' },
      { en: 'Justify Left', he: 'ליישר שמאלה', ru: 'Выравнять налево', title: '' },
      { en: 'Justify Center', he: 'ליישר במרכז', ru: 'Выравнять по центру', title: '' },
      { en: 'Justify Right', he: 'ליישר ימינה', ru: 'Выравнять направо', title: '' },
      { en: 'Justify Full', he: 'ליישר משני צדדים', ru: 'Выравнять полностью', title: '' },
      { en: 'Indent', he: 'הזחה', ru: 'Отступ', title: '' },
      { en: 'Outdent', he: 'הזחה החוצה', ru: 'Отступ наружу', title: '' },
      { en: 'Unordered List', he: 'רשימה לא מסודרת', ru: 'Неупорядоченный список', title: '' },
      { en: 'Ordered List', he: 'רשימה מסודרת', ru: 'Упорядоченный список', title: '' },
      { en: 'Text Color', he: 'צבע טקסט', ru: 'Цвет текста', title: '' },
      { en: 'Background Color', he: 'צבע רקע', ru: 'Фоновый цвет', title: '' }
    ];

    setTimeout(() => {
      this.changeTextBackgroundColor(this.config.widget.textBackgroundColor);

      buttonTitles.forEach((btnTitle) => {
        const button: HTMLElement = document.querySelector(
          `.angular-editor-button[title="${btnTitle.en}"]`
        );
        btnTitle.title = button.title = btnTitle[this.translate.currentLang];
      });

      this.translate.onLangChange.subscribe(({ lang }) => {
        buttonTitles.forEach((btnTitle) => {
          const button: HTMLElement = document.querySelector(
            `.angular-editor-button[title="${btnTitle.title}"]`
          );
          btnTitle.title = button.title = btnTitle[lang];
        });
      });
    }, 1500);
  }

  /**
   * Changes the text editor background to contrast the font colour.
   * @param {string} colour - a chosen editor background colour.
   */
  public changeTextBackgroundColor(colour: string): void {
    const textEditor: HTMLDivElement = document.querySelector(
      ".angular-editor-textarea"
    );
    if (!textEditor) return;
    textEditor.style.cssText = `
      height: auto;
      min-height: 15rem;
      outline: none;
      background-color: ${colour} !important
    `;
  }

  /**
   * Closes the modal window.
   */
  public onCancel(): void {
    this.dialogRef.close();
  }
}
