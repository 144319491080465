<!--Transparent-->
<div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span>{{ 'dashboard.widgetProperties.background.transparent' | translate }}</span>
  <mat-slide-toggle (change)="toggleTransparent($event.checked)"
                    [checked]="widget.background.type === backgroundTypes.Transparent">
  </mat-slide-toggle>
</div>

<!--Colour/Image-->
<div class="settings container-sm d-flex justify-content-center align-items-center mx-2" [class.disabled]="isTransparent">
  <div class="w-50 d-flex justify-content-between align-items-center">
    <span>{{ 'dashboard.general.colour' | translate }}</span>
    <mat-slide-toggle (change)="toggleBackground($event.checked)"
                      [checked]="widget.background.type === backgroundTypes.Image">
    </mat-slide-toggle>
    <span>{{ 'dashboard.general.image' | translate }}</span>
  </div>
</div>

<!--Background colour picker-->
<div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
     [class.disabled]="isTransparent"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span>{{ 'dashboard.general.colour' | translate }}</span>
  <div [(colorPicker)]="widget.background.color"
       [style.background]="widget.background.color || '#e7ddff'"
       [style.right]="direction === 'rtl' ? '' : '1rem'"
       [style.left]="direction === 'rtl' ? '1rem' : ''"
       [cpOKButton]="true"
       [cpOKButtonText]="'buttons.ok' | translate"
       cpOKButtonClass="btn btn-outline-primary"
       [cpCancelButton]="true"
       cpCancelButtonClass="btn btn-outline-danger"
       [cpCancelButtonText]="'buttons.reset' | translate"
       class="colour-picker">
  </div>
</div>

<!--Background image upload-->
<div class="settings container-sm d-flex align-items-center flex-column mx-2" [class.disabled]="isTransparent">
  <app-upload [title]="'dashboard.general.image' | translate" endpoint="widget"
              [widget]="widget"
              [screen]="screen"
              [style.direction]="direction"
              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  </app-upload>
  <ng-container *ngIf="widget.background.imageUrl">
    <div class="background-title mt-2">{{ 'dashboard.general.backgroundImage' | translate }}</div>
    <div class="background-image mt-2">
      <img [src]="resourcesUrl + widget.background.imageUrl"
           [alt]="'dashboard.general.backgroundImage' | translate"
           [title]="'dashboard.general.backgroundImage' | translate">
    </div>
  </ng-container>
  <ng-container *ngIf="!widget.background.imageUrl">
    <p>{{ 'dashboard.widgetProperties.background.noImage' | translate }}</p>
  </ng-container>

<!--  Stretch-->
  <div class="d-flex justify-content-between align-items-center w-50 mt-3"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span>{{ 'dashboard.widgetProperties.background.stretch' | translate }}</span>
    <mat-slide-toggle (change)="toggleStretched($event.checked)"
                      [checked]="widget.background.stretched"
                      [style.background-size]="widget.background.stretched ? '100% 100%' : 'initial'"
                      [class.disabled]="!widget.background.imageUrl">
    </mat-slide-toggle>
  </div>
</div>
