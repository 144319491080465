<div #container
     *ngIf="!yahrzeitTable.monthPerPlaque"
     [ngStyle]="gridContainerStyle"
     class="grid-container">
  <div *ngFor="let item of visibleItems; let i = index"
       [style.backgroundImage]="item.isYahrzeit ? 'url(\'' + yahrzeitTable.highlightBackground + '\')' : 'url(\'' + yahrzeitTable.background + '\')'"
       [style.color]="item.isYahrzeit ? yahrzeitTable.forecolor : 'inherit'"
       [style.gridArea]="'item-' + i"
       class="grid-item">
    <div *ngFor="let row of item.formattedText"
         class="yahrzeit-table-row">
      {{ row }}
    </div>
    <img *ngIf="(item.formattedText[0] !== '' && item.formattedText[1] !== '' && item.formattedText[2] !== '') &&
                yahrzeitTable.showCandle &&
                (yahrzeitTable.candleAllDays || item.isYahrzeit)"
         [ngStyle]="candleImageStyle"
         [src]="yahrzeitTable.candleFilename"
         alt="candle"
         class="candle">
  </div>
</div>

<div #container
     *ngIf="yahrzeitTable.monthPerPlaque"
     [ngStyle]="gridContainerStyle"
     class="grid-container">
  <div *ngFor="let items of yahrzeitObservableItems$; let i = index"
       [style.backgroundImage]="(items | async)?.isYahrzeit ?
          'url(\'' + yahrzeitTable.highlightBackground + '\')' :
          'url(\'' + yahrzeitTable.background + '\')'"
       [style.color]="(items | async)?.isYahrzeit ? yahrzeitTable.forecolor : 'initial'"
       [style.gridArea]="'item-' + i"
       class="grid-item">
    <div *ngFor="let row of (items | async)?.formattedText"
         class="yahrzeit-table-row">
      {{ row }}
    </div>
    <img *ngIf="((items | async)?.formattedText[0] !== '' && (items | async)?.formattedText[1] !== '' && (items | async)?.formattedText[2] !== '') &&
                yahrzeitTable.showCandle &&
                (yahrzeitTable.candleAllDays || (items | async)?.isYahrzeit)"
         [ngStyle]="candleImageStyle"
         [src]="yahrzeitTable.candleFilename"
         alt="candle"
         class="candle">
  </div>
</div>
