import { Component, OnChanges, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Input } from "@angular/core";
import { NgbCalendarHebrew, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { Observable, timer, Subscription, merge } from "rxjs";
import { distinctUntilChanged, switchMap, map } from "rxjs/operators";
import { UserDto, EithanApiParametersDto, ApiDto, StudiesApiDto, KosherZmanimOptionDto } from "src/app/_dto";
import { Languages, MinhagApi, ChangeTimes } from "src/app/_enums";
import { JewishHelper } from "src/app/_helpers";
import { ScrollDirections, EffectTypes, Widget, HorizontalAlign, VerticalAlign } from "src/app/_models";
import { JewishPipe } from "src/app/_pipes";
import { DateService, WidgetService, EithanApiService, HalachicTimesService } from "src/app/_services";

@Component({
  selector: "app-jewish-viewer",
  templateUrl: "./jewish-viewer.component.html",
  styleUrls: ["./jewish-viewer.component.css"],
})
export class JewishViewerComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  ScrollDirectionsEnum: typeof ScrollDirections = ScrollDirections;
  EffectTypesEnum: typeof EffectTypes = EffectTypes;

  @ViewChild("wrapper") parentElement: ElementRef<HTMLElement> | null;

  @Input() widget: Widget;
  @Input() effectType: EffectTypes;
  @Input() user: UserDto;
  @Input() back: any;

  public autoEffect: boolean;
  public effectTypes = EffectTypes;
  public languages = Languages;

  public itemsPerView = 1;
  public jewishHelper = new JewishHelper();
  public studies: string[];
  public hoshana: string;
  public haftara: string;
  public avot: string;
  public mergedDate$: Observable<Date>;
  public itActuallyScrolls = false;
  public values: string[] = [];
  public temp = false;
  get clones() {
    return new Array(this.itemsPerView);
  }

  private jewishCalendar = new NgbCalendarHebrew();

  private dateChanged$ = timer(0, 1000).pipe(
    map(() => {
      const date = new Date().getDate();
      // return date;
      return this.getLinkDay(
        new Date(),
        this.user.times,
        this.widget.changeTimeKey
      ) > 0
        ? date + 1
        : date;
    }),
    distinctUntilChanged(),
    map((date) => new Date(new Date().setDate(date)))
  );

  private timeChanged$ = timer(0, 60 * 1000).pipe(map(() => new Date()));
  private studySubscription: Subscription;
  private hoshanosSubscription: Subscription;
  private haftarotSubscription: Subscription;
  private avotSubscription: Subscription;
  public scrollDuration = 3;

  get scrollSpeed() {
    return this.scrollDuration;
  }
  // implementing the scrollable abstract class
  scrollDirection: ScrollDirections;
  horizontalScroll = [ScrollDirections.Right, ScrollDirections.Left];
  get isHorizontal(): boolean {
    return (
      this.scrollDirection === ScrollDirections.Left ||
      this.scrollDirection === ScrollDirections.Right
    );
  }

  get isVertical(): boolean {
    return (
      this.scrollDirection === ScrollDirections.Up ||
      this.scrollDirection === ScrollDirections.Down
    );
  }

  constructor(
    public dateService: DateService,
    private widgetService: WidgetService,
    private studiesService: EithanApiService,
    private halachicTimesService: HalachicTimesService,
    private jewishPip: JewishPipe
  ) {
    this.mergedDate$ = merge(this.timeChanged$, dateService.dateChanged$);
  }

  async getValues(date: Date) {
    if (!this.widget?.content?.jewish?.keys) {
      return;
    }

    this.values = await this.jewishPip.transform(
      this.widget.content.jewish.keys,
      this.widget.language,
      date,
      this.user.times,
      this.widget.content.jewish.timeFormat,
      this.widget.content.jewish.hebrewDateDay,
      this.widget.content.jewish.hebrewDateMonth,
      this.widget.content.jewish.hebrewDateYear,
      this.widget.content.jewish.gregorianDate,
      this.widget.content.jewish.parasha,
      this.widget.content.jewish.dayOfWeek,
      this.widget.content.jewish.sefiratHaomer,
      this.widget.content.jewish.dafYomi,
      this.user.settings?.fastEnd.isMinutes,
      this.user.settings?.fastEnd.minutes,
      this.user.settings?.fastEnd.option,
      this.user.settings?.shabbosEnd.isMinutes,
      this.user.settings?.shabbosEnd.minutes,
      this.user.settings?.shabbosEnd.option,
      this.user.settings?.moladOnShabbos,
      this.user.settings?.kiddushLevanahEndBetweenMoldos,
      this.user.settings?.dayDescription.zioni,
      this.user.settings?.dayDescription.shabbosMevorchim,
      this.user.settings?.moridHatal,
      this.user.inEretzIsrael,
      this.user.minhag,
      this.studies,
      this.haftara,
      this.user.settings?.haftara.caption,
      this.avot,
      this.hoshana,
      this.widget.changeTimeKey,
      this.widget.id,
      this.widget.content.jewish.padZero,
    ).toPromise();
  }

  public get horizontalAlignCenter(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Center;
  }

  public get horizontalAlignLeft(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Left;
  }

  public get horizontalAlignRight(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Right;
  }

  public get verticalAlignTop(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Top;
  }

  public get verticalAlignCenter(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Center;
  }

  public get verticalAlignBottom(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Bottom;
  }

  get isHorizontalAlignLeft(): boolean {
    if (this.widget.language === this.languages.Hebrew) {
      return this.horizontalAlignRight;
    }
    return this.horizontalAlignLeft;
  }

  get isHorizontalAlignRight(): boolean {
    if (this.widget.language === this.languages.Hebrew) {
      return this.horizontalAlignLeft;
    }
    return this.horizontalAlignRight;
  }

  get isVerticalAlignCenter(): boolean {
    return this.widget.content.jewish.multipleLines ? this.horizontalAlignCenter : this.verticalAlignCenter;
  }

  get isVerticalAlignTopOrBottom(): { top: boolean; bottom: boolean } {
    if (this.widget.content.jewish.multipleLines) {
      if (this.widget.language === this.languages.Hebrew) {
        return { top: this.horizontalAlignRight, bottom: this.horizontalAlignLeft };
      }
      return { top: this.horizontalAlignLeft, bottom: this.horizontalAlignRight };
    }
    return { top: this.verticalAlignTop, bottom: this.verticalAlignBottom };
  }
  
  get isVerticalAlignDefaultScroll(): boolean {
    return this.widget.content.jewish.multipleLines &&
      this.widget.effect.type === this.effectTypes.Scroll &&
      this.isVertical;
  }

  private get wrapperElement(): HTMLElement {
    return this.parentElement.nativeElement;
  }

  get singleItemElement(): HTMLElement {
    return this.wrapperElement.firstElementChild as HTMLElement;
  }

  public ngOnChanges(): void {
    if (!this.parentElement) return;
  }

  public ngOnInit(): void {
    this.listenToAutoEffect();
    this.getStudies();
    this.getHoshanos();
    this.getHaftarot();
    this.getPirkeiAvot();
    this.scrollDirection = this.widget.effect.scrollDirection;
  }

  public ngAfterViewInit(): void {
    if (!this.parentElement) return;
    this.autoEffect = this.widget.effect.autoEffect;
  }

  public ngOnDestroy(): void {
    this.studySubscription.unsubscribe();
    this.hoshanosSubscription.unsubscribe();
    this.haftarotSubscription.unsubscribe();
    this.avotSubscription.unsubscribe();
  }

  get isSwap(): boolean {
    return this.widget.effect.type === EffectTypes.FastSwap;
  }

  /**
   * Retrieves Haftarot from cache or server on date change.
   * @private
   */
  private getHaftarot(): void {
    this.haftarotSubscription = merge(
      this.dateChanged$,
      this.dateService.dateChanged$
    ).pipe(
      switchMap((date: Date) => {
        const { year, month, day } = this.jewishCalendar.fromGregorian(
          new NgbDate(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate() + this.getLinkDay(date, this.user.times, this.widget.changeTimeKey)
          )
        );

        const parameters: EithanApiParametersDto = {
          year,
          month,
          day,
          language: this.widget.language,
          minhag: MinhagApi[this.user.settings.haftara.minhag],
          inEretzIsrael: this.user.inEretzIsrael,
        };

        return this.studiesService.fetchHaftarot(parameters, true);
      })
    ).subscribe((haftarot: ApiDto) => {
      this.haftara = haftarot[""][0][0];
    });
  }

  /**
   * Retrieves Pirkei Avot from cache or server on date change.
   * @private
   */
  private getPirkeiAvot(): void {
    this.avotSubscription = merge(
      this.dateChanged$,
      this.dateService.dateChanged$
    ).subscribe((date: Date) => {
      const parameters: EithanApiParametersDto = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day:
          date.getDate() +
          this.getLinkDay(date, this.user.times, this.widget.changeTimeKey),
        language: this.widget.language,
        minhag: this.user.minhag,
        inEretzIsrael: this.user.inEretzIsrael,
      };

      this.studiesService
        .fetchPirkeiAvot(parameters, true)
        .subscribe((avot: ApiDto) => (this.avot = avot[""][0]));
    });
  }

  /**
   * Retrieves special studies from cache or server on date change.
   * @private
   */
  private getStudies(): void {
    this.studySubscription = merge(
      this.dateChanged$,
      this.dateService.dateChanged$
    ).subscribe((date: Date) => {
      const parameters: EithanApiParametersDto = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day:
          date.getDate() +
          this.getLinkDay(date, this.user.times, this.widget.changeTimeKey),
        language: this.widget.language,
        inEretzIsrael: this.user.inEretzIsrael,
      };

      this.studiesService
        .fetchStudies(parameters, true)
        .subscribe((studies: StudiesApiDto) => (this.studies = studies[""][0]));
    });
  }

  /**
   * Retrieves Hoshanos from cache or server on date change.
   * @private
   */
  private getHoshanos(): void {
    this.hoshanosSubscription = merge(
      this.dateChanged$,
      this.dateService.dateChanged$
    ).subscribe((date: Date) => {
      const { year, month, day } = this.jewishCalendar.fromGregorian(
        new NgbDate(
          date.getFullYear(),
          date.getMonth() + 1,
          date.getDate() +
          this.getLinkDay(date, this.user.times, this.widget.changeTimeKey)
        )
      );

      const parameters: EithanApiParametersDto = {
        year,
        month,
        day,
        language: this.widget.language,
        inEretzIsrael: this.user.inEretzIsrael,
      };

      this.studiesService
        .fetchHoshanos(parameters, true)
        .subscribe(
          (hoshanos: StudiesApiDto) => (this.hoshana = hoshanos[""][0][6])
        );
    });
  }

  /**
   * Calculates the day link in order to change date at the selected change time.
   * @param {Date} date - current/selected date.
   * @param {KosherZmanimOptionDto[]} times - array of halachic time objects.
   * @param {ChangeTimes} changeTimeKey - change time key (e.g. sunset, tzais)
   * @private
   */
  private getLinkDay(
    date: Date,
    times: KosherZmanimOptionDto[],
    changeTimeKey: ChangeTimes = ChangeTimes.Tzais
  ): number {
    if (changeTimeKey === ChangeTimes.Midnight) {
      return 0;
    }

    const changeTimeOption = times.find(
      (time) => time.key === changeTimeKey
    )?.option;
    let temp =
      date > new Date(this.halachicTimesService.halachicTimes[changeTimeOption])
        ? 1
        : 0;

    return temp;
  }

  /**
   * Listens to the auto-effect change and starts the scroll accordingly.
   * @private
   * @returns {void}
   */
  private listenToAutoEffect(): void {
    this.widgetService.effectUpdated$.subscribe(({ widgetId }) => {
      if (widgetId !== this.widget.id) return;
      this.autoEffect = this.widget.effect.autoEffect;
    });
  }
}
