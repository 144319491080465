import { Component, ElementRef, Input, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-text-flasher',
  templateUrl: './text-flasher.component.html',
  styleUrls: ['./text-flasher.component.css']
})
export class TextFlasherComponent implements  OnInit, AfterViewInit {
  @Input() content: string[];
  @ViewChild('widget', { static: true }) wrap: ElementRef<HTMLDivElement>;

  public currentValue$ = new BehaviorSubject<string>('');

  private initFontSize = 0;

  constructor() {
  }

  ngOnInit(): void {
    setInterval(() => {
      const first = this.content.shift();
      this.content.push(first);
      this.currentValue$.next(this.content[0]);
      this.fit(this.content[0].length);
    }, 2000);
  }

  ngAfterViewInit(): void {
    this.initFontSize = parseInt(window.getComputedStyle(this.wrap.nativeElement, null)
    .getPropertyValue('font-size'), 10);
  }

  private fit(len: number): void {
    const fontSize = (this.wrap.nativeElement.clientWidth / len) * 2;
    if (fontSize < this.initFontSize) {
      this.wrap.nativeElement.style.fontSize = fontSize + 'px';
    } else {
      this.wrap.nativeElement.style.fontSize = this.initFontSize + 'px';
    }
  }
}
