import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionTrackerService {
  private subscriptionCounters: { [key: string]: number } = {};

  incrementSubscriptionCounter(fileAndRow: string): void {
    if (!this.subscriptionCounters[fileAndRow]) {
      this.subscriptionCounters[fileAndRow] = 1;
    } else {
      this.subscriptionCounters[fileAndRow]++;
    }
  }

  getSubscriptionCounters(): { [key: string]: number } {
    return this.subscriptionCounters;
  }
}