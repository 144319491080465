import { createReducer, on } from "@ngrx/store";
import * as UserActions from "./user.actions";
import { initialUserState } from "./user.state";
import { getPermissionsFromToken } from "src/app/_helpers/roleToPermissionTranslator";

export const userReducer = createReducer(
  initialUserState,
  on(UserActions.loadUserPermissions, (state) => ({
    ...state,
    attributes: getPermissionsFromToken(),
  })),
);