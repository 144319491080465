import { Component, AfterViewInit, OnChanges, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList, Input, HostBinding, Renderer2 } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { Subscription, Subject, Observable } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { FitContainer } from "src/app/_directives";
import { Languages } from "src/app/_enums";
import { ScrollHelper } from "src/app/_helpers";
import { ScrollDirections, Widget, HorizontalAlign, VerticalAlign } from "src/app/_models";
import { WidgetService } from "src/app/_services";
import { ResizeObservable } from "src/app/_widgets";
import { AppState } from "src/app/store/app.state";
import { BaseScrollComponent } from "../base-scroll/base-scroll.component";

@Component({
  selector: 'app-vertical-scroll',
  templateUrl: './vertical-scroll.component.html',
  styleUrls: ['./vertical-scroll.component.css']
})
export class VerticalScrollComponent extends BaseScrollComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('textWrapper') textWrapper: ElementRef<HTMLDivElement>;
  @ViewChild("scrollContainer") scrollContainer: ElementRef<HTMLDivElement>;
  @ViewChild("hiddenContainer") hiddenContainer: ElementRef<HTMLDivElement>;
  @ViewChild("scrollingText") scrollingText: ElementRef<HTMLDivElement>;
  @ViewChildren(FitContainer) components: QueryList<FitContainer>;
  public Languages: typeof Languages = Languages;
  private _text: string[];
  resizeStopSubscription: Subscription;

  scrollIfShouldSubject = new Subject<any>();

  debouncer$: Observable<any> = this.scrollIfShouldSubject.pipe(
    debounceTime(200)
  );

  private debouncerSubscription: Subscription;
  private contentChangedSubscription: Subscription;
  private widgetStateSubscription: Subscription;


  @Input()
  get text(): string[] {
    return this._text;
  }
  set text(value: string[]) {
    this._text = value;
  }

  get animationDirection(): "down" | "up" {
    return this.scrollDirection === ScrollDirections.Down ? "down" : "up";
  }

  private _shouldScroll: "scroll" | "autoEffect" | "doNotScroll";

  @Input() set shouldScroll(type: "scroll" | "autoEffect" | "doNotScroll") {
    this._shouldScroll = type;
    this.scrollIfShouldSubject.next();
  }

  get shouldScroll(): "scroll" | "autoEffect" | "doNotScroll" {
    return this._shouldScroll;
  }

  @Input() widget: Widget;
  @Input() htmlContent: string;
  private _multiHtmlContent: string[];
  @Input() set multiHtmlContent(value: string[]) {
    this._multiHtmlContent = value;
    this.scrollIfShouldSubject.next();
  }
  get multiHtmlContent(): string[] {
    return this._multiHtmlContent;
  }
  @Input() scrollDirection: ScrollDirections;
  @Input() separator: string;
  @Input() speed: number = 20;

  @HostBinding('style.textAlign')
  get horizontalAlign() {
    switch (this.widget.font.horizontalAlign) {
      case HorizontalAlign.Left:
        return 'left';
      case HorizontalAlign.Center:
        return 'center';
      case HorizontalAlign.Right:
        return 'right';
      case HorizontalAlign.Justify:
        return 'justify';
    }
  }

  get verticalAlign(): 'top' | 'middle' | 'bottom' {
    switch (this.widget.font.verticalAlign) {
      case VerticalAlign.Top:
        return 'top';
      case VerticalAlign.Center:
        return 'middle';
      case VerticalAlign.Bottom:
        return 'bottom';
    }
  }

  /**
   * A ResizeObserver that observes changes in the size of the container element.
   */
  private containerResizeObserver = Subscription.EMPTY;
  resizeEndCallback = async () => {
    await new Promise(resolve => setTimeout(resolve, 100));
    this.components.forEach(component => {
      component.changeTextFontSize();
    });
    this.scrollIfShouldSubject.next();
  }

  constructor(
    private widgetService: WidgetService,
    public sanitizer: DomSanitizer,
    public elementRef: ElementRef,
    private renderer: Renderer2,
    private store: Store<AppState>,
  ) {
    super(renderer);
  }

  public startResizeObserver(containerElement: HTMLElement): void {
    // Create a ResizeObservable instance that listens for resize events on the container element
    this.containerResizeObserver =
      new ResizeObservable(containerElement)
        // Extract the first entry from the resize event array (since we are only observing one element)
        .pipe(
          map(entries => entries[0]),
        )
        // Subscribe to the resize events, and update the lastResizeEntry$ subject with the latest event
        .subscribe();

    // Subscribe to the resizeStopSource subject, and call the resizeEndCallback function with the last resize entry when the subject emits a value
    this.resizeStopSubscription = this.widget.resizeStopSource.subscribe(() => {
      this.resizeEndCallback();
    });

    this.widget.contentChangedSource.subscribe(async () => {
      // There is a bug that occurs when the following steps are taken:
      // 1. Add a record to the table with a date set to a different day
      // 2. Observe that the table initially displays the record in the current day
      // 3. Notice that the table begins to scroll, even though it shouldn't
      // 4. The record is then removed from the table, but the table continues to scroll
      //
      // To fix this issue, we wait for the table to update before checking if it should scroll.
      // This issue is not reproducible when the table is first loaded, so we only wait for the table to update when the content changes.
      //
      // TODO: Fix bug where table scrolls unexpectedly when a record is added with a date set to a different day.
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.resizeEndCallback();
    });
  }

  async ngAfterViewInit() {
    this.debouncerSubscription = this.debouncer$.subscribe(() => {
      this.scrollIfShould();
    });
    if (this.shouldScroll === "scroll") {
      this.scrollIfShouldSubject.next();
    }

    // Observe the scroll element for changes in size
    this.startResizeObserver(this.elementRef.nativeElement);
    // this.containerResizeObserver.observe(this.elementRef.nativeElement);
    this.contentChangedSubscription = this.widget.contentChangedSource.subscribe(async () => {
      // There is a bug that occurs when the following steps are taken:
      // 1. Add a record to the table with a date set to a different day
      // 2. Observe that the table initially displays the record in the current day
      // 3. Notice that the table begins to scroll, even though it shouldn't
      // 4. The record is then removed from the table, but the table continues to scroll
      //
      // To fix this issue, we wait for the table to update before checking if it should scroll.
      // This issue is not reproducible when the table is first loaded, so we only wait for the table to update when the content changes.
      //
      // TODO: Fix bug where table scrolls unexpectedly when a record is added with a date set to a different day.
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.scrollIfShouldSubject.next();
    });
    this.listenToAutoEffect();
    this.scrollIfShouldSubject.next();

    this.widgetStateSubscription = this.store.select(state => state.widgets[this.widget.id]).subscribe(_ => {
      this.components.forEach(component => {
        component.changeTextFontSize();
      });
      this.scrollIfShouldSubject.next();
    });
  }

  async ngOnChanges() {
    let index = 0;
    while (!this.hiddenContainer) {
      await new Promise(resolve => setTimeout(resolve, 100));
      console.log(index++);
    }
    requestAnimationFrame(async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      this.scrollIfShouldSubject.next();
    });
  }

  private listenToAutoEffect(): void {
    this.widgetService.effectUpdated$.subscribe(({ widgetId }) => {
      if (widgetId !== this.widget.id) return;
      this.speed = ScrollHelper.changeScrollSpeed(this.hiddenContainer.nativeElement, this.widget);
      this.scrollIfShouldSubject.next();
    });
  }

  /**
   * Handles the end of an animation for the text wrapper element.
   *
   * @param element - The element whose children's animations should be updated.
   */
  onAnimationEnd(element: HTMLElement) {
    const children = Array.from(element.children) as [HTMLElement, HTMLElement, HTMLElement];
    const animationDirection = this.animationDirection;
    const animations = children.map((child: HTMLElement) => {
      const currentAnimationState = this.getCurrentAnimationState(child);
      const nextAnimationState = this.getNextAnimationState(currentAnimationState);
      return `scroll-${animationDirection}-${nextAnimationState} ${this.speed}s linear`;
    });
    [children[0].style.animation, children[1].style.animation, children[2].style.animation] = animations;
  }

  getCurrentAnimationState(child: HTMLElement): string {
    return child.style.animation.split('-')[2];
  }

  getNextAnimationState(currentAnimationState: string): string {
    const animationStateMap = {
      start: 'middle',
      middle: 'end',
      end: 'start'
    };
    return animationStateMap[currentAnimationState];
  }

  private scrollIfShould = () => {
    if (this.shouldScroll === "autoEffect") {
      if (
        this.hiddenContainer?.nativeElement.offsetHeight > this.hiddenContainer?.nativeElement.parentElement?.offsetHeight
      ) {
        this.stopScrolling();
        this.startScrolling();
      } else {
        this.stopScrolling();
      }
    } else if (this.shouldScroll === "scroll") {
      this.stopScrolling();
      this.startScrolling();
    } else {
      this.stopScrolling();
    }
  }

  startScrolling = async () => {
    if (this._shouldScroll === "doNotScroll") return;
    while (true) {
      if (this.hiddenContainer?.nativeElement.offsetHeight === 0 || !this.scrollContainer?.nativeElement) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        continue;
      } else if (this.shouldScroll === 'autoEffect' && this.textWrapper?.nativeElement.offsetHeight < this.hiddenContainer?.nativeElement?.parentElement.offsetHeight) {
        return;
      } else {
        const scrollContainer = this.scrollContainer.nativeElement;
        const textWrapper = this.textWrapper.nativeElement;
        while (this.textWrapper?.nativeElement.offsetHeight < this.hiddenContainer?.nativeElement?.parentElement.offsetHeight) {
          textWrapper.insertBefore(textWrapper.children[0].cloneNode(true), textWrapper.children[0]);
        }

        if (this.textWrapper?.nativeElement.offsetHeight > this.hiddenContainer?.nativeElement?.parentElement.offsetHeight) {
          const animationDirection = this.animationDirection;
          while (scrollContainer.children.length < 3) {
            scrollContainer.appendChild(textWrapper.cloneNode(true));
          }
          this.speed = ScrollHelper.changeScrollSpeed(this.hiddenContainer.nativeElement.parentElement, this.widget);
          this.updateAnimation(scrollContainer, animationDirection, this.speed, this.renderer);
          return;
        }
      }

      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  stopScrolling = async () => {
    this.scrollContainer && super.stopScrolling(this.scrollContainer, this.hiddenContainer.nativeElement.innerHTML);
  };

  ngOnDestroy() {
    this.resizeStopSubscription.unsubscribe();
    this.containerResizeObserver.unsubscribe();
    this.scrollIfShouldSubject.unsubscribe();
    this.debouncerSubscription.unsubscribe();
    this.contentChangedSubscription.unsubscribe();
    this.widgetStateSubscription.unsubscribe();
  }
}
