import { AfterViewInit, Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { ScrollHelper } from '../../_helpers/scroll.helper';
import { Effect, EffectTypes, ScrollDirections } from '../../_models/effect';
import { Widget } from '../../_models/widget';
import { WidgetService } from '../../_services/widget.service';

/**
 * Applies a chosen effect (scroll, swap etc.) to the element.
 */
@Directive({
  selector: '[appEffect]'
})
export class EffectDirective implements OnInit, AfterViewInit {
  @Input('appEffect') isAutoEffect = true;
  @Input() widget: Widget;

  @HostBinding('class') classNames: string;
  @HostBinding('style') styles: SafeStyle;

  private animationDuration: number;

  constructor(private elementRef: ElementRef<HTMLElement>, private widgetService: WidgetService) {
  }

  public ngOnInit(): void {
    this.widgetService.effectUpdated$.subscribe(({ widgetId }) => {
      if (this.widget.id !== widgetId) return;
      this.loadEffect();
    });
  }

  public ngAfterViewInit(): void {
    this.widget.effectSource = new BehaviorSubject<Effect>(this.widget.effect);
    this.loadEffect();
  }

  /**
   * Listens to effect observable and loads the required effect.
   * @private
   */
  private loadEffect(): void {
    this.widget.effectSource && this.widget.effectSource.subscribe((effect: Effect) => {
      switch (effect.type) {
        case EffectTypes.None:
          this.removeEffectClass();
          break;
        case EffectTypes.Scroll:
          this.runScrollEffect(effect);
          break;
        case EffectTypes.BlurredSwap:
          break;
        case EffectTypes.FastSwap:
          break;
        default:
          break;
      }
    });
  }

  /**
   * Removes 'scroll' CSS classes.
   * @private
   */
  private removeEffectClass(): void {
    this.classNames = '';
  }

  /**
   * Removes the scroll effect CSS class.
   * @private
   */
  private runScrollEffect(effect: Effect): void {
    const { scrollSpeed, scrollDirection } = effect;

    if (!this.widget.effect.autoEffect) {
      setTimeout(() => this.addScrollClassAndCalculateSpeed(scrollDirection, scrollSpeed), 2000);
    } else {
      setTimeout(() => this.addClassByCondition(scrollDirection, scrollSpeed), 3000);
    }
  }

  /**
   * Wrapper for addScrollClassAndCalculateSpeed method. Checks if scroll is needed.
   * @param {ScrollDirections} direction - scroll direction (enum).
   * @param {number} speed - scroll speed.
   * @private
   */
  private addClassByCondition(direction: ScrollDirections, speed: number): void {
    if (ScrollHelper.isScrollRequired(this.elementRef.nativeElement, direction)) {
      this.addScrollClassAndCalculateSpeed(direction, speed);
    } else {
      this.removeEffectClass();
    }
  }

  /**
   * Adds scroll class name and defines the scroll speed CSS variable.
   * @param {ScrollDirections} direction - scroll direction (enum).
   * @param {number} speed - scroll speed.
   * @private
   */
  private addScrollClassAndCalculateSpeed(direction: ScrollDirections, speed: number): void {
    const scrollDirection = {
      [ScrollDirections.Up]: 'up',
      [ScrollDirections.Down]: 'down',
      [ScrollDirections.Left]: 'left',
      [ScrollDirections.Right]: 'right'
    };

    const literalScrollDirection = scrollDirection[direction];
    this.classNames = `scroll scroll-${literalScrollDirection}`;

    const { scrollWidth, scrollHeight } = this.elementRef.nativeElement;

    this.animationDuration = ['up', 'down'].includes(literalScrollDirection)
      ? scrollHeight / speed
      : scrollWidth / speed / 50;

    this.styles = `--scroll-duration: ${this.animationDuration}s;`;
  }
}
