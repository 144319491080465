<div class="container scheduler" [class.non-hebrew-width]="direction === 'ltr'">
  <div class="row"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <div class="col-6">
      <div *ngFor="let day of holidaysFirstColumn">
        <mat-checkbox (change)="onChange(day)"
                      [checked]="day.status === status.Checked"
                      [indeterminate]="day.status === status.Indeterminate"
                      [color]="day.status === status.Indeterminate ? 'accent' : 'primary'"
                      [title]="day.status === status.Indeterminate
                              ? ('dashboard.widgetProperties.scheduler.holidays.ignore' | translate)
                              : (day.status === status.Checked
                                    ? ('dashboard.widgetProperties.scheduler.holidays.show' | translate)
                                    : ('dashboard.widgetProperties.scheduler.holidays.hide' | translate))">
          <span class="mx-3">{{ direction === 'rtl' ? day.he : day.en }}</span>
        </mat-checkbox>
      </div>
    </div>
    <div class="col-6">
      <div *ngFor="let day of holidaysSecondColumn">
        <mat-checkbox (change)="onChange(day)"
                      [checked]="day.status === status.Checked"
                      [indeterminate]="day.status === status.Indeterminate"
                      [color]="day.status === status.Indeterminate ? 'accent' : 'primary'"
                      [disabled]="day.id === 'bahab'"
                      [title]="day.status === status.Indeterminate
                              ? ('dashboard.widgetProperties.scheduler.holidays.ignore' | translate)
                              : (day.status === status.Checked
                                    ? ('dashboard.widgetProperties.scheduler.holidays.show' | translate)
                                    : ('dashboard.widgetProperties.scheduler.holidays.hide' | translate))">
          <span class="mx-2">{{ direction === 'rtl' ? day.he : day.en }}</span>
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div #collapseErevShabbos="ngbCollapse"
       [(ngbCollapse)]="isCollapsedErevShabbos"
       class="row justify-content-around align-items-baseline ml-3">
    <mat-form-field appearance="standard" class="select-font w-35 erev-shabbos"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.holidays.start' | translate }}</mat-label>
      <input matInput type="time" [(ngModel)]="scheduler.holidays[10].erevShabbosStartTime">
    </mat-form-field>
  </div>

  <div #collapseHanukkah="ngbCollapse"
       [(ngbCollapse)]="isCollapsedHanukkah"
       class="row justify-content-center align-items-center">
    <div class="my-3 select-title">{{ 'dashboard.widgetProperties.scheduler.holidays.candles' | translate }}</div>
    <mat-button-toggle-group multiple>
      <mat-button-toggle *ngFor="let candle of [0, 1, 2, 3, 4, 5, 6, 7]"
                         [checked]="scheduler.holidays[11].hanukkahCandles[candle]"
                         (change)="toggleCandle(candle)">
        {{ candle + 1 }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

<!--  <div class="row justify-content-center w-100 mt-2">-->
<!--    <button (click)="clearHolidays()" mat-button>-->
<!--      {{ 'buttons.clearAll' | translate }}-->
<!--    </button>-->
<!--  </div>-->
</div>
