<div class="container">
  <mat-card-subtitle translate>times.subtitle</mat-card-subtitle>

  <div class="jewish-settings-single">
    <div [style.direction]="direction" [ngbNavOutlet]="nav"></div>

    <div class="tabs-container" [style.direction]="direction === 'rtl' ? 'ltr' : 'rtl'">
      <ul ngbNav #nav="ngbNav" [activeId]="activeTabIndex" class="nav-pills tabs">
        <li [ngbNavItem]="i" *ngFor="let option of options; let i = index">
          <a ngbNavLink>{{ 'jewish.' + option | translate }}</a>
          <ng-template ngbNavContent>
            <div [ngSwitch]="option">
              <div *ngSwitchCase="'hebrewDate'">
                <app-hebrew-date [user]="user"></app-hebrew-date>
              </div>
              <div *ngSwitchCase="'gregorianDate'">
                <app-gregorian-date [user]="user"></app-gregorian-date>
              </div>
              <div *ngSwitchCase="'parasha'">
                <app-parasha [user]="user"></app-parasha>
              </div>
              <div *ngSwitchCase="'dafYomi'">
                <app-daf-yomi [user]="user"></app-daf-yomi>
              </div>
              <div *ngSwitchCase="'sefiratHaomer'">
                <app-sefirat-haomer [user]="user"></app-sefirat-haomer>
              </div>
              <div *ngSwitchCase="'molad'">
                <app-molad [user]="user"></app-molad>
              </div>
              <div *ngSwitchCase="'kiddushLevanahEnd'">
                <app-kiddush-levana-end [user]="user"></app-kiddush-levana-end>
              </div>
              <div *ngSwitchCase="'dayOfWeek'">
                <app-day-of-week [user]="user"></app-day-of-week>
              </div>
              <div *ngSwitchCase="'haftara'">
                <app-haftara [user]="user"></app-haftara>
              </div>
              <div *ngSwitchCase="'dayDescription'">
                <app-day-description [user]="user"></app-day-description>
              </div>
              <div *ngSwitchCase="'moridHatal'">
                <app-morid-hatal [user]="user"></app-morid-hatal>
              </div>
              <div *ngSwitchDefault>default output</div>
            </div>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>

</div>
<div mat-dialog-actions class="actions" [style.direction]="direction">
  <button (click)="save()"
          color="primary"
          mat-raised-button
          matDialogClose
          type="submit">
    {{ 'buttons.ok' | translate }}
  </button>
</div>
