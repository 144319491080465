import { Injectable } from '@angular/core';
import { Languages } from '../_enums/languages';
import { LocalStorage } from '../_enums/local-storage';

export type Direction = 'ltr' | 'rtl';

@Injectable({ providedIn: 'root' })
export class TranslateHelper {

  /**
   * Returns a direction according to the current language.
   */
  public get direction(): Direction {
    const language = localStorage.getItem(LocalStorage.Language) || Languages.English;
    return language === Languages.Hebrew ? 'rtl' : 'ltr';
  }
}
