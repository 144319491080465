import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'times'
})
export class TimesPipe implements PipeTransform {

  /**
   * The purpose of this pipe is to transform a number into array in order to use with 'ngFor' directive.
   * @param {number} value - the length of the desired array.
   */
  public transform(value: number): number[] {
    console.log('value', value);

    return (new Array(value)).fill(0);
  }
}
