<div class="container"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane">

  <mat-card-subtitle translate class="mb-5">profile.subtitle</mat-card-subtitle>

  <form [formGroup]="updateForm" (ngSubmit)="update()" [style.direction]="direction">

    <div class="remove-autofocus-from-first-input-field">
      <input autofocus="true">
    </div>

    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'" class="profile-property">
      <mat-label><sup class="required">*</sup> {{ 'userList.register.inputs.username' | translate }}</mat-label>
      <input matInput formControlName="username" type="text">
      <div *ngIf="username.status === 'PENDING'" matSuffix class="spinner-border spinner-border-sm spinner" role="status"></div>
      <mat-icon *ngIf="!username.dirty" matSuffix class="success" style="visibility: hidden">done</mat-icon>
      <mat-icon *ngIf="username.dirty && username.valid" matSuffix class="success">done</mat-icon>
      <mat-icon *ngIf="username.dirty && username.invalid" matSuffix class="required">error_outline</mat-icon>
      <mat-error *ngIf="username.touched && username.invalid && username.errors?.minlength">
        {{ 'userList.register.errors.nameLength' | translate }}
      </mat-error>
      <mat-error *ngIf="username.dirty && username.errors?.usernameExists">
        {{ 'userList.register.errors.nameExists' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'" class="profile-property">
      <mat-label><sup class="required">*</sup> {{ 'userList.register.inputs.email' | translate }}</mat-label>
      <input matInput formControlName="email" type="email">
      <div *ngIf="email.status === 'PENDING'" matSuffix class="spinner-border spinner-border-sm spinner" role="status"></div>
      <mat-icon *ngIf="!email.dirty" matSuffix class="success" style="visibility: hidden">done</mat-icon>
      <mat-icon *ngIf="email.dirty && email.valid" matSuffix class="success">done</mat-icon>
      <mat-icon *ngIf="email.dirty && email.invalid" matSuffix class="required">error_outline</mat-icon>
      <mat-error *ngIf="email.touched && email.invalid && email.errors?.required">
        {{ 'userList.register.errors.emailRequired' | translate }}
      </mat-error>
      <mat-error *ngIf="email.touched && email.invalid && email.errors?.email">
        {{ 'userList.register.errors.email' | translate }}
      </mat-error>
      <mat-error *ngIf="email.dirty && email.invalid && email.errors?.emailExists">
        {{ 'userList.register.errors.emailExists' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'" class="profile-property">
      <mat-label translate><sup class="required">*</sup> profile.inputs.location</mat-label>
      <input ngx-google-places-autocomplete
             (onAddressChange)="handleAddressChange($event)"
             [options]="apiOptions"
             (input)="locationSelected = false"
             formControlName="location"
             matInput
             [placeholder]="'userList.register.inputs.locationPlaceholder' | translate">
    </mat-form-field>

    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'" class="profile-property">
      <mat-label translate><sup class="required">*</sup> profile.inputs.minhag</mat-label>
      <mat-select formControlName="minhag">
        <mat-option *ngFor="let minhagValue of minhags$ | async" [value]="minhagValue"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          {{ minhagValue }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="minhag.touched && minhag.invalid && minhag.errors?.required">
        {{ 'userList.register.errors.minhagRequired' | translate }}
      </mat-error>
    </mat-form-field>

<!--    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'" class="profile-property">-->
<!--      <mat-label translate><sup class="required">*</sup> profile.inputs.candle</mat-label>-->
<!--      <mat-select formControlName="candleLight">-->
<!--        <mat-option *ngFor="let time of candleLightTimes" [value]="time"-->
<!--                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">-->
<!--          {{ time }}-->
<!--        </mat-option>-->
<!--      </mat-select>-->
<!--      <mat-error *ngIf="candleLight.touched && candleLight.invalid && candleLight.errors?.required">-->
<!--        {{ 'userList.register.errors.candleLightRequired' | translate }}-->
<!--      </mat-error>-->
<!--    </mat-form-field>-->

    <!--    <mat-checkbox formControlName="altitude"><div class="checkbox-label">{{ 'profile.inputs.altitude' | translate }}</div></mat-checkbox>-->

    <div mat-dialog-actions class="actions" [style.direction]="direction">
      <button [disabled]="updateForm.invalid"
              color="primary"
              mat-raised-button
              type="submit">
        {{ 'profile.buttons.update' | translate }}
      </button>
      <button mat-dialog-close color="accent" mat-raised-button type="button">
        {{ 'profile.buttons.cancel' | translate }}
      </button>
    </div>
  </form>
</div>

