import { Component, Input } from '@angular/core';
import { UserDto } from '../../../../../_dto/user-dto';

@Component({
  selector: 'app-hebrew-date',
  templateUrl: './hebrew-date.component.html',
  styleUrls: ['./hebrew-date.component.css']
})
export class HebrewDateComponent {
  @Input() user: UserDto;
}
