import { Direction } from "@angular/cdk/bidi";
import { CdkDragEnd } from "@angular/cdk/drag-drop";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import { Subject, BehaviorSubject } from "rxjs";
import { UserDto } from "src/app/_dto";
import { Languages, WidgetTypes, LocalStorage, Highlight, ChangeTimes } from "src/app/_enums";
import { WidgetHelper, TranslateHelper, hasPermission, SchedulerHelper } from "src/app/_helpers";
import { WidgetIcons, BackgroundTypes, Screens, Widget, Content, EffectTypes, WidgetIcon, Holiday, TimeUnits, Screen, Effect } from "src/app/_models";
import { PermissionTypes } from "src/app/_models/identity";
import { WidgetService, AccountService, ContentApiService, SnackbarService, EditService } from "src/app/_services";
import { fonts, fontStyles } from "src/app/global";
import { environment } from "src/environments/environment";
import { ConfirmationComponent } from "../../admin/admin-dashboard/user-list/confirmation/confirmation.component";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('nav') nav: NgbNav;

  private resourcesUrl = environment.resourcesUrl;

  public images: object[] = [
    {
      image: this.resourcesUrl + 'ScreenBackgroundTemplates/background-1.jpg',
      thumbImage: this.resourcesUrl + 'ScreenBackgroundTemplates/background-1.jpg',
      alt: 'Background image',
      title: 'Background-1'
    },
    {
      image: this.resourcesUrl + 'ScreenBackgroundTemplates/background-2.jpg',
      thumbImage: this.resourcesUrl + 'ScreenBackgroundTemplates/background-2.jpg',
      alt: 'Background image',
      title: 'Background-2'
    },
    {
      image: this.resourcesUrl + 'ScreenBackgroundTemplates/background-3.jpg',
      thumbImage: this.resourcesUrl + 'ScreenBackgroundTemplates/background-3.jpg',
      alt: 'Background image',
      title: 'Background-3'
    }
  ];

  public imageLinks = [
    'ScreenBackgroundTemplates/background-1.jpg',
    'ScreenBackgroundTemplates/background-2.jpg',
    'ScreenBackgroundTemplates/background-3.jpg'
  ];

  public widgetIcons: WidgetIcons = WidgetHelper.widgetIcons;

  public fonts = fonts;
  public fontStyles = fontStyles;

  public backgroundTypes = BackgroundTypes;
  public languages = Languages;
  public screens: Screens = [];
  public currentScreen: Screen;
  public selectedWidget: Widget;
  public unavailableWidgets = [WidgetTypes.Music, WidgetTypes.YahrzeitTable, WidgetTypes.Web];

  public direction: Direction = 'ltr';
  public screensNumber = 1;

  public editMode = false;

  private user: UserDto;

  constructor(
    public widgetService: WidgetService,
    private accountService: AccountService,
    private contentApiService: ContentApiService,
    private snackbar: SnackbarService,
    private editService: EditService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  /**
   * Returns the app current language.
   */
  public get language(): string {
    return localStorage.getItem(LocalStorage.Language) || this.translate.currentLang;
  }

  /**
   * Determines whether the widget icon was dropped onto the screen.
   * @param {number} id - the dropped icon id HTML attribute.
   * @private
   * @static
   */
  private static isInScreen(id: number): boolean {
    const screenRect = DashboardComponent.getCoordinates('screen');
    const componentRect = DashboardComponent.getCoordinates(id.toString());

    return componentRect.top >= screenRect.top &&
      componentRect.bottom <= screenRect.bottom &&
      componentRect.left >= screenRect.left &&
      componentRect.right <= screenRect.right;
  }

  /**
   * Gets the dragged widget icon in order to determine whether it was dropped onto the screen.
   * @param {number} id - the dragged icon id HTML attribute.
   * @private
   * @static
   */
  private static getCoordinates(id: string): DOMRect {
    const element = document.getElementById(id);
    return element.getBoundingClientRect();
  }

  public ngOnInit(): void {
    this.setTranslation();
    this.editService.editMode$.subscribe((mode: boolean) => this.editMode = mode);
    this.accountService.getUser().subscribe((user: UserDto) => this.user = user);

    this.loadScreens();
    this.loadSelectedWidget();
    this.openWidgetsTabOnWidgetDelete();
  }

  /**
   * Handles Ctrl+D shortcut to duplicate the selected widget.
   * @param {KeyboardEvent} event - KeyboardEvent to stop default action and determine which keyboard button was pressed.
   */
  @HostListener('document:keydown.control.d', ['$event'])
  public duplicateWidget(event: KeyboardEvent): void {
    if (!hasPermission(PermissionTypes.ManageWidgets) || !this.editMode) return;
    event.preventDefault();

    this.currentScreen.dirty = true;
    let widgetDuplicate: Widget = { ...this.selectedWidget, contentSource: null, effectSource: null, contentChangedSource: null, resizeStopSource: null };
    widgetDuplicate = JSON.parse(JSON.stringify(widgetDuplicate));
    widgetDuplicate.contentSource = new Subject<Content>();
    widgetDuplicate.effectSource = new BehaviorSubject<Effect>({ type: EffectTypes.None });
    widgetDuplicate.contentChangedSource = new Subject<void>();
    widgetDuplicate.resizeStopSource = new Subject<IResizeEvent>();
    widgetDuplicate.id = WidgetHelper.getNewWidgetId(this.currentScreen);
    widgetDuplicate.position.top = this.selectedWidget.position.top + 5;
    widgetDuplicate.position.left = this.selectedWidget.position.left + 5;
    this.currentScreen.widgets.push(widgetDuplicate);
    this.updateScreen();
    this.snackbar.success(this.translate.instant('snackbar.widgetDuplicated'), this.translate.instant('buttons.ok'));
  }

  /**
   * Opens the widgets tab on the widget creation.
   */
  public openWidgetsTab(): void {
    this.widgetService.openWidgetsTab();
  }

  /**
   * Opens the remove confirmation modal window.
   */
  public openRemoveConfirmationPopup(): void {
    const dialogRef: MatDialogRef<ConfirmationComponent> = this.dialog.open(ConfirmationComponent);
    dialogRef.componentInstance.config = {
      message: this.translate.instant('snackbar.deleteScreen'),
      direction: this.direction as Direction
    };

    dialogRef.afterClosed().subscribe(result => {
      if (result === '') {
        this.removeScreen();
      }
    });
  }

  /**
   * Adds a new screen. Not implemented yet.
   */
  public addScreen(): void {
    // this.currentScreen = WidgetHelper.DefaultScreen;
    // this.updateScreen();
    this.screensNumber++;
  }

  /**
   * Selects a screen.
   * @param {string} screenName - selected screen name.
   */
  public selectScreen(screenName: string): void {
    this.currentScreen = this.screens?.find(screen => screen.name === screenName);
    if (!this.currentScreen.font) {
      this.currentScreen.font = WidgetHelper.DefaultFont;
    }
    this.updateScreen();
  }

  /**
   * Creates grabbing visual effect.
   * @param {MouseEvent} event - mouse event in order to check which mouse button was pressed.
   * @param {number} id - grabbed icon id HTML attribute.
   */
  public onGrabbed(event: MouseEvent, id: number): void {
    if (event.button === 0) {
      const element = document.getElementById(id.toString());
      element.style.position = 'fixed';
      element.style.top = event.clientY - 50 + 'px';
      element.style.left = event.clientX - 50 + 'px';
    }
  }

  /**
   * Creates a new widget.
   * @param {number} id - the widget icon HTML id attribute in order to detect whether the icon was dropped onto the screen.
   * @param {WidgetIcon} widgetIcon - the created widget icon object.
   * @param {MouseEvent | CdkDragEnd} [event] - Mouse or CdkDragEnd event to check whether the widget was created by double click or drag & drop (optional).
   */
  public addWidget(id: number, widgetIcon: WidgetIcon, event?: MouseEvent | CdkDragEnd): void {
    if (DashboardComponent.isInScreen(id) || (event as MouseEvent).type === 'dblclick') {
      const { timeFormat, settings: { hebrewDate, dafYomi, gregorianDate, sefiratHaomer, parasha, dayOfWeek } } = this.user;
      const defaultWidget: Widget = {
        id: WidgetHelper.getNewWidgetId(this.currentScreen),
        name: 'New Widget',
        type: widgetIcon.type,
        language: Languages.Hebrew,
        size: {
          width: 30,
          height: 20,
          widthPx: 0,
          heightPx: 0
        },
        position: {
          top: 40,
          left: 35
        },
        font: { ...WidgetHelper.DefaultFont },
        background: {
          type: BackgroundTypes.Transparent,
          color: '#91c4fb'
        },
        effect: { ...WidgetHelper.DefaultEffect },
        content: {
          text: {
            permanent: null,
            byDate: [],
            isShowAllYear: false,
            isShowMonth: false,
            isShowWeek: false,
            isShowDay: true,
            isPermanent: true,
            isDateHhebre: false,
            isSeeLineText: true,
            isSeeLineText1: true,
            isSeeLineText2: true,
            isSeeLineText3: true,
            isSeeLineText4: true,
            isSeeLineText5: true,
            isSeeLineText6: true,
            // singleLine: false,

          },
          clock: 1,
          digital: { format: 'HH:mm:ss', uppercase: true },
          jewish: {
            keys: [],
            timeFormat,
            hebrewDateDay: hebrewDate[0],
            hebrewDateMonth: hebrewDate[1],
            hebrewDateYear: hebrewDate[2],
            dafYomi,
            gregorianDate,
            sefiratHaomer,
            parasha,
            dayOfWeek: false,
            multipleLines: false,
            multipleLinesRemoveLines: false,
            smallTextAuto: false,
            padZero: false,
          },
          table: {
            singleLine: false,
            records: [],
            titleColumnWidth: 50,
            format: timeFormat,
            isAmPm: true,
            isUpper: false,
            padZero: false,
            highlight: Highlight.Never,
            highlightColor: '#ff8000',
            tableHolidaysHighlightConditions: SchedulerHelper.holidays.map((holiday: Holiday) => ({ ...holiday })),
            tableDaysHighlightConditions: [true, true, true, true, true, true, true],
            alertLayout: false,
            alertKeys: []
          },
          media: {
            interval: 3,
            items: [],
            stretched: true
          },
          pdf: {
            url: null,
            secondsPerPage: 5,
            originalPagesNumber: 0
          },
          rss: {
            url: null,
            delete: null,
            fontSize: 'medium',
            refresh: 60,
          },
        },
        separator: '●',
        contentSource: new Subject<Content>(),
        effectSource: new BehaviorSubject<Effect>({ type: EffectTypes.None }),
        widgetIcon,
        visible: true,
        scheduler: {
          daysOfWeek: null,
          dateRange: null,
          hebrewRange: null,
          timeRange: null,
          ifEmpty: false,
          dynamicTimeRange: null,
          holidays: SchedulerHelper.holidays.map((holiday: Holiday) => ({ ...holiday })),
          blink: {
            enabled: false,
            timeUnit: TimeUnits.Seconds,
            onTime: 1,
            offTime: 0,
            unitsCount: 1,
            activeUnit: 1
          }
        },
        enabled: true,
        changeTimeKey: ChangeTimes.Tzais,
        textBackgroundColor: '#ffffff',
        resizeStopSource: new Subject<IResizeEvent>(),
        contentChangedSource: new Subject<void>(),
        isHidden: false,
      };

      this.currentScreen.widgets.push(defaultWidget);
      this.updateScreen();
      this.widgetService.selectWidget(defaultWidget, widgetIcon);
      setTimeout(() => this.selectNewWidget(widgetIcon), 500);
    } else {
      const element = document.getElementById(id.toString());
      element.style.position = 'initial';
      (event as CdkDragEnd).source._dragRef.reset();
    }
  }

  /**
   * Toggles background image/colour.
   * @param {boolean} imageSelected - whether image background selected.
   */
  public toggleBackground(imageSelected: boolean): void {
    this.currentScreen.background.type = imageSelected
      ? BackgroundTypes.Image
      : BackgroundTypes.Color;

    this.updateScreen();
  }

  /**
   * Selects one of the standard background images.
   * @param {number} index - selected image link index.
   */
  public selectBackground(index: number): void {
    this.currentScreen.background.imageUrl = this.imageLinks[index];
    this.updateScreen();
  }

  /**
   * Emits the screen object on update.
   */
  public updateScreen(): void {
    this.widgetService.updateScreen(this.currentScreen);
  }

  /**
   * Change screen orientation.
   * @param {boolean} isPortrait - whether the portrait orientation is selected.
   */
  public changeOrientation(isPortrait: boolean): void {
    this.currentScreen.portraitOrientation = isPortrait;
    this.editService.setPortraitOrientation(isPortrait);
    this.updateScreen();
  }

  /**
   * Removes current screen.
   * @private
   */
  private removeScreen(): void {
    this.accountService.currentUser$.subscribe(({ legacyOrganizationId: legacyOrganizationId }) => {
      this.contentApiService.removeScreen(this.currentScreen.id, legacyOrganizationId).subscribe(
        () => {
          this.snackbar.info('Screen was successfully deleted');
          this.screensNumber--;
        },
        error => console.log(error)
      );
    });
  }

  /**
   * Selects the widget after its creation.
   * @param {WidgetIcon} widgetIcon - the created widget icon object.
   * @private
   */
  private selectNewWidget(widgetIcon: WidgetIcon): void {
    const widgets = document.querySelectorAll('.back');
    const current = widgets[widgets.length - 1] as HTMLDivElement;
    current.classList.add('selected-widget');
    Object.keys(widgetIcon).forEach(key => current.dataset[key] = widgetIcon[key]);
  }

  /**
   * Loads all screens for current organisation.
   * @private
   */
  private loadScreens(): void {
    this.widgetService.existingScreens$.subscribe((screens: Screens) => {
      if (screens && screens.length > 0) {
        this.screens = screens;
        this.screensNumber = screens.length;
        this.currentScreen = screens[0];
      } else {
        this.currentScreen = { ...WidgetHelper.DefaultScreen };
      }
    });
  }

  /**
   * Loads the selected widget.
   * @private
   */
  private loadSelectedWidget(): void {
    this.widgetService.widgetSelected$.subscribe((widget: Widget) => {
      this.selectedWidget = widget;
      if (this.nav.activeId !== 'selectedWidgetContent' && this.nav.activeId !== 'widgetProperties') {
        this.nav.select('selectedWidgetContent');
      }
    });
  }

  /**
   * Redirects to the Widgets tab on widget delete.
   * @private
   */
  private openWidgetsTabOnWidgetDelete(): void {
    this.widgetService.widgetsTabOpened$
      .subscribe(() => this.nav.select('widgets'));
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
