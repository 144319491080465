import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { TimeFormat, Languages, LocalStorage } from "src/app/_enums";
import { JewishHelper, TranslateHelper } from "src/app/_helpers";
import { Widget, EffectTypes, ScrollDirections, Screen } from "src/app/_models";
import { WidgetService } from "src/app/_services";
import { WidgetsState, changeSeparator, changeTimeFormatJewishWidget } from "src/app/store/widget";
import { JewishContentComponent } from "./jewish-content/jewish-content.component";

@Component({
  selector: 'app-jewish-dashboard',
  templateUrl: './jewish-dashboard.component.html',
  styleUrls: ['./jewish-dashboard.component.css']
})
export class JewishDashboardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;

  public timeFormat = TimeFormat;
  public dateFormats = JewishHelper.dateFormats;
  public languages = Languages;
  public direction: Direction = 'ltr';
  public effectTypes = EffectTypes;
  public scrollDirections = ScrollDirections;

  public chosenContent: string[] = [];
  public contentToChoose: string[] = [
    'hebrewDate',
    'gregorianDate',
    'dayOfWeek',

    'sunset',
    'sunrise',
    'fastEnd',

    'candleLight',
    'shabbosEnd',
    'rabeinuTam',

    'moridHageshem',
    'vetenTal',
    'tachanun',
    'yaaleVeyavo',
    'alHanisim',
    'nachem',
    'ledavid',
    'hamelechHakadosh',
    'atoYatzarto',
    'veaneinu',

    'dayDescription',
    'sefiratHaomer',
    'hoshana',
    'molad',
    'kiddushLevanah3',
    'kiddushLevanah7',
    'kiddushLevanahEnd',
    'eruvTavshilin',

    'parasha',
    'haftara',
    'dafYomi',
    'dafYerushalmi',
    'pirkeiAvot',
    'rambam3',
    'rambam1',
    'rambamSeferHamitzvos',
    'tehilimYomi',
    'tania',
    'chumash'
  ];

  private jewishContentRef: MatDialogRef<JewishContentComponent>;

  constructor(
    private widgetService: WidgetService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private store: Store<{ widgets: WidgetsState }>
  ) {
  }

  preventPropagation(event: Event) {
    // Prevent delete key event from triggering other events
    event.stopPropagation();
  }

  changeSeparator() {
    this.store.dispatch(changeSeparator({ widgetId: this.widget.id, separator: this.widget.separator }));
  }

  onTimeFormatChange(timeFormat: MatRadioChange): void {
    this.store.dispatch(changeTimeFormatJewishWidget({ widgetId: this.widget.id, timeFormat: timeFormat.value }));
  }

  /**
   * Gets the current app language.
   */
  public get language(): string {
    return localStorage.getItem(LocalStorage.Language) || this.translate.currentLang;
  }

  public ngOnInit(): void {
    this.setTranslation();
  }

  private isDialogOpen: boolean = false;
  /**
   * Opens jewish content modal window. Passes data to JewishContentComponent.
   */
  public openContentModal(): void {
    // Only open the dialog if it's not already open.
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Mark the dialog as open.

      this.jewishContentRef = this.dialog.open(JewishContentComponent, { hasBackdrop: false });
      this.jewishContentRef.componentInstance.widget = this.widget;
      this.jewishContentRef.componentInstance.chosenContent = this.chosenContent;
      this.jewishContentRef.componentInstance.contentToChoose = this.contentToChoose.filter(item => !this.widget.content.jewish.keys.includes(item));

      this.jewishContentRef.afterClosed()
        .subscribe((okayed: boolean) => {
          this.isDialogOpen = false; // Mark the dialog as closed.

          if (okayed || okayed === undefined) { // if not clicked Cancel
            this.widget.contentSource?.next(this.widget.content.jewish.keys);
            this.widgetService.updateScreen(this.screen);
          } else {
            this.screen.dirty = false;
          }
        });
    }
  }

  /**
  * Marks screen "dirty" for purpose of page leaving warning.
  */
  public markScreenDirty(): void {
    this.screen.dirty = true;
  }

  /**
   * triger event when "Make text small or break lines" is checked
   */
  public async makeTextSmallOrBreakLines(): Promise<void> {
    this.widget.resizeStopSource.next();
  }


  changeScrollDirection() {
    if (this.widget.content.jewish.multipleLines) {
      this.widget.effect.scrollDirection = this.scrollDirections.Up; this.updateEffect()
    }
    if (!this.widget.content.jewish.multipleLines) {
      this.widget.effect.scrollDirection = this.scrollDirections.Right; this.updateEffect()
    }
  }

  /**
   * Emits the specified effect.
   */
  public updateEffect(): void {
    this.widget.effectSource.next(this.widget.effect);
  }
  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }

}
