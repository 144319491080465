<ng-container *ngIf="
((widget.content.jewish.keys
  | jewish
    : widget.language
    : (mergedDate$ | async)
    : user.times
    : widget.content.jewish.timeFormat
    : widget.content.jewish.hebrewDateDay
    : widget.content.jewish.hebrewDateMonth
    : widget.content.jewish.hebrewDateYear
    : widget.content.jewish.gregorianDate
    : widget.content.jewish.parasha
    : widget.content.jewish.dayOfWeek
    : widget.content.jewish.sefiratHaomer
    : widget.content.jewish.dafYomi
    : user.settings?.fastEnd.isMinutes
    : user.settings?.fastEnd.minutes
    : user.settings?.fastEnd.option
    : user.settings?.shabbosEnd.isMinutes
    : user.settings?.shabbosEnd.minutes
    : user.settings?.shabbosEnd.option
    : user.settings?.moladOnShabbos
    : user.settings?.kiddushLevanahEndBetweenMoldos
    : user.settings?.dayDescription.zioni
    : user.settings?.dayDescription.shabbosMevorchim
    : user.settings?.moridHatal
    : user.inEretzIsrael
    : user.minhag
    : studies
    : haftara
    : user.settings?.haftara.caption
    : avot
    : hoshana
    : widget.changeTimeKey
    : widget.id
    : widget.content.jewish.padZero | async) || [])
as textData">
  <div *ngIf="!widget.content.jewish.multipleLines; then horizontalScroll; else verticalScroll"></div>
  <ng-template #verticalScroll>
    <app-vertical-scroll [text]="textData || []" [widget]="widget" [separator]="widget.separator" [speed]="scrollSpeed"
      [scrollDirection]="widget.effect.scrollDirection"
      [shouldScroll]="widget.effect.type === effectTypes.None ? 'doNotScroll' : widget.effect.autoEffect ? 'autoEffect' : 'scroll'"
      [class.horizontal-align-center]="horizontalAlignCenter" [class.horizontal-align-left]="isHorizontalAlignLeft"
      [class.horizontal-align-right]="isHorizontalAlignRight" [class.vertical-align-center]="isVerticalAlignCenter"
      [class.vertical-align-top]="isVerticalAlignTopOrBottom.top"
      [class.vertical-align-bottom]="isVerticalAlignTopOrBottom.bottom"
      [class.vertical-align-default-scroll]="isVerticalAlignDefaultScroll">
    </app-vertical-scroll>
    <div></div>
  </ng-template>

  <ng-template #horizontalScroll>
    <app-horizontal-scroll [text]="textData || []" [widget]="widget" [separator]="widget.separator"
      [speed]="scrollSpeed" [scrollDirection]="widget.effect.scrollDirection"
      [shouldScroll]="widget.effect.type === effectTypes.None ? 'doNotScroll' : widget.effect.autoEffect ? 'autoEffect' : 'scroll'"
      [class.horizontal-align-center]="horizontalAlignCenter" [class.horizontal-align-left]="isHorizontalAlignLeft"
      [class.horizontal-align-right]="isHorizontalAlignRight" [class.vertical-align-center]="isVerticalAlignCenter"
      [class.vertical-align-top]="isVerticalAlignTopOrBottom.top"
      [class.vertical-align-bottom]="isVerticalAlignTopOrBottom.bottom"
      [class.vertical-align-default-scroll]="isVerticalAlignDefaultScroll">
    </app-horizontal-scroll>
  </ng-template>
</ng-container>