<div *ngIf="widget?.content?.pdf"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
     class="settings container-sm d-flex align-items-center flex-column mx-2">
  <app-upload (uploadFinished)="setSafeUrl($event); markScreenDirty()"
              [screen]="screen"
              [title]="'dashboard.widgetProperties.specific.pdf.title' | translate"
              [widget]="widget"
              endpoint="pdf"></app-upload>
  <ng-container *ngIf="widget.content.pdf.url">
    <div class="background-title mt-2">{{ widget.content.pdf.name }}</div>
    <div class="background-title mt-2">{{ widget.content.pdf.size | filesize }}</div>
    <div class="mt-2">
      <embed width="120" name="plugin" [src]="safeUrl" type="application/pdf">
    </div>
  </ng-container>
  <ng-container *ngIf="!widget.content.pdf.url">
    <p>{{ 'dashboard.widgetProperties.specific.pdf.noFile' | translate }}</p>
  </ng-container>
</div>

<div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.pdf.number' | translate }}</span>
  <mat-form-field appearance="standard" class="select-font w-50">
    <mat-select (selectionChange)="markScreenDirty()" [(value)]="widget.content.pdf.pagesNumber">
      <mat-option *ngFor="let page of pages" [value]="+page">
        {{ page }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


<div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <div class="flex-grow-1">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.pdf.seconds' | translate }}</span>
  </div>
  <div class="d-flex justify-content-around flex-grow-2">
    <mat-form-field appearance="standard" class="select-font">
      <input (change)="markScreenDirty()" [(ngModel)]="widget.content.pdf.secondsPerPage" matInput max="180" min="1" step="1" type="number">
    </mat-form-field>
  </div>
</div>
