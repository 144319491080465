import { Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'base-component',
  template: '',
})
export class BaseScrollComponent {
  private lastUpdateTime: number = 0;

  constructor(private renderer2: Renderer2) { }
  public async stopScrolling(scrollContainer: ElementRef<HTMLDivElement>, innerHTMLToApply: string) {
    const scrollContainerNativeElement = scrollContainer.nativeElement;
    const firstChild = scrollContainerNativeElement.firstElementChild as HTMLElement;
    const firstOfFirst = firstChild.firstElementChild as HTMLElement;

    const tags = innerHTMLToApply.match(/<\/?\w+>/g);
    if (!tags || tags.length < 2) {
      return;
    }

    this.renderer2.setProperty(firstOfFirst, 'innerHTML', innerHTMLToApply);
    this.renderer2.setProperty(firstChild, 'innerHTML', '');
    this.renderer2.appendChild(firstChild, firstOfFirst);
    this.renderer2.removeStyle(firstChild, 'animation');
    this.renderer2.setProperty(scrollContainerNativeElement, 'innerHTML', '');
    this.renderer2.appendChild(scrollContainerNativeElement, firstChild);
    
    while (scrollContainerNativeElement.children.length > 1) {
      await new Promise((resolve) => setTimeout(resolve, 0));
    }
  };

  public updateAnimation(scrollContainer: HTMLDivElement, animationDirection: string, speed: number, renderer2: Renderer2) {
    const first = (scrollContainer.children[0] as HTMLElement);
    const second = (scrollContainer.children[1] as HTMLElement);
    const third = (scrollContainer.children[2] as HTMLElement);
    // first-animated
    renderer2.setStyle(first, 'animation', `${animationDirection}-first-animated ${speed}s linear infinite`);
    renderer2.setStyle(third, 'animation', `${animationDirection}-second-animated ${speed}s linear infinite`);
    renderer2.setStyle(second, 'animation', `${animationDirection}-third-animated ${speed}s linear infinite`);
  }
}
