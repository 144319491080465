import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, ChangeDetectorRef } from "@angular/core";
import { Subscription } from "rxjs";
import { Widget, HorizontalAlign, VerticalAlign } from "src/app/_models";
import { DateService } from "src/app/_services";

@Component({
  selector: 'app-digital-clock-viewer',
  templateUrl: './digital-clock-viewer.component.html',
  styleUrls: ['./digital-clock-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalClockViewerComponent implements OnInit, OnDestroy {
  @Input() widget: Widget;

  public time = new Date();
  private selectedTime = new Date();
  private counter = 0;
  private dateChangedSubscription: Subscription;

  private intervalId: any;

  constructor(private cdRef: ChangeDetectorRef, private dateService: DateService) {}

  public get horizontalAlignCenter(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Center;
  }

  public get horizontalAlignLeft(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Left;
  }

  public get horizontalAlignRight(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Right;
  }

  public get verticalAlignTop(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Top;
  }

  public get verticalAlignCenter(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Center;
  }

  public get verticalAlignBottom(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Bottom;
  }

  ngOnInit(): void {
    this.setTime();
    this.intervalId = setInterval(() => this.updateTime(), 1000);
  }

  ngOnDestroy(): void {
    if (this.dateChangedSubscription) {
      this.dateChangedSubscription.unsubscribe();
    }
    clearInterval(this.intervalId);
  }

  private updateTime(): void {
    const isUserSelectedTime = this.counter <= 60;
    const selectedTime = new Date(this.selectedTime);
    const userSelectedTime = new Date(selectedTime.setSeconds(selectedTime.getSeconds() + this.counter));
    this.time = isUserSelectedTime ? userSelectedTime : new Date();
    this.counter++;

    this.cdRef.markForCheck();
  }

  private setTime(): void {
    this.dateChangedSubscription = this.dateService.dateChanged$.subscribe(time => {
      this.selectedTime = time;
      this.counter = new Date().getSeconds();
    });
  }
}
