import { Component, OnInit, Input } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { DarkModeService } from "angular-dark-mode";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { WidgetTypes } from "src/app/_enums";
import { WidgetHelper } from "src/app/_helpers";
import { Widget, Content, EffectTypes, Screen, Effect } from "src/app/_models";
import { ConfirmationComponent } from "src/app/_modules/admin/admin-dashboard/user-list/confirmation/confirmation.component";
import { WidgetService, SnackbarService } from "src/app/_services";


@Component({
  selector: 'app-widgets-panel',
  templateUrl: './widgets-panel.component.html',
  styleUrls: ['./widgets-panel.component.css']
})
export class WidgetsPanelComponent implements OnInit {
  @Input() screen: Screen;

  columns = [
    {
      columnDef: 'IsHidden',
      header: 'IsHidden',
      cell: (widget) => `
        <mat-slide-toggle (change)="widget.isHidden = !$event.checked" [checked]="!widget.isHidden"
          [title]="'dashboard.widgetsTab.enable' | translate" class="enable">
        </mat-slide-toggle>
      `
    },
    {
      columnDef: 'Avatar',
      header: 'Avatar',
      cell: (widget) => `
        <div class="component-icon mr-3">
          <img *ngIf="!(darkMode$ | async)" [src]="widget.widgetIcon?.path">
          <img *ngIf="darkMode$ | async" [src]="widget.widgetIcon?.path + '.dm.svg'">
        </div>
      `
    },
    {
      columnDef: 'Name',
      header: 'Name',
      cell: (widget) => `{{ widget.name }}`
    },
    {
      columnDef: 'Type',
      header: 'Type',
      cell: (widget) => `
        {{ 'dashboard.widgetsTab.' + ((widget.type | lowercase) | tableType) | translate }}
      `
    },
    {
      columnDef: 'Duplicate',
      header: 'Duplicate',
      cell: (widget) => `
        <button (click)="duplicateWidget(widget)" mat-button color="primary"
          [title]="'dashboard.widgetProperties.duplicateBtn' | translate">
          <mat-icon>content_copy</mat-icon>
        </button>
      `
    },
    {
      columnDef: 'Remove',
      header: 'Remove',
      cell: (widget) => `
        <button (click)="removeWidget(widget)" mat-button color="accent"
          [title]="'dashboard.widgetsTab.remove' | translate">
          <mat-icon>delete</mat-icon>
        </button>
      `
    }
  ];

  public dataSource: MatTableDataSource<Widget>;
  public displayedColumns = ['IsHidden', 'Avatar', 'Name', 'Type', 'Duplicate', 'Remove'];
  public darkMode$: Observable<boolean> = this.darkModeService.darkMode$;

  constructor(
    public widgetService: WidgetService,
    protected dialog: MatDialog,
    private darkModeService: DarkModeService,
    private translate: TranslateService,
    private snackbar: SnackbarService,
    private sanitizer: DomSanitizer
  ) { }

  safeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  /**
   * Retrieves the filename from the URL.
   * @param {string} url - a URL to retrieve a filename from.
   * @private
   * @static
   */
  private static getFilenameFromUrl(url: string): string {
    if (!url) {
      return;
    }
    const arr = url.split('/');
    return arr[arr.length - 1];
  }

  public ngOnInit(): void {
    this.initTable();
  }

  /**
   * Opens the widget settings tab on the widget click.
   * @param {Widget} widget - a clicked widget.
   * @param {EventTarget} target - to determine the click place.
   */
  public goToWidget(widget: Widget, target: EventTarget): void {
    if ((target as HTMLElement).nodeName === 'MAT-ICON' || (target as HTMLElement).className.includes('mat-slide')) {
      return;
    }
    this.widgetService.selectWidget(widget, widget.widgetIcon);
  }

  /**
   * Adds the widget selection border for the specified widget.
   * @param {Widget} widget - selected widget.
   */
  public selectWidget(widget: Widget): void {
    const widgetElement = document.getElementById(`widget-${widget.id}`) as HTMLDivElement;
    widgetElement?.classList.add('selected-widget');
  }

  /**
   * Removes the widget selection border for all widgets.
   */
  public clearWidgetSelection(): void {
    const widgetElements = document.querySelectorAll('.back');
    widgetElements.forEach(element => element.classList.remove('selected-widget'));
  }

  /**
   * Deletes the widget & the files related to it from server and from the list.
   * @param {Widget} widget - a widget to delete.
   */
  public removeWidget(widget: Widget): void {
    const filesToRemove: string[] = [];
    const dialogRef: MatDialogRef<ConfirmationComponent> = this.dialog.open(ConfirmationComponent);

    dialogRef.componentInstance.config = {
      direction: 'ltr',
      message: this.translate.instant('snackbar.deleteWidget')
    };

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed === '') {

        if ([WidgetTypes.Media, WidgetTypes.PDF, WidgetTypes.Music].includes(widget.type)) {
          filesToRemove.push(WidgetsPanelComponent.getFilenameFromUrl(widget.background.imageUrl));
          widget.content.media?.items?.forEach(item => filesToRemove.push(WidgetsPanelComponent.getFilenameFromUrl(item?.url)));
          filesToRemove.push(WidgetsPanelComponent.getFilenameFromUrl(widget.content.pdf?.url));
        }

        this.screen.filesToRemove.push(...filesToRemove.filter(item => item));
        this.screen.widgets = this.screen.widgets.filter(w => w.id !== widget.id);
        this.initTable();
        this.widgetService.updateScreen(this.screen);
      }
    });
  }

  /**
   * Duplicates the specified widget.
   * @param {Widget} widget - a widget to duplicate.
   */
  public duplicateWidget(widget: Widget): void {
    this.screen.dirty = true;
    let widgetDuplicate: Widget = { ...widget, contentSource: null, effectSource: null, contentChangedSource: null, resizeStopSource: null };
    widgetDuplicate = JSON.parse(JSON.stringify(widgetDuplicate));
    widgetDuplicate.contentSource = new Subject<Content>();
    widgetDuplicate.effectSource = new BehaviorSubject<Effect>({ type: EffectTypes.None });
    widgetDuplicate.contentChangedSource = new Subject<void>();
    widgetDuplicate.resizeStopSource = new Subject<IResizeEvent>();
    widgetDuplicate.id = WidgetHelper.getNewWidgetId(this.screen);
    widgetDuplicate.position.top = widget.position.top + 5;
    widgetDuplicate.position.left = widget.position.left + 5;
    this.screen.widgets.push(widgetDuplicate);
    this.widgetService.updateScreen(this.screen);
    this.snackbar.success(this.translate.instant('snackbar.widgetDuplicated'), this.translate.instant('buttons.ok'));
  }

  /**
   * Initialises the widgets table.
   * @private
   */
  private initTable(): void {
    this.dataSource = new MatTableDataSource<Widget>(this.screen.widgets);
  }
}
