import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { KosherZmanimOptionDto } from "src/app/_dto";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { AccountService } from "src/app/_services";
import { SchedulerService } from "../scheduler.service";
import { Scheduler } from "src/app/_models";

@Component({
  selector: 'app-dynamic-time-range',
  templateUrl: './dynamic-time-range.component.html',
  styleUrls: ['./dynamic-time-range.component.css']
})
export class DynamicTimeRangeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() scheduler: Scheduler;

  public times: KosherZmanimOptionDto[];
  public isCollapsed = true;
  public direction: Direction = 'ltr';

  private clearSubscription: Subscription;

  constructor(
    private accountService: AccountService,
    private schedulerService: SchedulerService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.scheduler) {
      this.isCollapsed = !this.scheduler.dynamicTimeRange?.time;
    }
  }

  public ngOnInit(): void {
    this.setTranslation();
    this.getUserTimes();
    this.clearSubscription = this.schedulerService.schedulersCleared$.subscribe(() => this.clearDynamicTime());
  }

  public ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
  }

  /**
   * Clears the dynamic time scheduler.
   */
  public clearDynamicTime(): void {
    this.scheduler.dynamicTimeRange = null;
    this.isCollapsed = true;
  }

  /**
   * Sets the halachic time & default addition and duration.
   * @param {string} time - halachic time to set.
   */
  public setTime(time: string): void {
    this.scheduler.dynamicTimeRange = { time, addition: 0, duration: '00:00:00' };
    this.isCollapsed = false;
  }

  /**
   * Sets the addition to the halachic time.
   * @param addition - the addition in minutes.
   */
  public setAddition(addition: number): void {
    this.scheduler.dynamicTimeRange.addition = addition;
  }

  /**
   * Sets the dynamic time range duration.
   * @param {string} duration - a duration to set.
   */
  public setDuration(duration: string): void {
    this.scheduler.dynamicTimeRange.duration = duration;
  }

  /**
   * Retrieves the halachic time objects related to the current user.
   * @private
   */
  private getUserTimes(): void {
    this.accountService.getUser().subscribe(({ times }) => this.times = times);
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
