export enum WidgetTypes {
  YahrzeitTable = 'YahrzeitTable',
  YahrzeitList = 'YahrzeitList',
  HTML = 'HTML',
  Table = 'Table',
  TableAlert = 'TableAlert',
  Text = 'Text',
  Web = 'Web',
  Jewish = 'Jewish',
  EventsAlert = 'EventsAlert',
  TimesAlert = 'TimesAlert',
  Media = 'Media',
  Music = 'Music',
  SchedulerMediaList = 'SchedulerMediaList',
  RSS = 'RSS',
  Clock = 'Clock',
  Digital = 'Digital',
  PDF = 'PDF'
}
