import { Component, ChangeDetectionStrategy, OnInit } from "@angular/core";
import { LocalStorage } from "./_enums";
import { AccountService, PresenceService } from "./_services";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(
    private accountService: AccountService,
    private presence: PresenceService,
  ) {}

  public ngOnInit(): void {
    this.loadCurrentUser();
  }

  private loadCurrentUser(): void {
    const token = localStorage.getItem(LocalStorage.Token);
    if (token) {
      this.accountService.loadCurrentUser(token).subscribe({
        error: error => {
          console.error('Failed to load current user:', error);
        }
      });
      this.presence.createHubConnection(token);
    }
  }
}