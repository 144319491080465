import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { User } from '../_models/identity/user';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseApiService {
  public headers = new HttpHeaders();

  private uniqueId: string;
  private apiUrl = environment.apiUrl;
  private user: User;

  constructor(private http: HttpClient) {
    super();
  }

  public get currentUser(): User {
    return this.user;
  }

  public get uniqueDeviceId(): string {
    return this.uniqueId;
  }

  public set uniqueDeviceId(value: string) {
    this.uniqueId = value;
  }

  public getRss(url: string): Observable<string> {
    return this.http.get(url, {
      responseType: 'text',
    });
  }

  public getXml(url: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/xml',
      'Accept': 'application/xml'
    });
    return this.http.get(encodeURIComponent(url), {
      headers: headers,
      responseType: 'text',
    });
  }
}
