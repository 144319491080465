<div class="container">
  <mat-card-subtitle translate>times.subtitle</mat-card-subtitle>

  <div class="times-content">
    <div [style.direction]="direction" [ngbNavOutlet]="nav"></div>

    <div class="tabs-container" [style.direction]="direction === 'rtl' ? 'ltr' : 'rtl'">
      <ul ngbNav #nav="ngbNav" [activeId]="activeTabIndex" class="nav-pills tabs">
        <li [ngbNavItem]="i" *ngFor="let time of times; let i = index">
          <a ngbNavLink>{{ 'times.keys.' + time.key | translate }}</a>
          <ng-template ngbNavContent>
            <app-tab-content [timeOptions]="time" [userTimes]="userTimes"></app-tab-content>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>

  <div mat-dialog-actions class="actions" [style.direction]="direction">
    <button (click)="save()"
            color="primary"
            mat-raised-button
            matDialogClose
            type="submit">
      {{ 'times.buttons.save' | translate }}
    </button>
    <button color="accent"
            mat-raised-button
            matDialogClose
            type="button">
      {{ 'times.buttons.cancel' | translate }}
    </button>
  </div>
</div>
