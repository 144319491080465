import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ZmanimComponent } from './settings/zmanim/zmanim.component';
import { TabContentComponent } from './settings/zmanim/tab-content/tab-content.component';
import { GeneralSettingsComponent } from './settings/general-settings/general-settings.component';
import { ContactUsComponent } from './settings/contact-us/contact-us.component';
import { SettingsComponent } from './settings/settings.component';
import { UpdateProfileComponent } from './settings/update-profile/update-profile.component';
import { JewishSettingsComponent } from './settings/jewish-settings/jewish-settings.component';
import { HebrewDateComponent } from './settings/jewish-settings/hebrew-date/hebrew-date.component';
import { GregorianDateComponent } from './settings/jewish-settings/gregorian-date/gregorian-date.component';
import { ParashaComponent } from './settings/jewish-settings/parasha/parasha.component';
import { DafYomiComponent } from './settings/jewish-settings/daf-yomi/daf-yomi.component';
import { SefiratHaomerComponent } from './settings/jewish-settings/sefirat-haomer/sefirat-haomer.component';
import { ShabbosEndComponent } from './settings/jewish-settings/shabbos-end/shabbos-end.component';
import { MoladComponent } from './settings/jewish-settings/molad/molad.component';
import { DayOfWeekComponent } from './settings/jewish-settings/day-of-week/day-of-week.component';
import { HaftaraComponent } from './settings/jewish-settings/haftara/haftara.component';
import { FastEndComponent } from './settings/jewish-settings/fast-end/fast-end.component';
import { DayDescriptionComponent } from './settings/jewish-settings/day-description/day-description.component';
import { CandleLightComponent } from './settings/jewish-settings/candle-light/candle-light.component';
import { KiddushLevanaEndComponent } from './settings/jewish-settings/kiddush-levana-end/kiddush-levana-end.component';
import { MoridHatalComponent } from './settings/jewish-settings/morid-hatal/morid-hatal.component';
import { ShortcutsComponent } from './settings/shortcuts/shortcuts.component';

@NgModule({
  declarations: [
    SettingsComponent,
    ZmanimComponent,
    TabContentComponent,
    GeneralSettingsComponent,
    ContactUsComponent,
    UpdateProfileComponent,
    JewishSettingsComponent,
    GregorianDateComponent,
    ParashaComponent,
    DafYomiComponent,
    SefiratHaomerComponent,
    ShabbosEndComponent,
    MoladComponent,
    DayOfWeekComponent,
    HaftaraComponent,
    FastEndComponent,
    DayDescriptionComponent,
    HebrewDateComponent,
    CandleLightComponent,
    KiddushLevanaEndComponent,
    MoridHatalComponent,
    ShortcutsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SettingsModule { }
