import { Observable } from 'rxjs';
import { SubscriptionTrackerService } from './subscription-tracker.service';

export function patchRxjs(subscriptionTracker: SubscriptionTrackerService): void {
  const originalSubscribe = Observable.prototype.subscribe;

  Observable.prototype.subscribe = function (...args) {
    // Capture the error stack and split it into lines
    const stackLines = new Error().stack?.split('\n') || [];

    // Look for the line that likely contains the Angular component from main.js
    // but not the Angular framework or RxJS internals
    const relevantLine = stackLines.find(line =>
      line.includes('main.js') &&
      !line.includes('rxjs__WEBPACK_IMPORTED_MODULE') &&
      !line.includes('SubscribableStrategy.createSubscription') &&
      !line.includes('AsyncPipe._subscribe') &&
      !line.includes('AsyncPipe.transform') &&
      !line.includes('ɵɵpipeBind1')
    );

    let fileAndRow = 'unknown';
    if (relevantLine) {
      // The regex captures the filename and line number from the main.js file
      const match = /main\.js:(\d+):(\d+)/.exec(relevantLine.trim());
      if (match) {
        const [file, line, column] = match;
        // Construct the key using the file and line information
        fileAndRow = `${file}:${line}`;
      }
    } else {
      // Check if the line is in vendor.js and extract the location
      const vendorLine = stackLines.find(line => line.includes('vendor.js'));
      if (vendorLine) {
        const vendorMatch = /vendor\.js:(\d+):(\d+)/.exec(vendorLine.trim());
        if (vendorMatch) {
          // Use the matched line number from vendor.js instead
          fileAndRow = `vendor:${vendorMatch[1]}`;
        }
      }
    }
    if (fileAndRow === 'unknown') {
      debugger;
    }
    // Increment the subscription counter
    subscriptionTracker.incrementSubscriptionCounter(fileAndRow);

    // Call the original subscribe
    return originalSubscribe.apply(this, args);
  };
}