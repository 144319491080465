import { createReducer, on } from '@ngrx/store';
import { WidgetsState, initialWidgetsState } from './widget.state';
import { changeTimeFormatJewishWidget, toggleTimeFormatTableWidget, changeViewByDateMode, setLoadedWidgets, toggleDisplaySecondsTableWidget, toggleZeroPaddingTableWidget, toggleAmPmTableWidget, toggleUpperTableWidget, changeSeparator, changeJewishKeys, changeFontSize } from './widget.actions';

export enum ViewMode {
  AllYear = 'AllYear',
  AllMonth = 'AllMonth',
  AllWeek = 'AllWeek',
  AllDay = 'AllDay',
}

export const widgetsReducer = createReducer(
  initialWidgetsState,
  on(changeViewByDateMode, (state, { widgetId, viewMode }) => {
    return { ...state, [widgetId]: { ...state[widgetId], viewMode: viewMode }};
  }),
  on(changeTimeFormatJewishWidget, (state, { widgetId, timeFormat }) => {
    return { ...state, [widgetId]: { ...state[widgetId], timeFormat: timeFormat }};
  }),
  on(toggleTimeFormatTableWidget, (state, { widgetId, timeFormat }) => {
    return { ...state, [widgetId]: { ...state[widgetId], timeFormatTableWidget: timeFormat }};
  }),
  on(toggleDisplaySecondsTableWidget, (state, { widgetId, displaySeconds }) => {
    return { ...state, [widgetId]: { ...state[widgetId], displaySeconds }};
  }),
  on(toggleZeroPaddingTableWidget, (state, { widgetId, zeroPadding }) => {
    return { ...state, [widgetId]: { ...state[widgetId], zeroPaddingTableWidget: zeroPadding }};
  }),
  on(toggleAmPmTableWidget, (state, { widgetId, displayAmPm }) => {
    return { ...state, [widgetId]: { ...state[widgetId], displayAmPmTableWidget: displayAmPm }};
  }),
  on(toggleUpperTableWidget, (state, { widgetId, upperAmPm }) => {
    return { ...state, [widgetId]: { ...state[widgetId], upperAmPmTableWidget: upperAmPm }};
  }),
  on(changeSeparator, (state, { widgetId, separator }) => {
    return { ...state, [widgetId]: { ...state[widgetId], separator }};
  }),
  on(changeJewishKeys, (state, { widgetId, jewishKeys }) => {
    return { ...state, [widgetId]: { ...state[widgetId], jewishKeys }};
  }),
  on(changeFontSize, (state, { widgetId, fontSize }) => {
    return { ...state, [widgetId]: { ...state[widgetId], fontSize }};
  }),
  on(setLoadedWidgets, (state, { widgets }) => {
    const newWidgetState: WidgetsState = {};
    widgets.forEach(widget => {
      let viewMode;
      if (widget.content.text.isShowAllYear) {
        viewMode = ViewMode.AllYear;
      } else if (widget.content.text.isShowMonth) {
        viewMode = ViewMode.AllMonth;
      } else if (widget.content.text.isShowWeek) {
        viewMode = ViewMode.AllWeek;
      } else if (widget.content.text.isShowDay) {
        viewMode = ViewMode.AllDay;
      }
      newWidgetState[widget.id] = {
        viewMode: viewMode,
        timeFormat: widget.content.jewish.timeFormat,
        timeFormatTableWidget: widget.content.table.format,
        displaySeconds: widget.content.table.displaySeconds,
        zeroPaddingTableWidget: widget.content.table.padZero,
        displayAmPmTableWidget: widget.content.table.isAmPm,
        upperAmPmTableWidget: widget.content.table.isUpper,
        separator: widget.separator,
        jewishKeys: widget.content.jewish.keys,
        fontSize: widget.font.size,
      };
    });
    return { ...state, ...newWidgetState };
  }),
);



// export const widgetReducer = createReducer(
//   initialWidgetsState,
//   on(WidgetActions.loadWidgetsSuccess, (state, { widgets }) => ({ ...state, widgets })),
//   on(WidgetActions.updateWidgetLocally, (state, { widget }) => {
//     const updatedWidgets = state.currentScreen.widgets.map(w => (w.id === widget.id ? widget : w));
//     return { ...state, widgets: updatedWidgets };
//   }),
//   // Add other reducers like deleting the widget, updating the widget on the server, etc.
//   on(WidgetActions.loadScreensSuccess, (state, { screens }) => {
//     if (screens && screens.length > 0) {
//       return { ...state, screens, currentScreen: screens[0], screensNumber: screens.length };
//     } else {
//       return { ...state, screens, currentScreen: WidgetHelper.DefaultScreen, screensNumber: 0 };
//     }
//   }),
//   on(WidgetActions.updateScreen, (state, { screen, dirty }) => {
//     const updatedScreens = state.screens.map(s => (s.id === screen.id ? screen : s));
//     return { ...state, screens: updatedScreens };
//   }),
//   on(WidgetActions.selectWidget, (state, { widget, icon }) => {
//     return { ...state, selectedWidget: widget, selectedWidgetIcon: icon };
//   }),
//   on(WidgetActions.updateEffect, (state, { effect, widgetId }) => {
//     const updatedWidgets = state.currentScreen.widgets.map(w => (w.id === widgetId ? { ...w, effect } : w));
//     return { ...state, widgets: updatedWidgets };
//   }),
//   on(WidgetActions.openWidgetsTab, (state) => {
//     return { ...state, widgetsTab: true };
//   }),

//   on(WidgetActions.dropWidgetIcon, (state, { icon }) => {
//     return { ...state, selectedWidgetIcon: icon };
//   }),

//   on(WidgetActions.clearScreensCache, (state) => {
//     return { ...state, screens: [] };
//   }),
//   on(WidgetActions.addEffectSource, (state, { widgetId, effect }) => {
//     const updatedWidgets = state.currentScreen.widgets.map(w => (w.id === widgetId ? { ...w, effect } : w));
//     return { ...state, widgets: updatedWidgets };
//   }),
//   on(WidgetActions.updateEffectSource, (state, { widgetId, effectSource }) => {
//     const updatedWidgets = state.currentScreen.widgets.map(w => (w.id === widgetId ? { ...w, effectSource } : w));
//     return { ...state, widgets: updatedWidgets };
//   }),
//   on(WidgetActions.removeEffectSource, (state, { widgetId }) => {
//     const updatedWidgets = state.currentScreen.widgets.map(w => (w.id === widgetId ? { ...w, effect: null } : w));
//     return { ...state, widgets: updatedWidgets };
//   }),
// );
