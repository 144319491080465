import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { WidgetTypes, Highlight, Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget } from "src/app/_models";
import { AlertContentComponent } from "./alert-content/alert-content.component";

@Component({
  selector: 'app-alert-dashboard',
  templateUrl: './alert-dashboard.component.html',
  styleUrls: ['./alert-dashboard.component.css']
})
export class AlertDashboardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;
  public direction: Direction = 'ltr';

  private alertContentRef: MatDialogRef<AlertContentComponent>;

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private dialog: MatDialog
  ) { }

  public ngOnInit(): void {
    this.setTranslation();
  }

  /**
   * Opens alert content modal window. Passes data to AlertContentComponent.
   */
  public openContentModal(): void {
    this.alertContentRef = this.dialog.open(AlertContentComponent, { hasBackdrop: false });
    this.alertContentRef.componentInstance.widget = this.widget;
  }

  /**
   * Toggles table/alert layout.
   * @param {boolean} alertEnabled - whether the alert layout enabled.
   */
  public changeLayout(alertEnabled: boolean): void {
    this.widget.content.table.alertLayout = alertEnabled;
    this.widget.type = alertEnabled ? WidgetTypes.TableAlert : WidgetTypes.Table;

    if(alertEnabled) {
      this.widget.content.table.highlight = Highlight.Always;
    }
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
