import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Widget } from '../../_models';
import { DateService } from '../../_services';

@Component({
  selector: 'app-analog-clock-viewer',
  templateUrl: './analog-clock-viewer.component.html',
  styleUrls: ['./analog-clock-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalogClockViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('clock') clock: ElementRef<HTMLDivElement>;
  @ViewChild('hr') hr: ElementRef<HTMLDivElement>;
  @ViewChild('min') min: ElementRef<HTMLDivElement>;
  @ViewChild('sec') sec: ElementRef<HTMLDivElement>;

  @Input() widget: Widget;
  public hrStyle = {};
  public minStyle = {};
  public secStyle = {};

  private selectedDate = new Date();
  private counter = 0;
  private dateChangedSubscription: Subscription;

  private intervalId: any;

  constructor(private elementRef: ElementRef<HTMLElement>, private dateService: DateService, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.setTime();
    this.intervalId = setInterval(() => this.updateTime(), 1000);
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.parentElement.style.display = 'block';
  }

  ngOnDestroy(): void {
    if (this.dateChangedSubscription) {
      this.dateChangedSubscription.unsubscribe();
    }
    clearInterval(this.intervalId);
  }

  private updateTime(): void {
    const deg = 6;
    const time = new Date();
    const hh = time.getHours() * 30;
    const mm = time.getMinutes() * deg;
    const ss = time.getSeconds() * deg;

    this.hrStyle = { transform: `rotateZ(${hh + (mm / 12)}deg)` };
    this.minStyle = { transform: `rotateZ(${mm}deg)` };
    this.secStyle = { transform: `rotateZ(${ss}deg)` };

    this.cdRef.markForCheck();
  }

  /**
   * Listens to a change of the date/time and resets the counter.
   * @private
   */
  private setTime(): void {
    this.dateChangedSubscription = this.dateService.dateChanged$.subscribe(date => {
      this.selectedDate = date;
      this.counter = 0;
    });
  }
}
