import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Media, MediaItem } from '../../_models';

@Component({
  selector: 'app-scheduler-media-list',
  templateUrl: './scheduler-media-list.component.html',
  styleUrls: ['./scheduler-media-list.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SchedulerMediaListComponent {
  @Input() mediaContent: Media;
  @Input() isStretched: boolean;

  public currentImage: MediaItem = null;
}

