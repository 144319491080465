import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Media } from '../../_models';

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.css']
})
export class MediaViewerComponent implements AfterViewInit {
  @ViewChild('slider') slider: ElementRef<HTMLDivElement>;
  @ViewChildren('slides') slidesQueryList: QueryList<ElementRef<HTMLDivElement>>;

  @Input() mediaContent: Media;

  public defaultDelay = 3;
  currentImage = 0;
  private slides: HTMLDivElement[] = [];

  /**
   * Gets the current slide HTML element.
   * @private
   */
  private get currentSlide(): HTMLImageElement | HTMLVideoElement | HTMLAudioElement | null {
    if (this.slides && this.slides.length > 0) {
      return this.slides[0].children[0] as HTMLImageElement | HTMLVideoElement;
    }
    return null;
  }

  public ngAfterViewInit(): void {
    this.slidesQueryList.map((slideRef: ElementRef<HTMLDivElement>) => {
      return this.slides.push(slideRef.nativeElement);
    });

    setInterval(() => {
      if (this.mediaContent.items.length > 1) {
        this.currentImage === this.mediaContent.items.length - 1 ? this.currentImage = 0 : this.currentImage++;
        console.log("currentImage: ", this.currentImage);
      }
    }, 3000);
  }

  /**
   * Shuffles the slides and auto plays videos & audios.
   * @param {Event} event - DOM event on ending CSS animation, video or audio.
   */
  public onSlideEnded(event: Event): void {
    let currentSlide: HTMLImageElement | HTMLVideoElement | HTMLAudioElement | null = this.currentSlide;

    if (currentSlide) {
      if (
        currentSlide.localName === 'video' && event.type === 'ended' ||
        currentSlide.localName === 'audio' && event.type === 'ended' ||
        currentSlide.localName === 'img' && event.type === 'animationend'
      ) {
        this.slider.nativeElement.appendChild(this.slides[0]);
        this.slides.push(this.slides.shift());
      }

      currentSlide = this.currentSlide;

      if (['video', 'audio'].includes(currentSlide.localName)) {

        const playAttempt = setInterval(() => {
          (currentSlide as HTMLMediaElement).play()
            .then(() => clearInterval(playAttempt))
            .catch(error => console.log(`Unable to play the ${currentSlide.localName}, user has not interacted with the page yet.`));
        }, 3000);
      }
    }
  }
}
