import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'untitlecase'
})
export class UntitleCasePipe implements PipeTransform {

  /**
   * 'Untitles' the given string value.
   * @param {string} value - a string to 'untitle'.
   */
  public transform(value: string): string {
    return value[0].toLowerCase() + value.slice(1);
  }

}
