import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { SchedulerService } from "../scheduler.service";
import { Scheduler } from "src/app/_models";

@Component({
  selector: 'app-weekdays',
  templateUrl: './weekdays.component.html',
  styleUrls: ['./weekdays.component.css']
})
export class WeekdaysComponent implements OnInit, OnDestroy {
  @Input() scheduler: Scheduler;

  public daysChecked: boolean;
  public direction: Direction = 'ltr';

  private clearSubscription: Subscription;

  constructor(
    private schedulerService: SchedulerService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) { }

  public ngOnInit(): void {
    this.setTranslation();
    this.clearSubscription = this.schedulerService.schedulersCleared$.subscribe(() => this.scheduler.daysOfWeek = null);
    this.daysChecked = !this.scheduler.daysOfWeek || this.scheduler.daysOfWeek.every(day => day);
  }

  public ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
  }

  /**
   * Toggles all the days together.
   * @param {boolean} checked - whether all the days are checked.
   */
  public toggleAllDays(checked: boolean): void {
    this.daysChecked = checked;

    if (!this.scheduler) {
      this.scheduler = {} as Scheduler;
    }

    if (!this.scheduler.daysOfWeek) {
      this.scheduler.daysOfWeek = [true, true, true, true, true, true, true];
    }

    if (checked) {
      this.scheduler.daysOfWeek = null;
    } else {
      this.scheduler.daysOfWeek.fill(checked);
    }
  }

  /**
   * Toggles the specified week day.
   * @param {number} day - a day number to toggle.
   */
  public toggleDay(day: number): void {
    if (!this.scheduler) {
      this.scheduler = {} as Scheduler;
    }

    if (!this.scheduler.daysOfWeek) {
      this.scheduler.daysOfWeek = [true, true, true, true, true, true, true];
    }

    this.scheduler.daysOfWeek[day] = !this.scheduler.daysOfWeek[day];
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
