<header class="d-flex flex-md-row align-items-center justify-content-between p-3 px-md-4 mb-3  border-bottom shadow-sm">
  <div class="logo d-flex align-items-center">
    <img src="../../../../assets/images/logo_transparent.png" alt="logo">
    <p class="h5 my-0 me-md-auto fw-normal">Bee Zee Systems</p>
  </div>
  <div class="d-flex align-items-center">
    <nav class="my-2 my-md-0 me-md-3 d-flex">
      <div *ngIf="!isAdmin && hasManageScreenPermission" class="d-flex mr-3">
        <mat-button-toggle-group class="mr-3">
          <mat-button-toggle title="Undo" class="disabled">
            <mat-icon>undo</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle title="Redo" class="disabled">
            <mat-icon>redo</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group>
          <mat-button-toggle (click)="save()" title="{{ 'edit.buttons.save' | translate }}">
            <mat-icon>save</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle (click)="saveAndExit()" title="{{ 'edit.buttons.displayMode' | translate }}">
            <mat-icon>exit_to_app</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div>
        <img (click)="openSettings(1)"
             *ngIf="!isAdmin"
             [title]="window.localStorage.getItem('language') === 'he'
                ? username + ('buttons.profile' | translate)
                : ('buttons.profile' | translate) + username"
             alt="Profile"
             class="profile-image"
             src="assets/images/profile.svg">
        <mat-button-toggle-group>
          <mat-button-toggle *ngIf="!isAdmin && hasManageScreenPermission" (click)="toggleDatePicker()" title="{{ 'date.choose' | translate }}">
            <mat-icon>event</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle (click)="openSettings()" title="{{ 'menu.settings' | translate }}">
            <mat-icon>settings</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle (click)="logout()" title="{{ 'menu.logout' | translate }}">
            <mat-icon>logout</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-button-toggle-group class="ml-3">
        <mat-button-toggle (click)="onDarkModeToggle()"
                           [title]="(darkMode$ | async) ? ('buttons.light' | translate) : ('buttons.dark' | translate)">
          <mat-icon *ngIf="!(darkMode$ | async)">dark_mode</mat-icon>
          <mat-icon *ngIf="darkMode$ | async">light_mode</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </nav>
  </div>
</header>
