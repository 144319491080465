<div class="settings container-sm d-flex justify-content-center align-items-center parasha">
  <mat-slide-toggle (change)="user.settings.haftara.caption = !user.settings.haftara.caption"
                    [checked]="user.settings.haftara.caption">
    {{ 'jewishSettings.caption' | translate }}
  </mat-slide-toggle>
</div>

<mat-card-subtitle class="mt-3 font-italic text-center">{{ 'jewishSettings.overridden' | translate }}</mat-card-subtitle>

<div class="settings container-sm d-flex align-items-center mx-2">
  <div class="d-flex flex-column justify-content-between align-items-center">
    <mat-card-subtitle>{{ 'jewishSettings.minhag' | translate }}</mat-card-subtitle>
    <mat-radio-group [(ngModel)]="user.settings.haftara.minhag" class="d-flex flex-column">
      <mat-radio-button [value]="haftarot.Ashkenazi" class="mx-3">{{ 'jewishSettings.haftara.' + haftarot.Ashkenazi | translate }}</mat-radio-button>
      <mat-radio-button [value]="haftarot.Sefaradi" class="mx-3">{{ 'jewishSettings.haftara.' + haftarot.Sefaradi | translate }}</mat-radio-button>
      <mat-radio-button [value]="haftarot.Teimani" class="mx-3">{{ 'jewishSettings.haftara.' + haftarot.Teimani | translate }}</mat-radio-button>
      <mat-radio-button [value]="haftarot.Chabad" class="mx-3">{{ 'jewishSettings.haftara.' + haftarot.Chabad | translate }}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
