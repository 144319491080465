<div class="container" *ngIf="currentUserTime">
  <div class="time-name">
    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ "times.inputs.en" | translate }}</mat-label>
      <input matInput [(ngModel)]="currentUserTime.en">
    </mat-form-field>
  </div>
  <div class="time-name">
    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ "times.inputs.he" | translate }}</mat-label>
      <input matInput [(ngModel)]="currentUserTime.he">
    </mat-form-field>
  </div>

  <div class="divider"></div>

  <div class="mt-4" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-form-field [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">

      <mat-label>{{ "times.inputs.method" | translate }}</mat-label>
      <mat-select [(value)]="currentUserTime.option">
        <mat-option *ngFor="let timeOption of timeOptions.options"
                    [value]="timeOption.option"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                    [style.direction]="direction">
          {{ direction === 'rtl' ? timeOption.he : timeOption.en }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<!--  <div class="media">-->
<!--    <mat-card-subtitle translate>times.subtitleMedia</mat-card-subtitle>-->

<!--    <div class="preselected-media">-->
<!--      <mat-form-field [style.direction]="direction"-->
<!--                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">-->
<!--        <mat-label translate>times.inputs.alert</mat-label>-->
<!--        <mat-select [(value)]="currentTime.mediaFile">-->
<!--          <mat-option [value]="timeOptions.mediaFile">-->
<!--            {{ timeOptions.mediaFile }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->

<!--      <div class="play-button">-->
<!--        <audio #audio hidden src="https://api.twilio.com/cowbell.mp3">-->
<!--        </audio>-->
<!--        <button #player-->
<!--                (click)="toggleAudio()"-->
<!--                title="{{ buttonTitle | translate }}"-->
<!--                mat-mini-fab-->
<!--                color="primary">-->
<!--          <mat-icon>{{ buttonImage }}</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="custom-media">-->
<!--      <label for="file-upload" class="custom-file-upload">-->
<!--        <mat-icon>cloud_upload</mat-icon>-->
<!--        {{ 'times.buttons.custom' | translate }}-->
<!--      </label>-->
<!--      <input id="file-upload" type="file">-->
<!--    </div>-->

<!--  </div>-->

