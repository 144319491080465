import { PermissionTypes } from "src/app/_models/identity/PermissionTypes";

export interface UserState {
    attributes: PermissionTypes[];
    error: any;
}

export const initialUserState: UserState = {
    attributes: [],
    error: null
};
