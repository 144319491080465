import { Pipe, PipeTransform } from '@angular/core';
import { TextByDateEntry } from '../_models/TextByDateEntry';
import { Widget } from "../_models/widget";
import { HalachicTimesService } from '../_services/halachic-times.service';
import { ValueTextByMonthPipe } from './value-text-by-month.pipe';
import { ValueTextByWeekPipe } from './value-text-by-week.pipe';
import { ValueTextByYearPipe } from './value-text-by-year.pipe';
import { ValueTextByDayPipe } from './value-text-by-day.pipe';
import { AppState } from "src/app/store/app.state";
import { Store } from "@ngrx/store";
import { ViewMode } from '../store/widget/widget.reducer';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Pipe({
  name: 'valueTextBy'
})
export class ValueTextByPipe implements PipeTransform {
  constructor(
    private halachicTimesService: HalachicTimesService,
    private store: Store<AppState>
  ) { }

  transform(value: TextByDateEntry[], selDate: Date, widget: Widget): Observable<string[]> {
    return this.store.select(state => state.widgets[widget.id]).pipe(
      switchMap(widgetState => {
        let pipe: PipeTransform;
        if (widgetState.viewMode === ViewMode.AllYear) {
          pipe = new ValueTextByYearPipe();
        } else if (widgetState.viewMode === ViewMode.AllMonth) {
          pipe = new ValueTextByMonthPipe(this.halachicTimesService);
        } else if (widgetState.viewMode === ViewMode.AllWeek) {
          pipe = new ValueTextByWeekPipe(this.halachicTimesService);
        } else if (widgetState.viewMode === ViewMode.AllDay) {
          pipe = new ValueTextByDayPipe(this.halachicTimesService);
        } else {
          throw new Error(`Unsupported view mode: ${widgetState.viewMode}`);
        }
        return of(pipe.transform(value, selDate, widget));
      })
    );
  }
}
