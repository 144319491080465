import { Component, Input } from '@angular/core';
import { UserDto } from '../../../../../_dto/user-dto';

@Component({
  selector: 'app-day-of-week',
  templateUrl: './day-of-week.component.html',
  styleUrls: ['./day-of-week.component.css']
})
export class DayOfWeekComponent {
  @Input() user: UserDto;
}
