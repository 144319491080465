
import { ViewMode } from "./widget.reducer";
import { TimeFormat } from "src/app/_enums/time-format";

export interface RxWidgetState {
  viewMode: ViewMode;
  timeFormat: TimeFormat;
  timeFormatTableWidget: TimeFormat;
  displaySeconds: boolean;
  zeroPaddingTableWidget: boolean;
  displayAmPmTableWidget: boolean;
  upperAmPmTableWidget: boolean;
  separator: string;
  jewishKeys: string[];
  fontSize: number;
}

export interface WidgetsState {
  [id: string]: RxWidgetState;
}

export const initialWidgetsState: WidgetsState = {};
