import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { TimeFormat, Highlight, Languages, LocalStorage } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget, EffectTypes, ScrollDirections, Scheduler, Screen } from "src/app/_models";
import { WidgetsState, toggleTimeFormatTableWidget, toggleDisplaySecondsTableWidget, toggleZeroPaddingTableWidget, toggleAmPmTableWidget, toggleUpperTableWidget } from "src/app/store/widget";
import { TableContentComponent } from "./table-content/table-content.component";

@Component({
  selector: 'app-table-dashboard',
  templateUrl: './table-dashboard.component.html',
  styleUrls: ['./table-dashboard.component.css']
})
export class TableDashboardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;

  public schedulerMockHighlight: Scheduler;
  public timeFormat = TimeFormat;
  public highlight = Highlight;
  public languages = Languages;
  public effectTypes = EffectTypes;
  public scrollDirections = ScrollDirections;
  public direction: Direction = 'ltr';

  private tableContentRef: MatDialogRef<TableContentComponent>;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private store: Store<{ widgets: WidgetsState }>
  ) {
  }

  /**
   * Gets the current app language from the Local storage.
   */
  public get language(): string {
    return localStorage.getItem(LocalStorage.Language) || this.translate.currentLang;
  }

  public ngOnInit(): void {
    this.setTranslation();

    const { tableHolidaysHighlightConditions, tableDaysHighlightConditions } = this.widget.content.table;

    this.schedulerMockHighlight = {
      holidays: tableHolidaysHighlightConditions,
      daysOfWeek: tableDaysHighlightConditions
    };
  }

  /**
   * Opens table content modal window. Passes data to TableContentComponent.
   */
  public openContentModal(): void {
    if (!!this.tableContentRef) return;

    this.tableContentRef = this.dialog.open(TableContentComponent, { hasBackdrop: false });
    this.tableContentRef.componentInstance.timeFormat = this.widget.content.table?.format;
    this.tableContentRef.componentInstance.widget = this.widget;

    this.tableContentRef.afterClosed()
      .subscribe(() => this.tableContentRef = null);
  }

  /**
   * Marks screen "dirty" for purpose of page leaving warning.
   */
  public markScreenDirty(): void {
    this.screen.dirty = true;
  }

  timeformatChanged(event: MatRadioChange) {
    this.store.dispatch(toggleTimeFormatTableWidget({ widgetId: this.widget.id, timeFormat: event.value }));
  }

  toggleSecondsDisplay() {
    this.widget.content.table.displaySeconds = !this.widget.content.table.displaySeconds;
    this.store.dispatch(toggleDisplaySecondsTableWidget({ widgetId: this.widget.id, displaySeconds: this.widget.content.table.displaySeconds }));
  }

  toggleZeroPadding() {
    this.widget.content.table.padZero = !this.widget.content.table.padZero;
    this.store.dispatch(toggleZeroPaddingTableWidget({ widgetId: this.widget.id, zeroPadding: this.widget.content.table.padZero }));
  }

  toggleAmPm() {
    this.store.dispatch(toggleAmPmTableWidget({ widgetId: this.widget.id, displayAmPm: this.widget.content.table.isAmPm }));
  }

  toggleUpper() {
    this.store.dispatch(toggleUpperTableWidget({ widgetId: this.widget.id, upperAmPm: this.widget.content.table.isUpper }));
  }

  changeScrollDirection() {
    if (!this.widget.content.table.singleLine) {
      this.widget.effect.scrollDirection = this.scrollDirections.Up; this.updateEffect()
    }
    if (this.widget.content.table.singleLine) {
      this.widget.effect.scrollDirection = this.scrollDirections.Right; this.updateEffect()
    }
  }

  /**
   * Emits the specified effect.
   */
  public updateEffect(): void {
    this.widget.effectSource.next(this.widget.effect);
    this.widget.contentChangedSource.next();
  }
  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
