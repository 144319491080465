<!--Common to both - table & alert-->
<div [class.ru-width]="language === languages.Russian">

  <!--Edit table-->
  <div class="settings container-sm d-flex justify-content-center align-items-center">
    <button mat-raised-button (click)="openContentModal()"
            [style.direction]="direction"
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-icon>list</mat-icon>
      {{ 'dashboard.widgetProperties.specific.table.edit' | translate }}
    </button>
  </div>

  <!--Time Format-->
  <div class="settings container-sm mt-4 mx-2 pb-3 d-flex flex-column justify-content-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <div class="d-flex justify-content-between align-items-center">
      <span class="settings-title mx-3">{{ 'dashboard.widgetProperties.specific.table.format' | translate }}</span>
      <mat-radio-group [(ngModel)]="widget.content.table.format" class="d-flex" (change)="markScreenDirty(); timeformatChanged($event)">
        <mat-radio-button [value]="timeFormat._12H"
                          class="mx-3">{{ 'dashboard.widgetProperties.specific.table.12h' | translate }}</mat-radio-button>
        <mat-radio-button [value]="timeFormat._24H">{{ 'dashboard.widgetProperties.specific.table.24h' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="d-flex justify-content-around">
      <mat-checkbox [(ngModel)]="widget.content.table.isAmPm"
                    [disabled]="widget.content.table.format === timeFormat._24H"
                    (change)="toggleAmPm(); markScreenDirty()"
                    class="d-flex justify-content-center mt-2">
        <span class="mx-2">{{ widget.content.table.isUpper ? ('am / pm' | uppercase) : 'am / pm' }}</span>
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="widget.content.table.isUpper"
                    [disabled]="widget.content.table.format === timeFormat._24H || !widget.content.table.isAmPm"
                    (change)="toggleUpper(); markScreenDirty()"
                    class="d-flex justify-content-center mt-2">
        <span class="mx-2">{{ 'dashboard.widgetProperties.specific.table.upper' | translate }}</span>
      </mat-checkbox>
    </div>
  </div>

  <!--Seconds-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span>{{ 'dashboard.widgetProperties.specific.table.seconds' | translate }}</span>
    <mat-slide-toggle (change)="toggleSecondsDisplay(); markScreenDirty();"
                      [checked]="widget.content.table.displaySeconds">
    </mat-slide-toggle>
  </div>

  <!--Zero Padding-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span>{{ 'dashboard.widgetProperties.specific.table.zero' | translate }}</span>
    <mat-slide-toggle (change)="toggleZeroPadding(); markScreenDirty()"
                      [checked]="widget.content.table.padZero">
    </mat-slide-toggle>
  </div>

  <!--Highlight-->
  <div class="settings container-sm d-flex flex-column mx-2"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <div class="d-flex justify-content-between align-items-center mb-5">
      <span>{{ 'dashboard.widgetProperties.specific.table.highlight' | translate }}</span>
      <div (colorPickerChange)="markScreenDirty()"
           [(colorPicker)]="widget.content.table.highlightColor"
           [style.background]="widget.content.table.highlightColor || '#ff8000'"
           [style.right]="direction === 'rtl' ? '' : '1rem'"
           [style.left]="direction === 'rtl' ? '1rem' : ''"
           [cpDisabled]="!widget.content.table.highlight"
           [class.disabled]="widget.content.table.highlight === highlight.Never"
           [cpAlphaChannel]="'disabled'"
           [cpOKButton]="true"
           [cpOKButtonText]="'buttons.ok' | translate"
           cpOKButtonClass="btn btn-outline-primary"
           [cpCancelButton]="true"
           cpCancelButtonClass="btn btn-outline-danger"
           [cpCancelButtonText]="'buttons.cancel' | translate"
           class="colour-picker">
      </div>
    </div>

    <mat-radio-group (change)="markScreenDirty()"
                     [(ngModel)]="widget.content.table.highlight"
                     [style.font-size]="language === languages.Russian ? '0.8rem' : ''"
                     class="w-100 d-flex justify-content-between">
      <mat-radio-button [value]="highlight.Never">{{ 'dashboard.widgetProperties.specific.table.never' | translate }}</mat-radio-button>
      <mat-radio-button [value]="highlight.Always">{{ 'dashboard.widgetProperties.specific.table.always' | translate }}</mat-radio-button>
      <mat-radio-button [value]="highlight.Condition">{{ 'dashboard.widgetProperties.specific.table.condition' | translate }}</mat-radio-button>
    </mat-radio-group>

    <div [ngClass]="widget.content.table.highlight === highlight.Condition ? 'animate__fadeIn' : 'd-none'" class="animate__animated">
      <div class="d-flex flex-column align-items-center w-115">
        <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3">
          <app-weekdays [scheduler]="schedulerMockHighlight"></app-weekdays>
        </div>
        <div [style.direction]="direction"
             [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
             class="property container-sm d-flex flex-column align-items-center mt-2 mx-2 pb-1">
          <app-holidays [scheduler]="schedulerMockHighlight"></app-holidays>
        </div>
      </div>
    </div>

  </div>
</div>

<mat-accordion #accordion="matAccordion" [multi]="false">

  <!--Table layout-->
  <mat-expansion-panel [expanded]="!widget.content.table.alertLayout" [disabled]="widget.content.table.alertLayout">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="settings-title mb-3">{{ 'dashboard.widgetProperties.specific.table.tableLayout' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-0 mx-2">

      <div [class.ru-width]="language === languages.Russian">

        <!--Table/Single line-->
        <div class="settings container-sm d-flex justify-content-center align-items-center w-75">
          <span class="mx-3">{{ 'dashboard.widgetProperties.specific.table.table' | translate }}</span>
          <mat-slide-toggle (change)="widget.content.table.singleLine = !widget.content.table.singleLine; updateEffect(); markScreenDirty()"
                            [checked]="widget.content.table.singleLine"
                            (change)="changeScrollDirection()"
                            [style.direction]="'ltr'">
          </mat-slide-toggle>
          <span class="mx-3">{{ 'dashboard.widgetProperties.specific.table.singleLine' | translate }}</span>
        </div>

        <!--Title Width-->
        <div class="settings container-sm d-flex justify-content-between align-items-center mt-3 mx-2 pb-3"
             [style.direction]="direction"
             [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.table.width' | translate }}</span>
          <mat-slider (change)="markScreenDirty()" [(value)]="widget.content.table.titleColumnWidth" max="100" min="1" step="1"
                      thumbLabel></mat-slider>
        </div>

        <!--Title Font Size-->
        <div class="settings container-sm d-flex justify-content-between align-items-center mt-3 mx-2 pb-3"
             [style.direction]="direction"
             [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.table.fontSize' | translate }}</span>
          <mat-slider (change)="markScreenDirty()" [(value)]="widget.content.table.titleColumnFontSize" max="20" min="0" step="1"
                      thumbLabel></mat-slider>
        </div>

      </div>

    </div>

  </mat-expansion-panel>

  <!--Alert layout-->
  <mat-expansion-panel [expanded]="widget.content.table.alertLayout">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="settings-title mb-3">{{ 'dashboard.widgetProperties.specific.table.alertLayout' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3 w-100">
      <app-alert-dashboard [widget]="widget" class="w-100"></app-alert-dashboard>
    </div>
  </mat-expansion-panel>
</mat-accordion>
