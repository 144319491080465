<div class="container position-relative" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <button [title]="'buttons.close' | translate" class="close-btn" [mat-dialog-close]="false" mat-icon-button
    type="button">
    <mat-icon>close</mat-icon>
  </button>
  <div class="row">
    <mat-card class="col-8">
      <mat-card-header class="title-block" [style.direction]="direction"
        [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-card-title>{{ 'dashboard.widgetProperties.specific.jewish.chooseItems' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'dashboard.widgetProperties.specific.jewish.chooseSubtitle' | translate
          }}</mat-card-subtitle>
        <mat-form-field class="mx-3" [style.direction]="direction"
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          <mat-label>{{ 'dashboard.widgetProperties.specific.jewish.filter' | translate }}</mat-label>
          <input matInput [(ngModel)]="searchValue" tabindex="-1" autocomplete="off">
        </mat-form-field>
      </mat-card-header>
      <mat-card-content [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
        class="content-to-choose mt-3">
        <div class="add-all-container">
          <hr class="hr-text" [attr.data-content]="'jewish.calendar' | translate">
          <button [title]="'buttons.addAll' | translate" color="accent" mat-icon-button
            (click)="transferCategory(categories.calendar)">
            <mat-icon class="add-all-btn">playlist_add</mat-icon>
          </button>
          <mat-chip-list cdkDropList #calendar="cdkDropList" cdkDropListSortingDisabled
            (cdkDropListDropped)="dropItem($event)"
            [cdkDropListData]="(contentToChoose | jewishContent:categories.calendar) | filter:searchValue"
            [cdkDropListConnectedTo]="[chosenList]">
            <mat-chip (dblclick)="transferItem(item)"
              *ngFor="let item of ((contentToChoose | jewishContent:categories.calendar) | filter:searchValue)" cdkDrag>
              {{ 'jewish.' + item | translate }}
            </mat-chip>
          </mat-chip-list>
        </div>

        <div class="add-all-container">
          <hr class="hr-text" [attr.data-content]="'jewish.times' | translate">
          <button [title]="'buttons.addAll' | translate" color="accent" mat-icon-button
            (click)="transferCategory(categories.times)">
            <mat-icon class="add-all-btn">playlist_add</mat-icon>
          </button>
          <mat-chip-list cdkDropList #times="cdkDropList" cdkDropListSortingDisabled
            (cdkDropListDropped)="dropItem($event)"
            [cdkDropListData]="(contentToChoose | jewishContent:categories.times) | filter:searchValue"
            [cdkDropListConnectedTo]="[chosenList]">
            <mat-chip (dblclick)="transferItem(item)"
              *ngFor="let item of ((contentToChoose | jewishContent:categories.times) | filter:searchValue)" cdkDrag>
              {{ 'jewish.' + item | translate }}
            </mat-chip>
          </mat-chip-list>
        </div>

        <div class="add-all-container">
          <hr class="hr-text" [attr.data-content]="'jewish.prayerChanges' | translate">
          <button [title]="'buttons.addAll' | translate" color="accent" mat-icon-button
            (click)="transferCategory(categories.prayerChanges)">
            <mat-icon class="add-all-btn">playlist_add</mat-icon>
          </button>
          <mat-chip-list cdkDropList #prayerChanges="cdkDropList" cdkDropListSortingDisabled
            (cdkDropListDropped)="dropItem($event)"
            [cdkDropListData]="(contentToChoose | jewishContent:categories.prayerChanges) | filter:searchValue"
            [cdkDropListConnectedTo]="[chosenList]">
            <mat-chip (dblclick)="transferItem(item)"
              *ngFor="let item of ((contentToChoose | jewishContent:categories.prayerChanges) | filter:searchValue)"
              cdkDrag>
              {{ 'jewish.' + item | translate }}
            </mat-chip>
          </mat-chip-list>
        </div>

        <div class="add-all-container">
          <hr class="hr-text" [attr.data-content]="'jewish.special' | translate">
          <button [title]="'buttons.addAll' | translate" color="accent" mat-icon-button
            (click)="transferCategory(categories.special)">
            <mat-icon class="add-all-btn">playlist_add</mat-icon>
          </button>
          <mat-chip-list cdkDropList #special="cdkDropList" cdkDropListSortingDisabled
            (cdkDropListDropped)="dropItem($event)"
            [cdkDropListData]="(contentToChoose | jewishContent:categories.special) | filter:searchValue"
            [cdkDropListConnectedTo]="[chosenList]">
            <mat-chip (dblclick)="transferItem(item)"
              *ngFor="let item of ((contentToChoose | jewishContent:categories.special) | filter:searchValue)" cdkDrag>
              {{ 'jewish.' + item | translate }}
            </mat-chip>
          </mat-chip-list>
        </div>

        <div class="add-all-container">
          <hr class="hr-text" [attr.data-content]="'jewish.studies' | translate">
          <button [title]="'buttons.addAll' | translate" color="accent" mat-icon-button
            (click)="transferCategory(categories.studies)">
            <mat-icon class="add-all-btn">playlist_add</mat-icon>
          </button>
          <mat-chip-list cdkDropList #studies="cdkDropList" cdkDropListSortingDisabled
            (cdkDropListDropped)="dropItem($event)"
            [cdkDropListData]="(contentToChoose | jewishContent:categories.studies) | filter:searchValue"
            [cdkDropListConnectedTo]="[chosenList]">
            <mat-chip (dblclick)="transferItem(item)"
              *ngFor="let item of ((contentToChoose | jewishContent:categories.studies) | filter:searchValue)" cdkDrag>
              {{ 'jewish.' + item | translate }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-card-content>
    </mat-card>

    <div class="col-1"></div>

    <mat-card class="col-3">
      <mat-card-header class="title-block" [style.direction]="direction"
        [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-card-title>{{ 'dashboard.widgetProperties.specific.jewish.chosenItems' | translate }}</mat-card-title>
        <mat-card-subtitle class="drop-title">{{ 'dashboard.widgetProperties.specific.jewish.chosenSubtitle' | translate
          }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="mt-3" [style.direction]="direction"
        [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <div cdkDropList #chosenList="cdkDropList" cdkDropListOrientation="vertical"
          (cdkDropListDropped)="dropItem($event)" (cdkDropListEntered)="onDragEnter($event.container)"
          (cdkDropListExited)="onDragExit($event.container)" [cdkDropListData]="tempChosenContent"
          [cdkDropListConnectedTo]="[calendar, times, prayerChanges, special, studies]" class="chosen-list">
          <div *ngIf="tempChosenContent && tempChosenContent.length === 0" class="drop-placeholder"
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
            {{ 'dashboard.widgetProperties.specific.jewish.drop' | translate }}
          </div>
          <mat-chip *ngFor="let item of tempChosenContent" (dblclick)="transferBack(item)" color="primary" selected
            cdkDrag>
            {{ 'jewish.' + item | translate }}
          </mat-chip>
        </div>
        <div class="clear-all-box">
          <button (click)="clearAll()" [title]="'buttons.clearAll' | translate" mat-raised-button>
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="row justify-content-center" [style.direction]="direction"
    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <div class="mt-4">
      <button mat-raised-button color="primary" class="mx-5" (click)="applyChanges()" [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
      <button mat-raised-button color="accent" (click)="discardChanges()" [mat-dialog-close]="false">{{ 'buttons.cancel' | translate }}</button>
    </div>
  </div>
</div>
