<div #clock class="clock"
     [style.transform]="'scale(' + widget.content.clock + ')'"
     [style]="{'--hands-color': widget.font.color}">
  <div class="hour">
    <div class="hr" [ngStyle]="hrStyle"></div>
  </div>
  <div class="minute">
    <div class="min" [ngStyle]="minStyle"></div>
  </div>
  <div class="second">
    <div class="sec" [ngStyle]="secStyle"></div>
  </div>
</div>
