import { Direction } from "@angular/cdk/bidi";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { NgbCalendar, NgbCalendarHebrew, NgbDatepickerI18n, NgbDatepickerI18nHebrew, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Languages } from "src/app/_enums";
import { SchedulerHelper, TranslateHelper } from "src/app/_helpers";
import { Widget, HebrewMonth, TextByDateEntry } from "src/app/_models";
import { ConfirmationComponent } from "src/app/_modules/admin/admin-dashboard/user-list/confirmation/confirmation.component";
import { AddTextByDateComponent } from "../add-text-by-date/add-text-by-date.component";
import { AddTextComponent } from "../add-text/add-text.component";
import { delay } from "rxjs/operators";

type Callback = (() => void) | ((id: string) => void);

@Component({
  selector: "app-text-content",
  templateUrl: "./text-content.component.html",
  styleUrls: ["./text-content.component.css"],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarHebrew },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nHebrew },
  ],
})
export class TextContentComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Input() widget: Widget;

  public pageSizes = [10];
  pageEvent: PageEvent;

  public selectedMonthStart: HebrewMonth;
  public hebrewMonths = SchedulerHelper.hebrewMonths;
  public jewishCalendar = new NgbCalendarHebrew();

  public subscriptions: Subscription[];
  public languages = Languages;

  public dataSource;
  public direction: Direction = "ltr";
  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file: File = null; // Variable to store file
  table = []
  item
  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private translateHelper: TranslateHelper,
    public i18n: NgbDatepickerI18n
  ) { }
  ngOnInit(): void {
    this.setTranslation();
    this.dataSource = new MatTableDataSource(this.widget.content.text.byDate);
    // this.dataSource = this.widget.content.text.byDate.slice();
    this.dataSource.paginator = this.paginator;
  }

  georgianToHebrewDate(date: NgbDate) {
    return this.jewishCalendar.fromGregorian(date);
  }

  fileChanged(e) {
    this.file = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      let fileText = `${fileReader.result}`
      let rows = fileText.split('\n')
      rows.forEach((row, i) => {
        if (i > 0) {
          let items = row.split('\t');
          this.item = {
            dateDay: "32",
            dateMonth: "32",
            hebrewDate: {
              year: 5782,
              month: items[2] == '01A' ? 1 : items[2] == '02A' ? 2 : items[2] == '03A' ? 3 : items[2] == '04A' ? 4 : items[2] == '05A' ? 5 : items[2] == '06A' ? 6 : items[2] == '07A' ? 7 : items[2] == '08A' ? 8 : items[2] == '09A' ? 9 : items[2] == '10A' ? 10 : items[2] == '11A' ? 11 : items[2] == '12A' ? 12 : items[2] == '02A' ? 2 : items[2] == '06B' ? 14 : 13,
              day: parseInt(items[3]) >= 1 ? parseInt(items[3]) : 32
            },
            text: items[6] == undefined ? "" : items[6],
            text1: items[7] == '* * * * * *' ? "" : items[7] == undefined ? "" : items[7],
            text2: items[8] == '^^' ? "" : items[8] == undefined ? "" : items[8],
            text3: items[9] == '^^' ? "" : items[9] == undefined ? "" : items[9],
            text4: items[10] == '^^' ? "" : items[10] == undefined ? "" : items[10],
            text5: items[11] == '^^' ? "" : items[11] == undefined ? "" : items[11],
            text6: items[12] == '^^' ? "" : items[12] == undefined ? "" : items[12]
          }
          this.table.push(this.item)
          if (!this.widget.content.text.byDate) this.widget.content.text.byDate = [];
          this.widget.content.text.byDate.push(this.item);
          this.dataSource = new MatTableDataSource(this.widget.content.text.byDate);
          this.dataSource.paginator = this.paginator;
        }
      })
    }
    fileReader.readAsText(this.file);
  }
  // 'hebDay': items[0],
  // 'hebMonth': items[1],
  // 'HebMonthNum': items[2],
  // 'Heb Day Num': items[3],
  // 'FileName': items[5],
  // 'FileKind': items[6],
  // 'Row0': items[7],
  // 'Row1': items[8],
  // 'Row2': items[9],
  // 'Row3': items[10],
  // 'Row4': items[11],
  // 'Row5': items[12],
  // 'Row6': items[13],
  // 'ID': items[14],
  displayedColumns: string[] = ["hebrewDate", "text", "delete"];

  public selection: SelectionModel<any>;
  clickedRows = new Set<TextByDateEntry>();

  openUpdatePopup(row: TextByDateEntry | undefined, index?: number, target?) {
    const newRowRef = this.dialog.open(AddTextByDateComponent, {
      hasBackdrop: false,
      height: '80vh',
      width: '80vw',
    });
    const nowDate = new Date();
    newRowRef.componentInstance.config = {
      message: 'Update date',
      direction: this.direction,
      widget: this.widget,
      htmlContent: row?.text || '',
      date: row?.hebrewDate || new NgbDate(nowDate.getFullYear(), nowDate.getMonth() + 1, nowDate.getDate()),
    };

    newRowRef
      .afterClosed()
      .subscribe(() => {
        const year = newRowRef.componentInstance.config.date.year;
        const month = newRowRef.componentInstance.config.date.month;
        const day = newRowRef.componentInstance.config.date.day;
        const updatedRow = {
          dateDay: day.toString(),
          dateMonth: month.toString(),
          hebrewDate: new NgbDate(year, month, day),
          isDateHhebre: false,
          text: newRowRef.componentInstance.config.htmlContent,
          text1: "",
          text2: "",
          text3: "",
          text4: "",
          text5: "",
          text6: ""
        }
        if (index) {
          this.widget.content.text.byDate[index] = updatedRow;
        } else {
          this.widget.content.text.byDate.push(updatedRow);
        }
        this.dataSource = new MatTableDataSource(this.widget.content.text.byDate);
        this.dataSource.paginator = this.paginator;
      });
  }

  openAddTextPopup() {
    const dialogRef: MatDialogRef<AddTextComponent> =
      this.dialog.open(AddTextComponent);
    dialogRef.componentInstance.widget = this.widget;
    dialogRef
      .afterClosed()
      .pipe(delay(1500))
      .subscribe((data: boolean) => {
        if (data) {
          // if (!this.widget.content.text.byDate) this.widget.content.text.byDate = [];
          this.dataSource = new MatTableDataSource(this.widget.content.text.byDate);
          this.dataSource.paginator = this.paginator;
          // this.dataSource = this.widget.content.text.byDate.slice();
        }
      });
    return;
  }
  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange.subscribe(
      ({ lang }) => (this.direction = lang === Languages.Hebrew ? "rtl" : "ltr")
    );
    this.direction = this.translateHelper.direction;
  }

  /**
   * Opens a confirmation modal window.
   * @param {string} message - a confirmation message.
   * @param {Callback} callback - a callback function to call on confirmation.
   * @param {number} id - user ID.
   */
  public openConfirmationPopup(
    message: string,
    callback: Callback,
    element: any = null
  ): void {
    const dialogRef: MatDialogRef<ConfirmationComponent> = this.dialog.open(ConfirmationComponent);
    dialogRef.componentInstance.config = {
      message,
      direction: this.direction as Direction,
    };
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "") {
        // @ts-ignore
        element ? callback(element) : callback();
      }
    });
    return;
  }
  /**
   * Deletes the selected user by element.
   * @param {string} text - an element of the user to be deleted.
   */
  public deleteRow(element: TextByDateEntry) {
    let index = this.widget.content.text.byDate.indexOf(element, 0);
    this.widget.content.text.byDate.splice(index, 1);
    // this.dataSource = this.widget.content.text.byDate.slice();
    this.dataSource = new MatTableDataSource(this.widget.content.text.byDate);
    this.dataSource.paginator = this.paginator;

    index = null;
  }

  public setHebrewDate1(day, month) {
    let dayHebrew;
    let monthHebrew;
    switch (day) {
      case 1:
        dayHebrew = "א";
        break;
      case 2:
        dayHebrew = "ב";
        break;
      case 3:
        dayHebrew = "ג";
        break;
      case 4:
        dayHebrew = "ד";
        break;
      case 5:
        dayHebrew = "ה";
        break;
      case 6:
        dayHebrew = "ו";
        break;
      case 7:
        dayHebrew = "ז";
        break;
      case 8:
        dayHebrew = "ח";
        break;
      case 9:
        dayHebrew = "ט";
        break;
      case 10:
        dayHebrew = "י";
        break;
      case 11:
        dayHebrew = "יא";
        break;
      case 12:
        dayHebrew = "יב";
        break;
      case 13:
        dayHebrew = "יג";
        break;
      case 14:
        dayHebrew = "יד";
        break;
      case 15:
        dayHebrew = "טו";
        break;
      case 16:
        dayHebrew = "טז";
        break;
      case 17:
        dayHebrew = "יז";
        break;
      case 18:
        dayHebrew = "יח";
        break;
      case 19:
        dayHebrew = "יט";
        break;
      case 20:
        dayHebrew = "כ";
        break;
      case 21:
        dayHebrew = "כא";
        break;
      case 22:
        dayHebrew = "כב";
        break;
      case 23:
        dayHebrew = "כג";
        break;
      case 24:
        dayHebrew = "כד";
        break;
      case 25:
        dayHebrew = "כה";
        break;
      case 26:
        dayHebrew = "כו";
        break;
      case 27:
        dayHebrew = "כז";
        break;
      case 28:
        dayHebrew = "כח";
        break;
      case 29:
        dayHebrew = "כט";
        break;
      case 30:
        dayHebrew = "ל";
        break;
    }
    switch (month) {
      case 1:
        monthHebrew = "תשרי";
        break;
      case 2:
        monthHebrew = "חשוון";
        break;
      case 3:
        monthHebrew = "כסלו";
        break;
      case 4:
        monthHebrew = "טבת";
        break;
      case 5:
        monthHebrew = "שבט";
        break;
      case 6:
        monthHebrew = "אדר";
        break;
      case 14:
        monthHebrew = "'אדר א";
        break;
      case 13:
        monthHebrew = "'אדר ב";
        break;
      case 7:
        monthHebrew = "ניסן";
        break;
      case 8:
        monthHebrew = "אייר";
        break;
      case 9:
        monthHebrew = "סיון";
        break;
      case 10:
        monthHebrew = "תמוז";
        break;
      case 11:
        monthHebrew = "אב";
        break;
      case 12:
        monthHebrew = "אלול";
        break;

      // case 7:
      //   monthHebrew = "'אדר ב";
      //   break;
      //   case 8:
      //     monthHebrew = "ניסן";
      //     break;
      //   case 9:
      //     monthHebrew = "אייר";
      //     break;
      //   case 10:
      //     monthHebrew = "סיון";
      //     break;
      //   case 11:
      //     monthHebrew = "תמוז";
      //     break;
      //   case 12:
      //     monthHebrew = "אב";
      //     break;
      //   case 13:
      //     monthHebrew = "אלול";
      //     break;
    }
    return dayHebrew + " " + monthHebrew;
  }
}
