import { Pipe, PipeTransform } from '@angular/core';
import { TextByDateEntry } from '../_models/TextByDateEntry';
import { Widget } from "../_models/widget";

@Pipe({
  name: 'valueTextByYear'
})
export class ValueTextByYearPipe implements PipeTransform {
  transform(value: TextByDateEntry[], selDate: Date, widget: Widget): string[] {
    return value.map((item) => item.text);
  }
}
