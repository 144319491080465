<div class="settings container-sm d-flex justify-content-center align-items-center direction">
  <mat-button-toggle-group multiple>
    <mat-button-toggle (change)="user.settings.hebrewDate[0] = !user.settings.hebrewDate[0]"
                       [checked]="user.settings.hebrewDate[0]">
      {{ 'jewishSettings.day' | translate }}
    </mat-button-toggle>
    <mat-button-toggle (change)="user.settings.hebrewDate[1] = !user.settings.hebrewDate[1]"
                       [checked]="user.settings.hebrewDate[1]">
      {{ 'jewishSettings.month' | translate }}
    </mat-button-toggle>
    <mat-button-toggle (change)="user.settings.hebrewDate[2] = !user.settings.hebrewDate[2]"
                       [checked]="user.settings.hebrewDate[2]">
      {{ 'jewishSettings.year' | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>
<mat-card-subtitle class="mt-3 font-italic text-center">{{ 'jewishSettings.overridden' | translate }}</mat-card-subtitle>
