import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import { Observable, BehaviorSubject, Subject, combineLatest, EMPTY } from "rxjs";
import { publishReplay, refCount, switchMap, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Screens, Widget, WidgetIcon, EffectUpdate, Effect, Screen } from "../_models";
import { User } from "../_models/identity";
import { AccountService } from "./account.service";
import { BaseApiService } from "./base-api-service";

@Injectable({
  providedIn: 'root'
})
export class WidgetService extends BaseApiService {
  private apiUrl = environment.apiUrl;
  private screens$: Observable<Screens>;
  private screenUpdatedSource = new BehaviorSubject<Screen>(null);
  private widgetSelectedSource = new Subject<Widget>();
  private widgetsTabOpenedSource = new Subject<void>();
  private widgetIconDroppedSource = new BehaviorSubject<WidgetIcon>(null);
  private effectUpdatedSource = new Subject<EffectUpdate>();

  public widgetUpdateSpeedTable = new BehaviorSubject<Effect>(null);
  public screenUpdated$: Observable<Screen> = this.screenUpdatedSource.asObservable();
  public widgetSelected$: Observable<Widget> = this.widgetSelectedSource.asObservable();
  public widgetsTabOpened$: Observable<void> = this.widgetsTabOpenedSource.asObservable();
  public widgetIconDropped$: Observable<WidgetIcon> = this.widgetIconDroppedSource.asObservable();
  public effectUpdated$: Observable<EffectUpdate> = this.effectUpdatedSource.asObservable();
  public changeSize = new Subject<boolean>();

  constructor(private http: HttpClient, private accountService: AccountService) {
    super();
  }

  /**
   * Returns current organization screens as Observable<Screens>.
   */
  public get existingScreens$(): Observable<Screens> {
    return this.getScreensFromServerOrCache();
  }

  /**
   * Combines screens from a server and a dashboard and returns it as Observable<Screen>.
   */
  public getCombinedScreen(): Observable<Screen> {
    return combineLatest([this.getScreensFromServerOrCache(), this.screenUpdated$]).pipe(
      map(([serverScreens, clientScreen]) => clientScreen || serverScreens[0])
    );
  }

  /**
   * Emits update screen Observable.
   * @param {Screen} screen - an updated screen.
   * @param {boolean} dirty - whether the screen was updated and required a conformation on browser close.
   */
  public updateScreen(screen: Screen, dirty = true): void {
    if (screen) {
      screen.dirty = dirty;
      this.screenUpdatedSource.next(screen);
    }
  }

  /**
   * Emits a selected widget and its widget icon object.
   * @param {Widget} widget - selected widget.
   * @param {WidgetIcon} [icon] - selected widget icon object (optional).
   */
  public selectWidget(widget: Widget, icon?: WidgetIcon): void {
    this.widgetSelectedSource.next(widget);
    this.widgetIconDroppedSource.next(icon);
  }

  /**
   * Emits a specified widget effect.
   * @param {Effect} effect - a widget effect object.
   * @param {number} widgetId - the specified widget ID.
   */
  public updateEffect(effect: Effect, widgetId: number): void {
    if (effect) {
      this.effectUpdatedSource.next({ effect, widgetId });
    }
  }

  /**
   * Emits when widgets tab is opened.
   */
  public openWidgetsTab(): void {
    this.widgetsTabOpenedSource.next();
  }

  /**
   * Emits when a widget icon is dropped onto the screen.
   * @param {WidgetIcon} icon - dropped widget icon object.
   */
  public dropWidgetIcon(icon: WidgetIcon): void {
    this.widgetIconDroppedSource.next(icon);
  }

  /**
   * Clears the screens cache.
   */
  public clearScreensCache(): void {
    this.screens$ = null;
  }

  private fetchScreen(screenId: string): Observable<Screen[]> {
    const url = `${this.apiUrl}content/GetScreenForUser?screenId=${screenId}`;
    return this.http.get<Screen>(url, { headers: WidgetService.authorizationHeader })
      .pipe(
        map(screen => [this.processScreen(screen)]), // Encapsulate the Screen into an array
        publishReplay(1),
        refCount()
      );
  }

  private processScreens(screens: Screens): Screens {
    return screens.map(screen => this.processScreen(screen));
  }

  private processScreen(screen: Screen): Screen {
    screen.widgets = screen.widgets.map(widget => {
      widget.resizeStopSource = new Subject<IResizeEvent>();
      widget.contentChangedSource = new Subject<void>();
      widget.effectSource = new BehaviorSubject<Effect>(widget.effect);
      widget.isHidden = widget.isHidden ?? false;
      return widget;
    });
    return screen;
  }

  private getScreensFromUrl(url: string): Observable<Screens> {
    const isBaseURL = (url: string): boolean => new RegExp(`^${url}/?$`).test(url);
    return isBaseURL(url) ? this.fetchBaseScreens() : this.fetchScreen(new URLSearchParams(url.split('?')[1]).get('screenId'));
  }

  private fetchBaseScreens(): Observable<Screens> {
    if (!this.screens$) {
      this.screens$ = this.accountService.currentUser$.pipe(
        map((user: User) => user?.legacyOrganizationId),
        switchMap((orgId: number) => {
          if (orgId) {
            return this.http.get<Screens>(`${this.apiUrl}content/${orgId}`, { headers: WidgetService.authorizationHeader }).pipe(
              map((screens: Screens) => this.processScreens(screens)),
              publishReplay(1),
              refCount()
            );
          }
          return EMPTY;
        })
      );
    }
    return this.screens$;
  }

  private getScreensFromServerOrCache(): Observable<Screens> {
    if (!this.screens$) {
      this.accountService.currentUser$.subscribe((user: User) => {
        if (user) {
          this.screens$ = this.getScreensFromUrl(location.href);
        }
      });
    }
    return this.screens$;
  }
}