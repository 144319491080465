<div class="container" [style.direction]="direction">
  <mat-card-subtitle translate>contact.subtitle</mat-card-subtitle>

  <div class="details">
    <mat-card class="email" [class.rtl]="direction === 'rtl'">
      <div class="details-item">
        <mat-icon color="primary">email</mat-icon>
        <mat-card-title translate>contact.email.title</mat-card-title>
      </div>
      <p translate>contact.email.general <a href="mailto:info@beezeesystems.com">info@beezeesystems.com</a></p>
      <p translate>contact.email.support <a href="mailto:support@beezeesystems.com">support@beezeesystems.com</a></p>
      <p translate>contact.email.orders <a href="mailto:orders@beezeesystems.com">orders@beezeesystems.com</a></p>
    </mat-card>

    <mat-card class="tel" [class.rtl]="direction === 'rtl'">
      <div class="details-item">
        <mat-icon color="primary">call</mat-icon>
        <mat-card-title translate>contact.call.title</mat-card-title>
      </div>
      <p translate>contact.call.support</p>
      <a href="tel:1-877-522-5363">
        <h5>1-877-JCALENDAR</h5>
      </a>
      <p translate>contact.call.office
        <a href="tel:1-212-962-1163">1-212-962-1163</a>
      </p>
    </mat-card>
  </div>
</div>
<div class="d-flex flex-column align-items-center mt-5">
  <div>
    <a href="https://beezeesystems.com" target="_blank">&copy;&nbsp;Beezee Systems</a>
  </div>
  <div><span>{{ 'contact.lastUpdate' | translate }}</span>: 14.02.2021</div>
</div>
