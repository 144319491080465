export interface Media {
  items?: MediaItem[];
  interval: number;
  stretched: boolean;
}

export interface MediaItem {
  type: string;
  name: string;
  serverName: string;
  url: string;
  size: number;
  duration: number;
  enabled?: boolean;
}

export type MediaItems = MediaItem[];

export enum SingleMediaTypes {
  Image = 'Image',
  Video = 'Video',
  Audio = 'Sound',
  Pdf = 'PDF',
  Url = 'URL'
}
