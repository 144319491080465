import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TimesDto } from '../_dto';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class ValuesService extends BaseApiService {
  private apiUrl = environment.apiUrl + 'values/';
  private times$: Observable<TimesDto[]>;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Fetches minhags from a server.
   */
  public getMinhags(): Observable<string[]> {
    return this.http.get<string[]>(this.apiUrl + 'minhags', { headers: ValuesService.authorizationHeader });
  }

  /**
   * Fetches halachic times from a server and caches it.
   */
  public getTimes(): Observable<TimesDto[]> {
    if (!this.times$) {
      this.times$ = this.http.get<TimesDto[]>(this.apiUrl + 'times', { headers: ValuesService.authorizationHeader })
        .pipe(
          publishReplay(1),
          refCount()
        );
    }

    return this.times$;
  }
}
