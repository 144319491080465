import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableType'
})
export class TableTypePipe implements PipeTransform {

  /**
   * The purpose of the pipe is to return type 'table' also for 'tableAlert'.
   * @param {string} value - widget type.
   */
  public transform(value: string): string {
    return value.toLowerCase().includes('table') ? 'table' : value;
  }
}
