import { Component, Input } from '@angular/core';
import { UserDto } from '../../../../../_dto/user-dto';
import { MinhagHaftara } from '../../../../../_enums/minhag-haftara';

@Component({
  selector: 'app-haftara',
  templateUrl: './haftara.component.html',
  styleUrls: ['./haftara.component.css']
})
export class HaftaraComponent {
  @Input() user: UserDto;
  public haftarot = MinhagHaftara;
}
