export enum WeekDefinitions {
  SundaySaturday,
  SundayThursday,
  MondaySunday,
  TuesdayMonday,
  WednesdayTuesday,
  ThursdayWednesday,
  FridayThursday,
  SaturdayFriday
}

export const weekDefinitions = {
  [WeekDefinitions.SundaySaturday]: [0, 1, 2, 3, 4, 5, 6],
  [WeekDefinitions.SundayThursday]: [0, 1, 2, 3, 4],
  [WeekDefinitions.MondaySunday]: [1, 2, 3, 4, 5, 6, 7],
  [WeekDefinitions.TuesdayMonday]: [2, 3, 4, 5, 6, 7, 8],
  [WeekDefinitions.WednesdayTuesday]: [3, 4, 5, 6, 7, 8, 9],
  [WeekDefinitions.ThursdayWednesday]: [4, 5, 6, 7, 8, 9, 10],
  [WeekDefinitions.FridayThursday]: [5, 6, 7, 8, 9, 10, 11],
  [WeekDefinitions.SaturdayFriday]: [6, 7, 8, 9, 10, 11, 12]
};
