export interface Effect {
  type: EffectTypes;
  scrollSpeed?: number;
  scrollDirection?: ScrollDirections;
  autoEffect?: boolean;
}

export enum EffectTypes {
  None,
  Scroll,
  BlurredSwap,
  FastSwap
}

export enum ScrollDirections {
  Up,
  Down,
  Left,
  Right,
}
