import { Injectable } from '@angular/core';
import { getZmanimJson, Options } from 'kosher-zmanim';

import { KosherZmanimOptionDto } from '../_dto/kosher-zmanim-option-dto';
import { Minhags } from '../_enums/minhags';
import { DateHelper } from '../_helpers/date.helper';
import { JewishHelper } from '../_helpers/jewish.helper';
import { KosherZmanimObject, KosherZmanimObjectTwoDays } from '../_models/kosher-zmanim-object';

const TIMES_SERIAL_NUMBERS = {
  alos: 1,
  misheyakir: 2,
  sunrise: 3,
  shma: 4,
  tfilah: 5,
  achilasChametz: 6,
  biurChametz: 7,
  chatzos: 8,
  minchaGedola: 9,
  minchaKetana: 10,
  plag: 11,
  candleLight:12,
  sunset: 13,
  fastEnd:14,
  // bainHasmashos: 15,
  tzais: 15,
  shabbosEnd: 16,
  rabenuTam: 17,
  startKidush: 18,
  endKidush: 19,
};

@Injectable({
  providedIn: 'root'
})
export class HalachicTimesService {
  private _options: Options = { // default options
    complexZmanim: true,
    date: new Date(),
    elevation: 700,
    latitude: 31.771959,
    longitude: 35.217018,
    locationName: 'Jerusalem',
    timeZoneId: 'Asia/Jerusalem'
  };

  private _candleLightTime = 18;
  private _minhag = Minhags.Ashkenaz;
  private _times: KosherZmanimOptionDto[];
  private _halachicTimes: KosherZmanimObject;
  private _halachicTimesTwoDays: KosherZmanimObjectTwoDays;
  private _currentWeekTimes: KosherZmanimObject[] = [];
  static halachicTimesService: any;

  public get times(): KosherZmanimOptionDto[] {
    return this._times;
  }

  public set times(options: KosherZmanimOptionDto[]) {
    options.forEach(option => option.serialNumber = TIMES_SERIAL_NUMBERS[option.key]);
    this._times = options.sort(((a, b) => a.serialNumber - b.serialNumber));
  }

  public get options(): Options {
    return this._options;
  }

  public set options(value: Options) {
    this._options = value;
    this.clearHalachicTimes();
  }

  public set date(value: Date) {
    this._options.date = value;
    this.clearHalachicTimes();
  }

  public get candleLightTime(): number {
    return this._candleLightTime;
  }

  public set candleLightTime(value: number) {
    this._candleLightTime = value;
  }

  public get minhag(): Minhags {
    return this._minhag;
  }

  public set minhag(value: Minhags) {
    this._minhag = value;
  }

  public get halachicTimes(): KosherZmanimObject {
    if (!this._halachicTimes) {
      this._halachicTimes = getZmanimJson(this._options).Zmanim as KosherZmanimObject;
      [18, 20, 35, 40, 45, 60, 72].forEach(min => this._halachicTimes[`Minutes${min}`] = this.getTimeInMinutesOneDay(min));
      [15, 18, 20, 22, 24, 30, 40].forEach(min => this._halachicTimes[`Minutes_${min}`] = this.getTimeInMinutesOneDay(-min));

      // JewishHelper.halachicTimes = { ...this._halachicTimes };
    }
    return this._halachicTimes;
  }

  public set halachicTimes(value: KosherZmanimObject) {
    this._halachicTimes = value;
    [18, 20, 35, 40, 45, 60, 72].forEach(min => this._halachicTimes[`Minutes${min}`] = this.getTimeInMinutesOneDay(min));
    [15, 18, 20, 22, 24, 30, 40].forEach(min => this._halachicTimes[`Minutes_${min}`] = this.getTimeInMinutesOneDay(-min));

    JewishHelper.halachicTimes = { ...this._halachicTimes };
  }


  public get halachicTimesTwoDays(): KosherZmanimObjectTwoDays {
    return this._halachicTimesTwoDays;
  }

  public set halachicTimesTwoDays(value: KosherZmanimObjectTwoDays) {
    this._halachicTimesTwoDays = value;

    [18, 20, 35, 40, 45, 60, 72].forEach(min => {
      this._halachicTimesTwoDays[0][`Minutes${min}`] = this.getTimeInMinutes(min, 0);
      this._halachicTimesTwoDays[1][`Minutes${min}`] = this.getTimeInMinutes(min, 1);
    });

    [15, 18, 20, 22, 24, 30, 40].forEach(min => {
      this._halachicTimesTwoDays[0][`Minutes_${min}`] = this.getTimeInMinutes(-min, 0);
      this._halachicTimesTwoDays[1][`Minutes_${min}`] = this.getTimeInMinutes(-min, 1);
    });

    JewishHelper.halachicTimesTwoDays = { ...this._halachicTimesTwoDays };
  }



  /**
   * Retrieves current week halachic times from cache.
   * @param {Date} [date] - current/selected date, by default new Date().
   */
  public getCurrentWeekTimes(date = new Date()): KosherZmanimObject[] {
    if (!this._currentWeekTimes || !this._currentWeekTimes.length){
      this._currentWeekTimes =  this.getCurrentWeekHalachicTimes(date);
    }
    return this._currentWeekTimes;
  }

  /**
   * Clears week times cache.
   */
  public clearCurrentWeekTimes(): void {
    this._currentWeekTimes = null;
  }

  /**
   * Calculates current week halachic times.
   * @param {Date} selectedDate - selected date.
   * @private
   */
  private getCurrentWeekHalachicTimes(selectedDate: Date): KosherZmanimObject[] {
    const currentWeek: Date[] = [];

    const today = selectedDate.getDay();
    const cachingDaysNumber = 13;

    for (let dayNumber = 0; dayNumber < cachingDaysNumber; dayNumber++) {
      const date = new Date(selectedDate);
      date.setDate(date.getDate() - (today - dayNumber))
      currentWeek.push(date);
    }

    return currentWeek.map((day: Date) => {
      this.date = day;
      return this.halachicTimes;
    });
  }

  /**
   * Clears Kosher Zmanim halachic times object.
   * @private
   */
  private clearHalachicTimes(): void {
    this._halachicTimes = null;
    // JewishHelper.halachicTimes = null;
  }

  private getTimeInMinutes(minutes: number, dayLink?: number): string {
    const option = this.times.find(time => time.key === 'sunset').option;

    const [today, tomorrow] = Object.values(this._halachicTimesTwoDays)
      .map((times: KosherZmanimObject) => new Date(times[option]));

    const sunset = dayLink === 1 ? tomorrow : today;
    return DateHelper.getDateInISO8601Format(new Date(sunset.setMinutes(sunset.getMinutes() + minutes)));
  }


  private getTimeInMinutesOneDay(minutes: number): string {
    const option = this.times.find(time => time.key === 'sunset').option;
    const sunset = new Date(this._halachicTimes[option]);
    return DateHelper.getDateInISO8601Format(new Date(sunset.setMinutes(sunset.getMinutes() + minutes)));
  }

}
