import { ElementRef, Pipe, PipeTransform } from "@angular/core";
import { KosherZmanimOptionDto } from "../_dto/kosher-zmanim-option-dto";
import { CalcOptions } from "../_enums/calc-options";
import { ChangeTimes } from "../_enums/change-times";
import { Days } from "../_enums/days";
import { DecisiveDayOption } from "../_enums/decisive-day-option";
import { TimeFormat } from "../_enums/time-format";
import { weekDefinitions, WeekDefinitions } from "../_enums/week-definitions";
import { KosherZmanimKey, KosherZmanimObject, KosherZmanimObjectTwoDays } from "../_models/kosher-zmanim-object";
import { TableRecord } from "../_models/table";
import { Widget } from "../_models/widget";
import { HalachicTimesService } from "../_services/halachic-times.service";
import { TableViewerComponent } from "../_widgets/table-viewer/table-viewer.component";

@Pipe({
  name: "tableGetTime",
  pure: true,
})
export class TableGetTimePipe implements PipeTransform {
  public dayLink;
  private halachicTimes: KosherZmanimObject;
  public speed: number = 5;
  public date: Date;

  constructor(
    private halachicTimesService: HalachicTimesService,
  ) { }

  /**
   * Returns specific halachic time according to week definition.
   * @param {WeekDefinitions} definition - week definition (enum value).
   * @param {KosherZmanimKey} kosherZmanimKey - the key in the KosherZmanim library.
   * @param {DecisiveDayOption} decisiveDay - earliest, latest or specific option (enum value).
   * @param {Days} day - day of week (enum value).
   * @param {Date} date - selected date.
   * @private
   */
  public getHalachicTimeByWeekDefinition(definition: WeekDefinitions, kosherZmanimKey: KosherZmanimKey, decisiveDay: DecisiveDayOption,
    day: Days = Days.Friday, date: Date = new Date()): Date {

    // gets specific halachic time array of the chosen week definition (without date)
    const weekTimes: Date[] = this.halachicTimesService.getCurrentWeekTimes(date)
      .filter((_: KosherZmanimObject, index: number) => weekDefinitions[definition].includes(index))  // filters by week definition
      .sort((day1: KosherZmanimObject, day2: KosherZmanimObject) => { // sorts by day of week
        return new Date(day1[kosherZmanimKey]).getDay() - new Date(day2[kosherZmanimKey]).getDay();
      })
      .map(day => { // tosses the date and leaves the time (accepts the current date)
        const specificTime = new Date(day[kosherZmanimKey]);
        const date = new Date();
        date.setHours(specificTime.getHours(), specificTime.getMinutes(), specificTime.getSeconds());
        return date;
      });

    // default - specific day
    let result: Date = new Date(weekTimes[day]);

    if (decisiveDay === DecisiveDayOption.Earliest) {
      result = new Date(Math.min(...weekTimes.map(day => day.getTime())));
    } else if (decisiveDay === DecisiveDayOption.Latest) {
      result = new Date(Math.max(...weekTimes.map(day => day.getTime())));
    }
    return result;
  }

  transform(
    record: TableRecord,
    widget: Widget,
    times: KosherZmanimOptionDto[],
    date: Date,
    halachicTimesTwoDays: KosherZmanimObjectTwoDays,
    newDate: Date,
    changeTimeKey,
    isAmPm: boolean,
    isUpper: boolean,
    padZero: boolean,
    dayLinkForPipe: number,
    displaySeconds: boolean,
  ): string {
    this.dayLink = dayLinkForPipe;
    const fullDate = this.getTime(record, times, date, halachicTimesTwoDays);
    return this.formatTime(widget, fullDate, isAmPm, isUpper, padZero, record, displaySeconds);
  }

  getTime(
    record: TableRecord,
    times: KosherZmanimOptionDto[],
    date: Date,
    halachicTimesTwoDays: KosherZmanimObjectTwoDays
  ): Date {
    const currentTime = times?.find((time) => time.key === record.key);
    let fullDate: Date;

    if (record.calcOption === CalcOptions.Daily) {
      fullDate = new Date(halachicTimesTwoDays[this.dayLink][currentTime?.option]);
    } else {
      let weeklyDate = this.getWeeklyDate(record, date);
      fullDate = this.getHalachicTimeByWeekDefinition(record.weekDefinition, currentTime.option, record.decisiveDayOption, record.specificDay, weeklyDate);
    }

    fullDate.setMinutes(fullDate.getMinutes() + record.addMinutes);
    return fullDate;
  }

  getWeeklyDate(record: TableRecord, date: Date): Date {
    const currentDate = new Date(date);
    let weeklyDate = new Date(date);

    if (record.dayOfChange !== Days.Sunday && currentDate.getDay() >= record.dayOfChange) {
      weeklyDate = new Date(new Date(currentDate).setDate(currentDate.getDate() + 7));
    }

    return weeklyDate;
  }

  getTimeInTimeZone(date: Date, timeZone: string): Date {
    return new Date(date.toLocaleString('en-US', { timeZone }));
  }

  getFormattedAmPm(amPm: string, isAmPm: boolean, isUpper: boolean): string {
    if (!isAmPm) {
      return '';
    }
    return isUpper ? amPm.toUpperCase() : amPm;
  }

  getFormattedTimePart(part: number, shouldPad: boolean): string {
    if (part === 0) {
      return '00';
    }
    return shouldPad ? part.toString().padStart(2, '0') : part.toString();
  }

  formatTime(
    widget: Widget,
    fullDate: Date,
    isAmPm: boolean,
    isUpper: boolean,
    padZero: boolean,
    record: TableRecord,
    displaySeconds: boolean,
  ): string {
    const timeZone = this.halachicTimesService.options.timeZoneId;
    const formattedDate = this.getTimeInTimeZone(fullDate, timeZone);

    let { hours, amPm } = widget.content.table.format === TimeFormat._12H ? this.get12HourTime(formattedDate) : {
      hours: formattedDate.getHours(),
      amPm: ''
    };

    amPm = this.getFormattedAmPm(amPm, isAmPm, isUpper);
    const formattedHours = this.getFormattedTimePart(hours, padZero);
    const formattedSeconds = displaySeconds ? `:${this.getFormattedTimePart(formattedDate.getSeconds(), true)}` : '';
    const formattedMinutes = TableViewerComponent.roundTime(record.roundMin, record.roundOption, formattedDate.getMinutes());

    const formattedTime = `${formattedHours}:${this.getFormattedTimePart(formattedMinutes, true)}${formattedSeconds}`;
    return widget.content.table.format === TimeFormat._12H ? `${formattedTime} ${amPm}` : formattedTime;
  }

  get12HourTime(date: Date): { hours: number, amPm: string } {
    const hours24 = date.getHours();

    const isPm = hours24 >= 12;
    let hours = isPm ? hours24 - 12 : hours24;
    if (hours === 0) {
      hours = 12;
    }
    const amPm = isPm ? 'pm' : 'am';

    return { hours, amPm };
  }
}
