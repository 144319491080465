import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  /**
   * Filters a string array according to the provided value.
   * @param {string[]} arr - array to filter.
   * @param {string} searchValue - searching value.
   */
  public transform(arr: string[], searchValue: string): string[] {
    if (!searchValue) return arr;

    return arr.filter(value => {
      return value.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    });
  }
}
