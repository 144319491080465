import { Component, AfterViewInit, OnChanges, OnDestroy, HostBinding, Input, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { Subscription, Observable, of } from "rxjs";
import { tap, distinctUntilChanged, shareReplay, switchMap, map } from "rxjs/operators";
import { KosherZmanimOptionDto } from "src/app/_dto";
import { Languages } from "src/app/_enums";
import { ScrollHelper } from "src/app/_helpers";
import { TableRecord, VerticalAlign, HorizontalAlign, TableRecords, KosherZmanimObjectTwoDays, Widget, ScrollDirections } from "src/app/_models";
import { TimeFormatPipe, TableGetTimePipe } from "src/app/_pipes";
import { WidgetService, HalachicTimesService } from "src/app/_services";
import { ResizeObservable } from "src/app/_widgets";
import { WidgetsState, RxWidgetState } from "src/app/store/widget";
import { BaseScrollComponent } from "../base-scroll/base-scroll.component";
import { memoize } from "lodash";

enum WidthClassName {
  Right = 'right-width',
  Left = 'left-width'
}

@Component({
  selector: "app-horizontal-scroll",
  templateUrl: "./horizontal-scroll.component.html",
  styleUrls: ["./horizontal-scroll.component.css"],
})
export class HorizontalScrollComponent extends BaseScrollComponent implements AfterViewInit, OnChanges, OnDestroy {

  render = true;

  forceRerender() {
    this.render = false;
    setTimeout(() => this.render = true);
  }

  resizeEndCallback = () => {
    // Update the container height subject with the new height
    // Scroll the widget if needed
    this.scrollIfShould();
  }

  get widgetState() {
    const basicObservable = this.store.select(state => state.widgets[this.widget.id]);

    if (this.widget.isHidden === false) {
      return basicObservable.pipe(
        tap(() => this.widget.isHidden = true),
        tap(() => setTimeout(() => this.scrollIfShould(), 0)),
        tap(() => this.widget.isHidden = false),
      );
    } else {
      return basicObservable;
    }
  }


  private containerResizeObserver = Subscription.EMPTY;
  private resizeStopSubscription: Subscription;
  private contentChangedSubscription: Subscription;



  records$: Observable<{ record: TableRecord; timeContent: string; }[]> =
    this.store.select(state => state.widgets[this.widget.id]).pipe(
      map(widgetState =>
        this.widget.content.table.records.map(record =>
          ({ record, timeContent: this.recordTimeContent(record, widgetState) })
        )
      ),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      shareReplay(1),
      switchMap(records => {
        if (!this.widget.isHidden) {
          return of(records).pipe(
            tap(() => this.widget.isHidden = true),
            tap(() => setTimeout(() => this.scrollIfShould(), 0)),
            tap(() => this.widget.isHidden = false)
          );
        } else {
          return of(records);
        }
      })
    );


  @HostBinding('style.--container-height')
  get containerHeightStyle() {
    return this.widget?.size?.heightPx + 'px';
  }

  lastScrollingTextWidthStyle: number;
  @HostBinding('style.--scrolling-text-width')
  get scrollingTextWidthStyle() {
    const width = (this.textWrapper?.nativeElement?.firstElementChild as any).offsetWidth;
    if (width === undefined) {
      return this.lastScrollingTextWidthStyle;
    } else {
      this.lastScrollingTextWidthStyle = width;
      return width;
    }
  }

  @HostBinding('style.--vertical-align')
  get verticalAlignStyle() {
    switch (this.widget.font.verticalAlign) {
      case VerticalAlign.Top:
        return 'flex-start';
      case VerticalAlign.Center:
        return 'center';
      case VerticalAlign.Bottom:
        return 'flex-end';
    }
  }

  public startResizeObserver(containerElement: HTMLElement): void {
    // Create a ResizeObservable instance that listens for resize events on the container element
    this.containerResizeObserver =
      new ResizeObservable(containerElement)
        // Extract the first entry from the resize event array (since we are only observing one element)
        .pipe(
          map(entries => entries[0]),
        )
        // Subscribe to the resize events, and update the lastResizeEntry$ subject with the latest event
        .subscribe();

    // Subscribe to the resizeStopSource subject, and call the resizeEndCallback function with the last resize entry when the subject emits a value
    this.resizeStopSubscription = this.widget.resizeStopSource.subscribe(resizeEvent => {
      this.resizeEndCallback();
    });

    this.widget.contentChangedSource.subscribe(async () => {
      // There is a bug that occurs when the following steps are taken:
      // 1. Add a record to the table with a date set to a different day
      // 2. Observe that the table initially displays the record in the current day
      // 3. Notice that the table begins to scroll, even though it shouldn't
      // 4. The record is then removed from the table, but the table continues to scroll
      //
      // To fix this issue, we wait for the table to update before checking if it should scroll.
      // This issue is not reproducible when the table is first loaded, so we only wait for the table to update when the content changes.
      //
      // TODO: Fix bug where table scrolls unexpectedly when a record is added with a date set to a different day.
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.resizeEndCallback();
    });
  }
  public DEFAULT_HIGHLIGHT_COLOUR = '#ff8000';

  private _text: string[];
  @Input()
  get text(): string[] {
    return this._text;
  }

  set text(value: string[]) {
    this._text = value;
  }

  @Input() htmlContent: string;
  @Input() multiHtmlContent: string[];

  public get horizontalAlignLeft(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Left;
  }

  public get horizontalAlignRight(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Right;
  }

  public get verticalAlignTop(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Top;
  }

  public get verticalAlignBottom(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Bottom;
  }

  public align = HorizontalAlign;

  public widthClassName = WidthClassName;
  public languages = Languages;

  private _records: TableRecords;
  @Input()
  get records(): TableRecords {
    return this._records;
  }

  set records(records: TableRecords) {
    this._records = records;
  }

  @Input() halachicTimesTwoDays: KosherZmanimObjectTwoDays;
  @Input() dayLink: number;
  @Input() times: KosherZmanimOptionDto[];
  @Input() date: Date;


  async ngOnChanges() {
    let index = 0;
    while (!this.hiddenContainer && !this.singleRecord) {
      await new Promise(resolve => setTimeout(resolve, 100));
      console.log(index++);
    }
    requestAnimationFrame(async () => {
      this.scrollIfShould();
    });
  }

  private _shouldScroll: "scroll" | "autoEffect" | "doNotScroll";

  private async handleShouldScroll(): Promise<void> {
    while (!this.scrollContainer) {
      console.log("waiting for hidden container");
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.scrollIfShould();
  }

  @Input() set shouldScroll(type: "scroll" | "autoEffect" | "doNotScroll") {
    this._shouldScroll = type;
    this.handleShouldScroll();
  }

  get shouldScroll(): "scroll" | "autoEffect" | "doNotScroll" {
    return this._shouldScroll;
  }

  @Input() widget: Widget;
  @Input() scrollDirection: ScrollDirections;
  @Input() separator: string;
  @Input() speed: number = 20;

  @ViewChild("scrollContainer") scrollContainer: ElementRef<HTMLDivElement>;
  @ViewChild("hiddenContainerWithoutSeperator") hiddenContainer: ElementRef<HTMLDivElement>;
  @ViewChild("hiddenContainerWithSeperator") hiddenContainerWithSeperator: ElementRef<HTMLDivElement>;
  @ViewChild("singleRecord") singleRecord: ElementRef<HTMLDivElement>;
  @ViewChild("textWrapper") textWrapper: ElementRef<HTMLDivElement>;
  @ViewChild("scrollingText") scrollingText: ElementRef<HTMLDivElement>;
  @ViewChild("t1") t1: ElementRef<HTMLDivElement>;
  @ViewChild("t2") t2: ElementRef<HTMLDivElement>;

  constructor(
    private elementRef: ElementRef,
    public sanitizer: DomSanitizer,
    private widgetService: WidgetService,
    public halachicTimesService: HalachicTimesService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<{ widgets: WidgetsState }>,
  ) {
    super(renderer);
  }

  private scrollIfShould = () => {
    if (this._shouldScroll === "autoEffect") {
      if (
        (this.textWrapper?.nativeElement.children[0] as HTMLElement)
          .offsetWidth > this.hiddenContainer?.nativeElement?.offsetWidth
      ) {
        this.stopScrolling();
        this.startScrolling(this.text, this.records, this.htmlContent, this.multiHtmlContent, this.shouldScroll, this.hiddenContainer.nativeElement, this.elementRef.nativeElement, this.scrollContainer.nativeElement, this.getTextWrapper.bind(this), this.widget, this.animationDirection, this.speed, this.updateAnimation, this.renderer);
      } else {
        this.stopScrolling();
      }
    } else if (this._shouldScroll === "scroll") {
      this.stopScrolling();
      this.startScrolling(this.text, this.records, this.htmlContent, this.multiHtmlContent, this.shouldScroll, this.hiddenContainer.nativeElement, this.elementRef.nativeElement, this.scrollContainer.nativeElement, this.getTextWrapper.bind(this), this.widget, this.animationDirection, this.speed, this.updateAnimation, this.renderer);
    } else {
      this.stopScrolling();
    }
  }

  async ngAfterViewInit() {
    if (this.shouldScroll === "scroll") {
      this.startScrolling(this.text, this.records, this.htmlContent, this.multiHtmlContent, this.shouldScroll, this.hiddenContainer.nativeElement, this.elementRef.nativeElement, this.scrollContainer.nativeElement, this.getTextWrapper.bind(this), this.widget, this.animationDirection, this.speed, this.updateAnimation, this.renderer);
    }

    if (!this.hiddenContainer && !this.singleRecord) return;

    // Observe the scroll element for changes in size
    this.startResizeObserver(this.elementRef.nativeElement);
    this.contentChangedSubscription = this.widget.contentChangedSource.subscribe(async () => {
      // There is a bug that occurs when the following steps are taken:
      // 1. Add a record to the table with a date set to a different day
      // 2. Observe that the table initially displays the record in the current day
      // 3. Notice that the table begins to scroll, even though it shouldn't
      // 4. The record is then removed from the table, but the table continues to scroll
      //
      // To fix this issue, we wait for the table to update before checking if it should scroll.
      // This issue is not reproducible when the table is first loaded, so we only wait for the table to update when the content changes.
      //
      // TODO: Fix bug where table scrolls unexpectedly when a record is added with a date set to a different day.
      await new Promise(resolve => setTimeout(resolve, 1000));
      this.scrollIfShould();
    });
    this.listenToAutoEffect();
    let index = 0;
    while (this.textWrapper?.nativeElement.scrollWidth === 0 && this.textWrapper?.nativeElement.offsetWidth === 0) {
      await new Promise(resolve => setTimeout(resolve, 100));
      console.log(index++);
    }
    this.store.select(state => state.widgets[this.widget.id])
      .pipe(
        switchMap(state => {
          if (!this.widget.isHidden) {
            return of(state).pipe(
              tap((state) => {
                if (this.separator !== state.separator ||
                  (this.lastWidgetState?.timeFormat &&
                    (this.lastWidgetState?.timeFormat !== state.timeFormat))) {
                  this.widget.enabled = false;
                  setTimeout(() => this.widget.enabled = true, 0);
                }
              }),
              tap((state) => { this.lastWidgetState = state }),
              tap(() => this.widget.isHidden = true),
              tap(() => setTimeout(() => this.scrollIfShould(), 1000)),
              tap(() => this.widget.isHidden = false),
            );
          } else {
            return of(state);
          }
        })
      ).subscribe();

    this.scrollIfShould();
  }

  lastWidgetState: RxWidgetState;

  private listenToAutoEffect(): void {
    this.widgetService.effectUpdated$.subscribe(async ({ widgetId }) => {
      if (widgetId !== this.widget.id) return;
      // wait for next frame
      await new Promise((resolve) => requestAnimationFrame(resolve));
      this.speed = ScrollHelper.changeScrollSpeed(this.hiddenContainer.nativeElement, this.widget);
      this.scrollIfShould();
    });
  }

  startScrolling = async (
    text: string[],
    records: TableRecords,
    htmlContent: string,
    multiHtmlContent: string[],
    shouldScroll: string,
    hiddenContainer: HTMLDivElement,
    elementRef: HTMLDivElement,
    scrollContainer: HTMLDivElement,
    textWrapper: () => HTMLDivElement,
    widget: any,
    animationDirection: string,
    speed: number,
    updateAnimation: Function,
    renderer2: Renderer2
  ): Promise<void> => {
    if (!this.hasContent(text, records, htmlContent, multiHtmlContent)) {
      this.clearScrollContainer(scrollContainer);
      return;
    }
    if (shouldScroll === "doNotScroll") return;
    await this.waitForMultiHtmlContentScroll(hiddenContainer);
    while (true) {
      if (await this.waitForHiddenContainerWidth(hiddenContainer)) continue;
      if (this.shouldStopScrolling(shouldScroll, hiddenContainer)) return;
      this.cloneTextUntilContainerFilled(textWrapper, hiddenContainer);
      if (this.shouldUpdateScrollContainers(scrollContainer)) {
        this.clearScrollContainer(scrollContainer);
      }
      if (this.isTextWrapperWiderThanContainer(textWrapper, hiddenContainer, elementRef, multiHtmlContent)) {
        this.duplicateScrollContainers(scrollContainer, textWrapper);
        this.updateAnimationProperties(scrollContainer, hiddenContainer, widget, animationDirection, speed, updateAnimation, renderer2);
        return;
      }
    }
  };

  hasContent = (text: string[], records: TableRecords, htmlContent: string, multiHtmlContent: string[]): boolean =>
    !!(text?.length || records || htmlContent || multiHtmlContent);

  shouldStopScrolling = (shouldScroll: string, hiddenContainer: HTMLDivElement): boolean =>
    shouldScroll === 'autoEffect' && hiddenContainer.offsetWidth > hiddenContainer.children[0][this.multiHtmlContent ? 'offsetWidth' : 'scrollWidth'];

  shouldUpdateScrollContainers = (scrollContainer: HTMLDivElement): boolean =>
    scrollContainer.children.length === 3 && scrollContainer.children[0].innerHTML !== scrollContainer.children[1].innerHTML;

  isTextWrapperWiderThanContainer = (textWrapper: () => HTMLDivElement, hiddenContainer: HTMLDivElement | undefined, elementRef: HTMLDivElement, multiHtmlContent: string[]): boolean =>
    textWrapper()?.offsetWidth >= (multiHtmlContent ? hiddenContainer?.offsetWidth : elementRef.offsetWidth);

  waitForMultiHtmlContentScroll = async (hiddenContainer: HTMLDivElement | undefined): Promise<void> => {
    while (hiddenContainer?.classList.contains("multi-html-content-scroll")) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  };

  waitForHiddenContainerWidth = async (hiddenContainer: HTMLDivElement | undefined): Promise<boolean> => {
    if (hiddenContainer?.offsetWidth === 0) {
      await new Promise((resolve) => setTimeout(resolve, 100));
      return true;
    }
    return false;
  };

  cloneTextUntilContainerFilled = (textWrapper: () => HTMLDivElement, hiddenContainer: HTMLDivElement): void => {
    const containerWidth = hiddenContainer?.offsetWidth || 0;
    const textWrapperWidth = textWrapper()?.offsetWidth;
    const numClones = Math.ceil(containerWidth / textWrapperWidth) - 1;
    for (let i = 0; i < numClones; i++) {
      textWrapper()?.insertBefore(textWrapper()?.children[0].cloneNode(true), textWrapper()?.children[0]);
    }
  };

  clearScrollContainer = (scrollContainer: HTMLDivElement): void => {
    while (scrollContainer.children.length > 1) {
      scrollContainer.removeChild(scrollContainer.children[1]);
    }
    while (scrollContainer.children[0].children.length > 1) {
      scrollContainer.children[0].removeChild(scrollContainer.children[0].children[0]);
    }
  };

  duplicateScrollContainers = (scrollContainer: HTMLDivElement, textWrapper: () => HTMLDivElement): void => {
    while (scrollContainer.children.length < 3) {
      scrollContainer.appendChild(textWrapper()?.cloneNode(true));
    }
  };

  updateAnimationProperties = (scrollContainer: HTMLDivElement, hiddenContainer: HTMLDivElement, widget: any, animationDirection: string, speed: number, updateAnimation: Function, renderer2: Renderer2): void => {
    const newSpeed = ScrollHelper.changeScrollSpeed(hiddenContainer, widget);
    this.speed = newSpeed;
    // this.animationLoop(newSpeed);
    updateAnimation(scrollContainer, animationDirection, newSpeed, renderer2);
  };

  animationLoop = (speed: number): void => {
    // debugger
    // this.renderer.setStyle(this.t1.nativeElement, 'animation', `scroll-rtl ${speed}s linear infinite`);
    // this.renderer.setStyle(this.t2.nativeElement, 'animation', `scroll-rtl ${speed}s linear infinite`);
    // this.renderer.setStyle(this.t2.nativeElement, 'transform', `translateX(-100%)`);
    // setTimeout(() => {
    //   // add animation infinte loop to t2 with scroll-rtl-new keyframe
    //   this.renderer.setStyle(this.t1.nativeElement, 'animation', `scroll-rtl-new ${speed}s linear`);
    // }, 0);

    // setTimeout(() => {
    //   // add animation infinte loop to t1 with scroll-rtl-new keyframe
    //   this.renderer.setStyle(this.t2.nativeElement, 'animation', `scroll-rtl-new ${speed}s linear`);
    // }, speed * 1000);
  }

  private getTextWrapper(): HTMLDivElement | undefined {
    return this.textWrapper?.nativeElement;
  }

  async stopScrolling() {
    this.scrollContainer && super.stopScrolling(this.scrollContainer, this.text
      ? this.hiddenContainerWithSeperator.nativeElement.innerHTML
      : this.singleRecord.nativeElement.innerHTML);
  };

  get animationDirection(): "rtl" | "ltr" {
    return this.scrollDirection === ScrollDirections.Right ? "ltr" : "rtl";
  }

  /**
   * Handles the end of an animation for the text wrapper element.
   *
   * @param element - The element whose children's animations should be updated.
   */
  onAnimationEnd(element: ElementRef<HTMLElement> | HTMLElement, scrollBehavior: "scroll" | "autoEffect" | "doNotScroll") {
    if (element instanceof ElementRef) {
      element = element.nativeElement;
    }
    if (scrollBehavior !== "doNotScroll") {
      const visibleChildren = Array.from(element.children).slice(0, 3) as HTMLElement[];
      if (visibleChildren.length >= 3) {
        const [firstChild, secondChild, thirdChild] = visibleChildren;
        const direction = this.animationDirection;
        const speed = this.speed;
        firstChild.style.animation = this.getAnimationStyle(direction, this.getNextAnimationState(this.getCurrentAnimationState(firstChild)), speed);
        secondChild.style.animation = this.getAnimationStyle(direction, this.getNextAnimationState(this.getCurrentAnimationState(secondChild)), speed);
        thirdChild.style.animation = this.getAnimationStyle(direction, this.getNextAnimationState(this.getCurrentAnimationState(thirdChild)), speed);
      }
    }
  }

  getAnimationStyle = (direction: "rtl" | "ltr", state: string, duration: number): string => {
    return `scroll-${direction}-${state} ${duration}s linear`;
  };

  getCurrentAnimationState(child: HTMLElement): string {
    return child.style.animation.split('-')[2];
  }

  getNextAnimationState = memoize((currentAnimationState: string): string => {
    const animationStateMap = {
      start: 'middle',
      middle: 'end',
      end: 'start'
    };
    return animationStateMap[currentAnimationState];
  });

  recordTimeContent(record: TableRecord, widgetState: RxWidgetState): string {
    return !record.titleOnly
      ? record.isFixed
        ? new TimeFormatPipe().transform(record.fixedTime, widgetState.timeFormatTableWidget, this.widget.content.table.isAmPm, this.widget.content.table.isUpper, this.widget.content.table.padZero, this.widget.content.table.displaySeconds)
        : new TableGetTimePipe(this.halachicTimesService).transform(record, this.widget, this.times, this.date, this.halachicTimesService.halachicTimesTwoDays, this.date, this.widget.changeTimeKey, this.widget.content.table.isAmPm, this.widget.content.table.isUpper, this.widget.content.table.padZero, this.dayLink, widgetState.displaySeconds)
      : '';
  }

  public ngOnDestroy(): void {
    this.resizeStopSubscription.unsubscribe();
    this.containerResizeObserver.unsubscribe();
    this.contentChangedSubscription.unsubscribe();
  }
}
