export enum LocalStorage {
  Username = 'username',
  Role = 'role',
  PreviewOnly = 'previewOnly',
  Token = 'token',
  UniqueDeviceId = 'uniqueDeviceId',
  FreezeDate = 'freezeDate',
  Date = 'date',
  Browser = 'browser',
  OS = 'OS',
  Mobile = 'mobile',
  Address = 'address',
  Ip = 'ip',
  Language = 'language'
}
