import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../../../_enums/languages';
import { Direction, TranslateHelper } from '../../../../_helpers/translate.helper';

interface Shortcut {
  keyCombination: string;
  purposeKey: string;
}

const SHORTCUTS: Shortcut[] = [
  { keyCombination: 'Esc', purposeKey: 'editMode' },
  { keyCombination: 'Tab', purposeKey: 'settings' },
  { keyCombination: 'Ctrl + S', purposeKey: 'save' },
  { keyCombination: 'Ctrl + Shift + D', purposeKey: 'datePicker' },
  { keyCombination: 'Del', purposeKey: 'delete' },
  { keyCombination: 'Ctrl + D', purposeKey: 'duplicate' }
];

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.css']
})
export class ShortcutsComponent implements OnInit {
  public displayedColumns: string[] = ['keyCombination', 'purpose'];
  public dataSource: Shortcut[] = SHORTCUTS;
  public direction: Direction = 'ltr';

  constructor(private translate: TranslateService, private translateHelper: TranslateHelper) {
  }

  public ngOnInit(): void {
    this.setTranslation();
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
