import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { NgbCalendar, NgbCalendarHebrew, NgbDatepickerI18n, NgbDatepickerI18nHebrew, NgbDatepicker, NgbDateStruct, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Languages } from "../_enums";
import { DateHelper } from "../_helpers";
import { DateService, HalachicTimesService } from "../_services";

@Component({
  selector: 'app-date-selector[selectedDate][mode]',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css'],
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarHebrew },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nHebrew }
  ],
})
export class DateSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('datepicker', { static: true }) datepicker: NgbDatepicker;
  @Input() selectedDate: Date;
  @Input() mode: 'app-wide' | 'component-specific';
  @Output() selectedDateChange = new EventEmitter<Date>();

  public formattedDate: string;
  public hebrewDate: NgbDateStruct;
  public direction = 'ltr';

  private jewishCalendar = new NgbCalendarHebrew();
  private dateSubscription: Subscription;
  private pickedDate = new Date();
  public temp: number = 1
  constructor(
    private translate: TranslateService,
    private dateService: DateService,
    private halachicTimesService: HalachicTimesService,
    private calendar: NgbCalendar,
    public i18n: NgbDatepickerI18n
  ) {
    this.dayTemplateData = this.dayTemplateData.bind(this);
  }

  public ngOnInit(): void {
    this.temp = 1
    this.direction = this.translate.currentLang === Languages.Hebrew ? 'rtl' : 'ltr';
    this.formattedDate = DateHelper.getDateInISO8601Format(new Date(this.selectedDate));
    this.setHebrewDate(new Date(this.selectedDate));
  }

  public ngAfterViewInit(): void {
    this.dateSubscription = this.datepicker.dateSelect.subscribe((hebrewDate: NgbDate) => {
      const { year, month, day } = this.jewishCalendar.toGregorian(hebrewDate);
      const date = new Date(year, month - 1, day, this.pickedDate.getHours(), this.pickedDate.getMinutes(), this.pickedDate.getSeconds(),
        this.pickedDate.getMilliseconds());

      this.formattedDate = DateHelper.getDateInISO8601Format(date);
      // if it's app-wide, we need to update the date in the all app (using the service) if not, we need to emit the date to the parent component and let it handle it.
      this.mode === 'app-wide' ? this.dateService.setDate(date) : this.selectedDateChange.emit(date);
    });
  }

  public ngOnDestroy(): void {
    this.dateSubscription.unsubscribe();
  }

  public dayTemplateData(date: NgbDate): { gregorian: NgbDate } {
    return {
      gregorian: (this.calendar as NgbCalendarHebrew).toGregorian(date)
    };
  }

  /**
   * Sets the chosen date from the input field.
   * @param {string} datetime - date string.
   */
  public setDate(datetime: string): void {
    this.pickedDate = new Date(datetime);
    this.formattedDate = DateHelper.getDateInISO8601Format(this.pickedDate);

    this.setHebrewDate(this.pickedDate);

    this.dateService.setDate(this.pickedDate);
  }

  /**
   * Returns the date picker to the current date.
   */
  public selectToday(): void {
    const today = new Date();
    this.formattedDate = DateHelper.getDateInISO8601Format(today);
    this.hebrewDate = this.calendar.getToday();
    this.datepicker.navigateTo(this.hebrewDate);

    this.dateService.setDate(today);
  }

  /**
   * Returns the date picker to the All Year date.
   */
  public selectToAllYear(): void {
    this.callfunction();
    let temp = 1
    const repeater = setInterval(() => {
      if (temp < 365) {
        const today = new Date();
        today.setDate(today.getDate() + temp);
        this.formattedDate = DateHelper.getDateInISO8601Format(today);
        this.dateService.setDate(today);
        temp++
      } else {
        clearInterval(repeater);
      }
    }, 300);
  }
  callfunction() {
    const today = new Date();
    this.temp++
    today.setDate(today.getDate());
    this.formattedDate = DateHelper.getDateInISO8601Format(today);
  }

  /**
   * Sets the Hebrew date according to the Gregorian one.
   * @param {Date} date - Gregorian date.
   * @private
   */
  private setHebrewDate(date: Date): void {
    const ngbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    this.hebrewDate = this.jewishCalendar.fromGregorian(ngbDate);
    this.datepicker.navigateTo(this.hebrewDate);
  }
}
