import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Holidays } from './holiday';

export interface Scheduler {
  daysOfWeek?: boolean[];
  dateRange?: DateRange;
  hebrewRange?: HebrewRange;
  timeRange?: DateRange;
  ifEmpty?: boolean;
  dynamicTimeRange?: DynamicTimeRange;
  holidays?: Holidays;
  blink?: Blink;
}

interface HebrewRange {
  start: {
    date: NgbDate,
    time: string
  };
  end: {
    date: NgbDate,
    time: string
  };
}

export interface DateRange {
  start: string;
  end: string;
}

export interface DynamicTimeRange {
  time?: string,
  addition?: number,
  duration?: string;
}

export interface Blink {
  enabled: boolean;
  onTime: number;
  offTime: number;
  unitsCount: number;
  activeUnit: number;
  timeUnit: TimeUnits;
}

export enum TimeUnits {
  Seconds = 1,
  Minutes = 60,
  Hours = 3600,
  Days = 86_400,
  Weeks = 604_800
}
