import { Component, Input} from '@angular/core';
import { UserDto } from '../../../../../_dto/user-dto';

@Component({
  selector: 'app-sefirat-haomer',
  templateUrl: './sefirat-haomer.component.html',
  styleUrls: ['./sefirat-haomer.component.css']
})
export class SefiratHaomerComponent {
  @Input() user: UserDto;
}
