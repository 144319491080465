export class DateHelper {

  /**
   * Returns a date in ISO8601 format.
   * @param {Date} date - a date to format.
   */
  public static getDateInISO8601Format(date: Date): string {

    return date.getFullYear() + '-' + this.padZero(date, 'M') + '-' + this.padZero(date, 'd') + 'T' +
      this.padZero(date, 'h') + ':' + this.padZero(date, 'm') + ':' + this.padZero(date, 's');
  }

  /**
   * Calculates a specified part of date and pads it with zeroes.
   * @param {string | Date} date - a date to format.
   * @param {string} key - a key the specifies which part of a date to format.
   */
  public static padZero(date: string | Date, key: string): string {
    let time: number;
    switch (key) {
      case 'M':
        time = new Date(date).getMonth() + 1;
        break;
      case 'd':
        time = new Date(date).getDate();
        break;
      case 'h':
        time = new Date(date).getHours();
        break;
      case 'm':
        time = new Date(date).getMinutes();
        break;
      case 's':
        time = new Date(date).getSeconds();
        break;
    }
    return time.toString().padStart(2, '0');
  }
}
