import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Observable, of, timer } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import { LocationDto } from '../../../../_dto/location-dto';
import { PlacesAutocompleteResponse } from '../../../../_dto/location-prediction-dto';
import { UserDto } from '../../../../_dto/user-dto';
import { Languages } from '../../../../_enums/languages';
import { Minhags } from '../../../../_enums/minhags';
import { TranslateHelper } from '../../../../_helpers/translate.helper';
import { AccountService } from '../../../../_services/account.service';
import { SnackbarService } from '../../../../_services/snackbar.service';
import { ValuesService } from '../../../../_services/values.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  public user: UserDto;
  public updateForm: UntypedFormGroup;
  public minhags$: Observable<string[]>;
  public candleLightTimes = [15, 18, 20, 22, 24, 30, 40];
  public direction = 'ltr';
  public fetchedPlaces: any[] = [];
  public locationSelected = true;

  public apiOptions: Options = {
    types: ['geocode'],
    fields: ['place_id'],
    bounds: undefined,
    componentRestrictions: undefined,
    origin: undefined,
    strictBounds: false
  };

  private selectedPlaceId: string;

  constructor(
    private accountService: AccountService,
    private valuesService: ValuesService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UpdateProfileComponent>,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public get username(): AbstractControl {
    return this.updateForm?.get('username');
  }

  public get email(): AbstractControl {
    return this.updateForm?.get('email');
  }

  public get location(): AbstractControl {
    return this.updateForm?.get('location');
  }

  public get minhag(): AbstractControl {
    return this.updateForm?.get('minhag');
  }

  public get candleLight(): AbstractControl {
    return this.updateForm?.get('candleLight');
  }

  public ngOnInit(): void {
    this.setTranslation();

    this.getCurrentUserFromServer();
    this.getMinhagsFromServerAsObservable();
    this.initForm();
  }

  public update(): void {
    if (!this.locationSelected) {
      this.snackbar.error(this.translate.instant('snackbar.pickLocation'));
      return;
    }

    const placeId = !!this.selectedPlaceId ? this.selectedPlaceId : this.user.location.placeId;
    const location: LocationDto = { placeId, timestamp: Math.floor(Date.now() / 1000).toString() };

    const userDto: UserDto = {
      ...this.user,
      location,
      minhag: this.minhag.value,
      candleLight: this.candleLight.value
    };


    this.accountService.updateProfile(userDto).subscribe(
      () => {
        this.snackbar.success(this.translate.instant('snackbar.profileUpdated'), this.translate.instant('buttons.ok'));
      },
      error => {
        console.log(error);
        this.snackbar.error(error.error.Message, this.translate.instant('buttons.ok'));
      }
    );

    this.dialogRef.close(true);
  }

  /**
   * Sets placeId.
   * @param {Address} event - address event.
   */
  public handleAddressChange(event: Address): void {
    this.locationSelected = true;
    this.selectedPlaceId = event.place_id;
  }

  private initForm(): void {
    this.updateForm = this.formBuilder.group({
      username: { value: this.user?.username, disabled: true },
      email: { value: this.user?.email, disabled: true },
      location: this.user?.location.description,
      minhag: [this.user?.minhag || Minhags.Ashkenaz, [Validators.required]],
      candleLight: [this.user?.candleLight || 18, [Validators.required]]
    });
  }

  private validateEmailNotTaken(email: string): AsyncValidatorFn {
    return control => {
      return timer(500).pipe(
        switchMap(() => {
          if (!control.value || control.value.toLowerCase() === email.toLowerCase()) {
            return of(null);
          }
          return this.accountService.checkEmailExists(control.value).pipe(
            map(exists => exists ? { emailExists: true } : null)
          );
        })
      );
    };
  }

  private validateUsernameNotTaken(username: string): AsyncValidatorFn {
    return control => {
      return timer(500).pipe(
        switchMap(() => {
          if (!control.value || control.value.toLowerCase() === username.toLowerCase()) {
            return of(null);
          }
          return this.accountService.checkUsernameExists(control.value).pipe(
            map(exists => exists ? { usernameExists: true } : null)
          );
        })
      );
    };
  }

  private getCurrentUserFromServer(): void {
    this.accountService.getUser().subscribe((user: UserDto) => {
      this.user = user;
      console.log('CURRENT USER', user);
      this.updateForm?.setValue({
        username: user.username,
        email: user.email,
        location: user.location.description,
        minhag: user.minhag || Minhags.Ashkenaz,
        candleLight: user.candleLight || 18
      });

      this.username?.setValidators([Validators.required, Validators.minLength(3)]);
      this.username?.setAsyncValidators([this.validateUsernameNotTaken(this.user.username)]);
      this.email?.setValidators([Validators.required, Validators.email]);
      this.email?.setAsyncValidators([this.validateEmailNotTaken(this.user.email)]);
    });
  }

  private getMinhagsFromServerAsObservable(): void {
    this.minhags$ = this.valuesService.getMinhags();
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
