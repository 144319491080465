<div class="date-selector-container position-relative"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane">

  <button *ngIf="mode === 'app-wide'" [title]="'buttons.close' | translate" class="close-btn" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>

  <div *ngIf="mode === 'app-wide'" class="date-selector mt-3">
    <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'date.gregorian' | translate }}</mat-label>
      <input #date
             (change)="setDate(date.value)"
             [value]="formattedDate"
             matInput
             step="1"
             type="datetime-local">
    </mat-form-field>
  </div>

  <div class="hebrew container-sm d-flex flex-column justify-content-between align-items-center py-3">
    <div *ngIf="mode === 'app-wide'" class="settings-title mb-1">{{ 'date.hebrew' | translate }}</div>

    <ngb-datepicker #datepicker
                    [(ngModel)]="hebrewDate"
                    [dayTemplateData]="dayTemplateData"
                    [dayTemplate]="dayTemplate"
                    [displayMonths]="1"
                    [firstDayOfWeek]="7"
                    class="hebrew rtl">
    </ngb-datepicker>

    <ng-template #dayTemplate let-date let-data="data" let-selected="selected" let-currentMonth="currentMonth">
      <div class="hebrew-day" [class.outside]="date.month !== currentMonth" [class.selected]="selected">
        <div class="gregorian-num">{{ data.gregorian.day + '/' + (data.gregorian.month) }}</div>
        <div class="hebrew-num">{{ i18n.getDayNumerals(date) | formatHebrewDate }}</div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="mode === 'app-wide'" mat-dialog-actions class="mat-dialog-actions my-3" [style.direction]="direction">
    <button [mat-dialog-close]="formattedDate" color="primary" mat-raised-button>{{ 'date.buttons.ok' | translate }}</button>
    <button (click)="selectToday()" color="warn" mat-stroked-button>{{ 'date.buttons.today' | translate }}</button>
    <button color="accent" mat-dialog-close mat-raised-button>{{ 'date.buttons.cancel' | translate }}</button>
  </div>

</div>
