import { Direction } from "@angular/cdk/bidi";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget, MediaItems, MediaItem, Screen } from "src/app/_models";
import { SnackbarService, MediaService, ContentApiService } from "src/app/_services";

@Component({
  selector: 'app-media-content',
  templateUrl: './media-content.component.html',
  styleUrls: ['./media-content.component.css']
})
export class MediaContentComponent implements OnInit, AfterViewInit {
  @Input() widget: Widget;
  @Input() screen: Screen;
  @ViewChild('recordsList') recordsList: ElementRef<HTMLElement>;

  public direction: Direction = 'ltr';

  constructor(
    private snackbar: SnackbarService,
    private mediaService: MediaService,
    private contentApiService: ContentApiService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnInit(): void {
    this.setTranslation();
  }

  public ngAfterViewInit() {
    this.removeFading();
  }

  /**
   * Moves an item one index in an array to another.
   * @param {CdkDragDrop<MediaItems>} event - CdkDragDrop event.
   */
  public drop(event: CdkDragDrop<MediaItems>): void {
    moveItemInArray(this.widget.content.media.items, event.previousIndex, event.currentIndex);
  }

  /**
   * Removes media record from the list and from the server.
   * @param {MediaItem} item - media item to remove.
   * @param {HTMLDivElement} recordElement - record HTML element to remove.
   */
  public removeRecord(item: MediaItem, recordElement: HTMLDivElement): void {
    recordElement.classList.add('fade-out-bottom');
    setTimeout(() => {
      const itemIndex = this.widget.content.media.items.indexOf(item);
      this.widget.content.media.items.splice(itemIndex, 1);
    }, 700);
  }

  /**
   * Marks screen "dirty" for purpose of page leaving warning.
   */
  public markScreenDirty(): void {
    this.screen.dirty = true;
  }

  /**
   * Removes fading animation class.
   * @private
   */
  private removeFading(): void {
    this.recordsList.nativeElement.childNodes.forEach((node: HTMLElement) => {
      if (node.tagName === 'DIV') {
        node.classList.remove('fade-in-top');
      }
    });
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
