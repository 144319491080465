<div class="settings container-sm d-flex justify-content-between align-items-center mt-3 mx-2 pb-3"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <div>
    <div class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.clock.size' | translate }}</div>
  </div>
  <mat-slider [(value)]="widget.content.clock"
              (change)="markScreenDirty()"
              max="5"
              min="0.1"
              step="0.1"
              thumbLabel>
  </mat-slider>
</div>

<div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span>{{ 'dashboard.widgetProperties.specific.clock.colour' | translate }}</span>
  <div [(colorPicker)]="widget.font.color"
       (colorPickerChange)="markScreenDirty()"
       [style.background]="widget.font.color || '#000000'"
       [style.right]="direction === 'rtl' ? '' : '1rem'"
       [style.left]="direction === 'rtl' ? '1rem' : ''"
       [cpOKButton]="true"
       [cpOKButtonText]="'buttons.ok' | translate"
       cpOKButtonClass="btn btn-outline-primary"
       [cpCancelButton]="true"
       cpCancelButtonClass="btn btn-outline-danger"
       cpCancelButtonText="Reset"
       class="colour-picker">
  </div>
</div>
