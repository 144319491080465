export interface Font {
  family: string;
  color: string;
  size: number;
  style: FontStyle;
  lineHeight: number; // in percent
  horizontalAlign: HorizontalAlign;
  verticalAlign: VerticalAlign;
  highlightColor: string;
}

export enum FontStyle {
  Normal,
  Bold,
  Italic,
  Underline
}

export enum HorizontalAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify'
}

export enum VerticalAlign {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom'
}
