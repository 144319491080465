import { jwtDecode } from "jwt-decode";
import { RoleTypes } from "../_models/identity/RoleTypes";
import { PermissionTypes } from "../_models/identity/PermissionTypes";
import { LocalStorage } from "../_enums/local-storage";

export interface JWTPayload {
    attributes: PermissionTypes[];
    Role: RoleTypes;
    ScreenId: number;
}

export let decodedToken: JWTPayload;

export function getJWTPayload(): JWTPayload {
    if (!decodedToken) {
        const token = localStorage.getItem(LocalStorage.Token);
        decodedToken = jwtDecode<JWTPayload>(token);
    }
    return decodedToken;
}


export function hasPermission(permission: PermissionTypes): boolean {
    const token = localStorage.getItem(LocalStorage.Token);
    if (!token) {
        return false;
    }

    try {
        const decoded: JWTPayload = jwtDecode<JWTPayload>(token);
        
        return decoded.attributes?.includes(permission);
    } catch (error) {
        console.error('Error decoding token:', error);
        return false;
    }
}

export function getPermissionsFromToken(): PermissionTypes[] {
    const token = localStorage.getItem(LocalStorage.Token);
    if (!token) {
        return [];
    }

    try {
        const decoded: JWTPayload = jwtDecode<JWTPayload>(token);
        return decoded.attributes || [];
    } catch (error) {
        console.error('Error decoding token:', error);
        return [];
    }
}