<div #window class="container dialog-box position-relative" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
  (cdkDragEnded)="calculateWindowPosition()">
  <button [title]="'buttons.close' | translate" class="close-btn" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card>
    <mat-card-header class="edit-record-header" [style.direction]="direction">
      <mat-card-title>{{ 'dashboard.widgetProperties.specific.table.advanced.edit' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'dashboard.widgetProperties.specific.table.advanced.subtitle' | translate
        }}</mat-card-subtitle>
      <mat-card-subtitle>({{ label }})</mat-card-subtitle>
    </mat-card-header>
    <div class="wrapper">
      <div class="d-flex">
        <div class="w-50">
          <hr class="hr-text"
            [attr.data-content]="'dashboard.widgetProperties.specific.table.advanced.titleOnly' | translate">
          <div class="property container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
            <!-- Enabled -->
            <mat-checkbox (change)="markScreenDirty()" [(ngModel)]="record.enabled" [checked]="record.enabled"
              labelPosition="before" class="menu-item-checkbox mr-2">
              <span class="menu-item mr-3">
                {{ 'dashboard.widgetProperties.specific.table.advanced.enabled' | translate }}
              </span>
            </mat-checkbox>

            <!-- Title only -->
            <mat-checkbox (change)="record.titleOnly = !record.titleOnly; markScreenDirty()"
              [checked]="record.titleOnly" labelPosition="before" class="menu-item-checkbox">
              <span class="menu-item mr-2">
                {{ 'dashboard.widgetProperties.specific.table.titleOnly' | translate }}
              </span>
            </mat-checkbox>
          </div>

          <!-- Rounding options -->
          <hr class="hr-text"
            [attr.data-content]="'dashboard.widgetProperties.specific.table.advanced.rounding' | translate">
          <div [class.disabled]="record.titleOnly" [style.direction]="direction"
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="property container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1 w-100">
            <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.table.advanced.minutes' |
              translate }}</span>
            <mat-form-field appearance="standard" class="option-font w-50"
              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
              <mat-select [(value)]="record.roundMin" (selectionChange)="markScreenDirty()">
                <mat-option *ngFor="let min of minutes" [value]="min"
                  [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                  {{ min }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div [class.disabled]="record.titleOnly" [style.direction]="direction"
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="property container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
            <mat-radio-group (change)="markScreenDirty()" [(ngModel)]="record.roundOption"
              [ngClass]="record.roundMin !== 0 ? 'animate__fadeIn d-flex' : 'd-none'"
              class="w-100 justify-content-between animate__animated">
              <mat-radio-button [value]="roundingOptions.Nearest">
                {{ 'dashboard.widgetProperties.specific.table.advanced.nearest' | translate }}
              </mat-radio-button>
              <mat-radio-button [value]="roundingOptions.Previous" class="mx-3">
                {{ 'dashboard.widgetProperties.specific.table.advanced.previous' | translate }}
              </mat-radio-button>
              <mat-radio-button [value]="roundingOptions.Next">
                {{ 'dashboard.widgetProperties.specific.table.advanced.next' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>

        </div>
        
        <div class="w-50">
          <!-- Date of calculation-->
          <hr class="hr-text"
            [attr.data-content]="'dashboard.widgetProperties.specific.table.advanced.calc' | translate">
          <div [class.disabled]="record.titleOnly" [style.direction]="direction"
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="property container-sm d-flex justify-content-between align-items-center mt-2 mx-2 pb-1">
            <mat-radio-group (change)="markScreenDirty()" [(ngModel)]="record.calcOption"
              class="w-100 d-flex justify-content-between mt-2">
              <mat-radio-button [value]="calcOptions.Daily" class="mr-3">
                {{ 'dashboard.widgetProperties.specific.table.advanced.daily' | translate }}
              </mat-radio-button>
              <mat-radio-button [value]="calcOptions.Weekly" (change)="getCurrentWeekTimes()">
                {{ 'dashboard.widgetProperties.specific.table.advanced.weekly' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div [ngClass]="!record.titleOnly && record.calcOption === calcOptions.Weekly ? 'animate__fadeIn' : 'd-none'"
            class="animate__animated">
            <div [class.disabled]="record.titleOnly || record.calcOption === calcOptions.Daily"
              class="d-flex justify-content-between">
              <!-- Week definition-->
              <div class="d-flex flex-column">
                <mat-form-field appearance="standard" class="option-font" [style.direction]="direction"
                  [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                  <mat-label class="settings-title mr-3">{{
                    'dashboard.widgetProperties.specific.table.advanced.weekDefinitions.title' | translate
                    }}</mat-label>
                  <mat-select (selectionChange)="markScreenDirty()" [(value)]="record.weekDefinition">
                    <mat-select-trigger>
                      &nbsp;{{ 'dashboard.widgetProperties.specific.table.advanced.weekDefinitions.' +
                      record.weekDefinition | translate }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let _ of (obj.keys(weekDefinitions).length / 2) | times; let i = index"
                      [value]="weekDefinitions[weekDefinitions[i]]"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                      {{ 'dashboard.widgetProperties.specific.table.advanced.weekDefinitions.' +
                      weekDefinitions[weekDefinitions[i]] | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Day of change-->
              <div class="d-flex flex-column ml-4">
                <mat-form-field appearance="standard" class="option-font" [style.direction]="direction"
                  [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                  <mat-label class="settings-title mr-3">{{
                    'dashboard.widgetProperties.specific.table.advanced.changeDay.title' | translate }}</mat-label>
                  <mat-select (selectionChange)="markScreenDirty()" [(value)]="record.dayOfChange">
                    <mat-select-trigger>
                      &nbsp;{{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.' + record.dayOfChange |
                      translate }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let _ of (obj.keys(days).length / 2) | times; let i = index"
                      [value]="days[days[i]]" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                      {{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.' + days[days[i]] | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <!-- Decisive day-->
            <div
              [class.disabled]="record.titleOnly || record.calcOption === calcOptions.Daily || record.weekDefinition === undefined"
              [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
              class="property container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
              <mat-radio-group (change)="markScreenDirty()" [(ngModel)]="record.decisiveDayOption"
                class="w-100 d-flex justify-content-between">
                <mat-radio-button [value]="decisiveDay.Earliest">
                  {{ 'dashboard.widgetProperties.specific.table.advanced.earliest' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="decisiveDay.Latest">
                  {{ 'dashboard.widgetProperties.specific.table.advanced.latest' | translate }}
                </mat-radio-button>
                <mat-radio-button [value]="decisiveDay.Specific">
                  {{ 'dashboard.widgetProperties.specific.table.advanced.specific' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <!-- Specific day-->
          <div [ngClass]="!record.titleOnly
                     && record.calcOption === calcOptions.Weekly
                     && record.decisiveDayOption === decisiveDay.Specific
                     && record.weekDefinition !== undefined
                       ? 'animate__fadeIn'
                       : 'd-none'" class="animate__animated">
            <div [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
              class="property container-sm d-flex justify-content-center align-items-center mt-1 mx-2 pb-1 w-100">
              <mat-form-field appearance="standard" class="option-font"
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                <mat-label class="settings-title mr-3">{{ 'dashboard.widgetProperties.specific.table.advanced.specific'
                  | translate }}</mat-label>
                <mat-select (selectionChange)="markScreenDirty()" [(value)]="record.specificDay">
                  <mat-select-trigger>
                    &nbsp;{{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.' + record.specificDay |
                    translate }}
                  </mat-select-trigger>
                  <mat-option *ngFor="let day of definitions[record.weekDefinition]" [value]="days[days[day]]"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                    {{ 'dashboard.widgetProperties.specific.table.advanced.changeDay.' + days[days[day]] | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Conditions-->
      <hr class="hr-text"
        [attr.data-content]="'dashboard.widgetProperties.specific.table.advanced.conditions' | translate">
      <div class="d-flex minimised">

        <!-- Display-->
        <div class="d-flex flex-column align-items-center">
          <mat-card-subtitle class="mb--05 mt-1">{{ 'dashboard.widgetProperties.specific.table.advanced.display' |
            translate }}</mat-card-subtitle>
          <div
            class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3">
            <app-weekdays [scheduler]="schedulerMockDisplay"></app-weekdays>
          </div>
          <div [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="property container-sm d-flex flex-column align-items-center mt-2 mx-2 pb-1 right-border">
            <app-holidays (refreshPipe)="refreshPipe()" [scheduler]="schedulerMockDisplay"></app-holidays>
          </div>
        </div>


        <!-- Highlight-->
        <div class="d-flex flex-column align-items-center">
          <mat-card-subtitle class="mb--05 mt-1">{{ 'dashboard.widgetProperties.specific.table.advanced.highlight' |
            translate }}</mat-card-subtitle>
          <div
            class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3">
            <app-weekdays [scheduler]="schedulerMockHighlight"></app-weekdays>
          </div>
          <div [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            class="property container-sm d-flex flex-column align-items-center mt-2 mx-2 pb-1">
            <app-holidays [scheduler]="schedulerMockHighlight"></app-holidays>
          </div>
        </div>

      </div>
    </div>
    <!-- Buttons-->
    <div class="row justify-content-center">
      <div class="mt-4">
        <button mat-raised-button color="primary" class="mr-5" mat-dialog-close>{{ 'buttons.ok' | translate}}</button>
      </div>
    </div>

  </mat-card>
</div>
