<div class="container position-relative"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane">
  <button [mat-dialog-close]="true" [title]="'buttons.close' | translate" class="close-btn" mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card>
    <mat-card-header class="title-block"
                     [style.direction]="direction"
                     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-card-title>{{ 'dashboard.widgetProperties.specific.media.title' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'dashboard.widgetProperties.specific.media.subtitle' | translate }}</mat-card-subtitle>
      <app-upload [screen]="screen" [widget]="widget"></app-upload>
    </mat-card-header>

    <mat-card-content #recordsList class="records-list" cdkDropList (cdkDropListDropped)="drop($event)">
      <div #mediaRecord *ngFor="let item of widget.content.media?.items" class="record-box fade-in-top my-3" cdkDrag>
        <div class="record-custom-placeholder w-100" *cdkDragPlaceholder></div>
        <mat-card class="media-record w-100">
          <mat-card-content class="record">
<!--            Enabled-->
            <div class="inside-record-item ml-3 mr-4">
              <mat-slide-toggle [checked]="item.enabled"
                                (toggleChange)="item.enabled = !item.enabled; markScreenDirty()">
              </mat-slide-toggle>
            </div>

<!--            File image-->
            <div class="inside-record-item thumbnail-width mr-4">
              <img *ngIf="item.type.includes('image')" [src]="item.url" [alt]="item.name">

              <video *ngIf="item.type.includes('video')">
                <source [src]="item.url" [type]="item.type">
                Sorry, your browser doesn't support embedded videos.
              </video>

              <img *ngIf="item.type.includes('audio')" src="../../../../../../../../../assets/images/music.svg" [alt]="item.name">
            </div>

<!--            File name-->
            <div class="inside-record-item mr-4">
                <div class="filename">{{ item.name }}</div>
            </div>

<!--            Duration-->
            <div [style.direction]="direction" class="inside-record-item mr-4">
                <div>{{ item.duration | duration }}</div>
            </div>

<!--            Size-->
            <div class="inside-record-item mr-4">
                <div>{{ item.size | filesize }}</div>
            </div>

<!--            View-->
            <div class="inside-record-item mr-5">
                <div><a [href]="item.url" target="_blank">{{ 'dashboard.widgetProperties.specific.media.view' +
                '' | translate }}</a></div>
            </div>

<!--            Remove-->
            <div class="remove-record">
              <button (click)="removeRecord(item, mediaRecord)"
                      [title]="'dashboard.widgetProperties.specific.media.remove' | translate"
                      mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="row justify-content-center">
    <div class="mt-4">
      <button mat-raised-button color="primary" [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
    </div>
  </div>
</div>
