import { HttpHeaders } from '@angular/common/http';
import { LocalStorage } from '../_enums/local-storage';

export abstract class BaseApiService {
  /**
   * Gets the Authorization HTTP header.
   * @protected
   * @static
   */
  protected static get authorizationHeader(): HttpHeaders {
    return new HttpHeaders({ Authorization: BaseApiService.token });
  }

  /**
   * Retrieves a token from the Local storage.
   * @private
   */
  protected static get token(): string {
    return 'Bearer ' + localStorage.getItem(LocalStorage.Token);
  }
}
