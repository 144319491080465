import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { WidgetTypes, Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget, HorizontalAlign, VerticalAlign, FontStyle } from "src/app/_models";
import { fonts, fontStyles } from "src/app/global";
import { AppState } from "src/app/store/app.state";
import { changeFontSize } from "src/app/store/widget";

@Component({
  selector: 'app-font',
  templateUrl: './font.component.html',
  styleUrls: ['./font.component.css']
})
export class FontComponent implements OnInit {
  @Input() widget: Widget;

  public direction: Direction = 'ltr';
  public horizontalAlign = HorizontalAlign;
  public verticalAlign = VerticalAlign;
  public fonts = fonts;
  public fontStyles = fontStyles;
  public fStyle = FontStyle;
  public widgetTypes = WidgetTypes;
  public stop = false
  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper, private store: Store<AppState>,
  ) { }

  public ngOnInit(): void {
    this.setTranslation();
  }

  changeTextSize(e) {
    this.widget.font.size = e.value;
    this.store.dispatch(changeFontSize({ widgetId: this.widget.id, fontSize: e.value }));
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
