import { Component, Input } from '@angular/core';
import { WidgetIcon } from 'src/app/_models';
@Component({
  selector: 'app-widget-icon',
  templateUrl: './widget-icon.component.html',
  styleUrls: ['./widget-icon.component.css']
})
export class WidgetIconComponent {
  @Input() widgetIcon: WidgetIcon;
}
