import { AlertData } from './alert';
import { Digital } from './digital';
import { Jewish } from './jewish';
import { Text } from './text';
import { Media } from './media';
import { PDF } from './pdf';
import { RSS } from './rss';
import { Table } from './table';
import { Web } from './web';
import { Yahrzeit } from './yahrzeit';

export class WidgetContent {
  text: Text;
  clock: number;
  digital: Digital;
  jewish: Jewish;
  table?: Table;
  media?: Media;
  pdf?: PDF;
  rss?: RSS;
  yahrzeit?: Yahrzeit;
  alertData?: AlertData;
  web?: Web;
}
