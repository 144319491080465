import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, UntypedFormControl, Validators } from "@angular/forms";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateHelper } from "src/app/_helpers";
import { Widget, TextByDateEntry } from "src/app/_models";
import { DateService } from "src/app/_services";

@Component({
  selector: "app-update-text-by-date-component",
  templateUrl: "./update-text-by-date-component.component.html",
  styleUrls: ["./update-text-by-date-component.component.css"],
})
export class UpdateTextByDateComponentComponent implements OnInit {
  @Input() widget: Widget;
  @Input() textAshir: TextByDateEntry;

  public model: NgbDateStruct;
  public hebrewDateText: string;
  public hebrewCalander: boolean = false;
  public updateForm: UntypedFormGroup;
  public direction: Direction;
  public hebrewDateText1: NgbDateStruct;
  public dateText1: Date;
  public dateMonthDif = "";
  public dateDayDif = "";
  public dateHebrewMonthDif;
  public dateHebrewDayDif;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translationHelper: TranslateHelper,
    private dateService: DateService
  ) {}

  ngOnInit(): void {
    this.direction = this.translationHelper.direction;
    this.initForm();
  }

  /**
   * Gets the date form control.
   */
  public get date(): AbstractControl {
    return this.updateForm.get("date");
  }

  /**
   * Gets the hebrewDate form control.
   */
  public get hebrewDate(): AbstractControl {
    return this.updateForm.get("hebrewDate");
  }
  /**
   * Gets the text form control.
   */
  public get text(): AbstractControl {
    return this.updateForm.get("text");
  }

  /**
   * Gets the text form control.
   */
  public get text1(): AbstractControl {
    return this.updateForm.get("text1");
  }

  /**
   * Gets the text form control.
   */
  public get text2(): AbstractControl {
    return this.updateForm.get("text2");
  }

  /**
   * Gets the username form control.
   */
  public get text3(): AbstractControl {
    return this.updateForm.get("text3");
  }
  /**
   * Gets the username form control.
   */
  public get text4(): AbstractControl {
    return this.updateForm.get("text4");
  }
  /**
   * Gets the username form control.
   */
  public get text5(): AbstractControl {
    return this.updateForm.get("text5");
  }
  /**
   * Gets the username form control.
   */
  public get text6(): AbstractControl {
    return this.updateForm.get("text6");
  }
  /**
   * Updates a textData on form submit or shows an error snackbar.
   */
  public onSubmit(): void {
    const { date, hebrewDate, text, text1, text2, text3, text4, text5, text6 } =
      this.updateForm.value;
    const updete: any = {
      dateMonth:
        this.dateControlMonts.value?.num != "32"
          ? String(this.dateControlMonts.value?.num)
          : "32",
      dateDay:
        this.dateControlDays.value.name != "32"
          ? String(this.dateControlDays.value.name)
          : "32",
      hebrewDate: {
        year: 5782,
        month:
          parseInt(this.controlMonts.value?.num) > 0 &&
          parseInt(this.controlMonts.value?.num) < 12
            ? parseInt(this.controlMonts.value?.num)
            : parseInt(this.dateHebrewMonthDif.key),
        day:
          parseInt(this.animalControlDays.value?.num) > 0 &&
          parseInt(this.animalControlDays.value?.num) < 31
            ? parseInt(this.animalControlDays.value?.num)
            : parseInt(this.dateHebrewDayDif.key),
      },
      text: text,
      text1: text1,
      text2: text2,
      text3: text3,
      text4: text4,
      text5: text5,
      text6: text6,
    };
    for (const [index, value] of this.widget.content.text.byDate.entries()) {
      if (JSON.stringify(value) === JSON.stringify(this.textAshir)) {
        this.widget.content.text.byDate[index].hebrewDate =
          updete.hebrewDate != undefined
            ? updete.hebrewDate
            : this.textAshir.hebrewDate;
        this.widget.content.text.byDate[index].text =
          updete.text != undefined ? updete.text : this.textAshir.text;
        this.widget.content.text.byDate[index].text1 =
          updete.text1 != undefined ? updete.text1 : this.textAshir.text1;
        this.widget.content.text.byDate[index].text2 =
          updete.text2 != undefined ? updete.text2 : this.textAshir.text2;
        this.widget.content.text.byDate[index].text3 =
          updete.text3 != undefined ? updete.text3 : this.textAshir.text3;
        this.widget.content.text.byDate[index].text4 =
          updete.text4 != undefined ? updete.text4 : this.textAshir.text4;
        this.widget.content.text.byDate[index].text5 =
          updete.text5 != undefined ? updete.text5 : this.textAshir.text5;
        this.widget.content.text.byDate[index].text6 =
          updete.text6 != undefined ? updete.text6 : this.textAshir.text6;
      }
    }

    this.dateService.setDate(new Date());
    // this.widget.content.text.byDate.slice()
  }

  private initForm(): void {
    const {
      dateMonth,
      dateDay,
      hebrewDate,
      text,
      text1,
      text2,
      text3,
      text4,
      text5,
      text6,
    } = this.textAshir;
    this.dateMonthDif = dateMonth;
    this.dateDayDif = dateDay;
    switch (hebrewDate.month) {
      case 1:
        this.dateHebrewMonthDif = { key: 1, val: "תשרי" };
        break;
      case 2:
        this.dateHebrewMonthDif = { key: 2, val: "חשוון" };
        break;

      case 3:
        this.dateHebrewMonthDif = { key: 3, val: "כסלו" };
        break;
      case 4:
        this.dateHebrewMonthDif = { key: 4, val: "טבת" };
        break;
      case 5:
        this.dateHebrewMonthDif = { key: 5, val: "שבט" };
        break;
      case 6:
        this.dateHebrewMonthDif = { key: 6, val: "אדר" };
        break;
      case 13:
        this.dateHebrewMonthDif = { key: 13, val: "אדר ב'" };
        break;
      case 7:
        this.dateHebrewMonthDif = { key: 7, val: "ניסן" };
        break;
      case 8:
        this.dateHebrewMonthDif = { key: 8, val: "אייר" };
        break;
      case 9:
        this.dateHebrewMonthDif = { key: 9, val: "סיון" };
        break;
      case 10:
        this.dateHebrewMonthDif = { key: 10, val: "תמוז" };
        break;
      case 11:
        this.dateHebrewMonthDif = { key: 11, val: "אב" };
        break;
      case 12:
        this.dateHebrewMonthDif = { key: 12, val: "אלול" };
        break;
    }

    switch (hebrewDate.day) {
      case 1:
        this.dateHebrewDayDif = { key: 1, val: "א" };
        break;
      case 2:
        this.dateHebrewDayDif = { key: 2, val: "ב" };
        break;

      case 3:
        this.dateHebrewDayDif = { key: 3, val: "ג" };
        break;
      case 4:
        this.dateHebrewDayDif = { key: 4, val: "ד" };
        break;
      case 5:
        this.dateHebrewDayDif = { key: 5, val: "ה" };
        break;
      case 6:
        this.dateHebrewDayDif = { key: 6, val: "ו" };
        break;
      case 7:
        this.dateHebrewDayDif = { key: 7, val: "ז" };
        break;
      case 8:
        this.dateHebrewDayDif = { key: 8, val: "ח" };
        break;
      case 9:
        this.dateHebrewDayDif = { key: 9, val: "ט" };
        break;
      case 10:
        this.dateHebrewDayDif = { key: 10, val: "י" };
        break;
      case 11:
        this.dateHebrewDayDif = { key: 11, val: "יא" };
        break;
      case 12:
        this.dateHebrewDayDif = { key: 12, val: "יב" };
        break;
      case 13:
        this.dateHebrewDayDif = { key: 13, val: "יג" };
        break;
      case 14:
        this.dateHebrewDayDif = { key: 14, val: "יד" };
        break;
      case 15:
        this.dateHebrewDayDif = { key: 15, val: "טו" };
        break;
      case 16:
        this.dateHebrewDayDif = { key: 16, val: "טז" };
        break;
      case 17:
        this.dateHebrewDayDif = { key: 17, val: "יז" };
        break;
      case 18:
        this.dateHebrewDayDif = { key: 18, val: "יח" };
        break;
      case 19:
        this.dateHebrewDayDif = { key: 19, val: "'יט" };
        break;
      case 20:
        this.dateHebrewDayDif = { key: 20, val: "כ" };
        break;
      case 21:
        this.dateHebrewDayDif = { key: 21, val: "כא" };
        break;
      case 22:
        this.dateHebrewDayDif = { key: 22, val: "כב" };
        break;
      case 23:
        this.dateHebrewDayDif = { key: 23, val: "כג" };
        break;
      case 24:
        this.dateHebrewDayDif = { key: 24, val: "כד" };
        break;
      case 25:
        this.dateHebrewDayDif = { key: 25, val: "כה" };
        break;
      case 26:
        this.dateHebrewDayDif = { key: 26, val: "כו" };
        break;
      case 27:
        this.dateHebrewDayDif = { key: 27, val: "כז" };
        break;
      case 28:
        this.dateHebrewDayDif = { key: 28, val: "כח" };
        break;
      case 29:
        this.dateHebrewDayDif = { key: 29, val: "כט" };
        break;
      case 30:
        this.dateHebrewDayDif = { key: 30, val: "ל" };
        break;
    }
    this.updateForm = this.formBuilder.group({
      dateMonth: ["", [Validators.required]],
      dateDay: ["", [Validators.required]],

      hebrewDate: ["", [Validators.required], []],
      dateControlMonts: [dateMonth, [Validators.required]],
      dateControlDays: [dateDay, [Validators.required]],

      controlMonts: new UntypedFormControl("", Validators.required),
      selectFormControlMons: new UntypedFormControl("", Validators.required),

      text: [text, [Validators.required]],
      text1: [text1, [Validators.required]],
      text2: [text2, [Validators.required]],
      text3: [text3, [Validators.required]],
      text4: [text4, [Validators.required]],
      text5: [text5, [Validators.required]],
      text6: [text6, [Validators.required]],
    });
  }

  dateControlMonts = new UntypedFormControl("", Validators.required);
  selectDateFormControlMons = new UntypedFormControl("", Validators.required);
  montsDate: any[] = [
    { name: "1", num: "1" },
    { name: "2", num: "2" },
    { name: "3", num: "3" },
    { name: "4", num: "4" },
    { name: "5", num: "5" },
    { name: "6", num: "6" },
    { name: "7", num: "7" },
    { name: "8", num: "8" },
    { name: "9", num: "9" },
    { name: "10", num: "10" },
    { name: "11", num: "11" },
    { name: "12", num: "12" },
  ];

  dateControlDays = new UntypedFormControl("", Validators.required);
  selectDateFormControlDays = new UntypedFormControl("", Validators.required);
  daysDate: any[] = [
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
    { name: "6" },
    { name: "7" },
    { name: "8" },
    { name: "9" },
    { name: "10" },
    { name: "11" },
    { name: "12" },
    { name: "13" },
    { name: "14" },
    { name: "15" },
    { name: "16" },
    { name: "17" },
    { name: "18" },
    { name: "19" },
    { name: "20" },
    { name: "21" },
    { name: "22" },
    { name: "23" },
    { name: "24" },
    { name: "25" },
    { name: "26" },
    { name: "27" },
    { name: "28" },
    { name: "29" },
    { name: "30" },
    { name: "31" },
  ];

  controlMonts = new UntypedFormControl("", Validators.required);
  selectFormControlMons = new UntypedFormControl("", Validators.required);
  monts: any[] = [
    { name: "תשרי", num: "1" },
    { name: "חשוון", num: "2" },
    { name: "כסלו", num: "3" },
    { name: "טבת", num: "4" },
    { name: "שבט", num: "5" },
    { name: "אדר", num: "6" },
    { name: "'אדר א", num: "14" },
    { name: "'אדר ב", num: "13" },
    { name: "ניסן", num: "7" },
    { name: "אייר", num: "8" },
    { name: "סיון", num: "9" },
    { name: "תמוז", num: "10" },
    { name: "אב", num: "11" },
    { name: "אלול", num: "12" },

  ];
  datelControlDays = new UntypedFormControl("", Validators.required);

  animalControlDays = new UntypedFormControl("", Validators.required);
  selectFormControlDays = new UntypedFormControl("", Validators.required);
  days: any[] = [
    { name: "א", num: "1" },
    { name: "ב", num: "2" },
    { name: "ג", num: "3" },
    { name: "ד", num: "4" },
    { name: "ה", num: "5" },
    { name: "ו", num: "6" },
    { name: "ז", num: "7" },
    { name: "ח", num: "8" },
    { name: "ט", num: "9" },
    { name: "י", num: "10" },
    { name: "יא", num: "11" },
    { name: "יב", num: "12" },
    { name: "יג", num: "13" },
    { name: "יד", num: "14" },
    { name: "'טו", num: "15" },
    { name: "טז", num: "16" },
    { name: "יז", num: "17" },
    { name: "יח", num: "18" },
    { name: "יט", num: "19" },
    { name: "כ", num: "20" },
    { name: "כא", num: "21" },
    { name: "כב", num: "22" },
    { name: "כג", num: "23" },
    { name: "כד", num: "24" },
    { name: "כה", num: "25" },
    { name: "כו", num: "26" },
    { name: "כז", num: "27" },
    { name: "כח", num: "28" },
    { name: "כט", num: "29" },
    { name: "ל", num: "30" },
  ];
}
