import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IGridsterOptions } from "angular2gridster";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget, AlertGridsterItem } from "src/app/_models";

@Component({
  selector: 'app-alert-content',
  templateUrl: './alert-content.component.html',
  styleUrls: ['./alert-content.component.css']
})
export class AlertContentComponent implements OnInit {
  @Input() widget: Widget;

  public direction: Direction = 'ltr';

  public availableKeysList: AlertGridsterItem[] = [
    { key: 'name', x: 1, y: 0, w: 1, h: 1 },
    { key: 'time', x: 1, y: 1, w: 1, h: 1 },
    { key: 'countdown', x: 1, y: 2, w: 1, h: 1 }
  ];

  public gridsterOptions: IGridsterOptions = {
    lanes: 3,
    lines: {
      visible: true,
      always: true,
      color: '#dee2e6',
      width: 1,
      backgroundColor: 'pink'
    },
    floating: true,
    direction: 'none',
    dragAndDrop: true,
    resizable: false,
    useCSSTransforms: true,
    widthHeightRatio: 2,
    shrink: true
  };

  constructor(private translate: TranslateService, private translateHelper: TranslateHelper) { }

  public ngOnInit(): void {
    this.setTranslation();
    this.initAvailableKeysList();
  }

  public dropGridsterItem(item: AlertGridsterItem): void {
    this.modifyList(this.availableKeysList, this.widget.content.table.alertKeys, item);
  }

  public removeGridsterItem(item: AlertGridsterItem): void {
    this.modifyList(this.widget.content.table.alertKeys, this.availableKeysList, item);
  }

  public adjustGridsterItemPosition(event: any): void {
    this.ensurePositionInGrid(event.item);
    this.updateKeyMatrix();
  }

  private initAvailableKeysList(): void {
    this.availableKeysList = this.filterKeysNotInAlertKeys();
  }

  private setTranslation(): void {
    this.subscribeToLangChange();
    this.direction = this.translateHelper.direction;
  }

  private subscribeToLangChange(): void {
    this.translate.onLangChange.subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
  }

  private modifyList(fromList: AlertGridsterItem[], toList: AlertGridsterItem[], item: AlertGridsterItem): void {
    fromList.splice(fromList.indexOf(item), 1);
    toList.push(item);
    this.updateKeyMatrix();
  }

  private ensurePositionInGrid(item: AlertGridsterItem): void {
    if (item.y > 2) {
      item.y = 2;
    }
  }

  private updateKeyMatrix(): void {
    const keysMatrix: string[][] = [[], [], []];
    this.widget.content.table.alertKeys.forEach((alertKey: AlertGridsterItem) => {
      if (keysMatrix[alertKey.y]) {
        keysMatrix[alertKey.y][alertKey.x] = alertKey.key;
      }
    });

    this.fillEmptyCellsInMatrix(keysMatrix);
    this.widget.content.table.keys = keysMatrix.flat();
  }

  private fillEmptyCellsInMatrix(matrix: string[][]): void {
    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < 3; col++) {
        matrix[row][col] ??= '';
      }
    }
  }

  private filterKeysNotInAlertKeys(): AlertGridsterItem[] {
    return this.availableKeysList.filter((alertKey: AlertGridsterItem) => !this.isKeyInAlertKeys(alertKey.key));
  }

  private isKeyInAlertKeys(key: string): boolean {
    return this.widget.content.table.alertKeys.map(key => key.key).includes(key);
  }
}
