import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core"
import { Store } from "@ngrx/store"
import { Subscription, BehaviorSubject } from "rxjs"
import { ScrollHelper } from "src/app/_helpers"
import { WidgetService } from "src/app/_services"
import { AppState } from "src/app/store/app.state"
import { RxWidgetState } from "src/app/store/widget"
import { ResizeObservable } from "../table-viewer.component"
import { map, tap } from "rxjs/operators"

@Component({
  selector: 'app-table-vertical-scroll',
  templateUrl: './table-vertical-scroll.component.html',
  styleUrls: ['./table-vertical-scroll.component.css']
})
export class TableVerticalScrollComponent implements OnInit {
  @Input() widget
  @Input() verticalAlignBottom
  @Input() verticalAlignTop
  @Input() scrollDirections
  @Input() languages
  @Input() effectTypes
  @Input() refreshCount
  @Input() date
  @Input() dayLink
  @Input() shabbosEnd
  @Input() user
  @Input() DEFAULT_HIGHLIGHT_COLOUR
  @Input() horizontalAlignLeft
  @Input() horizontalAlignRight
  @Input() widthClassName
  @Input() align
  @Input() times
  @Input() halachicTimesTwoDays
  @Input() itemsPerView: number
  @Input() speed
  @Input() verticalAlignCenter
  @Input() sanitizer

  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;
  lastWidgetState: RxWidgetState;
  constructor(private widgetService: WidgetService, private elementRef: ElementRef, private store: Store<AppState>) { }

  widgetStateSubscription: Subscription;
  widgetState: RxWidgetState;

  ngOnInit(): void {
    this.widgetStateSubscription = this.store.select(state => state.widgets[this.widget.id]).subscribe(state => {
      this.widgetState = state;
    });
    this.listenToAutoEffect();

    this.widgetService.widgetUpdateSpeedTable.subscribe(data => {
      if (data) {
        this.updateScroll();
      }
    })

    this.store.select(state => state.widgets[this.widget.id]).pipe(
      tap((state) => {
        if ((this.lastWidgetState?.timeFormatTableWidget && (this.lastWidgetState?.timeFormatTableWidget !== state.timeFormatTableWidget))) {
          this.widget.enabled = false;
          setTimeout(() => this.widget.enabled = true, 0);
        }
      }),
      tap((state) => { this.lastWidgetState = state }),
    ).subscribe();
  }

  private containerHeight$ = new BehaviorSubject<number>(0);
  private wrapperHeight$ = new BehaviorSubject<number>(0);

  private containerResizeObserver = Subscription.EMPTY;
  private lastResizeEntry$: BehaviorSubject<ResizeObserverEntry> = new BehaviorSubject(null);
  resizeEndCallback = (entry: ResizeObserverEntry) => {
    const contentRect = entry.contentRect;
    // Update the container height subject with the new height
    this.containerHeight$.next(contentRect.height);
    // Scroll the widget if needed
    this.updateScroll();
  }

  /**
 * Updates the scroll of the widget.
 * @private
 * @returns {Promise<void>}
 * @memberof TableViewerComponent
 * @description
 * This method updates the scroll of the widget.
 **/
  private async updateScroll(): Promise<void> {
    [this.itemsPerView, this.speed] = [1, 0];
    while (this.wrapper?.nativeElement.children.length !== 1) await new Promise(resolve => setTimeout(resolve, 10));
    // await for the next tick requestAnimationFrame(() => {
    console.log('container offsetHeight', this.container.nativeElement.offsetHeight);
    console.log('wrapper offsetHeight', this.wrapper.nativeElement.offsetHeight);
    console.log('elementRef offsetHeight', this.elementRef.nativeElement.offsetHeight);


    [this.itemsPerView, this.speed] = ScrollHelper.scrollIfShould(this.elementRef.nativeElement, this.wrapper.nativeElement, this.widget);
    console.log('itemsPerView', this.itemsPerView, 'speed', this.speed);
  }

  public startResizeObserver(containerElement: HTMLElement): void {
    // Create a ResizeObservable instance that listens for resize events on the container element
    this.containerResizeObserver =
      new ResizeObservable(containerElement)
        // Extract the first entry from the resize event array (since we are only observing one element)
        .pipe(
          map(entries => entries[0]),
        )
        // Subscribe to the resize events, and update the lastResizeEntry$ subject with the latest event
        .subscribe((entry: ResizeObserverEntry) => this.lastResizeEntry$.next(entry));

    // Subscribe to the resizeStopSource subject, and call the resizeEndCallback function with the last resize entry when the subject emits a value
    this.widget.resizeStopSource.subscribe(() => {
      this.resizeEndCallback(this.lastResizeEntry$.value);
    });

    this.widget.contentChangedSource.subscribe(async () => {
      // There is a bug that occurs when the following steps are taken:
      // 1. Add a record to the table with a date set to a different day
      // 2. Observe that the table initially displays the record in the current day
      // 3. Notice that the table begins to scroll, even though it shouldn't
      // 4. The record is then removed from the table, but the table continues to scroll
      //
      // To fix this issue, we wait for the table to update before checking if it should scroll.
      // This issue is not reproducible when the table is first loaded, so we only wait for the table to update when the content changes.
      //
      // TODO: Fix bug where table scrolls unexpectedly when a record is added with a date set to a different day.
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (!this.widget.content.table.singleLine) {
        // this.horizntalScrollStarter();
      }
      this.resizeEndCallback(this.lastResizeEntry$.value);
    });

    // this.widget.contentSource.subscribe(() => {
    //   this.resizeEndCallback(this.lastResizeEntry$.value);
    // });
  }

  async ngAfterViewInit() {
    if (!this.widget.content.table.alertLayout) {
      let index = 0;
      while (this.container.nativeElement.offsetHeight === 0) {
        await new Promise(resolve => setTimeout(resolve, 100));
        console.log(index++);
      }
      this.startResizeObserver(this.container.nativeElement);
      this.updateScroll();
    }
  }

  public ngOnDestroy(): void {
    this.containerResizeObserver.unsubscribe();
    this.lastResizeEntry$.unsubscribe();
    this.containerHeight$.unsubscribe();
    this.wrapperHeight$.unsubscribe();
    this.widgetStateSubscription.unsubscribe();
  }

  /**
 * Listens to the auto-effect change and starts the scroll accordingly.
 * @private
 * @returns {void}
 */
  private listenToAutoEffect(): void {
    this.widgetService.effectUpdated$.subscribe(({ widgetId }) => {
      if (widgetId !== this.widget.id) return;
      this.updateScroll();
    });
  }

}
