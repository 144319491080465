import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDto } from '../_dto/user-dto';

@Injectable()
export class LocationInterceptor implements HttpInterceptor {

  /**
   * Sets a property 'inEretzIsrael' in the 'currentuser' response to true if the location name contains 'israel'.
   * @param {HttpRequest<unknown>} request - HTTP request.
   * @param {HttpHandler} next - HTTP handler.
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (!event.url?.toLowerCase().includes('/api/account/currentuser')) return event;

          const modifiedBody: UserDto = JSON.parse(JSON.stringify(event.body));
          modifiedBody.inEretzIsrael = modifiedBody.location?.description?.toLowerCase().includes('israel');

          return event.clone({ body: modifiedBody });
        }
      })
    );
  }
}
