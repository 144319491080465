import { Directive, ElementRef, Input } from '@angular/core';
import { Widget } from 'src/app/_models/widget';

@Directive({
  selector: '[fitContainerDirective]'
})
export class FitContainer {
  @Input() widget: Widget;
  @Input() containerEl: ElementRef;

  lastMode: 'fit' | 'break';

  constructor(private el: ElementRef) { }

  async ngOnInit() {
    await new Promise(resolve => setTimeout(resolve, 200));
    this.changeTextFontSize();
  }


  async changeTextFontSize() {
    let el = this.el.nativeElement;
    let fontSizeTemp = this.widget.font.size / 2;
    // if the text is in 'fit' mode, we need to check if the text is bigger than the container width and if so, decrease the font size
    // if the text is in 'break' mode, we need to set the font size to the default size
    if (this.widget.content.jewish.smallTextAuto) {
      // smallAutoText is 'fit' mode, so if the last mode was 'break' we need to force the change
      const force = this.widget.content.jewish.smallTextAuto && this.lastMode === 'break';
      this.lastMode = 'fit';
      // wait for the element to be rendered
      while (el.offsetWidth === 0) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      // check if the element width has changed since last time
      // in case mode changed from 'break' to 'fit' we need to force the change because the element width is still the same but the text line breaks changed
      if (el.getAttribute("data-container-width") !== this.containerEl.nativeElement.clientWidth.toString() || force) {
        let sizeUpdated = false;
        // set the font size to the default size
        el.setAttribute("style", `font-size:${fontSizeTemp}vh`);
        let count = 0;
        // while the text is bigger than the container width, decrease the font size
        while (el.offsetWidth >= this.containerEl.nativeElement.clientWidth && count < 30) {
          // decrease the font size by 0.5 so the text size will decrease faster
          // in the next loop we will fix the size by increasing it by 0.1 if needed
          fontSizeTemp = fontSizeTemp - 0.5;
          el.setAttribute("style", `font-size:${fontSizeTemp}vh`);
          sizeUpdated = true;
          count++;
        }
        // reset the counter
        count = 0;
        // while the text is smaller than the container width, increase the font size
        // it will iterate only if the font size was updated in the previous loop so we won't increase the font size if the text is already smaller than the container width even before the first loop so it will bever be bigger than the default size
        while (sizeUpdated && el.offsetWidth <= this.containerEl.nativeElement.clientWidth && count < 5) {
          count++;
          fontSizeTemp = fontSizeTemp + 0.1;
          el.setAttribute("style", `font-size:${fontSizeTemp}vh`);
        }
        // decrease the font size by 0.1 because it's now bigger than the container width by 1 loop
        fontSizeTemp = fontSizeTemp - 0.1;
        el.setAttribute("style", `font-size:${fontSizeTemp}vh`);
        if (sizeUpdated) {
          // save the container width so we can check if it changed in the next loop
          el.setAttribute("data-container-width", this.containerEl.nativeElement.clientWidth);
        }
      }
    } else {
      this.lastMode = 'break';
      el.setAttribute("style", `font-size:${this.widget.font.size / 2}vh`);
    }
  }
}
