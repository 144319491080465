import { Pipe, PipeTransform } from '@angular/core';
import {
  NgbCalendarHebrew,
  NgbDate,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { ChangeTimes } from "../_enums/change-times";
import { JewishHelper } from "../_helpers/jewish.helper";
import { TextByDateEntry } from '../_models/TextByDateEntry';
import { Widget } from "../_models/widget";
import { HalachicTimesService } from "../_services/halachic-times.service";

@Pipe({
  name: 'valueTextByWeek'
})
export class ValueTextByWeekPipe implements PipeTransform {
  public dayLink = 0;
  public jewishDateDayLink: any;
  public hebrewDate: NgbDateStruct;
  public textValue = [];
  public isSeeLineTexts = [];
  private jewishCalendar = new NgbCalendarHebrew();
  public textValueAfter = [];
  isSeeLineText;
  isSeeLineText1;
  isSeeLineText2;
  isSeeLineText3;
  isSeeLineText4;
  isSeeLineText5;
  isSeeLineText6;
  index;
  result: any = []
  constructor(private halachicTimesService: HalachicTimesService) { }

  transform(value: TextByDateEntry[], selDate: Date, widget: Widget): string[] {
    this.textValueAfter = [];

    const selectedDate = new Date(selDate);
    this.dayLink = this.getDayLink(selectedDate, widget.changeTimeKey);

    const dayLinkDate = ValueTextByWeekPipe.getGregorianDateByDayLink(
      selectedDate,
      this.dayLink
    );
    let array = this.getCurrentWeekDates(dayLinkDate)
    array.forEach(element => {
      let ishLeapYear = JewishHelper.isJewishLeapYear(element['year']);
      value.filter((val, index) => {
        const hebrewDate = this.jewishCalendar.fromGregorian(val.hebrewDate)

        //If a leap year =================
        if (
          ishLeapYear != true ||
          (ishLeapYear == true && element['month'] < 6)
        ) {
          if (element['month'] == hebrewDate.month && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;

            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts)
          }
        }
        if (ishLeapYear == true && element['month'] >= 6) {

          // NISAN
          if (
            element['month'] == 8 && hebrewDate.month == 7 && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;
            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts);
          }
          // IAAR
          if (
            element['month'] == 9 && hebrewDate.month == 8 && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;
            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts);
          }
          // SIVAN
          if (
            element['month'] == 10 && hebrewDate.month == 9 && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;
            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts);
          }
          // TAMUZ
          if (
            element['month'] == 11 && hebrewDate.month == 10 && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;
            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts);
          }
          // AV
          if (
            element['month'] == 11 && hebrewDate.month == 10 && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;
            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts);
          }
          // ADAR A'
          if (
            element['month'] == 6 && hebrewDate.month == 14 && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;
            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts);
          }
          //  ADAR B'
          if (
            element['month'] == 7 && hebrewDate.month == 13 && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;
            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts);
          }
          // ELUL
          if (
            element['month'] == 13 && hebrewDate.month == 12 && element['day'] == hebrewDate.day) {
            this.isSeeLineText = widget.content.text.isSeeLineText;
            this.isSeeLineText1 = widget.content.text.isSeeLineText1;
            this.isSeeLineText2 = widget.content.text.isSeeLineText2;
            this.isSeeLineText3 = widget.content.text.isSeeLineText3;
            this.isSeeLineText4 = widget.content.text.isSeeLineText4;
            this.isSeeLineText5 = widget.content.text.isSeeLineText5;
            this.isSeeLineText6 = widget.content.text.isSeeLineText6;
            this.isSeeLineTexts = [
              this.isSeeLineText,
              this.isSeeLineText1,
              this.isSeeLineText2,
              this.isSeeLineText3,
              this.isSeeLineText4,
              this.isSeeLineText5,
              this.isSeeLineText6]
            this.textByMonthInNormalYear(value, index, this.isSeeLineTexts);
          }
        }
      })
    });
    return this.textValueAfter;
  }




  private getCurrentWeekDates(currentDate = new Date()): Date[] {
    const sunday = new Date(currentDate);
    sunday.setDate(currentDate.getDate() - currentDate.getDay());
    const week: any[] = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(sunday);
      date.setDate(sunday.getDate() + i);

      const element = new Date(date);
      const ngbDate = new NgbDate(
        element.getFullYear(),
        element.getMonth() + 1,
        element.getDate()
      );
      this.hebrewDate = this.jewishCalendar.fromGregorian(ngbDate);

      week.push(this.hebrewDate)
    }

    return week;
  }

  private textByMonthInNormalYear(value, index, isSeeLineTexts: any[]) {
    this.textValue = [
      value[index].text,
      value[index].text1,
      value[index].text2,
      value[index].text3,
      value[index].text4,
      value[index].text5,
      value[index].text6,
    ];
    for (let i = 0; i < this.textValue.length; i++) {
      if (this.textValue[i] != "" && isSeeLineTexts[i] == true) {
        this.textValueAfter.push(this.textValue[i]);
      }
    }
  }
  private getDayLink(
    date: Date,
    changeTimeKey: ChangeTimes = ChangeTimes.Tzais
  ): number {
    const changeTimeOption = this.halachicTimesService.times.find(
      (time) => time.key === changeTimeKey
    )?.option;
    return date >
      new Date(this.halachicTimesService.halachicTimes[changeTimeOption])
      ? 1
      : 0;
  }

  public static getGregorianDateByDayLink(date: Date, dayLink: number): Date {
    return new Date(date.setDate(date.getDate() + dayLink));
  }
}
