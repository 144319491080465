import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Screen, Widget } from "src/app/_models";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-pdf-dashboard',
  templateUrl: './pdf-dashboard.component.html',
  styleUrls: ['./pdf-dashboard.component.css']
})
export class PdfDashboardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;

  public safeUrl: SafeResourceUrl;
  public pages: number[];
  public direction: Direction = 'ltr';

  private resourcesUrl = environment.resourcesUrl;

  constructor(
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) { }

  public ngOnInit(): void {
    this.setTranslation();
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.widget.content.pdf.url);
    this.pages = [...Array(this.widget.content.pdf.originalPagesNumber + 1).keys()];
    this.pages.shift();
    console.log('PAGES', this.pages);
  }

  /**
   * Creates a safe URL to retrieve the PDF file.
   * @param {string} filename - a PDF filename.
   */
  public setSafeUrl(filename: string) {
    this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.resourcesUrl + filename);
  }

  /**
   * Marks screen "dirty" for purpose of page leaving warning.
   */
  public markScreenDirty(): void {
    this.screen.dirty = true;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
