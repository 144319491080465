import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeInlineStyles' })
export class RemoveInlineStylesPipe implements PipeTransform {
  transform(value: string, shouldScroll: "scroll" | "autoEffect" | "doNotScroll"): string {
    if (shouldScroll !== 'doNotScroll') {
      const regex1 = /<br\s*[\/]?>/gi;
      value = value.replace(regex1, '&nbsp;');
      const regex2 = /div/g;
      return value
        .replace(regex2, 'span') // Replace opening tag
        .replace(/<\/(\w+)>/g, '&nbsp;</span>&nbsp;'); // Replace closing tag
    }
    return value;
  }
}
