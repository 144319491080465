import { Component, Input } from '@angular/core';
import { UserDto } from '../../../../../_dto/user-dto';

@Component({
  selector: 'app-morid-hatal',
  templateUrl: './morid-hatal.component.html',
  styleUrls: ['./morid-hatal.component.css']
})
export class MoridHatalComponent {
  @Input() user: UserDto;
}
