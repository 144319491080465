<div *ngIf="widget.content.digital"
     class="settings bottom-border container-sm mt-4 mx-2 pb-3 d-flex flex-column justify-content-center">
  <div class="d-flex justify-content-between align-items-center"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="settings-title mx-3">{{ 'dashboard.widgetProperties.specific.digital.timeFormat' | translate }}</span>
    <mat-radio-group (change)="setFormat('timeFormat', $event.value); markScreenDirty()"
                     [value]="widget.content.digital.format.includes('h') ? timeFormat._12H : timeFormat._24H">
      <mat-radio-button [value]="timeFormat._12H" class="mx-3">{{ 'dashboard.widgetProperties.specific.digital.12h' | translate }}</mat-radio-button>
      <mat-radio-button [value]="timeFormat._24H">{{ 'dashboard.widgetProperties.specific.digital.24h' | translate }}</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-checkbox (change)="setFormat('amPm', $event.checked); markScreenDirty()"
                [checked]="widget.content.digital.format.includes('a')"
                [disabled]="widget.content.digital.format.includes('H')"
                class="d-flex justify-content-center mt-2">
    a.m. / p.m.
  </mat-checkbox>
  <div class="settings container-sm d-flex justify-content-center align-items-center mx-2">
    <div class="uppercase d-flex justify-content-between align-items-center">
      <span>{{ 'dashboard.widgetProperties.specific.digital.uppercase' | translate }}</span>
      <mat-slide-toggle (change)="toggleCase($event.checked); markScreenDirty()"
                        [checked]="!widget.content.digital.uppercase"
                        [disabled]="uppercaseToggleDisabled">
      </mat-slide-toggle>
      <span>{{ 'dashboard.widgetProperties.specific.digital.lowercase' | translate }}</span>
    </div>
  </div>
</div>

<div *ngIf="widget.content.digital"
     class="settings bottom-border container-sm d-flex justify-content-between align-items-center mx-2"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span>{{ 'dashboard.widgetProperties.specific.digital.seconds' | translate }}</span>
  <mat-slide-toggle (change)="setFormat('seconds', $event.checked); markScreenDirty()"
                    [checked]="widget.content.digital.format.includes('ss')">
  </mat-slide-toggle>
</div>

<div *ngIf="widget.content.digital.format"
     class="settings bottom-border container-sm d-flex justify-content-between align-items-center mx-2"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span>{{ 'dashboard.widgetProperties.specific.digital.zero' | translate }}</span>
  <mat-slide-toggle (change)="setFormat('zero', $event.checked); markScreenDirty()"
                    [checked]="widget.content.digital.format.includes('hh') || widget.content.digital.format.includes('HH')">
  </mat-slide-toggle>
</div>
