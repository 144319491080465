<ng-container>
  <ng-container
    *ngTemplateOutlet="text ? textTemplate : records ? recordsTemplate : htmlContent ? htmlContentTemplate : multiHtmlContentTemplate"></ng-container>
</ng-container>
<ng-template #textTemplate>
  <div>
    <div #hiddenContainerWithoutSeperator class="hidden-container">
      <span>{{ text | separator : separator }}</span>
    </div>

    <div #hiddenContainerWithSeperator class="hidden-container">
      <span>{{ text | separator : separator : true }}</span>
    </div>

    <!-- Display the text in a scrolling container if it doesn't fit in the hidden container -->
    <div *ngIf="
      shouldScroll === 'scroll' ||
        (shouldScroll === 'autoEffect' &&
          hiddenContainerWithoutSeperator.clientWidth < hiddenContainerWithoutSeperator.children[0].scrollWidth);
      then scroll;
      else stay
    "></div>
    <ng-template #scroll>
      <div #scrollContainer class="scroll-container">
        <span #textWrapper class="text-wrapper" (animationend)="onAnimationEnd(scrollContainer, shouldScroll)">
          <span class="scrolling-text" #scrollingText [innerHTML]="text | separator : separator : true"></span>
        </span>
      </div>
    </ng-template>
    <!-- Display the text without scrolling if it fits in the container -->
    <ng-template #stay>
      <div #scrollContainer class="auto-effect">
        <span [innerHTML]="text | separator : separator"></span>
      </div>
    </ng-template>
  </div>
<!-- 
  <div>
    <div #hiddenContainerWithoutSeperator class="hidden-container">
      <span>{{ text | separator : separator }}</span>
    </div>

    <div #hiddenContainerWithSeperator class="hidden-container">
      <span>{{ text | separator : separator : true }}</span>
    </div>

    <div class="new-container">
      <div class="new-container">
        <div class="new-text-wrapper">
          <span class="new-scrolling-text t1" #t1 [innerHTML]="text | separator : separator : true"></span>
          <span class="new-scrolling-text t2" #t2 [innerHTML]="text | separator : separator : true"></span>
        </div>
      </div>
    </div>
  </div> -->

</ng-template>
<ng-template #recordsTemplate>
  <div #hiddenContainerWithoutSeperator>
    <div #singleRecord class="single-element hidden-container text-wrapper"
      (animationend)="onAnimationEnd(scrollContainer, shouldScroll)">
      <ng-container *ngIf="records$ | async as records">
        <span *ngFor="let item of records" class="scrolling-text"
          [style.color]="item.record.highlighted ? (widget.content.table.highlightColor || DEFAULT_HIGHLIGHT_COLOUR) : 'inherit'"
          [class.left]="horizontalAlignLeft" [class.right]="horizontalAlignRight">
          {{ item.record | halachic:widget.language }}&nbsp;{{ item.timeContent }}&nbsp;
        </span>
      </ng-container>
    </div>
  </div>
  <div #scrollContainer class="scroll-container" *ngIf="singleRecord">
    <span #textWrapper class="text-wrapper" (animationend)="onAnimationEnd(scrollContainer, shouldScroll)">
      <span class="scrolling-text" [innerHTML]="singleRecord.innerHTML"></span>
    </span>
  </div>
</ng-template>

<ng-template #htmlContentTemplate>
  <div class="html-content hidden-container" #hiddenContainerWithoutSeperator>
    <div #singleRecord class="text-wrapper" (animationend)="onAnimationEnd(scrollContainer, shouldScroll)">
      <span [innerHTML]="htmlContent | byPassSecurity"></span>
    </div>
  </div>
  <div #scrollContainer class="scroll-container html-content">
    <span #textWrapper class="text-wrapper" (animationend)="onAnimationEnd(scrollContainer, shouldScroll)">
      <span class="scrolling-text">{{ singleRecord.innerHTML | byPassSecurity }}</span>
    </span>
  </div>
</ng-template>

<ng-template #multiHtmlContentTemplate>
  <div [ngClass]="shouldScroll === 'doNotScroll' ? 'multi-html-content-scroll' : 'multi-html-content'"
    class="hidden-container" #hiddenContainerWithoutSeperator>
    <div #singleRecord
      [ngClass]="shouldScroll === 'doNotScroll' ? 'multi-html-content-scroll-item' : 'multi-html-wrapper'"
      (animationend)="onAnimationEnd(scrollContainer, shouldScroll)">
      <div *ngFor="let htmlContent of multiHtmlContent"
        [innerHTML]="htmlContent + '&nbsp;&nbsp;' | removeInlineStyles : shouldScroll | byPassSecurity">
      </div>
    </div>
  </div>
  <ng-container *ngIf="shouldScroll === 'doNotScroll' else scroll">
    <div #scrollContainer class="multi-html-content-scroll">
      <span #textWrapper class="multi-html-content-scroll-item"
        (animationend)="onAnimationEnd(scrollContainer, shouldScroll)">
        <div class="scrolling-text"
          [innerHTML]="singleRecord.innerHTML | removeInlineStyles : shouldScroll | byPassSecurity">
        </div>
      </span>
    </div>
  </ng-container>
  <ng-template #scroll>
    <div #scrollContainer class="multi-html-content">
      <span #textWrapper class="multi-html-wrapper" (animationend)="onAnimationEnd(scrollContainer, shouldScroll)">
        <div class="scrolling-text"
          [innerHTML]="singleRecord.innerHTML | removeInlineStyles : shouldScroll | byPassSecurity">
        </div>
      </span>
    </div>
  </ng-template>
</ng-template>