import { createAction, props } from '@ngrx/store';
import { WidgetState } from 'src/app/_models/widget';
import { ViewMode } from './widget.reducer';
import { TimeFormat } from 'src/app/_enums/time-format';

export const changeViewByDateMode = createAction(
  '[Widget] Change View By Date Mode',
  props<{ widgetId: number, viewMode: ViewMode }>()
);

export const changeTimeFormatJewishWidget = createAction(
  '[Widget] Change Time Format Jewish Widget',
  props<{ widgetId: number, timeFormat: TimeFormat }>()
);

export const toggleTimeFormatTableWidget = createAction(
  '[Widget] Toggle Time Format Table Widget',
  props<{ widgetId: number, timeFormat: TimeFormat }>()
);

export const toggleDisplaySecondsTableWidget = createAction(
  '[Widget] Toggle Display Seconds Table Widget',
  props<{ widgetId: number, displaySeconds: boolean }>()
);

export const toggleZeroPaddingTableWidget = createAction(
  '[Widget] Toggle Zero Padding Table Widget',
  props<{ widgetId: number, zeroPadding: boolean }>()
);
export const toggleAmPmTableWidget = createAction(
  '[Widget] Toggle Display AM/PM Table Widget',
  props<{ widgetId: number, displayAmPm: boolean }>()
);

export const toggleUpperTableWidget = createAction(
  '[Widget] Toggle Upper Table Widget',
  props<{ widgetId: number, upperAmPm: boolean }>()
);

export const setLoadedWidgets = createAction(
  '[Widget] Set Loaded Widgets',
  props<{ widgets: WidgetState[] }>()
);

export const changeSeparator = createAction(
  '[Widget] Change Separator',
  props<{ widgetId: number, separator: string }>()
);

export const changeJewishKeys = createAction(
  '[Widget] Change Jewish Keys',
  props<{ widgetId: number, jewishKeys: string[] }>()
);

export const changeFontSize = createAction(
  '[Widget] Change Font Size',
  props<{ widgetId: number, fontSize: number }>()
);
