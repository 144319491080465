import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { AlertKeys, Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget, TableRecord, AlertGridsterItem } from "src/app/_models";
import { DateService } from "src/app/_services";
import { AlertService, SpecifiedTableRecord } from "./alert.service";

@Component({
  selector: 'app-alert-viewer',
  templateUrl: './alert-viewer.component.html',
  styleUrls: ['./alert-viewer.component.css']
})
export class AlertViewerComponent implements OnInit, OnDestroy {
  @Input() widget: Widget;

  public alertKeys = AlertKeys;
  public alertRecord: TableRecord;
  public keys: string[] = [];
  public direction: Direction = 'ltr';
  public countdown: Date;

  private alertSubscription: Subscription;
  private date: Date;
  private initialDate: Date;
  private secondsCount = 0;

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private alertService: AlertService,
    private dateService: DateService
  ) {
  }

  /**
   * Gets time without consideration of time zone.
   * @param {TableRecord | number} record Current alert or time in milliseconds.
   * @returns {Date}
   * @private
   */
  private getDate(record: TableRecord): Date {
    const time = record?.time;

    if (!time) return;

    const date = new Date();
    const timeArr = time.split(':');
    date.setHours(+timeArr[0], +timeArr[1], +timeArr[2]);

    return date;
  }

  public ngOnInit(): void {
    this.initialDate = new Date();
    this.initialDate.setSeconds(0, 0);

    this.setTranslation();
    this.listenToDateChange();
    this.initKeys();
    this.listenToAlertRecordChange();

    setInterval(() => this.countdown = this.getCountDownTime(this.alertRecord), 1000);
  }

  public ngOnDestroy(): void {
    this.alertSubscription?.unsubscribe();
  }

  /**
   * Initialises the alert keys according to their x, y coordinates.
   * @param {number} lanes - the number of grid rows & columns. Default is 3.
   * @private
   */
  private initKeys(lanes: number = 3): void {
    const keysMatrix: string[][] = [[], [], []];
    this.widget.content.table.alertKeys.forEach((alertKey: AlertGridsterItem) => {
      keysMatrix[alertKey.y][alertKey.x] = alertKey.key;
    });

    for (let row = 0; row < lanes; row++) {
      for (let col = 0; col < lanes; col++) {
        keysMatrix[row][col] ??= '';
      }
    }

    this.keys = keysMatrix.flat();
  }

  /**
   * Calculates the current countdown time and returns it in the Date format.
   * @param {TableRecord} record Alert to calculate the countdown time.
   * @returns {Date}
   * @private
   */
  private getCountDownTime(record: TableRecord): Date {
    if (!record || !record.time) return;

    const date = new Date(this.date);
    date.setSeconds(0, 0);

    let selectedDate = new Date();

    if (this.initialDate.getTime() !== date.getTime()) {
      selectedDate = new Date(this.date);
      selectedDate.setSeconds(selectedDate.getSeconds() + this.secondsCount);
      this.secondsCount++;
    }

    const diff = this.getDate(record).getTime() - selectedDate.getTime();

    const countdown = moment();

    // Today at 12am plus the time left
    countdown.subtract(countdown.hours(), 'hours').subtract(countdown.minutes(), 'minutes').subtract(countdown.seconds(), 'seconds').add(diff);

    return countdown.toDate();
  }

  /**
   * Listens to alert table record change.
   * @private
   */
  private listenToAlertRecordChange(): void {
    this.alertSubscription = this.alertService.alertRecord$
      .subscribe((recordObject: SpecifiedTableRecord) => {
        if (recordObject.widgetId === this.widget.id) {
          this.alertRecord = recordObject.record;
        }
      });
  }

  /**
   * Listens to date change in the date calendar and calculates the day link.
   * @private
   */
  private listenToDateChange(): void {
    this.dateService.dateChanged$
      .subscribe((date: Date) => {
        this.date = date;
        this.secondsCount = 0;
      });
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
