<!--    Auto effect-->
<div [style.display]="widget.effect.type === effectTypes.None ? 'none' : ''"
     class="settings border-bottom-double container-sm flex justify-content-center align-items-center mt-1 mx-2 py-5"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <div class="flex justify-content-between align-items-center w-50">
    <span>{{ 'dashboard.widgetProperties.effects.auto' | translate }}</span>
    <mat-slide-toggle (change)="widget.effect.autoEffect = $event.checked; updateEffect(); updateAutoEffect()"
                      [checked]="widget.effect.autoEffect">
    </mat-slide-toggle>
  </div>
</div>

<!--    Effect select-->
<div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 py-3"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.effects.effect.title' | translate }}</span>
  <mat-form-field appearance="standard" class="select-font w-50">
    <mat-select #select [(value)]="widget.effect.type" (selectionChange)="updateEffect()">
      <mat-select-trigger class="d-flex align-items-center">
        <mat-icon class="mr-2">{{ getSelectedValue(select, 'icon') }}</mat-icon>
        &nbsp;{{ 'dashboard.widgetProperties.effects.effect.' + getSelectedValue(select, 'name').toLowerCase() | translate }}
      </mat-select-trigger>
      <mat-option *ngFor="let effect of effects"
                  [value]="effect.id"
                  [style.direction]="direction"
                  [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-icon>{{ effect.icon }}</mat-icon>
        {{ 'dashboard.widgetProperties.effects.effect.' + effect.name.toLowerCase() | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<!--    Scroll direction-->
<div [style.display]="widget.effect.type !== effectTypes.Scroll ? 'none' : ''"
     class="settings container-sm flex justify-content-between align-items-center mt-1 mx-2 py-3"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.effects.direction.title' | translate }}</span>
  <mat-button-toggle-group>
    <mat-button-toggle *ngIf="(widget.type == widgetTypes.Text && widget.content.table.multipleLines) || (widget.type == widgetTypes.Table && !widget.content.table.singleLine) || (widget.type === widgetTypes.Jewish && widget.content.jewish.multipleLines)"
                       [checked]="widget.effect.scrollDirection === scrollDirections.Up"
                       (change)="widget.effect.scrollDirection = scrollDirections.Up; updateEffect()"
                       [title]="'dashboard.widgetProperties.effects.direction.up' | translate">
      <mat-icon>north</mat-icon>
    </mat-button-toggle>

    <!-- Horizontal scroll direction available for all widgets except for Table & Jewish (multiple lines). -->
    <mat-button-toggle *ngIf="widget.type == widgetTypes.Text || (widget.type == widgetTypes.Table && widget.content.table.singleLine) || (widget.type === widgetTypes.Jewish && !widget.content.jewish.multipleLines)"
                       [checked]="widget.effect.scrollDirection === scrollDirections.Right"
                       (change)="widget.effect.scrollDirection = scrollDirections.Right; updateEffect()"
                       [title]="'dashboard.widgetProperties.effects.direction.right' | translate">
      <mat-icon>east</mat-icon>
    </mat-button-toggle>

    <mat-button-toggle *ngIf="(widget.type == widgetTypes.Text && widget.content.table.multipleLines) || (widget.type == widgetTypes.Table && !widget.content.table.singleLine) || (widget.type === widgetTypes.Jewish && widget.content.jewish.multipleLines)"
                       [checked]="widget.effect.scrollDirection === scrollDirections.Down"
                       (change)="widget.effect.scrollDirection = scrollDirections.Down; updateEffect()"
                       [title]="'dashboard.widgetProperties.effects.direction.down' | translate">
      <mat-icon>south</mat-icon>
    </mat-button-toggle>

    <!-- Horizontal scroll direction available for all widgets except for Table & Jewish (multiple lines). -->
    <mat-button-toggle *ngIf="widget.type == widgetTypes.Text || (widget.type == widgetTypes.Table && widget.content.table.singleLine) || (widget.type === widgetTypes.Jewish && !widget.content.jewish.multipleLines)"
                       [checked]="widget.effect.scrollDirection === scrollDirections.Left"
                       (change)="widget.effect.scrollDirection = scrollDirections.Left; updateEffect()"
                       [title]="'dashboard.widgetProperties.effects.direction.left' | translate">
      <mat-icon>west</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<!--    Scroll speed-->
<div [style.display]="widget.effect.type !== effectTypes.Scroll ? 'none' : ''"
     class="settings container-sm flex justify-content-between align-items-center mt-1 mx-2 pb-1"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <div>
    <div class="settings-title mr-3">{{ 'dashboard.widgetProperties.effects.speed' | translate }}</div>
    <div class="subtitle">({{ 'dashboard.widgetProperties.effects.speedSubtitle' | translate }})</div>
  </div>
  <mat-slider min="1" max="30" step="1" [(value)]="widget.effect.scrollSpeed" thumbLabel (change)="updateEffect()"></mat-slider>
</div>

<!--    Time interval-->
<div [style.display]="widget.effect.type !== effectTypes.FastSwap && widget.effect.type !== effectTypes.BlurredSwap ? 'none' : ''"
     class="settings container-sm flex justify-content-between align-items-center mt-1 mx-2 pb-1"
     [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.effects.interval' | translate }}</span>
  <mat-slider (click)="updateEffect()"
              [(value)]="widget.effect.scrollSpeed"
              max="10"
              min="1"
              step="1"
              thumbLabel
              tickInterval="1">
  </mat-slider>
</div>
