<mat-card-header class="w-100 mt-3 pb-3 pr-3 justify-content-between align-items-center widget-properties"
  [style.direction]="direction" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
  <mat-card-title>{{ 'dashboard.widgetProperties.title' | translate }} </mat-card-title>
  <mat-chip-list>
    <mat-chip [title]="'dashboard.widgetProperties.widgetName' | translate" disableRipple>
      {{ widget.name }}
    </mat-chip>
  </mat-chip-list>
  <button (click)="duplicateWidget()" [title]="'dashboard.widgetProperties.duplicateBtn' | translate" class="mini-btn"
    color="primary" mat-mini-fab>
    <mat-icon>content_copy</mat-icon>
  </button>
  <button (click)="removeWidget()" [title]="'dashboard.widgetProperties.removeBtn' | translate" class="mini-btn"
    color="accent" mat-mini-fab>
    <mat-icon>delete</mat-icon>
  </button>
</mat-card-header>

<mat-tab-group>
  <!--  Specific to widget-->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon [title]="'dashboard.widgetsTab.' + widgets[(widgetIcon$ | async).name].title.toLowerCase() | translate"
        [svgIcon]="widgets[(widgetIcon$ | async).name].icon" class="tab-icon">
      </mat-icon>
    </ng-template>

    <div [ngSwitch]="(widgetIcon$ | async).name">
      <div *ngSwitchCase="'text'">
        <app-text-dashboard [widget]="widget" [screen]="screen"></app-text-dashboard>
      </div>
      <div *ngSwitchCase="'jewish'">
        <app-jewish-dashboard [widget]="widget" [screen]="screen"></app-jewish-dashboard>
      </div>
      <div *ngSwitchCase="'table'">
        <app-table-dashboard [widget]="widget" [screen]="screen"></app-table-dashboard>
      </div>
      <div *ngSwitchCase="'media'">
        <app-media-dashboard [widget]="widget" [screen]="screen"></app-media-dashboard>
      </div>
      <div *ngSwitchCase="'clock'">
        <app-analog-clock-dashboard [widget]="widget" [screen]="screen"></app-analog-clock-dashboard>
      </div>
      <div *ngSwitchCase="'digital'">
        <app-digital-clock-dashboard [widget]="widget" [screen]="screen"></app-digital-clock-dashboard>
      </div>
      <div *ngSwitchCase="'pdf'">
        <app-pdf-dashboard [widget]="widget" [screen]="screen"></app-pdf-dashboard>
      </div>
      <div *ngSwitchCase="'rss'">
        <app-rss-dashboard [widget]="widget" [screen]="screen"></app-rss-dashboard>
      </div>
      <div *ngSwitchDefault>Default output</div>
    </div>

  </mat-tab>

  <!--  General-->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon" [title]="'dashboard.widgetProperties.tabs.general' | translate">settings</mat-icon>
    </ng-template>
    <app-general [widget]="widget"></app-general>
  </mat-tab>

  <!--  Background-->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon"
        [title]="'dashboard.widgetProperties.tabs.background' | translate">picture_in_picture</mat-icon>
    </ng-template>
    <app-background [widget]="widget" [screen]="screen"></app-background>
  </mat-tab>

  <!--  Font-->
  <mat-tab *ngIf="!disabledFontWidgetTypes.includes(widget.type)">
    <ng-template mat-tab-label>
      <mat-icon [title]="!disabledFontWidgetTypes.includes(widget.type)
          ? ('dashboard.widgetProperties.tabs.font' | translate)
          : ('dashboard.widgetProperties.tabs.fontDisabled' | translate)" class="tab-icon">
        text_format
      </mat-icon>
    </ng-template>
    <app-font [widget]="widget"></app-font>
  </mat-tab>

  <!--  Font of widget.TextByDate-->
  <mat-tab *ngIf="disabledFontWidgetTypes1.includes(widget.type) && widget.content.text.isPermanent == false">
    <ng-template mat-tab-label>
      <mat-icon [title]="!disabledFontWidgetTypes.includes(widget.type)
            ? ('dashboard.widgetProperties.tabs.font' | translate)
            : ('dashboard.widgetProperties.tabs.fontDisabled' | translate)" class="tab-icon">
        text_format
      </mat-icon>
    </ng-template>
    <app-font [widget]="widget"></app-font>
  </mat-tab>

  <!--  Effects-->
  <mat-tab *ngIf="widgetWithEffects.includes(widget.type)">
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon"
        [title]="'dashboard.widgetProperties.tabs.effects' | translate">directions_run</mat-icon>
    </ng-template>
    <app-effects [widget]="widget"></app-effects>
  </mat-tab>

  <!--  Schedulers -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon" [title]="'dashboard.widgetProperties.tabs.scheduler' | translate">date_range</mat-icon>
    </ng-template>
    <app-scheduler [scheduler]="widget.scheduler"></app-scheduler>
  </mat-tab>
</mat-tab-group>
