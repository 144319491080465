import { BehaviorSubject, Subject } from 'rxjs';
import { ChangeTimes } from '../_enums/change-times';
import { Scheduler } from './scheduler';
import { Background } from './background';
import { Effect } from './effect';
import { Languages } from '../_enums/languages';
import { WidgetTypes } from '../_enums/widget-types';
import { Font } from './font';
import { TableRecords } from './table';
import { WidgetContent } from './widget-content';
import { WidgetIcon } from './widget-icon';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';

export type Content = string | string[] | TableRecords;

export class WidgetState {
  id: number;
  name: string;
  type: WidgetTypes;
  size: Size;
  position: WidgetPosition;
  font: Font;
  background: Background;
  language: Languages;
  effect: Effect;
  content: WidgetContent;
  separator?: string;
  widgetIcon?: WidgetIcon;
  scheduler?: Scheduler;
  visible?: boolean;
  enabled?: boolean;
  changeTimeKey?: ChangeTimes;
  textBackgroundColor?: string;
  isHidden?: boolean;
}

export class Widget extends WidgetState {
  contentSource?: Subject<Content>;
  effectSource?: BehaviorSubject<Effect>;
  resizeStopSource = new Subject<IResizeEvent>();
  contentChangedSource = new Subject<void>();
}

export const widgetToWidgetState = (widget: Widget): WidgetState => {
  const widgetState = new WidgetState();
  widgetState.id = widget.id;
  widgetState.name = widget.name;
  widgetState.type = widget.type;
  widgetState.size = widget.size;
  widgetState.position = widget.position;
  widgetState.font = widget.font;
  widgetState.background = widget.background;
  widgetState.language = widget.language;
  widgetState.effect = widget.effect;
  widgetState.content = widget.content;
  widgetState.separator = widget.separator;
  widgetState.widgetIcon = widget.widgetIcon;
  widgetState.scheduler = widget.scheduler;
  widgetState.visible = widget.visible;
  widgetState.enabled = widget.enabled;
  widgetState.changeTimeKey = widget.changeTimeKey;
  widgetState.textBackgroundColor = widget.textBackgroundColor;
  widgetState.isHidden = widget.isHidden;
  return widgetState;
};

export type Widgets = Widget[];

export interface WidgetPosition {
  top: number;
  left: number;
}

export interface Size {
  width: number;
  height: number;
  widthPx: number;
  heightPx: number;
}
