import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Scheduler, TimeUnits } from "src/app/_models";
import { SchedulerService } from "../scheduler.service";

@Component({
  selector: 'app-blink',
  templateUrl: './blink.component.html',
  styleUrls: ['./blink.component.css']
})
export class BlinkComponent implements OnInit {
  @Input() scheduler: Scheduler;

  public units = TimeUnits;
  public timeUnits: string[];
  public direction: Direction = 'ltr';

  constructor(private schedulerService: SchedulerService,
              private translate: TranslateService,
              private translateHelper: TranslateHelper) {
    this.timeUnits = Object.keys(TimeUnits).filter(key => !isNaN(+key));
  }

  public ngOnInit(): void {
    this.setTranslation();
    this.schedulerService.schedulersCleared$.subscribe(() => this.scheduler.blink.enabled = false);
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
