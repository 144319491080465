import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KosherZmanimOptionDto } from '../../../../../_dto/kosher-zmanim-option-dto';
import { TimesDto } from '../../../../../_dto/times-dto';
import { Languages } from '../../../../../_enums/languages';
import { TranslateHelper } from '../../../../../_helpers/translate.helper';


@Component({
  selector: 'app-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.css']
})
export class TabContentComponent implements OnInit {
  @Input() userTimes: KosherZmanimOptionDto[];
  @Input() timeOptions: TimesDto;

  public currentUserTime: KosherZmanimOptionDto;
  public selectedOption: string;
  public direction = 'ltr';

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnInit(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => {
        this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr';
        // this.options = lang === Languages.Hebrew
        //   ? this.timeOptions.options.map(option => option.he)
        //   : this.timeOptions.options.map(option => option.en);
      });

    this.direction = this.translateHelper.direction;
    this.currentUserTime = this.userTimes?.find((time: KosherZmanimOptionDto) => time.key === this.timeOptions.key);
    this.selectedOption = this.currentUserTime?.option;
  }
}
