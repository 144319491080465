import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, UntypedFormControl, Validators } from "@angular/forms";
import { NgbDatepicker, NgbDateStruct, NgbCalendarHebrew, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorage, Languages } from "src/app/_enums";
import { SchedulerHelper, TranslateHelper } from "src/app/_helpers";
import { Widget, HebrewMonth } from "src/app/_models";

@Component({
  selector: "app-add-text",
  templateUrl: "./add-text.component.html",
  styleUrls: ["./add-text.component.css"],
})
export class AddTextComponent implements OnInit {
  @ViewChild("datepicker", { static: true }) datepicker: NgbDatepicker;

  @Input() widget: Widget;

  public scheduler1;
  public scheduler: any;
  public hebrewCalander: boolean = false;
  public hebrewDateText: string = "";
  public registerForm: UntypedFormGroup;
  public direction: Direction;
  public hebrewDateText1: NgbDateStruct;
  public dateText1: Date;

  private jewishCalendar = new NgbCalendarHebrew();
  public hebrewMonths = SchedulerHelper.hebrewMonths;
  public selectedMonthStart: HebrewMonth;
  public selectedDay: HebrewMonth;

  model: NgbDateStruct;

  values1 = [];

  values = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private translationHelper: TranslateHelper
  ) {
    this.translate.setDefaultLang(
      localStorage.getItem(LocalStorage.Language) || Languages.English
    );
  }

  ngOnInit(): void {
    this.setHebrewDate(new Date());
    this.direction = this.translationHelper.direction;
    this.initForm();
  }

  /**
   * Gets the username form control.
   */
  public get text(): AbstractControl {
    return this.registerForm.get("text");
  }

  /**
   * Gets the username form control.
   */
  public get text1(): AbstractControl {
    return this.registerForm.get("text1");
  }
  /**
   * Gets the username form control.
   */
  public get text2(): AbstractControl {
    return this.registerForm.get("text2");
  }
  /**
   * Gets the username form control.
   */
  public get text3(): AbstractControl {
    return this.registerForm.get("text3");
  }
  /**
   * Gets the username form control.
   */
  public get text4(): AbstractControl {
    return this.registerForm.get("text4");
  }
  /**
   * Gets the username form control.
   */
  public get text5(): AbstractControl {
    return this.registerForm.get("text5");
  }
  /**
   * Gets the username form control.
   */
  public get text6(): AbstractControl {
    return this.registerForm.get("text6");
  }

  public onSubmit(): void {
    const addTextDto: any = {
      // date: new Date('2022-04-04T08:54:19.000Z'),
      dateMonth:
        this.dateControlMonts.value?.num != undefined
          ? String(this.dateControlMonts.value?.num)
          : "32",
      dateDay:
        this.dateControlDays.value.name != undefined
          ? String(this.dateControlDays.value.name)
          : "32",
      hebrewDate: {
        year: 5782,
        month: parseInt(this.controlMonts.value?.num)
          ? parseInt(this.controlMonts.value?.num)
          : 32,
        // day: parseInt(this.controlDays.value?.num) ? parseInt(this.controlDays.value?.num) : 32,
        // day: parseInt(this.controlDays.value.name) ? parseInt(this.controlDays.value.name) : 32,
        day: parseInt(this.controlDays.value?.num)
          ? parseInt(this.controlDays.value?.num)
          : 32,
      },
      text: this.registerForm.value.text,
      text1: this.registerForm.value.text1,
      text2: this.registerForm.value.text2,
      text3: this.registerForm.value.text3,
      text4: this.registerForm.value.text4,
      text5: this.registerForm.value.text5,
      text6: this.registerForm.value.text6,
    };
    if (!this.widget.content.text.byDate) this.widget.content.text.byDate = [];
    this.widget.content.text.byDate.push(addTextDto);
    this.widget.content.text.isPermanent = false;
  }

  /**
   * Initialises the register form.
   * @private
   */
  private initForm(): void {
    this.registerForm = this.formBuilder.group({
      // date: ["", [Validators.required, Validators.minLength(1)], []],
      dateMonth: ["", [Validators.required]],
      dateDay: ["", [Validators.required]],
      hebrewDate: ["", [Validators.required], []],
      dateControlMonts: new UntypedFormControl("", Validators.required),
      dateControlDays: new UntypedFormControl("", Validators.required),

      controlMonts: new UntypedFormControl("", Validators.required),

      selectFormControlMons: new UntypedFormControl("", Validators.required),

      controlDays: new UntypedFormControl("", Validators.required),
      selectFormControlDays: new UntypedFormControl("", Validators.required),
      text: ["", [Validators.required]],
      text1: ["", [Validators.required]],
      text2: ["", [Validators.required]],
      text3: ["", [Validators.required]],
      text4: ["", [Validators.required]],
      text5: ["", [Validators.required]],
      text6: ["", [Validators.required]],
    });
  }
  /**
   * Sets the chosen date from the input field.
   * @param {string} datetime - date string.
   */
  public setDate(datetime: string): void {
    let pickedDate = new Date(datetime);
    this.setHebrewDate(pickedDate);
  }

  /**
   * Sets the Hebrew date according to the Gregorian one.
   * @param {Date} date - Gregorian date.
   * @private
   */
  public setHebrewDate(date: Date): void {
    this.dateText1 = date;
    const ngbDate = new NgbDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    this.model = this.jewishCalendar.fromGregorian(ngbDate);
    this.hebrewDateText1 = this.model;
  }


  dateControlMonts = new UntypedFormControl("", Validators.required);
  selectDateFormControlMons = new UntypedFormControl("", Validators.required);
  montsDate: any[] = [
    { name: "1", num: "1" },
    { name: "2", num: "2" },
    { name: "3", num: "3" },
    { name: "4", num: "4" },
    { name: "5", num: "5" },
    { name: "6", num: "6" },
    { name: "7", num: "7" },
    { name: "8", num: "8" },
    { name: "9", num: "9" },
    { name: "10", num: "10" },
    { name: "11", num: "11" },
    { name: "12", num: "12" },
  ];

  dateControlDays = new UntypedFormControl("", Validators.required);
  selectDateFormControlDays = new UntypedFormControl("", Validators.required);
  daysDate: any[] = [
    { name: "1" },
    { name: "2" },
    { name: "3" },
    { name: "4" },
    { name: "5" },
    { name: "6" },
    { name: "7" },
    { name: "8" },
    { name: "9" },
    { name: "10" },
    { name: "11" },
    { name: "12" },
    { name: "13" },
    { name: "14" },
    { name: "15" },
    { name: "16" },
    { name: "17" },
    { name: "18" },
    { name: "19" },
    { name: "20" },
    { name: "21" },
    { name: "22" },
    { name: "23" },
    { name: "24" },
    { name: "25" },
    { name: "26" },
    { name: "27" },
    { name: "28" },
    { name: "29" },
    { name: "30" },
    { name: "31" },
  ];

  controlMonts = new UntypedFormControl("", Validators.required);
  selectFormControlMons = new UntypedFormControl("", Validators.required);
  monts: any[] = [
    { name: "תשרי", num: "1" },
    { name: "חשוון", num: "2" },
    { name: "כסלו", num: "3" },
    { name: "טבת", num: "4" },
    { name: "שבט", num: "5" },
    { name: "אדר", num: "6" },
    { name: "'אדר א", num: "14" },
    { name: "'אדר ב", num: "13" },
    { name: "ניסן", num: "7" },
    { name: "אייר", num: "8" },
    { name: "סיון", num: "9" },
    { name: "תמוז", num: "10" },
    { name: "אב", num: "11" },
    { name: "אלול", num: "12" },
  ];
  datelControlDays = new UntypedFormControl("", Validators.required);

  controlDays = new UntypedFormControl("", Validators.required);
  selectFormControlDays = new UntypedFormControl("", Validators.required);
  days: any[] = [
    { name: "א", num: "1" },
    { name: "ב", num: "2" },
    { name: "ג", num: "3" },
    { name: "ד", num: "4" },
    { name: "ה", num: "5" },
    { name: "ו", num: "6" },
    { name: "ז", num: "7" },
    { name: "ח", num: "8" },
    { name: "ט", num: "9" },
    { name: "י", num: "10" },
    { name: "יא", num: "11" },
    { name: "יב", num: "12" },
    { name: "יג", num: "13" },
    { name: "יד", num: "14" },
    { name: "טו", num: "15" },
    { name: "טז", num: "16" },
    { name: "יז", num: "17" },
    { name: "יח", num: "18" },
    { name: "יט", num: "19" },
    { name: "כ", num: "20" },
    { name: "כא", num: "21" },
    { name: "כב", num: "22" },
    { name: "כג", num: "23" },
    { name: "כד", num: "24" },
    { name: "כה", num: "25" },
    { name: "כו", num: "26" },
    { name: "כז", num: "27" },
    { name: "כח", num: "28" },
    { name: "כט", num: "29" },
    { name: "ל", num: "30" },
  ];
}
