<div [style.direction]="direction"
     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">

<!--  Widget name-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.general.name' | translate }}</span>
    <mat-form-field appearance="standard"
                    class="select-font w-50"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <input matInput type="text" [(ngModel)]="widget.name">
    </mat-form-field>
  </div>

<!--  Widget type-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.general.type' | translate }}</span>
    <mat-form-field appearance="standard"
                    class="select-font w-50"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <input matInput type="text" disabled [value]="'dashboard.widgetsTab.' + widget.type.toLowerCase() | translate">
    </mat-form-field>
  </div>

<!--  Widget language-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-4 mx-2 pb-4">
    <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.general.language' | translate }}</span>
    <mat-button-toggle-group>
      <mat-button-toggle
        (click)="selectWidgetLanguage(languages.English)"
        [checked]="widget.language === languages.English"
        class="toggle-button"
        title="{{ 'generalSettings.en' | translate }}"
        value="en">
        <mat-icon svgIcon="usa"></mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        (click)="selectWidgetLanguage(languages.Hebrew)"
        [checked]="widget.language === languages.Hebrew"
        title="{{ 'generalSettings.he' | translate }}"
        class="toggle-button"
        value="he">
        <mat-icon svgIcon="israel"></mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

<!--  Location-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
    <div class="flex-grow-1">
      <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.general.location' | translate }}</span>
    </div>
    <div class="d-flex justify-content-around flex-grow-2">
      <mat-form-field appearance="standard"
                      class="select-font location"
                      [style.direction]="direction"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-label>{{ 'dashboard.widgetProperties.general.top' | translate }}</mat-label>
        <span matSuffix>&nbsp;%</span>
        <input matInput type="number" step="0.1" min="0" max="100" [(ngModel)]="widget.position.top" class="ltr">
      </mat-form-field>
      <mat-form-field appearance="standard"
                      class="select-font location"
                      [style.direction]="direction"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-label>{{ 'dashboard.widgetProperties.general.left' | translate }}</mat-label>
        <span matSuffix>&nbsp;%</span>
        <input matInput type="number" step="0.1" min="0" max="100" [(ngModel)]="widget.position.left" class="ltr">
      </mat-form-field>
    </div>
  </div>

<!--  Size-->
  <div class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
    <div class="flex-grow-1">
      <span class="settings-title mr-3">{{ 'dashboard.widgetProperties.general.size' | translate }}</span>
    </div>
    <div class="d-flex justify-content-around flex-grow-2">
      <mat-form-field appearance="standard"
                      class="select-font location"
                      [style.direction]="direction"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-label>{{ 'dashboard.widgetProperties.general.width' | translate }}</mat-label>
        <span matSuffix>&nbsp;%</span>
        <input matInput type="number" step="0.1" min="0" max="100" [(ngModel)]="widget.size.width" class="ltr">
      </mat-form-field>
      <mat-form-field appearance="standard"
                      class="select-font location"
                      [style.direction]="direction"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
        <mat-label>{{ 'dashboard.widgetProperties.general.height' | translate }}</mat-label>
        <span matSuffix>&nbsp;%</span>
        <input matInput type="number" step="0.1" min="0" max="100" [(ngModel)]="widget.size.height" class="ltr">
      </mat-form-field>
    </div>
  </div>

<!--  Change times-->
  <div *ngIf="changeTimeWidgetTypes.includes(widget.type)"
       class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
    <span>{{ 'dashboard.widgetProperties.general.changeTime' | translate }}</span>
    <mat-form-field appearance="standard"
                    class="select-font w-50"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-select [(value)]="widget.changeTimeKey">
        <mat-option *ngFor="let time of changeTimes"
                    [value]="time"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          {{ 'changeTimes.' + time | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

<!--  Change times of Text by Date-->
  <div *ngIf="changeTimeWidgetTypesOfTextByDate.includes(widget.type) && widget.content.text.isPermanent == false"
      class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1">
  <span>{{ 'dashboard.widgetProperties.general.changeTime' | translate }}</span>
  <mat-form-field appearance="standard"
              class="select-font w-50"
              [style.direction]="direction"
              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-select [(value)]="widget.changeTimeKey">
      <mat-option *ngFor="let time of changeTimes"
              [value]="time"
              [style.direction]="direction"
              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    {{ 'changeTimes.' + time | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  </div>
</div>
