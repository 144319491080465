import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Screen, Widget } from "src/app/_models";
import { WidgetService } from "src/app/_services";
import { MediaContentComponent } from "./media-content/media-content.component";

@Component({
  selector: 'app-media-dashboard',
  templateUrl: './media-dashboard.component.html',
  styleUrls: ['./media-dashboard.component.css']
})
export class MediaDashboardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;

  public direction: Direction = 'ltr';

  private mediaContentRef: MatDialogRef<MediaContentComponent>;

  constructor(
    private dialog: MatDialog,
    private widgetService: WidgetService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnInit(): void {
    this.setTranslation();
  }

  /**
   * Opens media content modal window. Passes data to MediaContentComponent.
   */
  public openContentModal(): void {
    if (!!this.mediaContentRef) return;

    this.mediaContentRef = this.dialog.open(MediaContentComponent);
    this.mediaContentRef.componentInstance.widget = this.widget;
    this.mediaContentRef.componentInstance.screen = this.screen;

    this.mediaContentRef.afterClosed()
      .subscribe((okayed: boolean) => {
        this.mediaContentRef = null;
        if (okayed || okayed === undefined) { // if not clicked Cancel
          this.widgetService.updateScreen(this.screen);
        } else {
          this.screen.dirty = false;
        }
      });
  }

  /**
   * Toggles image stretch.
   * @param {boolean} checked - whether an image is stretched.
   */
  public toggleStretched(checked: boolean): void {
    this.widget.content.media.stretched = checked;
    this.widgetService.updateScreen(this.screen);
  }

  /**
   * Marks screen "dirty" for purpose of page leaving warning.
   */
  public markScreenDirty(): void {
    this.screen.dirty = true;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
