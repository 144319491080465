import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  public transform(timeInSeconds: number): string {

    if (!timeInSeconds) return '';

    const sec = this.translate.instant('dashboard.widgetProperties.specific.media.sec');
    const min = this.translate.instant('dashboard.widgetProperties.specific.media.min');
    const h = this.translate.instant('dashboard.widgetProperties.specific.media.h');

    if (timeInSeconds >= 3600) {
      const hours = Math.floor(timeInSeconds / 3600);
      timeInSeconds -= hours * 3600;
      return `${hours} ${h} ${Math.floor(timeInSeconds / 60)} ${min} ${timeInSeconds % 60} ${sec}`;
    }

    if (timeInSeconds >= 60) {
      return `${Math.floor(timeInSeconds / 60)} ${min} ${timeInSeconds % 60} ${sec}`;
    }

    return `${timeInSeconds} ${sec}`;
  }

}
