<div class="container popup-size position-relative">

  <button [title]="'buttons.close' | translate" class="close-btn" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>

  <div class="title">
    <mat-label translate>settings.title</mat-label>
  </div>

<!--  General-->
  <mat-tab-group [selectedIndex]="tabIndex" color="accent" mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>settings</mat-icon>
        {{ 'settings.general' | translate }}
      </ng-template>
      <app-general-settings [user]="user$ | async"></app-general-settings>
    </mat-tab>

<!--    Profile-->
    <mat-tab *ngIf="!isAdmin">
      <ng-template mat-tab-label>
        <mat-icon>how_to_reg</mat-icon>
        {{ 'settings.profile' | translate }}
      </ng-template>
      <app-update-profile></app-update-profile>
    </mat-tab>

<!--    Times-->
    <mat-tab *ngIf="!isAdmin">
      <ng-template mat-tab-label>
        <mat-icon>more_time</mat-icon>
        {{ 'settings.times' | translate }}
      </ng-template>
      <app-dynamic-times [times]="times"></app-dynamic-times>
    </mat-tab>

<!--    Jewish-->
    <mat-tab *ngIf="!isAdmin">
      <ng-template mat-tab-label>
        <mat-icon svgIcon="menorah"></mat-icon>
        {{ 'settings.jewish' | translate }}
      </ng-template>
      <app-jewish-settings *ngIf="times" [times]="times"></app-jewish-settings>
    </mat-tab>

<!--    Shortcuts-->
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>keyboard</mat-icon>
        {{ 'settings.shortcuts.title' | translate }}
      </ng-template>
      <app-shortcuts></app-shortcuts>
    </mat-tab>

<!--    Contact-->
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>contact_support</mat-icon>
        {{ 'settings.contact' | translate }}
      </ng-template>
      <app-contact-us></app-contact-us>
    </mat-tab>
  </mat-tab-group>
</div>
