import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { MatNativeDateModule } from '@angular/material/core';
import { AngularDraggableModule } from 'angular2-draggable';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EditModule } from './_modules/edit/edit.module';
import { SettingsModule } from './_modules/settings/settings.module';
import { SharedModule } from './_modules/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ScreenComponent } from './screen/screen.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { TestErrorComponent } from './test-error/test-error.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ServerErrorComponent } from './errors/server-error/server-error.component';
import { HorizontalScrollComponent } from './components/horizontal-scroll/horizontal-scroll.component';
import { VerticalScrollComponent } from './components/vertical-scroll/vertical-scroll.component';
import { BaseScrollComponent } from './components/base-scroll/base-scroll.component';
import { StoreModule } from '@ngrx/store';
import { widgetsReducer } from './store/widget/widget.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppState } from './store/app.state';
import { userReducer } from './store/user/user.reducer';
import { UserEffects } from './store/user/user.effects';
import { SubscriptionTrackerService } from './_services/subscription-tracker.service';
import { patchRxjs } from './_services/patch-rxjs';
import { ErrorInterceptor, LocationInterceptor, TokenInterceptor } from './_interceptors';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage, Languages } from './_enums';
import { EffectDirective, FitTextDirective, FitContainer, } from './_directives';
import { YahrzeitViewerComponent, TableViewerComponent, TextFlasherComponent, TextViewerComponent, JewishViewerComponent, AnalogClockViewerComponent, WebViewerComponent, PdfViewerComponent, RssViewerComponent, HtmlViewerComponent, SchedulerMediaListComponent, AlertViewerComponent, TableVerticalScrollComponent, MediaViewerComponent, DigitalClockViewerComponent } from './_widgets';
import { JewishPipe, HalachicPipe, TimeFormatPipe, FilterTableRecordsPipe, RefreshPipe, UnpadPipe, TableGetTimePipe, SeparatorPipe } from './_pipes';

@NgModule({
  declarations: [
    AppComponent,
    ScreenComponent,
    YahrzeitViewerComponent,
    TableViewerComponent,
    TextFlasherComponent,
    TextViewerComponent,
    JewishViewerComponent,
    HomeComponent,
    AnalogClockViewerComponent,
    EffectDirective,
    MediaViewerComponent,
    FitTextDirective,
    FitContainer,
    DigitalClockViewerComponent,
    WebViewerComponent,
    PdfViewerComponent,
    RssViewerComponent,
    HtmlViewerComponent,
    LoadingSpinnerComponent,
    SchedulerMediaListComponent,
    JewishPipe,
    HalachicPipe,
    TimeFormatPipe,
    TestErrorComponent,
    NotFoundComponent,
    ServerErrorComponent,
    FilterTableRecordsPipe,
    RefreshPipe,
    AlertViewerComponent,
    UnpadPipe,
    TableGetTimePipe,
    HorizontalScrollComponent,
    SeparatorPipe,
    VerticalScrollComponent,
    TableVerticalScrollComponent,
    BaseScrollComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    PdfViewerModule,
    SharedModule,
    EditModule,
    SettingsModule,
    AngularDraggableModule,
    MatNativeDateModule,
    StoreModule.forRoot<AppState>({ user: userReducer, widgets: widgetsReducer }),
    EffectsModule.forRoot([UserEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  providers: [
    JewishPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(subscriptionTracker: SubscriptionTrackerService, private translate: TranslateService) {
    this.configureTranslation();
    if (environment.debugMemoryLeak) {
      patchRxjs(subscriptionTracker);
      this.trackSubscriptions(subscriptionTracker);
    }
  }

  private trackSubscriptions(subscriptionTracker: SubscriptionTrackerService): void {
    let previousSubscriptionTotal = 0;
    const newSubscriptionsLast10Seconds = [];
    let previousSubscriptions = {};

    setInterval(() => {
      const currentSubscriptions = subscriptionTracker.getSubscriptionCounters();
      if (!this.subscriptionsHaveChanged(previousSubscriptions, currentSubscriptions)) {
        return;
      }

      const currentSubscriptionTotal = Object.values(currentSubscriptions).reduce((sum, count) => sum + count, 0);
      const newSubscriptions = currentSubscriptionTotal - previousSubscriptionTotal;
      previousSubscriptionTotal = currentSubscriptionTotal;

      newSubscriptionsLast10Seconds.push(newSubscriptions);
      if (newSubscriptionsLast10Seconds.length > 10) {
        newSubscriptionsLast10Seconds.shift();
      }

      const movingAverage = newSubscriptionsLast10Seconds.reduce((sum, count) => sum + count, 0) / newSubscriptionsLast10Seconds.length;

      console.log('Subscription Counters:', currentSubscriptions);
      console.log('New Listeners Moving Average:', movingAverage);
      console.log('Total Listeners:', currentSubscriptionTotal);

      previousSubscriptions = { ...currentSubscriptions };
    }, 1000);
  }

  private subscriptionsHaveChanged(prevSubscriptions: Record<string, number>, currentSubscriptions: Record<string, number>): boolean {
    return JSON.stringify(prevSubscriptions) !== JSON.stringify(currentSubscriptions);
  }

  private configureTranslation(): void {
    this.translate.setDefaultLang(Languages.English);
    const currentLanguage = localStorage.getItem(LocalStorage.Language) || Languages.English;
    this.translate.use(currentLanguage);
  }
}