<!-- <p>text-content works!</p> -->
<div class="wrapper" cdkDrag
cdkDragRootElement=".cdk-overlay-pane">
<mat-card class="px-5">

  <button [style.direction]="direction"
  [title]="'buttons.close' | translate" class="close-btn" mat-dialog-close mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>

  <mat-card-title class="text-center">{{ 'dashboard.widgetProperties.specific.textByDate.ListOfTextByDate' | translate }}</mat-card-title>
  <!--      Buttons-->
  <div style="display: flex; justify-content: space-between;" [style.direction]="direction">
    <div  class="button-container" (click)="uploadFile.click()" [style.direction]="direction">
     <button mat-raised-button color="primary">{{ 'dashboard.widgetProperties.specific.textByDate.chooseFile' | translate }}</button>
     <input #uploadFile (change)="fileChanged($event)" type='file' style="display:none"/>
   </div>

    <div class="button-container" [style.direction]="direction">
      <button (click)="openUpdatePopup(undefined)" class="add-btn" mat-fab title="{{ 'userList.buttons.add' | translate }}">
        <mat-icon>add</mat-icon>
      </button>
    </div>
</div>
    <table style="width: 715px;"
    mat-table [dataSource]="dataSource" [style.direction]="direction"
      class="mat-elevation-z8 demo-table ta" >

      <!-- hebrewDate Column -->
      <ng-container  matColumnDef="hebrewDate">
        <th style="width: 30%;
        padding-right: 5%;
        " mat-header-cell *matHeaderCellDef class="ta"
       >
          {{ 'dashboard.widgetProperties.specific.textByDate.hebrewDate' | translate }}</th>
        <td style="    padding-right: 5%;
        " class="ta" mat-cell *matCellDef="let element"
        >
        {{ setHebrewDate1(georgianToHebrewDate(element.hebrewDate).day,georgianToHebrewDate(element.hebrewDate).month) }}

        </ng-container>
      <!-- text Column -->
      <ng-container matColumnDef="text">
        <th mat-header-cell *matHeaderCellDef class="ta">
          {{ 'dashboard.widgetProperties.specific.textByDate.text' | translate }}</th>
        <td style="  width: 100px;
        " mat-cell *matCellDef="let element"  class="ta" [style.direction]="direction">
          {{element.text != "" ? element.text : element.text1 != "" ? element.text1 : element.text2 != "" ? element.text2 : element.text3 != ""  ? element.text3 : element.text4 != ""  ? element.text4 : element.text5 != ""  ? element.text5 : element.text6 != "" ? element.text6 : element.text }}</td>
      </ng-container>


      <ng-container matColumnDef="delete">
        <th style="width: 17%;" mat-header-cell *matHeaderCellDef class="ta">
          {{ 'userList.titles.delete' | translate }}
        </th>

        <td style="width:17%;" mat-cell *matCellDef="let element" class="ta">
          <button (click)="$event.stopPropagation()" (click)="openConfirmationPopup(translate.instant('dashboard.widgetProperties.specific.textByDate.delete'), deleteRow.bind(this), element)"

                  mat-icon-button
                  color="warn"
                  [title]="'userList.buttons.deleteUser' | translate">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" [style.direction]="direction" style="cursor: auto"></tr>
        <tr mat-row *matRowDef="let row; index as i; columns: displayedColumns" [style.direction]="direction" (click)="openUpdatePopup(row, i, $event.target)" class="user-row"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
            No text found
          </td>
        </tr>

      </table>
    <mat-paginator [length]="dataSource / 10" pageSize="10" showFirstLastButtons [style.direction]="direction"  aria-label="Select page"></mat-paginator>
  <!-- </div> -->
  </mat-card>
</div>
