import { Component, ChangeDetectionStrategy, AfterViewInit, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { Languages, Devices } from "src/app/_enums";
import { ScrollHelper } from "src/app/_helpers";
import { ScrollDirections, EffectTypes } from "src/app/_models";
import { ResponsiveService } from "src/app/_services";

enum Coefficient {
  Smartphone = 1 / 3,
  Tablet = 0.5,
  TabletLarge = 0.6,
  DesktopSmall = 0.7,
  Display4K = 2
}

@Component({
  selector: 'app-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrls: ['./html-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HtmlViewerComponent implements AfterViewInit, OnInit {
  @ViewChild('scrollElement') scrollDivRef: ElementRef<HTMLDivElement>;

  @Input() content = '';
  @Input() scrollDirection: ScrollDirections;
  @Input() effectType: EffectTypes;
  @Input() language: Languages;
  @Input() fontSize: number;

  public htmlContent: SafeHtml;
  public languages = Languages;

  private resolution: Devices;

  constructor(
    private el: ElementRef<HTMLElement>,
    private sanitizer: DomSanitizer,
    private responsiveService: ResponsiveService
  ) {
  }

  public ngOnInit(): void {
    this.checkAndReplaceFontSize();
  }

  public ngAfterViewInit(): void {
    if (this.scrollDirection === ScrollDirections.Right || this.scrollDirection === ScrollDirections.Left) {
      this.el.nativeElement.style.display = 'flex';
    }

    if (this.scrollDirection === ScrollDirections.Up || this.scrollDirection === ScrollDirections.Down) {
      this.el.nativeElement.style.flexDirection = 'column';
      this.el.nativeElement.style.whiteSpace = 'normal';
    }

    if (this.effectType === EffectTypes.Scroll) {
      this.silkySmoothScroll();
    }
  }

  private silkySmoothScroll(): void {
    const scrollElement = this.scrollDivRef.nativeElement;
    const parentElement = this.el.nativeElement;

    const height = parseInt(getComputedStyle(scrollElement).height, 10);
    const parentHeight = parseInt(getComputedStyle(parentElement).height, 10);

    const width = parseInt(getComputedStyle(scrollElement).width, 10);
    const parentWidth = parseInt(getComputedStyle(parentElement).width, 10);

    let itemsPerView: number;

    const isTooLarge = height > parentHeight;
    const isHorizontal = this.scrollDirection === ScrollDirections.Left || this.scrollDirection === ScrollDirections.Right;

    if (isTooLarge || isHorizontal) {
      parentElement.classList.remove('scroll-wrapper');
    }

    switch (this.scrollDirection) {
      case ScrollDirections.Up:
      case ScrollDirections.Down:
        itemsPerView = Math.floor(parentHeight / height);
        if (itemsPerView === Infinity) {
          itemsPerView = 1;
        }
        ScrollHelper.duplicateHtmlElement(itemsPerView, scrollElement, parentElement, null);
        break;
      case ScrollDirections.Left:
      case ScrollDirections.Right:
        scrollElement.style.whiteSpace = 'nowrap';
        itemsPerView = Math.floor(parentWidth / width);
        if (itemsPerView === Infinity) {
          itemsPerView = 1;
        }
        ScrollHelper.duplicateHtmlElement(itemsPerView, scrollElement, parentElement, null);
    }
  }

  private checkAndReplaceFontSize(): void {
    const windowWidth = window.innerWidth;
      this.htmlContent = this.content;
    if (windowWidth > Devices.DesktopSmall && windowWidth < Devices.Display4K) {
      this.htmlContent = this.content;
    } else {
      this.replaceFontParameters();
    }
  }

  private replaceFontParameters(): void {
    const htmlContent = this.content['changingThisBreaksApplicationSecurity'];
    this.resolution = HtmlViewerComponent.getResolution();
    this.htmlContent = this.getSafeHtml(htmlContent);
  }

  private static getResolution(): Devices {
    const windowWidth = window.innerWidth;

    if (windowWidth <= Devices.Smartphone) {
      return Devices.Smartphone;
    } else if (windowWidth <= Devices.Tablet) {
      return Devices.Tablet;
    } else if (windowWidth <= Devices.TabletLarge) {
      return Devices.TabletLarge;
    } else if (windowWidth <= Devices.DesktopSmall) {
      return Devices.DesktopSmall;
    } else {
      return Devices.Display4K;
    }
  }

  private getSafeHtml(htmlContent: string): SafeHtml {
    const fontSizeRegex = /[0-9]*pt;/g;

    const replacedHtml = htmlContent
      .replace(fontSizeRegex, this.convertFontSize.bind(this));

    return this.sanitizer.bypassSecurityTrustHtml(replacedHtml);
  }

  private convertFontSize(match: string): string {
    return this.responsiveService.resolution.width === Devices.Display4K
      ? parseInt(match, 10) / 4 + 'pt;'
      : parseInt(match, 10) * this.coefficient + 'pt;';
  }

  private get coefficient(): number {
    return Coefficient[Devices[this.resolution]];
  }
}
