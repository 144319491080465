<div class="scheduler d-flex flex-column align-items-center justify-content-center w-100">

  <!--  Enable slider-->
  <div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3">
    <mat-slide-toggle (change)="scheduler.blink.enabled = $event.checked"
                      [checked]="scheduler.blink.enabled"
                      labelPosition="before"
                      [style.direction]="direction"
                      [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <span class="mx-3">{{ 'dashboard.widgetProperties.scheduler.blink.enable' | translate }}</span>
    </mat-slide-toggle>
  </div>

<!--  Time units-->
  <div [class.disabled]="!scheduler.blink.enabled"
       class="settings container-sm d-flex justify-content-center align-items-center mt-1 mx-2 pb-1">
    <mat-form-field appearance="standard"
                    class="select-font w-50"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.blink.timeUnits.title' | translate }}</mat-label>
      <mat-select [(value)]="scheduler.blink.timeUnit">
        <mat-option *ngFor="let unit of timeUnits"
                    [value]="+unit"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
          {{ 'dashboard.widgetProperties.scheduler.blink.timeUnits.' + unit | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div [class.disabled]="!scheduler.blink.enabled"
       class="settings container-sm d-flex justify-content-around align-items-center mt-1 mx-2 pb-1">
<!--    On time-->
    <mat-form-field appearance="standard"
                    class="select-font w-25"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.blink.timeOn' | translate }}</mat-label>
      <input [(ngModel)]="scheduler.blink.onTime"
             appNumericInput
             [minValue]="1"
             matInput
             type="number"
             min="1">
    </mat-form-field>

<!--    Off time-->
    <mat-form-field appearance="standard"
                    class="select-font w-25"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.blink.timeOff' | translate }}</mat-label>
      <input [(ngModel)]="scheduler.blink.offTime"
             appNumericInput
             matInput
             type="number"
             min="0">
    </mat-form-field>
  </div>

<!--  Units count-->
  <div [class.disabled]="!scheduler.blink.enabled"
       class="settings container-sm d-flex justify-content-around align-items-center mt-1 mx-2 pb-1">
    <mat-form-field appearance="standard"
                    class="select-font w-25"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.blink.unitsCount' | translate }}</mat-label>
      <input [(ngModel)]="scheduler.blink.unitsCount"
             appNumericInput
             [minValue]="1"
             matInput
             type="number"
             min="1">
    </mat-form-field>

<!--    Active unit index-->
    <mat-form-field appearance="standard"
                    class="select-font w-25"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.blink.index' | translate }}</mat-label>
      <input [(ngModel)]="scheduler.blink.activeUnit"
             [max]="scheduler.blink.unitsCount"
             appNumericInput
             [maxValue]="scheduler.blink.unitsCount"
             [minValue]="1"
             matInput
             min="1"
             type="number">
    </mat-form-field>
  </div>

  <div [class.disabled]="!scheduler.blink.enabled"
       class="conclusion pt-3"
       [style.direction]="direction"
       [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <p>{{ 'dashboard.widgetProperties.scheduler.blink.durationIs' | translate }} {{ scheduler.blink.unitsCount * (scheduler.blink.onTime +
      scheduler.blink.offTime) }} {{ ('dashboard.widgetProperties.scheduler.blink.timeUnits.' + scheduler.blink.timeUnit | translate).toLowerCase()
      }}.</p>
    <p>{{ 'dashboard.widgetProperties.scheduler.blink.widgetDisplayed' | translate }} {{ (scheduler.blink.activeUnit - 1) *
    (scheduler.blink.onTime + scheduler.blink.offTime) }} - {{
    (scheduler.blink.activeUnit - 1) * (scheduler.blink.onTime + scheduler.blink.offTime) + scheduler.blink.onTime }} {{
      ('dashboard.widgetProperties.scheduler.blink.timeUnits.' + scheduler.blink.timeUnit | translate).toLowerCase()}}.</p>
  </div>

</div>
