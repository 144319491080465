export enum AlignItems {
  Top = 'flex-start',
  Middle = 'center',
  Bottom = 'flex-end'
}

export enum TextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify'
}

export enum JustifyContent {
  Left = 'flex-start',
  Center = 'center',
  Right = 'flex-end'
}

export enum ALHorizontalAlign {
  Right = 'flex-start',
  Center = 'center',
  Left = 'flex-end'
}
