<!--Enable alert layout-->
<div class="settings container-sm d-flex flex-column justify-content-between align-items-center mt-1 mx-2 py-3">
  <mat-slide-toggle (change)="changeLayout($event.checked)"
                    [checked]="widget.content.table.alertLayout"
                    labelPosition="before"
                    [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <span class="mx-3">{{ 'dashboard.widgetProperties.specific.table.alert.enable' | translate }}</span>
  </mat-slide-toggle>
</div>

<!--Edit alert-->
<div [class.disabled]="!widget.content.table.alertLayout"
     class="settings container-sm d-flex justify-content-center align-items-center">
  <button mat-raised-button (click)="openContentModal()"
          [style.direction]="direction"
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
    <mat-icon>list</mat-icon>
    {{ 'dashboard.widgetProperties.specific.table.editAlert' | translate }}
  </button>
</div>
