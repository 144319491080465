<div class="scheduler date-range-container w-100">
  <div class="d-flex justify-content-between align-items-center mb-2">
    <span>{{ 'dashboard.widgetProperties.scheduler.dateRange.gregorian' | translate }}</span>
    <mat-slide-toggle (change)="isHebrewRange = !isHebrewRange" [checked]="isHebrewRange" class="mx-3"></mat-slide-toggle>
    <span>{{ 'dashboard.widgetProperties.scheduler.dateRange.hebrew' | translate }}</span>
  </div>

  <!--  Gregorian date range  -->
  <div *ngIf="!isHebrewRange" class="datetime-range mt-3">
    <mat-form-field [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.start' | translate }}</mat-label>
      <input #sourceStart matInput type="datetime-local" (input)="setDateRange(sourceStart, targetStart)">
      <input #targetStart matInput type="text" disabled [value]="formatDate(scheduler.dateRange?.start)">
      <button [title]="'dashboard.widgetProperties.scheduler.dateRange.pick' | translate"
              class="calendar-button"
              mat-icon-button>
        <mat-icon>event</mat-icon>
      </button>
      <button (click)="clearDate(targetStart)"
              [title]="'buttons.clear' | translate"
              mat-icon-button
              class="clear-button">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field [style.direction]="direction"
                    [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.end' | translate }}</mat-label>
      <input #sourceEnd matInput type="datetime-local" (input)="setDateRange(sourceEnd, targetEnd)">
      <input #targetEnd matInput type="text" disabled [value]="formatDate(scheduler.dateRange?.end)">
      <button [title]="'dashboard.widgetProperties.scheduler.dateRange.pick' | translate"
              class="calendar-button"
              mat-icon-button>
        <mat-icon>event</mat-icon>
      </button>
      <button (click)="clearDate(targetEnd)"
              [title]="'buttons.clear' | translate"
              mat-icon-button
              class="clear-button">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <!--  Hebrew date range  -->
  <div *ngIf="isHebrewRange" class="w-100">
    <div class="d-flex justify-content-between align-items-center w-100 hebrew-datetime-field"
         [style.direction]="direction"
         [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.start' | translate }}</mat-label>
      <mat-form-field class="month">
        <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.month' | translate }}</mat-label>
        <mat-select #monthStart [value]="selectedMonthStart" (selectionChange)="setHebrewDate()">
          <mat-option *ngFor="let month of hebrewMonths" [value]="month">
            {{ direction === 'rtl' ? month.he : month.en }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="day">
        <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.day' | translate }}</mat-label>
        <input #dayStart (input)="setHebrewDate()" matInput type="number" min="1" [max]="selectedMonthStart?.days"
               [value]="scheduler?.hebrewRange?.start.date.day.toString()">
      </mat-form-field>

      <mat-form-field class="time">
        <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.time' | translate }}</mat-label>
        <input #timeStart (input)="setHebrewTime(timeStart.value, 'start')" [value]="scheduler?.hebrewRange?.start.time" matInput type="time">
      </mat-form-field>
    </div>

    <div class="d-flex justify-content-between align-items-center w-100 hebrew-datetime-field"
         [style.direction]="direction"
         [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.end' | translate }}</mat-label>
      <mat-form-field class="month">
        <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.month' | translate }}</mat-label>
        <mat-select #monthEnd [value]="selectedMonthEnd" (selectionChange)="setHebrewDate()">
          <mat-option *ngFor="let month of hebrewMonths" [value]="month">
            {{ direction === 'rtl' ? month.he : month.en }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="day">
        <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.day' | translate }}</mat-label>
        <input #dayEnd (input)="setHebrewDate()" matInput type="number" min="1" [max]="selectedMonthEnd?.days"
               [value]="scheduler?.hebrewRange?.end.date.day.toString()">
      </mat-form-field>

      <mat-form-field class="time">
        <mat-label>{{ 'dashboard.widgetProperties.scheduler.dateRange.time' | translate }}</mat-label>
        <input #timeEnd (input)="setHebrewTime(timeEnd.value, 'end')" [value]="scheduler?.hebrewRange?.end.time" matInput type="time">
      </mat-form-field>
    </div>

    <div class="d-flex justify-content-center w-100">
      <button (click)="clearHebrewRange()"
              [title]="'buttons.clearAll' | translate"
              mat-button>
        {{ 'buttons.clearAll' | translate }}
      </button>
    </div>
  </div>
</div>
