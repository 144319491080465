<!-- Start of parent div -->
<div class="parent">
  <!-- Start of grid item div, which is repeated for each key in widget.content.table.keys or keys -->
  <!-- The reason that we loop over keys and not checing there existence is we want to preserve the order of the keys -->
  <div
    *ngFor="let key of (widget.content.table.keys || keys); let i = index"
    [class]="'cell-' + i"
    class="grid-item"
    [ngStyle]="{
      'direction': (key === alertKeys.Name && alertRecord?.custom)
        && (alertRecord?.custom.charCodeAt(0) >= 1424 && alertRecord?.custom.charCodeAt(0) <= 1535)
        ? 'rtl'
        : 'ltr'
    }">

    <!-- ngSwitch directive to decide which template to display based on the value of 'key' -->
    <ng-container [ngSwitch]="key">

      <!-- Case when key equals alertKeys.Name -->
      <ng-container *ngSwitchCase="alertKeys.Name">
        {{ alertRecord | halachic:widget.language }}
      </ng-container>

      <!-- Case when key equals alertKeyzs.Time -->
      <ng-container *ngSwitchCase="alertKeys.Time">
        {{ alertRecord?.time | timeFormat:widget.content.table.format:widget.content.table.isAmPm:widget.content.table.isUpper:widget.content.table.padZero:widget.content.table.displaySeconds }}
      </ng-container>

      <!-- Case when key equals alertKeys.Countdown -->
      <ng-container *ngSwitchCase="alertKeys.Countdown">
        {{ countdown | date : 'H:mm:ss' }}
      </ng-container>

    </ng-container>
  </div>
</div>

