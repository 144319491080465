import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";
import { WidgetTypes, Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Widget, ScrollDirections, EffectTypes } from "src/app/_models";
import { WidgetService } from "src/app/_services";
import { effects } from "src/app/global";

@Component({
  selector: 'app-effects',
  templateUrl: './effects.component.html',
  styleUrls: ['./effects.component.css']
})
export class EffectsComponent implements OnInit {
  @Input() widget: Widget;
  public direction: Direction = 'ltr';
  public effects = effects;
  public scrollDirections = ScrollDirections;
  public widgetTypes = WidgetTypes;
  public effectTypes = EffectTypes;

  constructor(private widgetService: WidgetService,
              private translate: TranslateService,
              private translateHelper: TranslateHelper) { }

  public ngOnInit(): void {
    this.setTranslation();
  }

  /**
   * Gets the selected effect key.
   * @param {MatSelect} select - a MatSelect element.
   * @param {string} key - an effect key to search.
   */
  public getSelectedValue(select: MatSelect, key: string): string {
    if (this.widget.type == this.widgetTypes.Table && this.widget.effect.type !== this.effectTypes.None) {
      if (!this.widget.content.table.singleLine) {

        if (this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection == ScrollDirections.Up) {
          this.widget.effect.scrollDirection = ScrollDirections.Up;
          //  this.updateEffect()
        }
      //   if ( this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection == ScrollDirections.Down) {
      //     this.widget.effect.scrollDirection = ScrollDirections.Down; this.updateEffect()
      //   }
      }
      if (this.widget.content.table.singleLine) {
          if (this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection == ScrollDirections.Left) {
            this.widget.effect.scrollDirection = ScrollDirections.Left;
            //  this.updateEffect()
          }
          if (this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection != ScrollDirections.Left) {
            this.widget.effect.scrollDirection = ScrollDirections.Right;
            // this.updateEffect()
          }
      }
      }
      if (this.widget.type == this.widgetTypes.Jewish && this.widget.effect.type !== this.effectTypes.None) {
        if (this.widget.content.jewish && !this.widget.content.jewish.multipleLines && this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection == ScrollDirections.Left) {
          this.widget.effect.scrollDirection = ScrollDirections.Left;
          // this.updateEffect()
        }
        if (this.widget.content.jewish && !this.widget.content.jewish.multipleLines && this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection !== ScrollDirections.Left) {
          this.widget.effect.scrollDirection = ScrollDirections.Right;
          // this.updateEffect()

        }
      }

      return this.effects?.find(effect => effect.id === select.value)[key];
    }
      // if (!this.widget.content.table.singleLine && this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection == ScrollDirections.Up) {
      //   this.widget.effect.scrollDirection = ScrollDirections.Up; this.updateEffect()
      // }
      // if (!this.widget.content.table.singleLine && this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection == ScrollDirections.Down) {
      //   this.widget.effect.scrollDirection = ScrollDirections.Down; this.updateEffect()
      // }
      // if (this.widget.content.table.singleLine && this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection == ScrollDirections.Left) {
      //   this.widget.effect.scrollDirection = ScrollDirections.Left; this.updateEffect()
      // }
      // if (this.widget.content.table.singleLine && this.widget.effect.type == this.effectTypes.Scroll && this.widget.effect.scrollDirection === ScrollDirections.Right) {
      //   this.widget.effect.scrollDirection = ScrollDirections.Right; this.updateEffect()
      // }


  /**
   * Emits the specified effect.
   */
  public updateEffect(): void {
    this.widgetService.updateEffect(this.widget.effect, this.widget.id);
    this.widget.effectSource.next(this.widget.effect);
    if (this.widget.type == this.widgetTypes.Table) {
      this.widgetService.widgetUpdateSpeedTable.next(this.widget.effect)
    }

  }

  /**
   * Emits the specified widget effect.
   */
  public updateAutoEffect(): void {
    this.widgetService.updateEffect(this.widget.effect, this.widget.id);
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
