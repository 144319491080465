import { Direction } from "@angular/cdk/bidi";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialogRef, MatDialog, DialogPosition } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Position } from "angular2-draggable";
import { KosherZmanimOptionDto } from "src/app/_dto";
import { TimeFormat, ChangeTimes, RoundingOptions, CalcOptions, WeekDefinitions, Days, DecisiveDayOption, Languages } from "src/app/_enums";
import { TranslateHelper, SchedulerHelper } from "src/app/_helpers";
import { Widget, TableRecord, Holiday, TableRecords, Screen } from "src/app/_models";
import { SnackbarService, HalachicTimesService, RefreshPipeService } from "src/app/_services";
import { TableRecordOptionsMultipleComponent } from "./table-record-options-multiple/table-record-options-multiple.component";
import { TableRecordOptionsComponent } from "./table-record-options/table-record-options.component";

@Component({
  selector: 'app-table-content',
  templateUrl: './table-content.component.html',
  styleUrls: ['./table-content.component.css']
})
export class TableContentComponent implements OnInit, AfterViewInit {
  @Input() screen: Screen;
  @Input() widget: Widget;
  @Input() timeFormat: TimeFormat;
  @Input() changeTimeKey: ChangeTimes;
  @ViewChild('recordsList') recordsList: ElementRef<HTMLElement>;

  public recordsForm: UntypedFormGroup;

  public halachicTimeInvalid = false;
  public halachicTimeErrorMatcher: ErrorStateMatcher = {
    isErrorState: () => this.halachicTimeInvalid
  };

  public titleInvalid = false;
  public titleErrorMatcher: ErrorStateMatcher = {
    isErrorState: () => this.titleInvalid
  };

  public fixedTimeInvalid = false;
  public timeErrorMatcher: ErrorStateMatcher = {
    isErrorState: () => this.fixedTimeInvalid
  };

  public settingsWindowPosition: Position;
  public standardHalachicTimes: KosherZmanimOptionDto[];
  public standardTitles: { [key: string]: string } = {};
  public direction: Direction = 'ltr';
  public selectedIndexes: number[] = [];
  public allSelected = false;

  private tableRecordOptionsDialogRef: MatDialogRef<TableRecordOptionsComponent>;
  private tableRecordOptionsMultipleDialogRef: MatDialogRef<TableRecordOptionsMultipleComponent>;
  private controlIndex: number;

  constructor(
    private snackbar: SnackbarService,
    private halachicTimesService: HalachicTimesService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private dialog: MatDialog,
    private contentDialogRef: MatDialogRef<TableContentComponent>,
    public refreshService: RefreshPipeService
  ) {
  }

  /**
   * Getter. Determines the last record HTML element.
   * @private
   * @return {HTMLDivElement} The last record HTML element.
   */
  private get lastHtmlRecord(): HTMLDivElement {
    const recordElements: HTMLDivElement[] = [];
    this.recordsList.nativeElement.childNodes.forEach((element: HTMLDivElement) => {
      if (element.tagName === 'DIV') {
        recordElements.push(element);
      }
    });

    return recordElements[recordElements.length - 1];
  }

  /**
   * Transforms the time to 24H format.
   * @param {string} time - chosen time.
   * @private
   */
  private static getIn24(time: string): string {
    console.log(time);
    const h = parseInt(time, 10);
    const m = time.split(':')[1].split(' ')[0];
    return h + 12 + ':' + m;
  }

  public ngOnInit(): void {
    this.setTranslation();
    this.initForm();
    this.controlIndex = this.widget.content.table.records.length;
    this.standardHalachicTimes = this.halachicTimesService.times;
    this.standardHalachicTimes.forEach(time => this.standardTitles[time.key] = this.direction === 'rtl' ? time.he : time.en);
  }

  public ngAfterViewInit() {
    this.removeFading();
  }

  /**
   * Saves the selected record index.
   * @param {number} id - selected record index.
   */
  public setSelected(id: number): void {
    if (this.selectedIndexes.includes(id)) {
      this.selectedIndexes = this.selectedIndexes.filter(index => index !== id);
    } else {
      this.selectedIndexes.push(id);
    }
  }

  /**
   * Updates the allSelected field.
   */
  public updateAllEnabled(): void {
    this.allSelected = this.widget.content.table.records != null && this.widget.content.table.records.length === this.selectedIndexes.length;
  }

  /**
   * Checks whether some records are selected.
   */
  public someSelected(): boolean {
    if (this.widget.content.table.records === null) {
      return false;
    }
    return this.selectedIndexes.length > 0 && !this.allSelected;
  }

  /**
   * Marks all the records selected.
   * @param {boolean} selected - whether the checkbox is selected.
   */
  public setAll(selected: boolean): void {
    this.allSelected = selected;
    if (this.widget.content.table.records === null) {
      return;
    }

    if (selected) {
      this.widget.content.table.records.forEach((record: TableRecord, index: number) => this.selectedIndexes.push(index));
    } else {
      this.selectedIndexes = [];
    }
  }

  /**
   * Toggles record's state (dynamic/fixed).
   * @param {TableRecord} record - current record.
   */
  public toggleDynamicFixed(record: TableRecord): void {
    record.isFixed = !record.isFixed;
    record.key = '';
    record.custom = '';
    record.fixedTime = '';
    this.titleInvalid = false;
    this.fixedTimeInvalid = false;
    this.halachicTimeInvalid = false;
  }

  /**
   * Called on window close. Filters the empty records.
   */
  public filterAndApprove(): void {
    if (this.emptyRecords()) {
      this.markEmptyRecordFields();
      this.snackbar.info(this.translate.instant('snackbar.emptyRecord'), this.translate.instant('buttons.ok'));
    } else {
      this.widget.content.table.records = this.widget.content.table.records.filter(record => !!record.key || record.titleOnly || record.isFixed);
      this.contentDialogRef.close();
      if (!!this.screen) {
        this.screen.dirty = true;
      }
      this.widget.contentChangedSource.next();
    }
  }

  /**
   * Adds a new record to the list.
   */
  public addRecord(): void {
    if (this.emptyRecords()) {
      this.markEmptyRecordFields();
      this.snackbar.info(this.translate.instant('snackbar.emptyRecord'), this.translate.instant('buttons.ok'));
    } else {
      const newRecord: TableRecord = {
        enabled: true,
        key: '',
        en: '',
        he: '',
        addMinutes: 0,
        isFixed: false,
        titleOnly: false,
        highlighted: false,
        roundMin: 0,
        roundOption: RoundingOptions.Previous,
        calcOption: CalcOptions.Daily,
        weekDefinition: WeekDefinitions.SundayThursday,
        dayOfChange: Days.Sunday,
        decisiveDayOption: DecisiveDayOption.Earliest,
        recordHolidaysDisplayConditions: SchedulerHelper.holidays.map((holiday: Holiday) => ({ ...holiday })),
        recordDaysDisplayConditions: [true, true, true, true, true, true, true],
        recordHolidaysHighlightConditions: SchedulerHelper.holidays.map((holiday: Holiday) => ({ ...holiday })),
        recordDaysHighlightConditions: [true, true, true, true, true, true, true],
        displayed: false
      };

      this.widget.content.table.records.push(newRecord);

      if (this.screen) {
        this.screen.dirty = true;
      }

      setTimeout(() => {
        this.lastHtmlRecord.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
        this.removeFading();
      }, 0);

      this.addFormControls();
    }
  }

  public duplicateRecord(record: TableRecord): void {
    const recordClone: TableRecord = JSON.parse(JSON.stringify(record));

    this.widget.content.table.records.push(recordClone);
    if (this.screen) {
      this.screen.dirty = true;
    }

    setTimeout(() => {
      this.lastHtmlRecord.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      this.removeFading();
    }, 500);

    this.addFormControls();
  }

  /**
   * Changes the record's halachic time key in order to obtain the selected time.
   * @param {string} value - halachic time key.
   * @param {TableRecord} record - current record.
   */
  public changeKey(value: string, record: TableRecord): void {
    if (value) {
      record.time = this.getRecordTime(record);
    }
  }

  /**
   * Sets fixed time.
   * @param {TableRecord} record - current table record.
   * @param {string} time - time to set.
   */
  public setFixedTime(record: TableRecord, time: string): void {
    record.fixedTime = time;
    this.fixedTimeInvalid = false;
    this.refreshService.refreshPipe();
  }

  /**
   * Removes record from the list.
   * @param {TableRecord} record - the record to remove.
   * @param {HTMLDivElement} recordElement - record DIV element.
   */
  public removeRecord(record: TableRecord, recordElement: HTMLDivElement): void {
    recordElement.classList.add('fade-out-bottom');
    setTimeout(() => {
      const recordIndex = this.widget.content.table.records.indexOf(record);
      this.widget.content.table.records.splice(recordIndex, 1);
      this.removeFormControls();
      this.refreshService.refreshPipe();
    }, 700);
  }

  /**
   * Listens to table record drop event (to change the records order).
   * @param {CdkDragDrop<TableRecords>} event - CdkDragDrap event.
   */
  public drop(event: CdkDragDrop<TableRecords>): void {
    moveItemInArray(this.widget.content.table.records, event.previousIndex, event.currentIndex);
  }

  /**
   * Opens record's settings dialogue window in the place of the previous window, and passes the necessary data to TableRecordOptionsComponent.
   * @param {number} recordIndex - record's index.
   */
  public openAdvancedSettings(recordIndex: number): void {
    let position: DialogPosition = null;

    if (!!this.tableRecordOptionsDialogRef && !!this.tableRecordOptionsDialogRef.componentInstance) {
      const { x, y } = this.tableRecordOptionsDialogRef.componentInstance.settingsWindowPosition;
      position = {
        top: (y - 24) + 'px',  // 24px - is the Mat Dialog element padding
        left: (x - 24) + 'px'
      };
      this.tableRecordOptionsDialogRef.close();
      this.tableRecordOptionsDialogRef = null;
    }

    this.tableRecordOptionsDialogRef = this.dialog.open(TableRecordOptionsComponent, { hasBackdrop: false, position });
    this.tableRecordOptionsDialogRef.componentInstance.record = this.widget.content.table.records[recordIndex];
    this.tableRecordOptionsDialogRef.componentInstance.screen = this.screen;
    this.tableRecordOptionsDialogRef.componentInstance.label = this.widget.content.table.records[recordIndex].custom || this.standardTitles[this.widget.content.table.records[recordIndex].key];
  }

  /**
   * Opens settings dialogue window for selected records, and passes the necessary data to TableRecordOptionsMultipleComponent.
   */
  public openAdvancedSettingsForSelected(): void {
    if (!this.selectedIndexes.length) {
      this.snackbar.info(this.translate.instant('snackbar.someRecords'), this.translate.instant('buttons.ok'));
      return;
    }

    if (!!this.tableRecordOptionsMultipleDialogRef) {
      return;
    }

    this.tableRecordOptionsMultipleDialogRef = this.dialog.open(TableRecordOptionsMultipleComponent, { hasBackdrop: false });
    this.tableRecordOptionsMultipleDialogRef.componentInstance.records = this.widget.content.table.records.filter((record: TableRecord, index: number) => {
      return this.selectedIndexes.includes(index);
    });
    this.tableRecordOptionsMultipleDialogRef.afterClosed().subscribe((records: TableRecords) => {
      if (records && records.length) {
        this.selectedIndexes.forEach((recordIndex: number, id: number) => this.widget.content.table.records[recordIndex] = records[id]);
      }
      this.tableRecordOptionsMultipleDialogRef = null;
    });
  }

  /**
   * Removes fading effect class for all the record DIV elements.
   * @private
   */
  private removeFading(): void {
    this.recordsList.nativeElement.childNodes.forEach((node: HTMLElement) => {
      if (node.tagName === 'DIV') {
        node.classList.remove('fade-in-top');
      }
    });
  }

  /**
   * Checks whether all the records are properly filled.
   * @private
   * @return {boolean} True if there are empty records.
   */
  private emptyRecords(): boolean {
    return this.widget.content.table.records.some(record => {
      if (record.titleOnly) {
        return !record.custom;
      }

      if (record.isFixed) {
        return !record.custom || !record.fixedTime;
      }
      return !record.key && !(record.titleOnly && record.custom);
    });
  }

  /**
   * Formats record's time according to the chosen preferences.
   * @param {TableRecord} record - current table record.
   * @private
   * @return {string} Record's formatted time.
   */
  private getRecordTime(record: TableRecord): string {
    if (record.isFixed) {
      if (record.fixedTime.toLowerCase().includes('m')) {
        return TableContentComponent.getIn24(record.fixedTime).padStart(5, '0') + ':00';
      }
      return record.fixedTime + ':00';
    }

    const currentTime = this.halachicTimesService.times?.find(time => time.key === record.key);
    const date = this.halachicTimesService.halachicTimes[currentTime?.option];
    const fullDate = new Date(date);
    fullDate.setMinutes(fullDate.getMinutes() + record.addMinutes);

    return fullDate.toLocaleTimeString('en-US', {
      timeZone: this.halachicTimesService.options.timeZoneId,
      hour12: false
    });
  }

  /**
   * Initialises the records form.
   * @private
   */
  private initForm(): void {
    this.recordsForm = new UntypedFormGroup({});
    if (this.widget.content.table.records.length === 0) return;

    this.widget.content.table.records.forEach(({ key, custom, fixedTime }: TableRecord, index: number) => {
      this.recordsForm.addControl('halachicTime' + index, new UntypedFormControl(key, Validators.required));
      this.recordsForm.addControl('title' + index, new UntypedFormControl(custom, Validators.required));
      this.recordsForm.addControl('time' + index, new UntypedFormControl(fixedTime, Validators.required));
    });
  }

  /**
   * Adds controls to the records form.
   * @private
   */
  private addFormControls(): void {
    this.recordsForm.addControl('halachicTime' + this.controlIndex, new UntypedFormControl('', Validators.required));
    this.recordsForm.addControl('title' + this.controlIndex, new UntypedFormControl('', Validators.required));
    this.recordsForm.addControl('time' + this.controlIndex, new UntypedFormControl('', Validators.required));
    this.controlIndex++;
  }

  /**
   * Removes controls from the records form.
   * @private
   */
  private removeFormControls(): void {
    this.recordsForm.removeControl('halachicTime' + this.controlIndex);
    this.recordsForm.removeControl('title' + this.controlIndex);
    this.recordsForm.removeControl('time' + this.controlIndex);
    this.controlIndex--;
    this.halachicTimeInvalid = false;
    this.titleInvalid = false;
    this.fixedTimeInvalid = false;
  }

  /**
   * Sets variables to mark last record empty fields.
   * @private
   */
  private markEmptyRecordFields(): void {
    const lastRecord = this.widget.content.table.records[this.widget.content.table.records.length - 1];

    if (lastRecord.titleOnly && !lastRecord.custom) {
      this.titleInvalid = true;
      return;
    }

    if (!lastRecord.isFixed) {
      this.halachicTimeInvalid = true;
    } else {
      if (!lastRecord.custom) this.titleInvalid = true;
      if (!lastRecord.fixedTime) this.fixedTimeInvalid = true;
    }
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
