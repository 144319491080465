<div class="web-container">
  <iframe [src]="safeUrl"
          [ngStyle]="{
              'top': widget.content.web.webLocationTop + '%',
              'left': widget.content.web.webLocationLeft + '%',
              'width': widget.content.web.webSizeWidth + 'vw',
              'height': widget.content.web.webSizeHeight + 'vh'
          }">
    Your browser does not support iframes.
  </iframe>
</div>
