<mat-card class="dashboard-card" *ngIf="currentScreen">

  <div class="d-flex justify-content-between w-100 h-100">
    <div [ngbNavOutlet]="nav"></div>

    <div [style.font-size]="language === languages.Russian ? '0.8rem' : '0.9rem'" class="tabs-container">
      <ul ngbNav #nav="ngbNav" class="nav-tabs" orientation="vertical">

<!--        Body-->
        <li ngbNavItem class="body">
          <a class="d-flex flex-column align-items-center py-3" ngbNavLink>
            <mat-icon>palette</mat-icon>
            <div>{{ 'dashboard.body' | translate }}</div>
          </a>

          <ng-template ngbNavContent>
            <mat-card-header class="mt-3 pb-3 justify-content-between align-items-center"
                             [style.direction]="direction"
                             [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
              <mat-card-title>{{ 'dashboard.general.title' | translate }}</mat-card-title>
              <mat-card-subtitle class="mb-0">{{ 'dashboard.general.subtitle' | translate }}</mat-card-subtitle>
              <div>
                <button (click)="addScreen()"
                        mat-mini-fab
                        [title]="'dashboard.general.add' | translate"
                        color="primary"
                        class="mr-2"
                        disabled>
                  <mat-icon>add</mat-icon>
                </button>
                <button (click)="openRemoveConfirmationPopup()"
                        [disabled]="screensNumber < 2"
                        mat-mini-fab
                        [title]="'dashboard.general.remove' | translate"
                        color="accent">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-card-header>

<!--            Screen selection-->
            <div *ngIf="screens" class="settings container-sm d-flex justify-content-between align-items-center mt-1 mx-2 pb-1"
                 [style.direction]="direction"
                 [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
              <span class="settings-title mr-3">{{ 'dashboard.general.screen' | translate }}</span>
              <mat-form-field appearance="standard" class="select-font w-50"
                              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                <mat-select (selectionChange)="selectScreen($event.value)" [value]="currentScreen.name">
                  <mat-option *ngFor="let style of screens" [value]="style.name"
                              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                    {{ style.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

<!--            Orientation selection-->
            <div class="settings container-sm d-flex justify-content-center align-items-center mx-2">
              <div class="w-75 d-flex justify-content-between align-items-center">
                <span>{{ 'dashboard.general.landscape' | translate }}</span>
                <mat-slide-toggle (change)="changeOrientation($event.checked)"
                                  [checked]="currentScreen.portraitOrientation"
                                  [class.mx-2]="language === languages.Russian">
                </mat-slide-toggle>
                <span>{{ 'dashboard.general.portrait' | translate }}</span>
              </div>
            </div>

<!--            Screen name-->
            <div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
                 [style.direction]="direction"
                 [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
              <span class="settings-title mr-3">{{ 'dashboard.general.name' | translate }}</span>
              <mat-form-field [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                <input matInput [value]="currentScreen?.name" [(ngModel)]="currentScreen.name">
              </mat-form-field>
            </div>

<!--            Background-->
            <div class="settings container-sm d-flex justify-content-center align-items-center mx-2">
              <div class="w-75 d-flex justify-content-between align-items-center">
                <span>{{ 'dashboard.general.colour' | translate }}</span>
                <mat-slide-toggle (change)="toggleBackground($event.checked)"
                                  [checked]="currentScreen.background.type === backgroundTypes.Image"
                                  [class.mx-2]="language === languages.Russian">
                </mat-slide-toggle>
                <span>{{ 'dashboard.general.image' | translate }}</span>
              </div>
            </div>

<!--            Background colour-->
            <div class="settings container-sm d-flex justify-content-between align-items-center mx-2"
                 [style.direction]="direction"
                 [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
              <span class="settings-title mr-3">{{ 'dashboard.general.backgroundColour' | translate }}</span>
              <div [(colorPicker)]="currentScreen.background.color"
                   [style.background]="currentScreen.background.color || '#e7ddff'"
                   [style.right]="direction === 'rtl' ? '' : '1rem'"
                   [style.left]="direction === 'rtl' ? '1rem' : ''"
                   (colorPickerChange)="updateScreen()"
                   [cpOutputFormat]="'hex'"
                   [cpAlphaChannel]="'disabled'"
                   [cpOKButton]="true"
                   [cpOKButtonText]="'buttons.ok' | translate"
                   cpOKButtonClass="btn btn-outline-primary"
                   [cpCancelButton]="true"
                   cpCancelButtonClass="btn btn-outline-danger"
                   [cpCancelButtonText]="'buttons.reset' | translate"
                   class="colour-picker">
              </div>
            </div>

<!--            Background image upload-->
            <div class="settings container-sm d-flex align-items-center flex-column mx-2">
              <app-upload [screen]="currentScreen"
                          [endpoint]="'screen'"
                          [title]="'dashboard.general.background' | translate"
                          [style.direction]="direction"
                          [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
              </app-upload>

<!--              Background images for example-->
              <div class="background-title mt-2 mb-3">{{ 'dashboard.general.backgroundImage' | translate }}</div>
              <ng-image-slider
                (imageClick)="selectBackground($event)"
                [infinite]="true"
                [imageSize]="{ height: '100px', space: 4 }"
                [animationSpeed]="0.5"
                [imagePopup]="false"
                [images]="images">
              </ng-image-slider>
            </div>
          </ng-template>
        </li>

<!--        Add widget-->
        <li [ngbNavItem]="'widgets'">
          <a class="d-flex flex-column align-items-center py-3" ngbNavLink>
            <mat-icon>add_photo_alternate</mat-icon>
            <div>{{ 'dashboard.addWidget' | translate }}</div>
          </a>
          <ng-template ngbNavContent>
            <mat-card-header [style.direction]="direction"
                             [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                             class="mt-3">
              <mat-card-title>{{ 'dashboard.addWidget' | translate }}</mat-card-title>
              <mat-card-subtitle>{{ 'dashboard.widgetsTab.addWidgetsSubtitle' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content [style.direction]="direction"
                              class="mr-4">
              <app-widget-icon (cdkDragEnded)="addWidget(i, widgetIcon, $event)"
                                       (dblclick)="addWidget(i, widgetIcon, $event)"
                                       (mousedown)="onGrabbed($event, i)"
                                       *ngFor="let widgetIcon of widgetIcons; let i = index"
                                       [cdkDragData]="widgetIcon"
                                       [class.disabled]="unavailableWidgets.includes(widgetIcon.type)"
                                       [id]="i"
                                       [title]="unavailableWidgets.includes(widgetIcon.type)
                                          ? ('snackbar.coming' | translate)
                                          : ('dashboard.widgetsTab.pickMe' | translate)"
                                       [widgetIcon]="widgetIcon"
                                       cdkDrag
                                       class="cdk-drag-animating">
              </app-widget-icon>
            </mat-card-content>
          </ng-template>
        </li>

        <!--        Widgets-->
        <li [ngbNavItem]="'widgetProperties'">
          <a class="d-flex flex-column align-items-center py-3" ngbNavLink>
            <mat-icon>format_shapes</mat-icon>
            <div [matBadge]="currentScreen.widgets.length == 0 ? '' : currentScreen.widgets.length"
                 [matBadgePosition]="direction === 'rtl' ? 'before' : 'after'"
                 matBadgeOverlap="false"
                 matBadgeColor="accent">{{ 'dashboard.widgets' | translate }}</div>
          </a>

          <ng-template ngbNavContent>
            <mat-card-header [style.direction]="direction"
                             [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                             class="mt-3">
              <mat-card-title>{{ 'dashboard.widgets' | translate }}
                <span  *ngIf="currentScreen.widgets.length > 0">&nbsp;({{ currentScreen.widgets.length }})</span>
              </mat-card-title>
              <mat-card-subtitle>{{ 'dashboard.widgetsTab.widgetsSubtitle' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <app-widgets-panel *ngIf="currentScreen.widgets.length"
                               [screen]="currentScreen"
                               [style.direction]="direction"
                               [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
            </app-widgets-panel>
            <div *ngIf="!currentScreen.widgets.length" class="d-flex justify-content-center w-100">
              <button (click)="openWidgetsTab()" mat-stroked-button>{{ 'dashboard.widgetsTab.add' | translate }}</button>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="'selectedWidgetContent'" *ngIf="selectedWidget">
          <a class="d-flex flex-column align-items-center py-3" ngbNavLink>
            <mat-icon>settings</mat-icon>
            <div>{{ 'dashboard.widgetSettings' | translate }}</div>
          </a>
          <ng-template ngbNavContent *ngIf="selectedWidget">
            <app-widget-settings [widget]="selectedWidget"
                                 [screen]="currentScreen"
                                 [editMode]="editMode"
                                 [nav]="nav">
            </app-widget-settings>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</mat-card>
