import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserDto } from '../../../../../_dto/user-dto';
import { Languages } from '../../../../../_enums/languages';
import { TranslateHelper } from '../../../../../_helpers/translate.helper';

@Component({
  selector: 'app-kiddush-levana-end',
  templateUrl: './kiddush-levana-end.component.html',
  styleUrls: ['./kiddush-levana-end.component.css']
})
export class KiddushLevanaEndComponent implements OnInit {
  @Input() user: UserDto;

  public direction = 'ltr';

  constructor(
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) { }

  public ngOnInit(): void {
    this.setTranslationConfig();
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslationConfig(): void {
    this.translate.onLangChange.subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
