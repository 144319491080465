import { EffectTypes } from './_models/effect';
import { FontStyle } from './_models/font';

export const fonts = ['Arial', 'David', 'CURSIVE', 'Times New Roman', 'Helvetica', 'Roboto'];

export const fontStyles = [
  { name: 'Normal', id: FontStyle.Normal },
  { name: 'Bold', id: FontStyle.Bold },
  { name: 'Italic', id: FontStyle.Italic },
  { name: 'Underline', id: FontStyle.Underline }
];

export const effects = [
  { name: 'None', id: EffectTypes.None, icon: 'stop' },
  { name: 'Scroll', id: EffectTypes.Scroll, icon: 'directions_bike' },
  { name: 'Swap', id: EffectTypes.FastSwap, icon: 'swap_horiz' },
  { name: 'Blur', id: EffectTypes.BlurredSwap, icon: 'swap_horizontal_circle' }
];
