<mat-sidenav-container [hasBackdrop]="false">

  <mat-sidenav #dashboard [disableClose]="true" class="shadow" mode="side" position="end">
    <app-dashboard></app-dashboard>
  </mat-sidenav>

  <mat-sidenav-content>

    <div class="screen op0">

      <div *ngIf="screen"
           [class.edit-landscape]="editMode && !portraitOrientation"
           [class.edit-portrait]="editMode && portraitOrientation"
           id="screen"
           #screenBox
           [style.background-color]="screen.background.color"
           [style.background-image]="screen.background.type === backgroundTypes.Image ? 'url(' + resourcesUrl + screen.background.imageUrl + ')' : 'none'"
           class="screen-container">

        <div #back
            *ngFor="let widget of screen?.widgets"
             [appEffect]="widget.effect.autoEffect"
             [widget]="widget"
             (contextmenu)="openContextMenu($event, widget, back)"
             [class.edit-mode-border]="editMode"
             [id]="'widget-' + widget.id"
             [ngStyle]="generateCss(widget)"
             [style.background-image]="widget.background.type === backgroundTypes.Image ? 'url(' + resourcesUrl + widget.background.imageUrl + ')' : 'none'"
             [ngDraggable]="editMode"
             [bounds]="screenBox"
             (started)="selectWidget(widget, $event)"
             (endOffset)="onDragEnded($event, widget, back)"
             (movingOffset)="back.style.zIndex = (++maxZIndex).toString()"
             [trackPosition]="false"
             [ngResizable]="back.classList.contains('selected-widget')"
             (rzResizing)="onResizing($event, widget)"
             (rzStop)="onResizeStop($event, widget)"
             [rzContainment]="screenBox"
             [style.display]="widget.visible ? 'block' : 'none'"
             [style.container-type]="'size'"
             rzHandles="all"
             class="back">

          <ng-container *ngIf="widget.enabled">
            <app-text-viewer *ngIf="widget.type === widgetTypes.Text || widget.type === widgetTypes.HTML"
                             [widget]="widget"
                             [effectType]="widget.effect.type"
                             [content]="widget.content.text.permanent">
            </app-text-viewer>

            <app-yahrzeit-viewer *ngIf="widget.type === widgetTypes.YahrzeitTable"
                                 [items]="widget.content.yahrzeit.items"
                                 [size]="widget.size"
                                 [yahrzeitTable]="widget.content.yahrzeit.properties" [font]="widget.font">
            </app-yahrzeit-viewer>

            <app-table-viewer *ngIf="widget.type === widgetTypes.Table || widget.type === widgetTypes.TableAlert"
                              [highlightColor]="widget.font.highlightColor ? widget.font.highlightColor : 'inherit'"
                              [widget]="widget"
                              [user]="user"
                              [effect]="widget.effect.type">
            </app-table-viewer>


            <app-web-viewer *ngIf="widget.type === widgetTypes.Web"
                            class="web-page"
                            [widget]="widget">
            </app-web-viewer>

            <app-jewish-viewer *ngIf="widget.type === widgetTypes.Jewish || widget.type === widgetTypes.YahrzeitList"
                               [widget]="widget"
                               [effectType]="widget.effect.type"
                               [user]="user"
                               [back]="back"
                               [style.flex-direction]="horizontalScrollDirections.includes(this.widget.effect.scrollDirection) ? 'row' : 'column'">
            </app-jewish-viewer>

            <app-alert-viewer *ngIf="widget.type === widgetTypes.EventsAlert || widget.type === widgetTypes.TimesAlert"
                              [widget]="widget">
            </app-alert-viewer>

            <app-pdf-viewer *ngIf="widget.type === widgetTypes.PDF"
                            [widget]="widget">
            </app-pdf-viewer>

            <app-media-viewer *ngIf="widget.type === widgetTypes.Media"
                              [mediaContent]="widget.content.media">
            </app-media-viewer>

            <app-scheduler-media-list *ngIf="widget.type === widgetTypes.SchedulerMediaList"
                                      [mediaContent]="widget.content.media"
                                      [isStretched]="widget.background.stretched">
            </app-scheduler-media-list>


            <app-digital-clock-viewer *ngIf="widget.type === widgetTypes.Digital"
                                      [widget]="widget">
            </app-digital-clock-viewer>


            <app-analog-clock-viewer *ngIf="widget.type === widgetTypes.Clock"
                                     [widget]="widget">
            </app-analog-clock-viewer>

            <app-rss-viewer *ngIf="widget.type === widgetTypes.RSS"
                            [rssUrl]="widget.content.rss?.url"
                            [effect]="widget.effect.type">
            </app-rss-viewer>
          </ng-container>


        </div>

      </div>

    </div>
  </mat-sidenav-content>

</mat-sidenav-container>

<ng-template #menu let-widget>
  <mat-list role="list" class="user-menu shadow pb-2">
    <button (click)="removeWidget(widget)"
            [style.direction]="direction"
            [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
            color="accent"
            mat-button>
      <mat-icon>delete</mat-icon>
      <span class="mx-2">{{ 'menu.delete' | translate }}</span>
    </button>
  </mat-list>
</ng-template>
