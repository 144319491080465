<div class="table-container mr-4">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="IsHidden">
      <th mat-header-cell *matHeaderCellDef>IsHidden</th>
      <td mat-cell *matCellDef="let widget">
        <mat-slide-toggle (change)="widget.isHidden = !$event.checked" [checked]="!widget.isHidden"
          [title]="'dashboard.widgetsTab.enable' | translate" class="enable">
        </mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="Avatar">
      <th mat-header-cell *matHeaderCellDef> Avatar</th>
      <td mat-cell *matCellDef="let widget">
        <div class="component-icon mr-3">
          <img *ngIf="!(darkMode$ | async)" [src]="widget.widgetIcon?.path">
          <img *ngIf="darkMode$ | async" [src]="widget.widgetIcon?.path + '.dm.svg'">
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let widget">
        <div class="mr-3">{{ widget.name }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let widget">
        <div class="mr-3">{{ 'dashboard.widgetsTab.' + ((widget.type | lowercase) | tableType) | translate }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Duplicate">
      <th mat-header-cell *matHeaderCellDef>Duplicate</th>
      <td mat-cell *matCellDef="let widget">
        <button (click)="duplicateWidget(widget)" mat-button color="primary"
          [title]="'dashboard.widgetProperties.duplicateBtn' | translate">
          <mat-icon>content_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="Remove">
      <th mat-header-cell *matHeaderCellDef>Remove</th>
      <td mat-cell *matCellDef="let widget">
        <button (click)="removeWidget(widget)" mat-button color="accent"
          [title]="'dashboard.widgetsTab.remove' | translate">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr (click)="goToWidget(widget, $event.target)" (mouseenter)="selectWidget(widget)"
      (mouseleave)="clearWidgetSelection()" *matRowDef="let widget; columns: displayedColumns;"
      [id]="(widgetService.widgetSelected$ | async)?.id === widget.id ? 'selected-widget' : ''" class="widget-row"
      mat-row>
    </tr>
  </table>
</div>
