<ng-container *ngTemplateOutlet="text ? textTemplate : htmlContent ? htmlContentTemplate : multiHtmlContentTemplate">
</ng-container>
<ng-template #textTemplate>
  <div class="hidden-container">
    <div #hiddenContainer>
      <div *ngFor="let item of text">
        <span fitContainerDirective [containerEl]="elementRef" [widget]="this.widget" [innerHTML]="item"
          [ngClass]="{'small-text': widget.content.jewish.smallTextAuto}"></span>
        <div>
          {{separator}}
        </div>
      </div>
    </div>
  </div>

  <!-- Display the text in a scrolling container if it doesn't fit in the hidden container -->
  <div *ngIf="text &&
    shouldScroll === 'scroll' ||
      (shouldScroll === 'autoEffect' &&
        hiddenContainer.parentElement.clientHeight < hiddenContainer.scrollHeight);
    then scroll;
    else stay
  "></div>

  <ng-template #scroll>
    <div #scrollContainer class="scroll-container flex-align-center">
      <span #textWrapper class="text-wrapper" (animationend)="onAnimationEnd(scrollContainer)">
        <div>
          <div *ngFor="let item of text" [style.direction]="widget.language === Languages.Hebrew ? 'rtl' : 'ltr'" >
            <span [innerHTML]="item" [ngClass]="{'small-text': widget.content.jewish.smallTextAuto}" [style.verticalAlign]="verticalAlign"></span>
            <div>
              {{separator}}
            </div>
          </div>
        </div>
      </span>
    </div>
  </ng-template>
  <!-- Display the text without scrolling if it fits in the container -->
  <ng-template #stay>
    <span #textWrapper class="text-wrapper">
      <div>
        <div *ngFor="let item of text; let i = index">
          <span [innerHTML]="item" [ngClass]="{'small-text': widget.content.jewish.smallTextAuto}" [style.verticalAlign]="verticalAlign"></span>
          <div *ngIf="i < text.length - 1">
            {{separator}}
          </div>
        </div>
      </div>
    </span>
  </ng-template>
</ng-template>

<ng-template #htmlContentTemplate>
  <div class="html-content hidden-container">
    <div #hiddenContainer #singleRecord class="text-wrapper" style="width: 100%;" (animationend)="onAnimationEnd(scrollContainer)">
      <div [innerHTML]="htmlContent | byPassSecurity"></div>
    </div>
  </div>
  <div #scrollContainer class="scroll-container">
    <div #textWrapper class="text-wrapper" style="width: 100%;" (animationend)="onAnimationEnd(scrollContainer)">
      <div [innerHTML]="htmlContent  | byPassSecurity"></div>
    </div>
  </div>
</ng-template>

<ng-template #multiHtmlContentTemplate>
  <div class="html-content hidden-container">
    <div #hiddenContainer #singleRecord class="" (animationend)="onAnimationEnd(scrollContainer)">
      <div *ngFor="let item of multiHtmlContent">
        <div [innerHTML]="item | byPassSecurity"></div>
      </div>
    </div>
  </div>
  <div #scrollContainer class="scroll-container">
    <div *ngIf="!shouldScroll" #textWrapper class="text-wrapper stay" (animationend)="onAnimationEnd(scrollContainer)">
      <div *ngFor="let item of multiHtmlContent">
        <div [innerHTML]="item | byPassSecurity"></div>
      </div>
    </div>
    <div *ngIf="shouldScroll" #textWrapper class="text-wrapper move" (animationend)="onAnimationEnd(scrollContainer)">
      <div *ngFor="let item of multiHtmlContent">
        <div [innerHTML]="item | byPassSecurity"></div>
      </div>
    </div>
  </div>
</ng-template>
