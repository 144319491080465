import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "src/app/_enums";
import { TranslateHelper } from "src/app/_helpers";
import { Screen, Widget } from "src/app/_models";

@Component({
  selector: 'app-analog-clock-dashboard',
  templateUrl: './analog-clock-dashboard.component.html',
  styleUrls: ['./analog-clock-dashboard.component.css']
})
export class AnalogClockDashboardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() screen: Screen;

  public direction: Direction = 'ltr';

  constructor(private translate: TranslateService, private translateHelper: TranslateHelper) { }

  public ngOnInit(): void {
    this.setTranslation();
  }

  /**
   * Marks screen "dirty" for purpose of page leaving warning.
   */
  public markScreenDirty(): void {
    this.screen.dirty = true;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
