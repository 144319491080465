import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseApiService } from './base-api-service';
import { FileUploader } from 'ng2-file-upload';
import { Screen } from '../_models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MediaService extends BaseApiService {
  private mediaUrl = environment.apiUrl + 'media/';

  uploader: FileUploader;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {
    super();
  }

  /**
   * Deletes media file by filename.
   * @param {string} filename - name of a file to be deleted.
   */
  public deleteFile(filename: string): Observable<void> {
    return this.http.delete<void>(this.mediaUrl + filename, { headers: MediaService.authorizationHeader });
  }

  private static getFileConfig(file: File): { type: string, maxsize: string, maxFilesize: number } {
    const fileConfig = [
      { type: 'Image', maxsize: '2 Mb', maxFilesize: 2 * 1024 * 1024 },
      { type: 'Video', maxsize: '10 Mb', maxFilesize: 10 * 1024 * 1024 },
      { type: 'Audio', maxsize: '10 Mb', maxFilesize: 10 * 1024 * 1024 },
      { type: 'PDF', maxsize: '5 Mb', maxFilesize: 5 * 1024 * 1024 }
    ];

    for (const config of fileConfig) {
      if (file.type.includes(config.type.toLowerCase())) {
        return config;
      }
    }
  }

  public async checkFileSize([file]: File[]): Promise<string> {
    if (!file) return;

    const { type, maxsize, maxFilesize } = MediaService.getFileConfig(file);
    if (file.size <= maxFilesize) return;

    return `${type} ${this.translate.instant('snackbar.fileSize')} ${maxsize}`;
  }

  public initialiseUploader(endpoint: string, allowedFileTypes: string[], screen?: Screen): FileUploader {
    const url = endpoint === 'screen' ? this.mediaUrl + screen?.id : this.mediaUrl + 'upload';
    const uploader = new FileUploader({
      url,
      authToken: MediaService.token,
      isHTML5: true,
      allowedFileType: allowedFileTypes,
      removeAfterUpload: true,
      autoUpload: true
    });

    uploader.onAfterAddingFile = file => file.withCredentials = false;

    return uploader;
  }

  public async getMediaDuration(file: File): Promise<number> {
    return new Promise(resolve => {
      const tag = file.type.split('/')[0];
      const media = document.createElement(tag) as HTMLMediaElement;
      media.preload = 'metadata';
      media.onloadedmetadata = () => resolve(media.duration);
      media.src = URL.createObjectURL(file);
    });
  }
}
