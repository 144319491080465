<div class="d-flex justify-content-between align-items-center w-100">
  <span class="settings-title mx-4">{{ title }}</span>
  <div (fileOver)="fileOverBase($event); widgetService.updateScreen(screen)"
       (click)="fileUpload.click(); widgetService.updateScreen(screen)"
       [style.border-style]="hasBaseDropZoneOver ? 'dotted' : ''"
       [style.background-color]="hasBaseDropZoneOver ? '#dee2e6' : ''"
       [uploader]="uploader"
       class="card py-3 align-items-center text-center mb-3 custom-file-upload"
       ng2FileDrop>
    <mat-icon>upload</mat-icon>
    <p class="m-0">{{ 'dashboard.general.download' | translate }}</p>
  </div>

  <input #fileUpload
         (onFileSelected)="checkFileSize($event)"
         [accept]="acceptedFileTypes"
         [uploader]="uploader"
         ng2FileSelect
         type="file">
</div>
