import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatHebrewDate'
})
export class FormatHebrewDatePipe implements PipeTransform {

  /**
   * Removes quotes in the datepicker.
   * @param {string} date - a date to remove quotes.
   */
  public transform(date: string): string {
    return date.replace('׳', '').replace('״', '');
  }
}
