import { Pipe, PipeTransform } from '@angular/core';
import { HebrewDateFormatter, JewishCalendar, JewishDate } from 'kosher-zmanim';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { KosherZmanimOptionDto } from '../_dto/kosher-zmanim-option-dto';
import { UserDto } from '../_dto/user-dto';
import { ChangeTimes } from '../_enums/change-times';
import { Languages } from '../_enums/languages';
import { Minhags } from '../_enums/minhags';
import { TimeFormat } from '../_enums/time-format';
import { HalachicTimesConfig, JewishHelper } from '../_helpers/jewish.helper';
import { DayDescriptionSettings, JewishSettings } from '../_models/jewish';
import { AccountService } from '../_services/account.service';
import { DayInfoResponse, EithanApiService, FetchDayParams } from '../_services/eithan-api.service';
import { HalachicTimesService } from '../_services/halachic-times.service';
import { WidgetService } from '../_services/widget.service';

@Pipe({
  name: 'jewish',
  pure: true
})
export class JewishPipe implements PipeTransform {

  public tempArr: any[] = []
  private formatter = new HebrewDateFormatter();

  constructor(private halachicTimesService: HalachicTimesService,
    private widgetService: WidgetService, private eithanApiService: EithanApiService, private accountService: AccountService) {
  }

  /**
   * Converts keys into actual Jewish items (e.g. Hebrew date, Sefirat Haomer etc.) according to specified parameters.
   * @param {string[]} keys - Jewish keys.
   * @param {Languages} language - current language (enum).
   * @param {Date} date - selected date.
   * @param {KosherZmanimOptionDto[]} times - a list of halachic time objects.
   * @param {TimeFormat} timeFormat - time format (enum) 12H/24H.
   * @param {[]} args - additional parameters.
   */
  public transform(keys: string[], language: Languages, date: Date, times: KosherZmanimOptionDto[], timeFormat: TimeFormat, ...args: [
    boolean, boolean, boolean, string, boolean, boolean, boolean, boolean, boolean, number, string, boolean, number, string, boolean, boolean, boolean, boolean, boolean, boolean, Minhags, string[], string, boolean, string, string, ChangeTimes, number, boolean]): Observable<string[]> {
    const [day, month, year, gregorianDate, parashaWithCaption, dayOfWeek, longSefiratHaomer, dafYomiWithCaption, fastIsMinutes, fastMinutes, fastOption, shabbosEndIsMinutes, shabbosEndMinutes, shabbosEndOption, moladOnShabbos, kiddushLevanahEndBetweenMoldos, zioni, shabbosMevorchim, moridHatal, inEretzIsrael, minhag, studies, haftara, haftaraCaption, avot, hoshana, changeTimeKey, widgetId, padZero] = args;
    this.widgetService.updateEffect(null, widgetId);

    const dayDescription: DayDescriptionSettings = { shabbosMevorchim };

    const settings: JewishSettings = {
      hebrewDate: [day, month, year],
      gregorianDate,
      parasha: parashaWithCaption,
      dayOfWeek,
      sefiratHaomer: longSefiratHaomer,
      dafYomi: dafYomiWithCaption,
      shabbosEnd: {
        isMinutes: shabbosEndIsMinutes,
        minutes: shabbosEndMinutes,
        option: shabbosEndOption
      },
      fastEnd: {
        isMinutes: fastIsMinutes,
        minutes: fastMinutes,
        option: fastOption
      },
      moladOnShabbos,
      kiddushLevanahEndBetweenMoldos,
      dayDescription,
      moridHatal
    };

    const transformed: string[] = [];

    const calendar = new JewishCalendar(date);

    calendar.setInIsrael(inEretzIsrael);
    calendar.setUseModernHolidays(zioni);

    this.configure(language, minhag);
    const dayLink = this.getLinkDay(date, times, changeTimeKey);

    const config: HalachicTimesConfig = {
      options: this.halachicTimesService.options,
      candleLight: this.halachicTimesService.candleLightTime,
      formatter: this.formatter,
      calendar,
      jewishDate: new JewishDate(date),
      language,
      date,
      settings,
      times,
      minhag,
      timeFormat,
      studies,
      haftara,
      haftaraCaption,
      avot,
      hoshana,
      dayLink
    };

    return this.accountService.getUser()
      .pipe(
        switchMap((user: UserDto) => {
          const daysToAdd = this.getLinkDay(date, times, changeTimeKey);
          const newDate = new Date(date);
          newDate.setDate(date.getDate() + daysToAdd);
          const fetchDayParams: FetchDayParams = {
            Lyear: newDate.getFullYear() as any,
            Lmonth: newDate.getMonth() + 1 as any,
            Lday: newDate.getDate() as any,
            Chool: !inEretzIsrael,
            TextLanguage: language === Languages.Hebrew ? 1 : 2 as any,
            Latitude: user.location.latitude,
            Longitude: user.location.longitude,
            CandleLight: user.candleLight as any,
            isZioni: zioni,
          };
          return this.eithanApiService.fetchDayInfo(fetchDayParams);
        }),
        catchError(err => {
          console.log(err);
          return of(err);
        }),
        switchMap((res: DayInfoResponse) => {
          keys.forEach(key => {
            switch (key) {
              case 'dayDescription':
              case 'moridHatal':
              case 'moridHageshem':
              case 'yaaleVeyavo':
              case 'hoshana':
              case 'tachanun':
              case 'ledavid':
              case 'alHanisim':
              case 'hamelechHakadosh':
              case 'atoYatzarto':
              case 'eruvTavshilin':
              case 'dafYomi':
              case 'kiddushLevanahEnd':
              case 'kiddushLevanah3':
              case 'kiddushLevanah7':
              case 'vetenTal':
                if (key === 'dafYomi') {
                  dafYomiWithCaption ? transformed.push(DayInfoResponse.getByKey(key, res)) : transformed.push(DayInfoResponse.getByKey(key, res).split(': ')[1]);
                  break;
                } else {
                  DayInfoResponse.getByKey(key, res) ? transformed.push(DayInfoResponse.getByKey(key, res)) : null;
                  break;
                }
              case 'sunrise':
              case 'sunset':
              case 'fastEnd':
              case 'shabbosEnd':
              case 'rabeinuTam':
              case 'candleLight':
                let time = JewishHelper.halachicConfig[key](config);
                if (time) {
                  if (!padZero && time.split(':')[1].trimStart().startsWith('0')) {
                    time = time.replace('0', '');
                  }
                  transformed.push(time);
                }
                break;
              default:
                const value = JewishHelper.halachicConfig[key](config);
                if (value) {
                  transformed.push(value);
                }
            }
          });

          return of(transformed);
        })
      );
  }


  /**
   * Configures the HebrewDateFormatter.
   * @param {Languages} language - current language (enum).
   * @param {boolean} isLongWeek - whether to use the long week format.
   * @param {Minhags} minhag - current user minhag (enum) in order to determine the Sfirath Haomer prefix.
   * @private
  */
  private configure(language: Languages, minhag: Minhags): void {
    const isHebrew = language === Languages.Hebrew;
    this.formatter.setHebrewFormat(isHebrew);
    this.formatter.setLongWeekFormat(true);

    const omerPrefix = minhag === Minhags.Ashkenaz ? 'ב' : 'ל';
    this.formatter.setHebrewOmerPrefix(omerPrefix);
  }

  /**
   * Calculates the day link in order to change date at the selected change time.
   * @param {Date} date - current/selected date.
   * @param {KosherZmanimOptionDto[]} times - array of halachic time objects.
   * @param {ChangeTimes} changeTimeKey - change time key (e.g. sunset, tzais)
   * @private
   */
  private getLinkDay(date: Date, times: KosherZmanimOptionDto[], changeTimeKey: ChangeTimes = ChangeTimes.Tzais): number {
    if (changeTimeKey === ChangeTimes.Midnight) {
      return 0;
    }

    const changeTimeOption = times.find(time => time.key === changeTimeKey)?.option;

    // Adjusting time zone
    const timeZoneOptions: Intl.DateTimeFormatOptions = { timeZone: this.halachicTimesService.options.timeZoneId };

    if (date.getHours() === 0 && date.getMinutes() === 0) {
      this.halachicTimesService.date = date;
    }

    const changeTimeDate = new Date(this.halachicTimesService.halachicTimes[changeTimeOption])
      .toLocaleString('en-US', timeZoneOptions);

    return date > new Date(changeTimeDate) ? 1 : 0;
  }
}
