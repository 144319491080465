import { Component, ChangeDetectionStrategy, OnChanges, OnInit, AfterViewInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { Observable, timer, merge } from "rxjs";
import { map } from "rxjs/operators";
import { Languages } from "src/app/_enums";
import { Widget, EffectTypes, ScrollDirections, HorizontalAlign, VerticalAlign } from "src/app/_models";
import { DateService } from "src/app/_services";
import { AppState } from "src/app/store/app.state";
import { ViewMode } from "src/app/store/widget";

enum WidthClassName {
  Right = 'right-width',
  Left = 'left-width'
}
@Component({
  selector: 'app-text-viewer',
  templateUrl: './text-viewer.component.html',
  styleUrls: ['./text-viewer.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TextViewerComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() widget: Widget;
  @Input() content: string;
  @Input() effectType: EffectTypes;
  @ViewChild('container') contentRef: ElementRef<HTMLDivElement>;

  public ViewMode: typeof ViewMode = ViewMode;

  public horizontalScroll = [ScrollDirections.Right, ScrollDirections.Left];
  public verticalScroll = [ScrollDirections.Up, ScrollDirections.Down];

  public widthClassName = WidthClassName;
  public align = HorizontalAlign;
  public effectTypes = EffectTypes;
  public languages = Languages;
  public textByDate = ''
  public htmlContent: SafeHtml;
  public itemsPerView = 1;
  public newDate: Date;
  public horizontalScrollDirections = [ScrollDirections.Left, ScrollDirections.Right];
  public date: Date;
  public dayLink = 0;
  public list = []
  public mergedDate$: Observable<Date>;
  public speed: number = 5;

  public jewishDateDayLink: any
  public textValue = []
  public textValueAfter = []

  get widgetState() {
    return this.store.select(state => state.widgets[this.widget.id]);
  }

  get clones() {
    if (this.widget.effect.type === this.effectTypes.None) {
      this.itemsPerView = 1
      return new Array(this.itemsPerView);
    }
    return new Array(this.itemsPerView || 1);
  }

  private timeChanged$ = timer(0, 60 * 1000).pipe(
    map(() => new Date())
  );

  public scrollDuration = 3;

  get scrollSpeed() {
    this.calculetSpeed()
    return this.scrollDuration;

  }
  private calculetSpeed() {
    const singleItem = this.scrollElement1;

    const width = (((singleItem?.clientWidth / 100) / this.widget?.effect.scrollSpeed) || 3) * 20;
    const widthMath = Math.round(width * 10) / 10;

    const height = (((singleItem?.clientHeight / 100) / this.widget?.effect.scrollSpeed) || 3) * 20;
    const heightMath = Math.round(height * 10) / 10;

    const isHorizontal = [ScrollDirections.Left, ScrollDirections.Right].includes(this.widget.effect.scrollDirection);
    this.scrollDuration = isHorizontal ? widthMath : heightMath;

    if (this.widget.effect.autoEffect) {
      this.scrollDuration = 0;
    } else {
      this.silkySmoothScroll();
    }
  }

  /**
  * Scroll wrapper.
  * @private
  */
  private async silkySmoothScrollByTextByDate(): Promise<void> {
    while (!this.scrollElement1) {
      await new Promise(resolve => setTimeout(resolve, 100));
      console.log('waiting for scrollElement1');
    }
    setTimeout(() => this.scroll(this.scrollElement1, this.wrapperElement), 100);
  }

  constructor(
    private appElementRef: ElementRef,
    public sanitizer: DomSanitizer,
    public dateService: DateService,
    private store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.mergedDate$ = merge(this.timeChanged$, dateService.dateChanged$);
  }

  public ngOnChanges(): void {
    if (this.widget.content.text.isPermanent) {
      if (this.widget.effect.type === EffectTypes.Scroll) {
        this.silkySmoothScroll();
      }
    } else {
      if (this.widget.effect.type === EffectTypes.Scroll) {
        this.silkySmoothScrollByTextByDate();
      }
    }
  }



  public ngOnInit(): void {
    if (this.widget.content.text.isPermanent) {
      this.setSafeHtml(this.widget.content.text.permanent);
      this.getContent();
    }
  }

  public ngAfterViewInit(): void {
    this.store.select(state => state.widgets[this.widget.id]).subscribe(widget => {
      this.widget.content.text = { ...this.widget.content.text };
      this.changeDetectorRef.detectChanges();
    });
    if (this.widget.content.text.isPermanent) {
      if (this.widget.effect.type === EffectTypes.Scroll) {
        this.silkySmoothScroll();
      }
    } else {
      if (this.widget.effect.type === EffectTypes.Scroll) {
        setTimeout(() => {
          this.calculetSpeed();
        }, 3000)
        this.silkySmoothScrollByTextByDate();
      }
    }
  }

  public get horizontalAlignCenter(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Center;
  }

  public get verticalAlignCenter(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Center;
  }

  public get horizontalAlignLeft(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Left;
  }

  public get horizontalAlignRight(): boolean {
    return this.widget.font.horizontalAlign === HorizontalAlign.Right;
  }

  isHorizontalScroll() {
    return this.widget.effect.type == this.effectTypes.Scroll && this.horizontalScrollDirections.includes(this.widget.effect.scrollDirection);
  }

  scrollSetting() {
    return this.widget.effect.type === this.effectTypes.None ? 'doNotScroll' : this.widget.effect.autoEffect ? 'autoEffect' : 'scroll';
  }

  public get verticalAlignTop(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Top;
  }

  public get verticalAlignBottom(): boolean {
    return this.widget.font.verticalAlign === VerticalAlign.Bottom;
  }

  get isVertical(): boolean {
    return (
      this.widget.effect.scrollDirection === ScrollDirections.Up ||
      this.widget.effect.scrollDirection === ScrollDirections.Down
    );
  }

  get changeScrollSpeed() {
    let heightMath = 3
    let widthMath = 3

    const singleItem = this.scrollElement1;
    const isHorizontal = [ScrollDirections.Left, ScrollDirections.Right].includes(this.widget.effect.scrollDirection);
    if (isHorizontal) {
      const width = (((singleItem?.clientWidth / 100) / this.widget?.effect.scrollSpeed) || 3) * 20;
      widthMath = Math.round(width * 10) / 10;
    } else {
      const height = (((singleItem?.clientHeight / 100) / this.widget?.effect.scrollSpeed) || 3) * 20;
      heightMath = Math.round(height * 10) / 10;
    }

    this.scrollDuration = isHorizontal ? widthMath : heightMath;

    if (this.widget.effect.autoEffect) {
      this.scrollDuration = 0;
    } else {
      this.silkySmoothScroll();
    }
    return this.scrollDuration;
  }

  private get scrollElement1(): HTMLElement {
    return this?.contentRef?.nativeElement;
  }

  private get wrapperElement(): HTMLElement {
    return this.appElementRef.nativeElement;
  }

  /**
   * Listens to text content change.
   * @private
   */
  private getContent(): void {
    if (this.widget.contentSource) {
      this.widget.contentSource.subscribe((content: string) => this.setSafeHtml(content));
    }
  }

  /**
   * Converts HTML content to SafeHtml in order to render it.
   * @param {string} content - text content as HTML
   * @private
   */
  private setSafeHtml(content: string): void {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(content);
  }

  /**
   * Enables scroll
   * @private
   */
  private silkySmoothScroll(): void {
    const parentElement: HTMLElement = this.appElementRef.nativeElement;
    const scrollElement = parentElement.children[0] as HTMLElement;
    if (!scrollElement) return;
    setTimeout(() => this.scroll(scrollElement, parentElement), 2000);
  }

  /**
   * Defines number of items to scroll.
   * @param {HTMLElement} scrollElement - content to scroll.
   * @param {HTMLElement} parentElement - wrapper element.
   * @private
   * @return void
   */
  private scroll(scrollElement: HTMLElement, parentElement: HTMLElement): void {
    if (this.horizontalScrollDirections.includes(this.widget.effect.scrollDirection)) {
      const width = scrollElement.clientWidth;
      const parentWidth = parentElement.clientWidth;
      this.itemsPerView = Math.floor(parentWidth / width) * 2 + 10 || 10;
    } else {
      const height = scrollElement.clientHeight;
      const parentHeight = parentElement.clientHeight;
      this.itemsPerView = Math.floor(parentHeight / height) * 2 + 10 || 10;
    }
  }
}
