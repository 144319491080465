import { Component, Input } from '@angular/core';
import { UserDto } from '../../../../../_dto/user-dto';

@Component({
  selector: 'app-daf-yomi',
  templateUrl: './daf-yomi.component.html',
  styleUrls: ['./daf-yomi.component.css']
})
export class DafYomiComponent {
  @Input() user: UserDto;
}
