<div class="rss-dashboard-component container-sm d-flex flex-column justify-content-center align-items-center">
  <!-- RSS URL input -->
  <div class="settings mb-2 w-100">
    <mat-form-field floatLabel="always" class="w-100">
      <mat-label>RSS URL</mat-label>
      <input matInput id="rssUrl" type="text" [(ngModel)]="widget.content.rss.url" (change)="updateRssUrl()" />
    </mat-form-field>
  </div>

  <!-- Font size selection -->
  <div class="settings mb-2 w-100">
    <mat-form-field floatLabel="always" class="w-100">
      <mat-label>Font Size</mat-label>
      <mat-select id="fontSize" [(ngModel)]="widget.content.rss.fontSize" (selectionChange)="updateFontSize($event)">
        <mat-option *ngFor="let size of fontSizes" [value]="size">{{ size }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Number of items to display -->
  <div class="settings mb-2 w-100">
    <mat-form-field floatLabel="always" class="w-100">
      <mat-label>Number of Items</mat-label>
      <input matInput id="numberOfItems" type="number" [(ngModel)]="widget.content.rss.numberOfItems"
        (change)="updateNumberOfItems()" />
    </mat-form-field>
  </div>
</div>