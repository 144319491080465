import { WidgetTypes } from '../_enums/widget-types';
import { BackgroundTypes } from '../_models/background';
import { Effect, EffectTypes, ScrollDirections } from '../_models/effect';
import { Font, FontStyle, HorizontalAlign, VerticalAlign } from '../_models/font';
import { Jewish } from '../_models/jewish';
import { Screen } from '../_models/screen';
import { WidgetIcons } from '../_models/widget-icon';

const { Table, YahrzeitTable, Text, Media, Digital, Jewish, Clock, RSS, PDF, Web } = WidgetTypes;

export class WidgetHelper {

  public static DefaultFont: Font = {
    family: 'David',
    size: 5,
    color: '#131313',
    style: FontStyle.Normal,
    lineHeight: 120,
    horizontalAlign: HorizontalAlign.Justify,
    verticalAlign: VerticalAlign.Top,
    highlightColor: '#131313'
  };

  public static DefaultEffect: Effect = {
    type: EffectTypes.None,
    scrollSpeed: 25,
    scrollDirection: ScrollDirections.Up,
    autoEffect: false
  }

  public static DefaultScreen: Screen = {
    name: 'New Screen',
    font: { ...WidgetHelper.DefaultFont },
    background: { type: BackgroundTypes.Color, color: '#e7ddff' },
    widgets: [],
    portraitOrientation: false
  };

  public static widgetIcons: WidgetIcons = [
    { path: 'assets/images/text-format.svg', name: 'text', type: Text },
    { path: 'assets/images/menorah.svg', name: 'jewish', type: Jewish },
    { path: 'assets/images/two-columns-layout.svg', name: 'table', type: Table },
    { path: 'assets/images/play-button.svg', name: 'media', type: Media },
    { path: 'assets/images/clock.svg', name: 'clock', type: Clock },
    { path: 'assets/images/digital-clock.svg', name: 'digital', type: Digital },
    { path: 'assets/images/candle.svg', name: 'yahrzeit', type: YahrzeitTable },
    { path: 'assets/images/globe.svg', name: 'web', type: Web },
    { path: 'assets/images/pdf-file.svg', name: 'pdf', type: PDF },
    { path: 'assets/images/rss-feed.svg', name: 'rss', type: RSS }
  ];

  public static widgets = {
    text: {
      icon: 'text-format',
      title: 'Text'
    },
    jewish: {
      icon: 'menorah',
      title: 'Jewish'
    },
    table: {
      icon: 'two-columns-layout',
      title: 'Table'
    },
    media: {
      icon: 'play-button',
      title: 'Media'
    },
    music: {
      icon: 'music',
      title: 'Music'
    },
    pdf: {
      icon: 'pdf-file',
      title: 'PDF'
    },
    clock: {
      icon: 'clock',
      title: 'Clock'
    },
    digital: {
      icon: 'digital-clock',
      title: 'Digital'
    },
    yahrzeit: {
      icon: 'candle',
      title: 'Yahrzeit'
    },
    web: {
      icon: 'globe',
      title: 'Web'
    },
    rss: {
      icon: 'rss-feed',
      title: 'RSS'
    }
  };

  /**
   * Clears the selected widget border for all widgets.
   * @param {string} className - selected widget class, 'selected-widget' by default.
   * @private
   */
  public static clearSelectedStyle(className = 'selected-widget'): void {
    const widgetElements = document.querySelectorAll('.back');
    widgetElements.forEach(element => element.classList.remove(className));
  }

  /**
   * Calculates a new widget ID.
   * @param {Screen} screen - current screen.
   */
  public static getNewWidgetId(screen: Screen): number {
    let id = 1;
    while (WidgetHelper.widgetIdExists(screen, id)) {
      id += 1;
    }

    return id;
  }

  /**
   * Checks whether a widget exists on a specified screen.
   * @param {Screen} screen - current screen.
   * @param {number} id - widget ID.
   * @private
   */
  private static widgetIdExists(screen: Screen, id: number): boolean {
    if (screen.widgets.length === 0) {
      return false;
    }
    return screen.widgets.some(widget => widget.id === id);
  }
}
