<pdf-viewer *ngIf="!isBlocked && !isError"
            [fit-to-page]="true"
            [page]="pageNumber + 1"
            [render-text]="false"
            [show-all]="false"
            [src]="src"
            [zoom-scale]="'page-width'"
            (error)="onError($event)"
            (after-load-complete)="setPagesNumber($event)"
            (page-rendered)="fixView()">
</pdf-viewer>

<div *ngIf="isBlocked" class="blocked-message">
  <p>התוכן חסום.</p>
  <p>נא תפנו לספק שלכם.</p>
<!--  <p>חסום על ידי Netfree.</p>-->
<!--  <p>הגדירו בהגדרות הסינון האישיות שלכם “אל תתריע על שיבוש קבצים” ולחצו “שמור” בסיום.</p>-->
</div>

<div *ngIf="isError" class="error-message">
  <p>PDF loading error. Contact the support.</p>
</div>
