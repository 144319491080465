<div class="container position-relative"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane">
  <button (click)="filterAndApprove()" class="close-btn" mat-icon-button type="button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-card [formGroup]="recordsForm">
    <mat-card-header class="title-block"
                     [style.direction]="direction"
                     [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
      <mat-card-title>{{ 'dashboard.widgetProperties.specific.table.create' | translate }}</mat-card-title>
      <mat-card-subtitle>{{ 'dashboard.widgetProperties.specific.table.subtitle' | translate }}</mat-card-subtitle>

      <button (click)="openAdvancedSettingsForSelected()"
              [title]="'dashboard.widgetProperties.specific.table.advanced.settingsAll' | translate"
              [style.right]="direction === 'rtl' ? '' : '2rem'"
              [style.left]="direction === 'rtl' ? '2rem' : ''"
              color="primary"
              class="settings-all"
              mat-icon-button>
        <mat-icon>settings</mat-icon>
      </button>

      <button (click)="addRecord()"
              [title]="'dashboard.widgetProperties.specific.table.addRecord' | translate"
              [style.right]="direction === 'rtl' ? '' : '0'"
              [style.left]="direction === 'rtl' ? '0' : ''"
              type="submit"
              color="accent"
              class="add-record"
              mat-icon-button>
        <mat-icon>add</mat-icon>
      </button>

      <div [class.disabled]="!widget.content.table.records.length" class="all-enabled-checkbox">
        <mat-checkbox (change)="setAll($event.checked)"
                      [checked]="allSelected"
                      [indeterminate]="someSelected()"
                      [labelPosition]="direction === 'rtl' ? 'before' : 'after'">
          {{ 'dashboard.widgetProperties.specific.table.advanced.selectAll' | translate }}
        </mat-checkbox>
      </div>
    </mat-card-header>

    <mat-card-content #recordsList (cdkDropListDropped)="drop($event)" cdkDropList class="records-list">
      <div #tableRecord
           *ngFor="let record of widget.content.table.records; let i = index"
           cdkDrag
           class="record-box fade-in-top my-3">
        <div class="record-custom-placeholder w-100" *cdkDragPlaceholder></div>
        <mat-card class="table-record w-100">
          <mat-card-content class="record">
<!--            Checkbox-->
            <div class="enabled-checkbox">
              <mat-checkbox (change)="setSelected(i); updateAllEnabled()"
                            [checked]="selectedIndexes.includes(i)"
                            [title]="selectedIndexes.includes(i)
                                    ? ('dashboard.widgetProperties.specific.table.advanced.unselect' | translate)
                                    : ('dashboard.widgetProperties.specific.table.advanced.select' | translate)">
              </mat-checkbox>
            </div>

<!--            Settings button-->
            <div class="record-settings">
              <button (click)="openAdvancedSettings(i)"
                      [title]="'dashboard.widgetProperties.specific.table.advanced.title' | translate"
                      color="primary"
                      mat-icon-button>
                <mat-icon>settings</mat-icon>
              </button>
            </div>

            <div class="col-1"></div>

<!--            Dynamic/Fixed-->
            <div class="col-4 inside-record-item">
              <mat-slide-toggle (toggleChange)="toggleDynamicFixed(record)"
                                *ngIf="!record.titleOnly"
                                (change)="refreshService.refreshPipe()"
                                [checked]="record.isFixed"
                                class="event-type">
                <span class="mx-2">{{ 'dashboard.widgetProperties.specific.table.dynamic' | translate }}</span>
              </mat-slide-toggle>
            </div>

<!--            Custom title-->
            <div class="col-2 inside-record-item">
              <mat-form-field appearance="standard" class="title"
                              [style.direction]="direction"
                              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                <mat-label *ngIf="!(record.key && !record.custom)">{{ 'dashboard.widgetProperties.specific.table.title' | translate }}</mat-label>
                <input [(ngModel)]="record.custom"
                       [formControlName]="'title' + i"
                       [errorStateMatcher]="i === widget.content.table.records.length - 1 && record.isFixed ? titleErrorMatcher : null"
                       (input)="refreshService.refreshPipe(); titleInvalid = false"
                       [placeholder]="record.isFixed ? '' : standardTitles[record.key]"
                       [value]="record.custom ? record.custom : ''"
                       [required]="record.isFixed || record.titleOnly"
                       matInput
                       type="text">
                <mat-error *ngIf="titleInvalid">
                  {{ 'dashboard.widgetProperties.specific.table.errors.customRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

<!--            Add minutes-->
            <div class="col-1 inside-record-item" *ngIf="!record.isFixed && !record.titleOnly">
              <mat-form-field class="time"
                              [style.direction]="direction"
                              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                <mat-label>{{ 'dashboard.widgetProperties.specific.table.add' | translate }}</mat-label>
                <input [(ngModel)]="record.addMinutes"
                       [ngModelOptions]="{ standalone: true }"
                       (input)="refreshService.refreshPipe()"
                       matInput
                       type="number">
              </mat-form-field>
            </div>

<!--            Set fixed time-->
            <div class="col-1 inside-record-item" [class.d-none]="!(record.isFixed && !record.titleOnly)">
              <mat-form-field class="set-time">
                <mat-label>{{ 'dashboard.widgetProperties.specific.table.setTime' | translate }}</mat-label>
                <input (input)="refreshService.refreshPipe(); fixedTimeInvalid = false"
                       [format]="timeFormat"
                       [ngxTimepicker]="timePicker"
                       [formControlName]="'time' + i"
                       [errorStateMatcher]="i === widget.content.table.records.length - 1 && record.isFixed ? timeErrorMatcher : null"
                       [value]="record.fixedTime"
                       [required]="record.isFixed"
                       matInput
                       readonly>

                <ngx-material-timepicker-toggle [for]="timePicker" matSuffix>
                  <mat-icon [color]="fixedTimeInvalid ? 'warn' : 'primary'" ngxMaterialTimepickerToggleIcon>schedule</mat-icon>
                </ngx-material-timepicker-toggle>

                <ngx-material-timepicker #timePicker
                                         (timeSet)="setFixedTime(record, $event)"
                                         [cancelBtnTmpl]="cancelButton"
                                         [confirmBtnTmpl]="okButton"
                                         [enableKeyboardInput]="true">
                </ngx-material-timepicker>

                <ng-template #cancelButton>
                  <button mat-raised-button color="accent" style="margin-right: 1rem">{{ 'buttons.cancel' | translate }}</button>
                </ng-template>

                <ng-template #okButton>
                  <button mat-raised-button color="primary">{{ 'buttons.ok' | translate }}</button>
                </ng-template>

                <mat-error *ngIf="fixedTimeInvalid">
                  {{ 'dashboard.widgetProperties.specific.table.errors.fixedTimeRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

<!--            Halachic times-->
            <div [class.d-none]="record.titleOnly || record.isFixed"
                 class="col-3 inside-record-item key-select">
              <mat-form-field [style.direction]="direction"
                              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                <mat-label>{{ 'dashboard.widgetProperties.specific.table.halachicTime' | translate }}</mat-label>
                <mat-select [(value)]="record.key"
                            (selectionChange)="changeKey($event.value, record); refreshService.refreshPipe(); halachicTimeInvalid = false"
                            [formControlName]="'halachicTime' + i"
                            [errorStateMatcher]="i === widget.content.table.records.length - 1 ? halachicTimeErrorMatcher : null"
                            required>
                  <mat-option *ngFor="let time of standardHalachicTimes"
                              [value]="time.key"
                              [style.direction]="direction"
                              [style.text-align]="direction === 'rtl' ? 'right' : 'left'">
                    {{ direction === 'rtl' ? time.he : time.en }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="halachicTimeInvalid">
                  {{ 'dashboard.widgetProperties.specific.table.errors.halachicTimeRequired' | translate }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="duplicate-record">
              <button (click)="duplicateRecord(record)"
                      [title]="'dashboard.widgetProperties.specific.table.duplicateRecord' | translate"
                      color="primary"
                      mat-icon-button>
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>

            <div class="remove-record">
              <button (click)="removeRecord(record, tableRecord)"
                      [title]="'dashboard.widgetProperties.specific.table.removeRecord' | translate"
                      color="accent"
                      mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="row justify-content-center">
    <div class="mt-4">
      <button mat-raised-button color="primary" class="mr-5" (click)="filterAndApprove()">{{ 'buttons.ok' | translate }}</button>
    </div>
  </div>
</div>
