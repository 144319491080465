import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Observable, of } from 'rxjs';
import { publishReplay, refCount, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { OrganisationDto } from '../_dto/organisation-dto';
import { LocalStorage } from '../_enums/local-storage';
import { Screen } from '../_models/screen';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class ContentApiService extends BaseApiService {
  private apiUrl = environment.apiUrl;
  private hubUrl = environment.hubUrl;
  private hubConnection: HubConnection;
  private _organisations$: Observable<OrganisationDto[]>;
  public groupStatus: {
    connectedCount: number,
    groupId: string,
    totalCount: number
  }[] = []

  constructor(private http: HttpClient) {
    super();
  }

  public get organisations$(): Observable<OrganisationDto[]> {
    return this._organisations$;
  }

  /**
   * Fetches the list of organisations from a server and caches it.
   */
  public getOrganisations(): Observable<OrganisationDto[]> {
    if (!this._organisations$) {
      this._organisations$ = this.http.get<OrganisationDto[]>(this.apiUrl + 'content', { headers: ContentApiService.authorizationHeader })
        .pipe(
          publishReplay(1),
          refCount()
        );
    }
    return this._organisations$;
  }

  /**
   * Creates a new organisation.
   * @param {OrganisationDto} organisationDto - organisation details.
   */
  public addOrganisation(organisationDto: OrganisationDto): Observable<OrganisationDto[]> {
    return this.http.post<OrganisationDto[]>(this.apiUrl + 'content', organisationDto, { headers: ContentApiService.authorizationHeader })
      .pipe(tap((organisations: OrganisationDto[]) => this._organisations$ = of(organisations)));
  }

  /**
   * Updates an organisation.
   * @param {OrganisationDto} organisationDto - organisation details.
   */
  public updateOrganisation(organisationDto: OrganisationDto): Observable<OrganisationDto[]> {
    return this.http.put<OrganisationDto[]>(this.apiUrl + 'content/', organisationDto, { headers: ContentApiService.authorizationHeader })
      .pipe(tap((organisations: OrganisationDto[]) => this._organisations$ = of(organisations)));
  }

  /**
   * Deletes organisation by its ID.
   * @param {number} id - organisation ID.
   */
  public removeOrganisation(id: number): Observable<OrganisationDto[]> {
    return this.http.delete<OrganisationDto[]>(this.apiUrl + 'content/' + id, { headers: ContentApiService.authorizationHeader })
      .pipe(tap((organisations: OrganisationDto[]) => this._organisations$ = of(organisations)));
  }

  /**
   * Deletes selected organisations by their ID.
   * @param {number[]} ids - organisation IDs.
   */
  public removeOrganisations(ids: number[]): void {
    ids.forEach((id: number, index: number, array: number[]) => this.http.delete(this.apiUrl + 'content/' + id, {
      headers: ContentApiService.authorizationHeader
    })
      .subscribe((organisations: OrganisationDto[]) => {
        if (index === array.length - 1) {
          this._organisations$ = of(organisations);
        }
      })
    );
  }

  /**
   * Checks whether organisation name already exists.
   * @param {string} name - a name to be checked.
   */
  public checkNameExists(name: string): Observable<boolean> {
    return this.http.get<boolean>(this.apiUrl + 'content/nameexists?name=' + name);
  }

  /**
   * Creates a new screen to a specific organisation.
   * @param {Screen} screen - screen details.
   * @param {number} id - organisation ID.
   */
  public addScreen(screen: Screen, id: number): Observable<Screen> {
    return this.http.post<Screen>(this.apiUrl + 'content/' + id, screen, { headers: ContentApiService.authorizationHeader });
  }

  /**
   * Updates a screen on server via SignalR connection.
   * @param {Screen} screen - screen details.
   */
  public updateScreen(screen: Screen): Observable<void> {
    // Create a sanitized screen copy and remove unnecessary properties from widgets
    const sanitizedScreen = this.sanitizeScreen(screen);

    // Make the API request to update the screen
    return this.http.put<void>(this.apiUrl + 'content/screens', sanitizedScreen, { headers: ContentApiService.authorizationHeader })
      .pipe(
        tap(() => {
          // Refresh the screen using SignalR
          this.refreshScreen();
        })
      );
  }

  private sanitizeScreen(originalScreen: Screen): Screen {
    const sanitizedScreen = { ...originalScreen, widgets: [] };
    for (const widget of originalScreen.widgets) {
      const sanitizedWidget = { ...widget };
      delete sanitizedWidget.resizeStopSource;
      delete sanitizedWidget.contentChangedSource;
      delete sanitizedWidget.effectSource;
      sanitizedScreen.widgets.push(sanitizedWidget);
    }
    return sanitizedScreen;
  }

  private refreshScreen(): void {
    this.hubConnection.invoke('RefreshScreen').catch(error => console.log(error));
  }

  /**
   * Deletes a screen from in a specific organisation.
   * @param {number} screenId - screen ID.
   * @param {number} organisationId - organisation ID
   */
  public removeScreen(screenId: number, organisationId: number): Observable<any> {
    return this.http.delete(this.apiUrl + 'content/' + organisationId + '/' + screenId, { headers: ContentApiService.authorizationHeader });
  }

  /**
   * Clears the organisation cache.
   */
  public clearOrganisationsCache(): void {
    this._organisations$ = null;
  }

  /**
   * Creates the UpdateHub connection.
   * @param {string} token - user's token for authorisation purpose.
   * @param {number} screenId - user's screen ID.
   */
  public createHubConnection(token: string, screenId: number): void {
    const deviceId = localStorage.getItem(LocalStorage.UniqueDeviceId);

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.hubUrl}refresh?screenId=${screenId}&deviceId=${deviceId}`, { accessTokenFactory: () => token })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.start()
      .catch(error => console.error('Error while starting connection: ' + error));

    this.hubConnection.on('ReceiveRefreshNotification', (id: string) => {
      this.hubConnection.invoke('SendRefreshAck', deviceId).catch(error => console.error('Error while sending refresh ack: ' + error));
      if (id !== deviceId) {
        window.location.reload();
      }
    });

    this.hubConnection.on('UpdateGroupStatus', (data) => {
      this.groupStatus = data.groups;
      console.log(this.groupStatus);
    });
  }

  /**
   * Stops the UpdateHub connection.
   */
  public stopHubConnection(): void {
    if (this.hubConnection) {
      this.hubConnection.stop().catch(error => console.error('Error while stopping connection: ' + error));
    }
  }

  /**
   * Checks whether UpdateHub connection exists.
   * @return {boolean}
   */
  public get updateHubConnectionExists(): boolean {
    return !!this.hubConnection;
  }
}
