<div [style.direction]="config.direction">
  <div mat-dialog-content>
    <mat-label>{{ config.message }}</mat-label>
  </div>

  <app-date-selector [selectedDate]="date || dateService.dateChangeSource.value" (selectedDateChange)="selectedDateChange($event)" mode="component-specific"></app-date-selector>

  <div [style]="{
    '--text-editor-textarea': config.direction === 'rtl' ? 'rtl' : 'ltr'
  }" class="settings text-editor container-sm d-flex justify-content-center align-items-center pr-4">
    <angular-editor [(ngModel)]="config.htmlContent" [config]="editorConfig">
    </angular-editor>
  </div>

  <div mat-dialog-actions class="actions">
    <button mat-raised-button color="warn" matDialogClose>{{ 'userList.popups.buttons.yes' | translate }}</button>
    <button mat-raised-button color="primary" (click)="onCancel()">{{ 'userList.popups.buttons.cancel' | translate }}</button>
  </div>

</div>
