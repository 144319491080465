import { OverlayRef, Overlay } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, Output, ViewChild, TemplateRef, ElementRef, ChangeDetectorRef, ViewContainerRef, EventEmitter } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSidenav, MatDrawerToggleResult } from "@angular/material/sidenav";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { IResizeEvent } from "angular2-draggable/lib/models/resize-event";
import { JewishCalendar } from "kosher-zmanim";
import { Subscription, interval, BehaviorSubject, Subject, fromEvent } from "rxjs";
import { environment } from "src/environments/environment";
import { UserDto } from "../_dto";
import { WidgetTypes, TimeFormat, Languages, ChangeTimes } from "../_enums";
import { Direction, TranslateHelper, JewishHelper, WidgetHelper, SchedulerHelper, hasPermission } from "../_helpers";
import { ScrollDirections, BackgroundTypes, Widget, WidgetIcon, Effect, EffectTypes, FontStyle, Screen } from "../_models";
import { PermissionTypes } from "../_models/identity";
import { ConfirmationComponent } from "../_modules/admin/admin-dashboard/user-list/confirmation/confirmation.component";
import { ApiService, EditService, WidgetService, AccountService, HalachicTimesService, DateService } from "../_services";
import { filter, take } from "rxjs/operators";

interface IBgImage {
  backgroundImage: string;
}

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ScreenComponent implements OnInit, OnDestroy {
  @Input() screen: Screen;
  @Output() sidenavOpened = new EventEmitter<boolean>();
  @ViewChild('menu') menu: TemplateRef<any>;
  @ViewChild('dashboard') dashboard: MatSidenav;
  @ViewChild('screenBox') screenElement: ElementRef<HTMLDivElement>;

  public resourcesUrl = environment.resourcesUrl;
  public widgetTypes = WidgetTypes;
  public horizontalScrollDirections = [ScrollDirections.Right, ScrollDirections.Left];
  public editMode = false;
  public portraitOrientation = false;
  public bgImage: IBgImage;
  public logoUrl = '';
  public singleMediaTypes = ['Image', 'Video', 'Sound', 'PDF', 'URL'];
  public maxZIndex = 20;
  public backgroundTypes = BackgroundTypes;
  public user: UserDto;
  public direction: Direction = 'ltr';

  private overlayRef: OverlayRef | null;
  private selectedDate: Date;
  private contextMenuSubscription: Subscription;
  private editModeChangeSubscription: Subscription;
  private orientationSubscriptionFromScreen: Subscription;
  private orientationSubscriptionFromDashboard: Subscription;

  // private readonly halachicTimes: object;

  constructor(
    private sanitizer: DomSanitizer,
    private ajax: ApiService,
    private appRef: ChangeDetectorRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private editService: EditService,
    private widgetService: WidgetService,
    private accountService: AccountService,
    private halachicTimesService: HalachicTimesService,
    private dateService: DateService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private translateHelper: TranslateHelper

  ) {
    // this.halachicTimes = halachicTimesService.halachicTimes;
    accountService.getUser().subscribe((user: UserDto) => this.user = user);
  }

  /**
   * Checks whether the object is empty.
   * @param {object} obj - the object to be checked.
   * @private
   * @static
   */
  private static isEmpty(obj: {}): boolean {
    return Object.keys(obj).length > 0;
  }

  /**
   * Retrieves a filename from the URL.
   * @param {string} url - given URL.
   * @private
   * @static
   */
  private static getFilenameFromUrl(url: string): string {
    if (!url) return;
    const arr = url.split('/');
    return arr[arr.length - 1];
  }

  /**
   * Checks whether the browser is in Full Screen mode.
   * @private
   * @static
   */
  private static isInFullScreen(): boolean {
    return window.innerHeight === screen.height;
  }

  public async ngOnInit(): Promise<void> {
    this.observeDateChange();
    this.setTranslation();

    this.listenToEditModeChange();
    this.listenToOrientationChange();

    this.setDate();
    while (!this.user) {
      await new Promise(r => setTimeout(r, 100));
    }
    const clockInterval$ = interval(1000);
    clockInterval$.subscribe(() => {
      const candleLightTime = JewishHelper.getShabbosRelatedTime<Date>('candleLight', this.selectedDate, this.halachicTimesService.options, TimeFormat._24H, this.user.times, true, 'date');
      const shabbosEndTime = JewishHelper.getShabbosRelatedTime<Date>('shabbosEnd', this.selectedDate, this.halachicTimesService.options, TimeFormat._24H, this.user.times, true, 'date');
      this.halachicTimesService.options.date = this.selectedDate;
      const sunsetTime = JewishHelper.getTodaysRelatedTime<Date>('sunset', this.halachicTimesService.options, this.user.times, TimeFormat._24H, 'date');
      this.screen?.widgets.forEach(async widget => {
        widget.visible = !widget.isHidden && await this.isWidgetVisible(widget, candleLightTime, shabbosEndTime, sunsetTime);
      });
      this.appRef.detectChanges();
    });
  }

  observeDateChange(): void {
    this.dateService.dateChanged$.subscribe(_ => {
      this.screen?.widgets.forEach(widget => {
        widget.contentChangedSource.next();
      });
    });
  }

  public ngOnDestroy(): void {
    this.orientationSubscriptionFromDashboard.unsubscribe();
    this.orientationSubscriptionFromScreen.unsubscribe();
    this.editModeChangeSubscription.unsubscribe();
  }

  /**
   * Sets selected widget border, creates a widget icon object and emits both.
   * @param {Widget} widget - selected widget.
   * @param {HTMLElement} host - widget wrapper HTML element.
   * @param {boolean} fromMenu - whether the widget was selected from the context menu.
   */
  public selectWidget(widget: Widget, host?: HTMLElement, fromMenu = false): void {
    console.log('WIDGET:', widget);

    WidgetHelper.clearSelectedStyle();
    if (host) {
      host.classList.add('selected-widget');
      host.style.zIndex = (++this.maxZIndex).toString();
    }

    let widgetIcon: WidgetIcon;
    if (!fromMenu && ScreenComponent.isEmpty(host.dataset)) {
      const { path, name, type } = host.dataset;
      widgetIcon = { path, name, type: WidgetTypes[type] };
    } else {
      widgetIcon = widget.widgetIcon;
    }

    if (!widget.contentSource) {
      widget.contentSource = new BehaviorSubject(widget.content.jewish.keys);
      widget.contentSource.next(widget.content.jewish.keys);
    }

    if (!widget.effectSource) {
      widget.effectSource = new BehaviorSubject<Effect>({ type: EffectTypes.None });
    }

    if (!widget.contentChangedSource) {
      widget.contentChangedSource = new Subject<void>();
    }

    if (!widget.resizeStopSource) {
      widget.resizeStopSource = new Subject<IResizeEvent>();
    }

    this.widgetService.selectWidget(widget, widgetIcon);
    this.close();
  }

  /**
   * Opens the widget remove conformation modal window and removes all the media files related to the removed widget.
   * @param {Widget} widget - the widget to remove.
   */
  public removeWidget(widget: Widget): void {
    const filesToRemove: string[] = [];
    const dialogRef: MatDialogRef<ConfirmationComponent> = this.dialog.open(ConfirmationComponent);

    dialogRef.componentInstance.config = {
      direction: 'ltr',
      message: this.translate.instant('snackbar.deleteWidget')
    };

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed === '') {

        if ([WidgetTypes.Media, WidgetTypes.PDF, WidgetTypes.Music].includes(widget.type)) {
          filesToRemove.push(ScreenComponent.getFilenameFromUrl(widget.background.imageUrl));
          widget.content.media?.items?.forEach(item => filesToRemove.push(ScreenComponent.getFilenameFromUrl(item?.url)));
          filesToRemove.push(ScreenComponent.getFilenameFromUrl(widget.content.pdf?.url));
        }

        this.screen.filesToRemove.push(...filesToRemove.filter(item => item));
        this.screen.widgets = this.screen.widgets.filter(w => w.id !== widget.id);
        this.widgetService.updateScreen(this.screen);
        this.widgetService.openWidgetsTab();
      }
    });
  }

  /**
   * Calculates (in percentage) the new widget width & height after resize.
   * @param {IResizeEvent} event - the resize event.
   * @param {Widget} widget - the resizing widget.
   */
  public onResizing(event: IResizeEvent, widget: Widget): void {
    const { position: { left, top }, size: { width, height } } = event;

    widget.position.top = this.getDimensionInPercents(top, 'height');
    widget.position.left = this.getDimensionInPercents(left, 'width');
    widget.size.height = this.getDimensionInPercents(height, 'height');
    widget.size.width = this.getDimensionInPercents(width, 'width');
    widget.size.widthPx = event.size.width;
    widget.size.heightPx = event.size.height;
  }

  public onResizeStop(event: IResizeEvent, widget: Widget): void {
    widget.resizeStopSource.next(event);
  }

  /**
   * Calculates (in percentage) the new widget position after widget drag .
   * @param {number} x - widget x coordinate.
   * @param {number} y - widget y coordinate.
   * @param {Widget} widget - the dragged widget.
   * @param {HTMLDivElement} host - widget wrapper HTML element.
   */
  public onDragEnded({ x, y }: { x: number, y: number }, widget: Widget, host: HTMLDivElement): void {
    host.style.zIndex = (++this.maxZIndex).toString();
    widget.position.left += this.getDimensionInPercents(x, 'width');
    widget.position.top += this.getDimensionInPercents(y, 'height');

    host.style.transform = 'none';

    this.widgetService.updateScreen(this.screen);
  }

  /**
   * Applies CSS according to the widget preferences.
   * @param {Widget} widget - the widget to apply CSS to.
   */
  public generateCss(widget: Widget): object {
    const css: any = {
      position: 'absolute',
      top: widget.position.top + '%',
      left: widget.position.left + '%',
      width: widget.size.width + '%',
      height: widget.size.height + '%',
      backgroundSize: '100% 100%',
      overflow: 'hidden',
      direction: widget.language === Languages.Hebrew && widget.type !== WidgetTypes.TableAlert ? 'rtl' : 'ltr',
      resize: ScreenComponent.isInFullScreen ? 'initial' : 'both'
    };

    if (widget.type !== WidgetTypes.Text) {
      css.textAlign = 'center';
    }

    // if (widget.font && widget.type !== WidgetTypes.Text) {
    if (widget.font && (widget.widgetIcon.type !== WidgetTypes.Text)) {
      css.color = widget.font.color;
      css.fontFamily = widget.font.family;
      css.fontSize = widget.font.size / 2 + 'vh';
      css.fontWeight = widget.font.style === FontStyle.Bold ? 'bold' : 'normal';
      css.fontStyle = widget.font.style === FontStyle.Italic ? 'italic' : 'normal';
      css.textDecorationLine = widget.font.style === FontStyle.Underline ? 'underline' : 'none';
      css.lineHeight = widget.font.lineHeight + '%';
    }

    switch (widget.background.type) {
      case BackgroundTypes.Transparent: { // makes screen background like element background
        css.backgroundImage = '';
        css.backgroundSize = '100vw 100vh';
        css.backgroundPosition = -(widget.position.left) + 'vw -' + (widget.position.top) + 'vh';
        break;
      }
      case BackgroundTypes.Color: {
        css.backgroundColor = widget.background.color;
        break;
      }
      case BackgroundTypes.Image: {
        css.backgroundSize = widget.background.stretched ? '100% 100%' : 'contain';
        css.backgroundRepeat = 'no-repeat';
        css.backgroundPosition = 'center';
        break;
      }
    }

    return css;
  }

  /**
   * Opens widget context menu.
   * @param {MouseEvent} event - mouse event on right button click.
   * @param {Widget} widget - the widget for which the context menu is being opened.
   * @param {HTMLDivElement} element - widget wrapper HTML element.
   */
  public openContextMenu(event: MouseEvent, widget: Widget, element: HTMLDivElement) {
    event.preventDefault();
    if (!this.editMode) return;
    this.close();

    WidgetHelper.clearSelectedStyle();
    element.classList.add('selected-widget');

    const { x, y } = event;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.menu, this.viewContainerRef, { $implicit: widget }));

    this.contextMenuSubscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(e => {
          const clickTarget = e.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close());

  }

  /**
   * Determines whether the widget is visible.
   * @param {Widget} widget - widget in question.
   * @private
   * @async
   */
  private async isWidgetVisible(widget: Widget, candleLightTime: Date, shabbosEndTime: Date, sunsetTime: Date): Promise<boolean> {
    if (!widget.scheduler || !widget.enabled) return true;
    const date = new Date(this.selectedDate);
    const jewishCalendar = new JewishCalendar(date);

    return SchedulerHelper.isDayVisible(widget.scheduler, date) &&
      SchedulerHelper.isRangeVisible(widget.scheduler, date) &&
      SchedulerHelper.isHebrewRangeVisible(widget.scheduler, jewishCalendar, date) &&
      SchedulerHelper.isTimeRangeVisible(widget.scheduler.timeRange, date) &&
      await SchedulerHelper.isHolidayVisible(widget.scheduler.holidays, this.getLinkDay(date, widget.changeTimeKey), jewishCalendar, date, candleLightTime, shabbosEndTime) &&
      (widget.scheduler.dynamicTimeRange?.time ? SchedulerHelper.isDynamicTimeRangeVisible(widget.scheduler.dynamicTimeRange, sunsetTime, date) : true) &&
      SchedulerHelper.isBlinkVisible(widget.scheduler.blink) &&
      SchedulerHelper.isNotEmpty(widget.scheduler.ifEmpty, widget.id);
  }

  /**
   * Converts dimension from pixels to percents.
   * @param {number} value - dimension in px.
   * @param {'width' | 'height'} key - dimension key ('width' | 'height').
   * @private
   */
  private getDimensionInPercents(value: number, key: 'width' | 'height'): number {
    const { clientWidth, clientHeight } = this.screenElement.nativeElement;
    const dimension = {
      width: clientWidth,
      height: clientHeight
    };
    return value * 100 / dimension[key];
  }

  /**
   * Closes the context menu.
   * @private
   */
  private close(): void {
    if (this.contextMenuSubscription) {
      this.contextMenuSubscription.unsubscribe();
    }
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  /**
   * Calculates halachic time considering the timezone.
   * @param time
   * @private
   */
  private getHalachicTime(time: string): Date {
    return new Date(this.halachicTimesService.halachicTimes[time]);
  }

  /**
   * Calculates the day link in order to change date at the selected change time.
   * @param {Date} date - current/selected date.
   * @param {ChangeTimes} changeTimeKey - change time key (e.g. sunset, tzais)
   * @private
   */
  private getLinkDay(date: Date, changeTimeKey: ChangeTimes = ChangeTimes.Tzais): number {
    const changeTimeOption = this.user.times.find(time => time.key === changeTimeKey)?.option;
    return date > new Date(this.halachicTimesService.halachicTimes[changeTimeOption]) ? 1 : 0;
  }

  /**
   * Sets the selected date.
   * @private
   */
  private setDate(): void {
    this.dateService.dateChanged$.subscribe(date => this.selectedDate = date);
  }

  /**
   * Opens a sidenav on edit mode enabling.
   * @private
   */
  private listenToEditModeChange(): void {
    this.editModeChangeSubscription = this.editService.editMode$.subscribe(editMode => {
      if (hasPermission(PermissionTypes.ManageWidgets)) {
        this.editMode = editMode;
        this.dashboard.toggle()
          .then((res: MatDrawerToggleResult) => this.sidenavOpened.emit(res === 'open'));
      }
    });
  }

  /**
   * Subscribes to orientation change from screen or dashboard and toggles it (landscape/portrait).
   * @private
   */
  private listenToOrientationChange(): void {
    this.orientationSubscriptionFromDashboard = this.editService.portraitOrientation$
      .subscribe((portraitEnabled: boolean) => this.portraitOrientation = portraitEnabled && this.editMode);

    this.orientationSubscriptionFromScreen = this.widgetService.getCombinedScreen()
      .subscribe(({ portraitOrientation }) => this.portraitOrientation = portraitOrientation);
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
