<div class="settings container-sm d-flex align-items-center mx-2">
  <div class="d-flex flex-column justify-content-between">
    <mat-card-subtitle>{{ 'jewishSettings.description' | translate }}</mat-card-subtitle>
    <mat-checkbox (change)="user.settings.dayDescription.zioni = !user.settings.dayDescription.zioni"
                  [checked]="user.settings.dayDescription.zioni"
                  [ngClass]="direction === 'rtl' ? 'text-right' : 'text-left'">
      {{ 'jewishSettings.dayDescription.zioni' | translate }}
    </mat-checkbox>
    <mat-checkbox (change)="user.settings.dayDescription.shabbosMevorchim = !user.settings.dayDescription.shabbosMevorchim"
                  [checked]="user.settings.dayDescription.shabbosMevorchim"
                  [ngClass]="direction === 'rtl' ? 'text-right' : 'text-left'">
      {{ 'jewishSettings.dayDescription.shabbosMevorchim' | translate }}
    </mat-checkbox>
  </div>
</div>
