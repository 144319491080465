import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Direction } from '../../../../../_helpers/translate.helper';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent {
  @Input() config: {
    message: string,
    direction: Direction
  };

  constructor(private dialogRef: MatDialogRef<ConfirmationComponent>) { }

  /**
   * Closes the modal window.
   */
  public onCancel(): void {
    this.dialogRef.close();
  }
}
