import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { PdfViewerComponent as PdfComponent } from 'ng2-pdf-viewer';
import { Subscription, interval } from 'rxjs';
import { repeat, take } from 'rxjs/operators';
import { Widget } from '../../_models';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit, AfterViewInit {
  @ViewChild(PdfComponent, { static: false }) pdfViewer!: PdfComponent;
  private afterLoadCompleteSubscription: Subscription
  fixView() {
    const elements = document.querySelectorAll('pdf-viewer .ng2-pdf-viewer-container');
    elements.forEach(element => {
      element.setAttribute('style', 'position: static');
    });
  }
  @Input() widget: Widget;
  private _src
  public get src() {
    return this._src;
  }
  public set src(value) {
    this._src = value;
  }
  public pageNumber = 0;
  public isBlocked = false;
  public isError = false;

  public async ngOnInit(): Promise<void> {
    this.src = this.widget.content.pdf.url;
    this.widget.contentChangedSource.subscribe(() => {
      this.updatePdfViewerContainerStyles('position: absolute');
      this.src = this.widget.content.pdf.url;
    });

    while (!this.pdfViewer) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.afterLoadCompleteSubscription = this.pdfViewer.afterLoadComplete.subscribe((event) => {
      this.pdfViewer.pageRendered.subscribe((event) => {
        console.log('page rendered', event);
        this.pdfViewer.updateSize();
        this.updatePdfViewerContainerStyles('position: static');
      }).unsubscribe();

      // this row triggers page rendered event
      this.updatePdfViewerContainerStyles('position: static');
    });

    this.widget.resizeStopSource.subscribe(() => {
      this.updatePdfViewerContainerStyles('position: static');
    });
  }

  public async ngAfterViewInit(): Promise<void> {
    while (!this.pdfViewer || !this.pdfViewer?.pdfViewer?.viewer?.hasChildNodes()) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    this.updatePdfViewerContainerStyles('position: static');
  }

  private updatePdfViewerContainerStyles(style: string): void {
    if (this.src) {
      this.pdfViewer.updateSize();
    }
    const elements = document.querySelectorAll('pdf-viewer .ng2-pdf-viewer-container');
    elements.forEach(element => {
      element.setAttribute('style', style);
    });

  }

  public onError(error: any) {
    console.log(error);
    this.isBlocked = true;
  }

  public setPagesNumber(event: any): void {
    this.isBlocked = false;
    this.widget.content.pdf.originalPagesNumber = event._pdfInfo.numPages;

    if (!this.widget.content.pdf.pagesNumber || this.widget.content.pdf.pagesNumber === 0) {
      this.widget.content.pdf.pagesNumber = event._pdfInfo.numPages;
    }

    if (this.widget.content.pdf.pagesNumber === 1) {
      this.pageNumber = 0;
    } else {
      const pageNumber = interval(this.widget.content.pdf.secondsPerPage * 1000);
      pageNumber.pipe(
        take(this.widget.content.pdf.pagesNumber),
        repeat()
      )
        .subscribe(page => this.pageNumber = page);
    }
  }

  ngOnDestroy(): void {
    this.afterLoadCompleteSubscription.unsubscribe();
  }

}
