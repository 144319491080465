import { Component, OnInit, Input, ViewChild, ElementRef, HostBinding } from "@angular/core";
import { Observable, timer, of } from "rxjs";
import { delay, map, repeat, take } from "rxjs/operators";
import { YahrzeitItem, YahrzeitTableProperties, GridContainerStyle, CandleStyle, Resolution, Font, Size } from "src/app/_models";
import { ResponsiveService } from "src/app/_services";

@Component({
  selector: 'app-yahrzeit-viewer',
  templateUrl: './yahrzeit.component.html',
  styleUrls: ['./yahrzeit.component.css']
})
export class YahrzeitViewerComponent implements OnInit {
  @Input() items: YahrzeitItem[];
  @Input() yahrzeitTable: YahrzeitTableProperties;
  @Input() size: Size;
  @Input() font: Font;

  @ViewChild('container') container: ElementRef<HTMLDivElement>;

  @HostBinding('style.fontSize') fontSize = 'inherit';

  public plaqueStyle: {};
  public visibleItems: YahrzeitItem[];
  public yahrzeitObservableItems$: Observable<YahrzeitItem>[] = [];
  public gridContainerStyle: {
    gridTemplateRows: string,
    gridTemplateColumns: string,
    rowGap: string,
    columnGap: string,
    gridTemplateAreas: string,
    padding: string
  };
  public candleImageStyle: {
    left: string,
    top: string,
    width: string,
    height: string
  };

  private sortedItems: YahrzeitItem[][] = [];
  private emptyItem = { isYahrzeit: false, formattedText: ['', '', ''] } as YahrzeitItem;

  constructor(private responsiveService: ResponsiveService) {
  }

  public ngOnInit(): void {
    const fontSize = this.font.size * 1.3;
    this.fontSize = this.responsiveService.getResponsiveFontSize(fontSize);

    if (this.yahrzeitTable.monthPerPlaque) {
      this.prepareMonthPerPlaque();
      this.yahrzeitObservableItems$ = this.sortedItems.map((itemsArr: YahrzeitItem[]) => this.getYahrzeitItemsAsObservablesArray(itemsArr));
    } else {
      if (this.items.length === 0) {
        this.fillWithEmptyPlaques();
      } else {
        this.items = this.addEmptyPlaques(YahrzeitViewerComponent.removeDisabled(this.items));
      }
      this.getVisibleItems(this.items);
    }

    this.gridContainerStyle = this.containerStyle;
    this.candleImageStyle = this.candleStyle;
  }

  private get itemsNumber(): number {
    return this.yahrzeitTable.colsNum * this.yahrzeitTable.rowsNum - this.yahrzeitTable.window.height * this.yahrzeitTable.window.width;
  }

  private get containerStyle(): GridContainerStyle {
    return {
      gridTemplateRows: `repeat(${ this.yahrzeitTable.rowsNum }, 1fr)`,
      gridTemplateColumns: `repeat(${ this.yahrzeitTable.colsNum }, 1fr)`,
      rowGap: `${ this.yahrzeitTable.rowGap / 10 }%`,
      columnGap: `${ this.yahrzeitTable.columnGap / 10 }%`,
      gridTemplateAreas: this.gridTemplateAreas,
      padding: `${ this.yahrzeitTable.yShift }px ${ this.yahrzeitTable.margin }px ${ this.yahrzeitTable.margin }px`
    };
  }

  private get candleStyle(): CandleStyle {
    return {
      left: this.candleLocation.left + 'px',
      top: this.candleLocation.top + 'px',
      width: this.candleSize.width + 'px',
      height: this.candleSize.height + 'px'
    };
  }

  private get candleSize(): Resolution {
    return new Resolution(
      this.yahrzeitTable.candle.width * window.innerWidth / this.responsiveService.resolution.width,
      this.yahrzeitTable.candle.height * window.innerHeight / this.responsiveService.resolution.height
    );
  }

  private get candleLocation(): Location {
    return new Location(
      this.yahrzeitTable.candle.left * window.innerWidth / this.responsiveService.resolution.width,
      this.yahrzeitTable.candle.top * window.innerHeight / this.responsiveService.resolution.height
    );
  }

  private get gridTemplateAreas(): string {
    const emptyWindow = {
      fromCol: this.yahrzeitTable.window.right,
      toCol: this.yahrzeitTable.window.right + this.yahrzeitTable.window.width,
      fromRow: this.yahrzeitTable.window.top,
      toRow: this.yahrzeitTable.window.top + this.yahrzeitTable.window.height
    };

    let result = '';
    let reducer = 0;
    for (let row = 0; row < this.yahrzeitTable.rowsNum; row++) {
      result += ' "';
      let templateRow = '';
      for (let col = 0; col < this.yahrzeitTable.colsNum; col++) {
        if (row >= emptyWindow.fromRow && row < emptyWindow.toRow) {
          if (col >= emptyWindow.fromCol && col < emptyWindow.toCol) {
            templateRow += ' . ';
            reducer++;
            continue;
          }
        }
        const itemNumber = row * this.yahrzeitTable.colsNum + col - reducer;
        templateRow += `item-${ itemNumber } `;
      }
      result += `${ templateRow } "`;
    }

    return result;
  }

  private getVisibleItems(items: YahrzeitItem[]): void {
    const viewsNumber = Math.ceil(items.length / this.itemsNumber) + 1;
    const itemsInterval$ = timer(0, this.yahrzeitTable.interval * 1000);
    itemsInterval$.pipe(
      take(viewsNumber),
      map(counter => items.slice(counter * this.itemsNumber, (counter + 1) * this.itemsNumber)),
      repeat()
    )
      .subscribe((yahrzeitItems: YahrzeitItem[]) => this.visibleItems = yahrzeitItems);
  }

  private prepareMonthPerPlaque(): void {
    this.sortItemsByMonth();
    this.addEmptyMonths();
    this.sortedItems.splice(0, this.yahrzeitTable.firstMonth - 1);
    this.sortedItems.length = this.itemsNumber;
  }

  private sortItemsByMonth(): void {
    this.items.forEach(item => {
      const index = item.hebrewMonth - 1;
      if (this.sortedItems[index]) {
        this.sortedItems[index].push(item);
      } else {
        this.sortedItems[index] = [item];
      }
    });
  }

  private addEmptyMonths(): void {
    const monthsNumber = 12;
    for (let i = 0; i < monthsNumber; i++) {
      if (!this.sortedItems[i]) {
        this.sortedItems[i] = [this.emptyItem];
      }
    }
  }

  private getYahrzeitItemsAsObservablesArray(array: YahrzeitItem[]): Observable<YahrzeitItem> {
    const itemsInterval$ = timer(0, this.yahrzeitTable.interval * 1000);

    return !array[0].firstNameHebrew ?
      of(this.emptyItem) :
      itemsInterval$.pipe(
        take(array.length),
        map(counter => array[counter]),
        delay(this.yahrzeitTable.interval * 1000),
        repeat()
      );
  }

  private static removeDisabled(items: YahrzeitItem[]): YahrzeitItem[] {
    return items.filter(item => item.enabled);
  }

  private addEmptyPlaques(items: YahrzeitItem[]): YahrzeitItem[] {
    const emptyPlaquesNumber = this.itemsNumber * Math.ceil(items.length / this.itemsNumber) - items.length;
    const result = [...items];
    for (let i = 0; i < emptyPlaquesNumber; i++) {
      result.push(this.emptyItem);
    }

    return result;
  }

  private fillWithEmptyPlaques(): void {
    const yahrzeitItem = { isYahrzeit: false, formattedText: ['', '', ''] } as YahrzeitItem;
    for (let i = 0; i < this.yahrzeitTable.rowsNum * this.yahrzeitTable.colsNum; i++) {
      this.items.push(yahrzeitItem);
    }
  }
}

class Location {
  constructor(public left: number, public top: number) { }
}
