import { Pipe, PipeTransform } from "@angular/core";
import {
  NgbCalendarHebrew,
  NgbDate,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { ChangeTimes } from "../_enums/change-times";
import { JewishHelper } from "../_helpers/jewish.helper";
import { TextByDateEntry } from "../_models/TextByDateEntry";
import { Widget } from "../_models/widget";
import { HalachicTimesService } from "../_services/halachic-times.service";

@Pipe({
  name: "valueText",
})
export class ValueTextPipe implements PipeTransform {
  public dayLink = 0;
  public jewishDateDayLink: any;
  public hebrewDate: NgbDateStruct;
  public textValue: string[] = [];
  private jewishCalendar = new NgbCalendarHebrew();
  public textValueAfter: string[] = [];

  constructor(private halachicTimesService: HalachicTimesService) { }

  transform(value: TextByDateEntry, selDate: Date, widget: Widget): string[] {
    this.textValueAfter = [];
    const {
      dateMonth,
      dateDay,
      hebrewDate,
      text,
    } = value;
    this.textValue = [text];

    for (let i = 0; i < this.textValue.length; i++) {
      const textDateOfMonth = parseInt(dateMonth);
      const textDateOfDay = parseInt(dateDay);
      const selectedDate = new Date(selDate);
      this.dayLink = this.getDayLink(selectedDate, widget.changeTimeKey);

      const dayLinkDate = ValueTextPipe.getGregorianDateByDayLink(
        selectedDate,
        this.dayLink
      );

      const ngbDate = new NgbDate(
        dayLinkDate.getFullYear(),
        dayLinkDate.getMonth() + 1,
        dayLinkDate.getDate()
      );
      this.hebrewDate = this.jewishCalendar.fromGregorian(ngbDate);
      // jewish Date
      let ishLeapYear = JewishHelper.isJewishLeapYear(this.hebrewDate.year);
      this.jewishDateDayLink = this.hebrewDate;
      if (widget.content.text.isDateHhebre == false) {
        // If normal year (not passed)
        if (
          ishLeapYear != true ||
          (ishLeapYear == true && this.jewishDateDayLink.month < 6)
        ) {
          if (
            hebrewDate.month == this.jewishDateDayLink.month &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {

            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }
        }

        //If a leap year
        if (ishLeapYear == true && this.jewishDateDayLink.month >= 6) {
          // NISAN
          if (
            hebrewDate.month == 7 &&
            this.jewishDateDayLink.month == 8 &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {
            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }

          // IAAR
          if (
            hebrewDate.month == 8 &&
            this.jewishDateDayLink.month == 9 &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {
            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }

          // SIVAN
          if (
            hebrewDate.month == 9 &&
            this.jewishDateDayLink.month == 10 &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {
            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }

          // TAMUZ
          if (
            hebrewDate.month == 10 &&
            this.jewishDateDayLink.month == 11 &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {
            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }

          // AV
          if (
            hebrewDate.month == 11 &&
            this.jewishDateDayLink.month == 12 &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {
            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }

          // ADAR A'
          if (
            hebrewDate.month == 14 &&
            this.jewishDateDayLink.month == 6 &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {
            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }

          // ADAR B'
          if (
            hebrewDate.month == 13 &&
            this.jewishDateDayLink.month == 7 &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {
            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }
          // ELUL
          if (
            hebrewDate.month == 12 &&
            this.jewishDateDayLink.month == 13 &&
            hebrewDate.day == this.jewishDateDayLink.day
          ) {
            if (i + 1 == this.textValue.length) {
              return this.textValueAfter;
            }
          }
        }
        // }
      }

      //Date loazi
      if (widget.content.text.isDateHhebre === true) {
        if (
          textDateOfMonth == new Date(dayLinkDate).getMonth() + 1 &&
          textDateOfDay == dayLinkDate.getDate()
        ) {
          if (i + 1 == this.textValue.length) {
            return this.textValueAfter;
          }
        }
      }
    }
  }

  private getDayLink(date: Date, changeTimeKey: ChangeTimes = ChangeTimes.Tzais): number {
    const changeTimeOption = this.halachicTimesService.times.find((time) => time.key === changeTimeKey)?.option;
    return date > new Date(this.halachicTimesService.halachicTimes[changeTimeOption]) ? 1 : 0;
  }

  public static getGregorianDateByDayLink(date: Date, dayLink: number): Date {
    return new Date(date.setDate(date.getDate() + dayLink));
  }
}
