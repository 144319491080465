import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxStatus } from '../../../../../../_enums/checkbox-status';
import { Languages } from '../../../../../../_enums/languages';
import { Direction, TranslateHelper } from '../../../../../../_helpers/translate.helper';
import { Holidays } from '../../../../../../_models/holiday';
import { Scheduler } from '../../../../../../_models/scheduler';
import { SchedulerService } from './scheduler.service';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css']
})
export class SchedulerComponent implements OnInit, OnChanges {
  @Input() scheduler: Scheduler;
  @ViewChild('accordion') accordion: MatAccordion;

  public schedulersEnabled: boolean;
  public holidaysEnabled = false;
  public direction: Direction = 'ltr';

  constructor(
    private schedulerService: SchedulerService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.scheduler) {
      this.checkIfSchedulersEnabled();
      this.schedulerService.updateSchedulers(changes.scheduler.currentValue);
      this.holidaysEnabled = changes.scheduler.currentValue.holidays?.some(day => day.status !== CheckboxStatus.Indeterminate);
    }
  }

  public ngOnInit(): void {
    this.setTranslation();
    this.schedulerService.holidaysCollapsed$.subscribe(() => this.holidaysEnabled = false);
  }

  /**
   * Toggles the scheduler & clears on the main slider disabled.
   * @param {boolean} enabled - whether the main scheduler slider is enabled.
   */
  public toggleSchedulers(enabled: boolean): void {
    this.schedulersEnabled = enabled;
    if (!enabled) {
      this.schedulerService.clearSchedulers();
      this.accordion.closeAll();
    }
  }

  /**
   * Determines whether the scheduler is enabled.
   * @private
   */
  private checkIfSchedulersEnabled(): void {
    this.schedulersEnabled = this.scheduler && Object.values(this.scheduler).some(scheduler => {
      return scheduler && Array.isArray(scheduler) && typeof scheduler !== 'boolean' // checks if the scheduler is 'holidays'
             ? this.isHolidaysEnabled(scheduler)
             : !!scheduler;
    });

    this.schedulersEnabled = this.schedulersEnabled || this.scheduler.blink.enabled;
  }

  /**
   * Determines whether some special days are enabled.
   * @param {Holidays} holidays - a list of holiday object.
   * @private
   */
  private isHolidaysEnabled(holidays: Holidays): boolean {
    return holidays.some(holiday => holiday.status !== CheckboxStatus.Indeterminate);
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
