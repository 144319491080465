import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TableRecord } from '../../_models';

export interface SpecifiedTableRecord {
  record: TableRecord;
  widgetId: number;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alertRecordSource = new Subject<SpecifiedTableRecord>();

  public alertRecord$: Observable<SpecifiedTableRecord> = this.alertRecordSource.asObservable();

  public emitAlertRecord(record: TableRecord, widgetId: number): void {
    this.alertRecordSource.next({ record, widgetId });
  }
}
