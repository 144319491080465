import { Pipe, PipeTransform } from '@angular/core';
import { ChangeTimes } from '../_enums/change-times';
import { TextByDateEntry } from '../_models/TextByDateEntry';
import { Widget } from '../_models/widget';
import { HalachicTimesService } from '../_services/halachic-times.service';

@Pipe({
  name: 'valueTextByDay',
})
export class ValueTextByDayPipe implements PipeTransform {
  public dayLink = 0;

  constructor(private halachicTimesService: HalachicTimesService) { }

  transform(value: TextByDateEntry[], selectedDate: Date, widget: Widget): string[] {
    if (!value || value.length === 0) {
      return [];
    }

    this.dayLink = this.getDayLink(selectedDate, widget.changeTimeKey);
    const dayLinkDate = ValueTextByDayPipe.getGregorianDateByDayLink(selectedDate, this.dayLink);

    return value
      .filter(entry => entry.hebrewDate.day === dayLinkDate.getDate() && entry.hebrewDate.month - 1 === dayLinkDate.getMonth())
      .map(item => item.text);
  }

  private getDayLink(date: Date, changeTimeKey: ChangeTimes = ChangeTimes.Tzais): number {
    const changeTimeOption = this.halachicTimesService.times.find(time => time.key === changeTimeKey)?.option;
    return date > new Date(this.halachicTimesService.halachicTimes[changeTimeOption]) ? 1 : 0;
  }

  public static getGregorianDateByDayLink(date: Date, dayLink: number): Date {
    return new Date(date.setDate(date.getDate() + dayLink));
  }
}
