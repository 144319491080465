export interface Background {
  type?: BackgroundTypes;
  color: string;
  imageUrl?: string;
  stretched?: boolean;
}

export enum BackgroundTypes {
  Transparent,
  Color,
  Image
}
