import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { Scheduler } from "src/app/_models";

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {
  private schedulersClearSource = new Subject<void>();
  private schedulersUpdateSource = new Subject<Scheduler>();
  private holidaysCollapseSource = new Subject<void>();

  public schedulersCleared$: Observable<void> = this.schedulersClearSource.asObservable();
  public schedulersUpdated$: Observable<Scheduler> = this.schedulersUpdateSource.asObservable();
  public holidaysCollapsed$: Observable<void> = this.holidaysCollapseSource.asObservable();

  public clearSchedulers(): void {
    this.schedulersClearSource.next();
  }

  public updateSchedulers(scheduler: Scheduler): void {
    this.schedulersUpdateSource.next(scheduler);
  }

  public collapseHolidays(): void {
    this.holidaysCollapseSource.next();
  }
}
