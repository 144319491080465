import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private _selectedDate: Date;
  public dateChangeSource = new BehaviorSubject<Date>(new Date());

  public dateChanged$: Observable<Date> = this.dateChangeSource.asObservable();

  public get selectedDate(): Date {
    return this._selectedDate;
  }

  public set selectedDate(value: Date) {
    this._selectedDate = value;
  }

  /**
   * Emits the selected date.
   * @param {Date} date - selected date.
   */
  public setDate(date: Date): void {
    this.dateChangeSource.next(date);
  }
}
