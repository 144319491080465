import { Direction } from "@angular/cdk/bidi";
import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { NgbCalendarHebrew, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Languages } from "src/app/_enums";
import { SchedulerHelper, TranslateHelper, DateHelper } from "src/app/_helpers";
import { HebrewMonth, Scheduler } from "src/app/_models";
import { SchedulerService } from "../scheduler.service";

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css']
})
export class DateRangeComponent implements OnInit, OnDestroy {
  @Input() scheduler: Scheduler;

  @ViewChild('sourceStart') sourceStart: ElementRef<HTMLInputElement>;
  @ViewChild('sourceEnd') sourceEnd: ElementRef<HTMLInputElement>;
  @ViewChild('targetStart') targetStart: ElementRef<HTMLInputElement>;
  @ViewChild('targetEnd') targetEnd: ElementRef<HTMLInputElement>;

  @ViewChild('monthStart') monthStart: MatSelect;
  @ViewChild('monthEnd') monthEnd: MatSelect;
  @ViewChild('dayStart') dayStart: ElementRef<HTMLInputElement>;
  @ViewChild('dayEnd') dayEnd: ElementRef<HTMLInputElement>;
  @ViewChild('timeStart') timeStart: ElementRef<HTMLInputElement>;
  @ViewChild('timeEnd') timeEnd: ElementRef<HTMLInputElement>;

  public hebrewMonths = SchedulerHelper.hebrewMonths;
  public selectedMonthStart: HebrewMonth;
  public selectedMonthEnd: HebrewMonth;

  public isHebrewRange: boolean;
  public isCollapsed = true;
  public direction: Direction = 'ltr';

  private clearSubscription: Subscription;
  private updateSubscription: Subscription;

  private hebrewCalendar = new NgbCalendarHebrew();

  constructor(
    private schedulerService: SchedulerService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper
  ) {
  }

  public ngOnInit(): void {
    this.setTranslation();
    this.clearRanges();
    this.updateDateRangeSchedulers();
    this.resetHebrewDateRangeScheduler(this.scheduler);
  }

  public ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
    this.updateSubscription.unsubscribe();
  }

  /**
   * Formats the date in the specified format.
   * @param {Date | string} date - a date to format.
   */
  public formatDate(date: Date | string): string {
    return date
      ? `${DateHelper.padZero(date, 'd')}/${DateHelper.padZero(date, 'M')}, ` +
      `${DateHelper.padZero(date, 'h')}:${DateHelper.padZero(date, 'm')}`
      : null;
  }

  /**
   * Sets the Gregorian date range.
   * @param {HTMLInputElement} source - the start date HTML input element.
   * @param {HTMLInputElement} target - the end date HTML input element.
   */
  public setDateRange(source: HTMLInputElement, target: HTMLInputElement): void {
    const date = new Date(source.value);
    target.value = this.formatDate(date);

    if (!this.scheduler.dateRange) {
      this.scheduler.dateRange = { start: null, end: null };
    }

    const start = this.sourceStart.nativeElement.value;
    const end = this.sourceEnd.nativeElement.value;

    if (start && end) {
      this.scheduler.dateRange = { start, end };
    }
  }

  /**
   * Sets the Hebrew date.
   */
  public setHebrewDate(): void {
    const monthStart = this.monthStart.value;
    const monthEnd = this.monthEnd.value;
    const dayStart = this.dayStart.nativeElement.value;
    const dayEnd = this.dayEnd.nativeElement.value;

    if (!(monthStart && monthEnd && dayStart && dayEnd)) return;

    const year = this.hebrewCalendar.getToday().year;
    this.scheduler.hebrewRange = {
      start: {
        date: new NgbDate(year, monthStart.id, +dayStart),
        time: this.timeStart.nativeElement.value
      },
      end: {
        date: new NgbDate(year, monthEnd.id, +dayEnd),
        time: this.timeEnd.nativeElement.value
      }
    }
  }

  /**
   * Sets the Hebrew date range time start/stop.
   * @param {string} time - a time value to set.
   * @param {'start' | 'end'} key - a key to set.
   */
  public setHebrewTime(time: string, key: 'start' | 'end'): void {
    if (!this.scheduler.hebrewRange) return;

    this.scheduler.hebrewRange[key].time = time;
  }

  /**
   * Clears the date input field & the date range scheduler.
   * @param {HTMLInputElement} input - date input field.
   */
  public clearDate(input: HTMLInputElement): void {
    input.value = null;

    if (!this.targetStart.nativeElement.value && !this.targetEnd.nativeElement.value) {
      this.scheduler.dateRange = null;
    }
  }

  /**
   * Clears the Hebrew date range.
   */
  public clearHebrewRange(): void {
    this.scheduler.hebrewRange = null;
    this.selectedMonthStart = null;
    this.selectedMonthEnd = null;
    this.isHebrewRange = false;
  }

  /**
   * Initialises the Hebrew range date scheduler.
   * @param {Scheduler} scheduler - a Scheduler object.
   * @private
   */
  private initHebrewRange(scheduler: Scheduler): void {
    this.selectedMonthStart = scheduler?.hebrewRange
      ? this.hebrewMonths.find(month => month.id === scheduler?.hebrewRange?.start.date.month)
      : null;

    this.selectedMonthEnd = scheduler?.hebrewRange
      ? this.hebrewMonths.find(month => month.id === scheduler?.hebrewRange?.end.date.month)
      : null;
  }

  /**
   * Listens to the scheduler update & resets the Hebrew range date scheduler on emit.
   * @private
   */
  private updateDateRangeSchedulers(): void {
    this.updateSubscription = this.schedulerService.schedulersUpdated$.subscribe((scheduler: Scheduler) => {
      this.resetHebrewDateRangeScheduler(scheduler);
    });
  }

  /**
   * Resets the Hebrew date range scheduler.
   * @param {Scheduler} scheduler - a Scheduler object.
   * @private
   */
  private resetHebrewDateRangeScheduler(scheduler: Scheduler): void {
    this.isHebrewRange = !!scheduler.hebrewRange;
    this.initHebrewRange(scheduler);
  }

  /**
   * Listens to schedulersCleared$ Observable & clears date ranges on emit.
   * @private
   */
  private clearRanges(): void {
    this.clearSubscription = this.schedulerService.schedulersCleared$.subscribe(() => {
      this.clearDateRange()
      this.clearHebrewRange();
    });
  }

  /**
   * Clears the Gregorian date range scheduler.
   * @private
   */
  private clearDateRange(): void {
    if (this.targetStart) this.targetStart.nativeElement.value = null;
    if (this.targetEnd) this.targetEnd.nativeElement.value = null;
    this.scheduler.dateRange = null;
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslation(): void {
    this.translate.onLangChange
      .subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
