import { NgbCalendarHebrew, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { JewishCalendar } from 'kosher-zmanim';
import { CheckboxStatus } from '../_enums/checkbox-status';
import { HebrewMonth } from '../_models/hebrew-month';
import { Holidays } from '../_models/holiday';
import { DynamicTimeRange, Scheduler, DateRange, Blink } from '../_models/scheduler';

interface HolidayConfig {
  date: Date;
  dayLink: number;
  jewishCalendar: JewishCalendar;
  hanukkahCandles?: boolean[];
  erevShabbosTime?: Date;
  candleLightTime?: Date;
  shabbosEndTime?: Date;
}

export class SchedulerHelper {

  public static hebrewMonths: HebrewMonth[] = [
    { id: 1, en: 'Tishrei', he: 'תשרי', days: 30 },
    { id: 2, en: 'Cheshvan', he: 'חשוון', days: 30 },
    { id: 3, en: 'Kislev', he: 'כסליו', days: 30 },
    { id: 4, en: 'Tevet', he: 'טבת', days: 29 },
    { id: 5, en: 'Shevat', he: 'שבט', days: 30 },
    { id: 6, en: 'Adar I', he: 'אדר א', days: 29 },
    { id: 7, en: 'Adar II', he: 'אדר ב', days: 29 },
    { id: 8, en: 'Nissan', he: 'ניסן', days: 30 },
    { id: 9, en: 'Iyar', he: 'אייר', days: 29 },
    { id: 10, en: 'Sivan', he: 'סיון', days: 30 },
    { id: 11, en: 'Tammuz', he: 'תמוז', days: 29 },
    { id: 12, en: 'Av', he: 'אב', days: 30 },
    { id: 13, en: 'Elul', he: 'אלול', days: 29 }
  ];

  public static holidays: Holidays = [
    { en: 'Chol HaMoed', he: 'חול המועד', id: 'chol_hamoed', status: CheckboxStatus.Indeterminate },
    { en: 'Yom Tov', he: 'יום טוב', id: 'yom_tov', status: CheckboxStatus.Indeterminate },
    { en: 'Yom Chol', he: 'יום חול', id: 'yom_chol', status: CheckboxStatus.Indeterminate },
    { en: 'Shabbat', he: 'שבת', id: 'shabbat', status: CheckboxStatus.Indeterminate },
    { en: 'Rosh Chodesh', he: 'ראש חודש', id: 'rosh_chodesh', status: CheckboxStatus.Indeterminate },
    { en: 'Tzom', he: 'צום', id: 'tzom', status: CheckboxStatus.Indeterminate },
    { en: 'Yk\'k', he: 'יוכ"ק', id: 'yom_kippur_katan', status: CheckboxStatus.Indeterminate },
    { en: 'BaHaB', he: 'בה"ב', id: 'bahab', status: CheckboxStatus.Indeterminate },
    { en: 'Erev Pesach', he: 'ערב פסח', id: 'erev_pesach', status: CheckboxStatus.Indeterminate },
    { en: 'Erev Chag', he: 'ערב חג', id: 'erev_chag', status: CheckboxStatus.Indeterminate },
    { en: 'Erev Shabbat', he: 'ערב שבת', id: 'erev_shabbat', status: CheckboxStatus.Indeterminate, erevShabbosStartTime: '12:00' },
    {
      en: 'Hanukkah', he: 'חנוכה', id: 'hanukkah', status: CheckboxStatus.Indeterminate,
      hanukkahCandles: [true, true, true, true, true, true, true, true]
    }
  ];

  private static ngbCalendar = new NgbCalendarHebrew();

  private static holidayCheckers = {
    chol_hamoed: SchedulerHelper.holHaMoed,
    yom_tov: SchedulerHelper.yomTov,
    yom_chol: SchedulerHelper.yomChol,
    shabbat: SchedulerHelper.shabbat,
    rosh_chodesh: SchedulerHelper.roshChodesh,
    tzom: SchedulerHelper.tzom,
    yom_kippur_katan: SchedulerHelper.yomKippurKatan,
    // bahab: SchedulerHelper.bahab,
    erev_pesach: SchedulerHelper.erevPesach,
    erev_chag: SchedulerHelper.erevChag,
    erev_shabbat: SchedulerHelper.erevShabbat,
    hanukkah: SchedulerHelper.hanukkah
  };

  /**
   * Determines whether the widget is visible due to the 'Week Days' scheduler.
   * @param {Scheduler} scheduler - scheduler options.
   * @param {Date} date - current/selected date.
   */
  public static isDayVisible(scheduler: Scheduler, date: Date): boolean {
    let dayVisible: boolean;
    const day = date.getDay();

    if (!scheduler.daysOfWeek) {
      dayVisible = true;
    } else {
      scheduler.daysOfWeek.forEach((visible: boolean, index: number) => {
        if (day === index && visible) {
          dayVisible = true;
        }
      });
    }

    return dayVisible;
  }

  /**
   * Determines whether the widget is visible due to the 'Date Range' scheduler.
   * @param {Scheduler} scheduler - scheduler options.
   * @param {Date} date - current/selected date.
   */
  public static isRangeVisible(scheduler: Scheduler, date: Date): boolean {
    return !scheduler.dateRange || (new Date(scheduler.dateRange.start) <= date && date <= new Date(scheduler.dateRange.end));
  }

  /**
   * Determines whether the widget is visible due to the 'Hebrew Date Range' scheduler.
   * @param {Scheduler} scheduler - scheduler options.
   * @param {JewishCalendar} jewishCalendar - JewishCalendar options.
   * @param {Date} date - current/selected date.
   */
  public static isHebrewRangeVisible(scheduler: Scheduler, jewishCalendar: JewishCalendar, date: Date): boolean {
    if (!scheduler.hebrewRange) {
      return true;
    }

    const currentHebrewDate = this.toHebrewDate(new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate()));
    if (!jewishCalendar.isJewishLeapYear() && currentHebrewDate.month > 7) {
      currentHebrewDate.month++;
    }

    const startDateCode = +(scheduler.hebrewRange.start.date.month.toString().padStart(2, '0') + scheduler.hebrewRange.start.date.day.toString().padStart(2, '0'));
    const endDateCode = +(scheduler.hebrewRange.end.date.month.toString().padStart(2, '0') + scheduler.hebrewRange.end.date.day.toString().padStart(2, '0'));
    const currentDateCode = +(currentHebrewDate.month.toString().padStart(2, '0') + currentHebrewDate.day.toString().padStart(2, '0'));

    const dateRangeVisible = startDateCode <= currentDateCode && currentDateCode <= endDateCode;

    const hasTime = scheduler.hebrewRange.start.time && scheduler.hebrewRange.end.time;
    const isRangeEdgeDay = SchedulerHelper.isRangeEdgeDay(scheduler.hebrewRange.start.date) || this.isRangeEdgeDay(scheduler.hebrewRange.end.date);

    if (!hasTime || !isRangeEdgeDay) {
      return dateRangeVisible;
    }

    const timeRangeVisible = SchedulerHelper.isRangeEdgeDay(scheduler.hebrewRange.start.date) &&
      this.isTimeRangeVisible({ start: scheduler.hebrewRange?.start?.time, end: '23:59' }, date) ||
      (SchedulerHelper.isRangeEdgeDay(scheduler.hebrewRange.end.date) && this.isTimeRangeVisible({
        start: '00:00',
        end: scheduler.hebrewRange.end.time
      }, date));

    return dateRangeVisible && timeRangeVisible;
  }

  /**
   * Determines whether the widget is visible due to the 'Time Range' scheduler.
   * @param {DateRange} timeRange - start & end times of the range.
   * @param {Date} date - current/selected date.
   */
  public static isTimeRangeVisible(timeRange: DateRange, date: Date): boolean {
    if (!timeRange?.start || !timeRange?.end) {
      return true;
    }

    const time = new Date(date);
    const start = timeRange.start.split(':');
    const end = timeRange.end.split(':');
    const fromTime = time.setHours(+start[0], +start[1], 0);
    const toTime = time.setHours(+end[0], +end[1], 0);

    const currentTime = new Date(date);

    return currentTime.getTime() >= fromTime && currentTime.getTime() <= toTime;
  }

  /**
   * Determines whether the widget is visible due to the 'Holidays' scheduler.
   * @async
   * @param {Holidays} holidays - the list of options for every special day.
   * @param {number} dayLink - determines whether the date was changed according to the change time.
   * @param {JewishCalendar} jewishCalendar - JewishCalendar object.
   * @param {Date} selectedDate - current/selected date.
   * @param {Date} [candleLightTime] - candlelight time (optional).
   * @param {Date} [shabbosEndTime] - Shabbos end time (optional).
   */
  public static async isHolidayVisible(holidays: Holidays, dayLink: number, jewishCalendar: JewishCalendar, selectedDate: Date,
                                       candleLightTime?: Date, shabbosEndTime?: Date): Promise<boolean> {

    return new Promise(resolve => {
      if (!holidays) resolve(true);

      const positives: boolean[] = [];

      holidays.forEach(day => {
        if (day.status == CheckboxStatus.Indeterminate) return;

        const date = new Date(selectedDate);

        let erevShabbosTime: Date;
        if (day.erevShabbosStartTime) {
          let time = day.erevShabbosStartTime.split(':');
          erevShabbosTime = new Date(selectedDate);
          erevShabbosTime.setHours(+time[0], +time[1], 0, 0);
        }

        const hanukkahCandles = day.hanukkahCandles ? [...day.hanukkahCandles] : null;

        const config: HolidayConfig = { date, dayLink, jewishCalendar, hanukkahCandles, candleLightTime, shabbosEndTime, erevShabbosTime };

        const isTodayHoliday = this.holidayCheckers[day.id](config);

        if (day.status === CheckboxStatus.Unchecked && isTodayHoliday) {
          resolve(false);
        }

        if (day.status === CheckboxStatus.Checked) {
          positives.push(isTodayHoliday);
        }
      });

      if (positives.length) {
        resolve(positives.some(value => value));
      }

      resolve(true);
    });
  }

  /**
   * Determines whether the widget is visible due to the 'Dynamic Time Range' scheduler.
   * @param {DynamicTimeRange} dynamicTimeRange - scheduler options.
   * @param {Date} time - halachic time.
   * @param {Date} selectedDate - current/selected time.
   */
  public static isDynamicTimeRangeVisible(dynamicTimeRange: DynamicTimeRange, time: Date, selectedDate: Date): boolean {
    if (!dynamicTimeRange) {
      return true;
    }

    const { addition, duration } = dynamicTimeRange;
    const [hh, mm, ss] = duration.split(':');

    const startTime = new Date(time);
    startTime.setMinutes(startTime.getMinutes() + addition);

    const endTime = new Date(time);
    endTime.setHours(endTime.getHours() + +hh, endTime.getMinutes() + +mm, endTime.getSeconds() + +ss);

    const currentTime = new Date(selectedDate);

    return startTime <= currentTime && currentTime <= endTime;
  }

  /**
   * Determines whether the widget is visible due to the 'Is Empty' scheduler.
   * @param {boolean} ifEmpty - whether the scheduler is enabled.
   * @param {number} id - widget ID.
   */
  public static isNotEmpty(ifEmpty: boolean, id: number): boolean {
    if (!ifEmpty) {
      return true;
    }

    const containerElement = document.getElementById('widget-' + id);
    if (!containerElement) {
      return true;
    }

    return containerElement.innerText !== '';
  }

  /**
   * Determines whether the widget is visible due to the 'Blink' scheduler.
   * @param {Blink} blink - the blink options.
   */
  public static isBlinkVisible(blink: Blink): boolean {
    // Possible for timeOff to be 0, so cannot use value => !value.
    if (!blink || Object.values(blink).some(value => value === false || value === null || value === undefined)) return true;

    const { onTime, offTime, unitsCount, timeUnit, activeUnit } = blink;

    const unitDuration = onTime + offTime;
    const cycleDuration = unitDuration * unitsCount;
    const positionInCycle = Math.floor(Date.now() / 1000 / timeUnit) % cycleDuration;
    const start = unitDuration * (activeUnit - 1);

    if (positionInCycle >= start && positionInCycle < start + unitDuration) {
      return positionInCycle < start + onTime;
    }

    return false;
  }

  /* ---------------------------------------------------- Helper methods ------------------------------------------------------------------------ */

  /**
   * Checks whether current day on edge of the range.
   * @param {NgbDate} edge - the edge of the range (start or end).
   * @private
   */
  private static isRangeEdgeDay(edge: NgbDate): boolean {
    if (!edge) return null;

    const today = this.ngbCalendar.getToday();
    return today.month === edge.month && today.day === edge.day;
  }

  /**
   * Converts the Gregorian date to Hebrew one.
   * @param {NgbDate} date - a Gregorian date
   * @private
   */
  private static toHebrewDate(date: NgbDate): NgbDate {
    if (!date) return null;

    return this.ngbCalendar.fromGregorian(date);
  }

  /**
   * Checks whether the current/selected date is Hol Hamoed.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static holHaMoed({ jewishCalendar }: HolidayConfig): boolean {
    return jewishCalendar.isCholHamoed() || jewishCalendar.getJewishMonth() === JewishCalendar.TISHREI && jewishCalendar.getJewishDayOfMonth() === 21;
  }

  /**
   * Checks whether the current/selected date is Yom Tov.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static yomTov({ jewishCalendar, candleLightTime, date, shabbosEndTime }: HolidayConfig): boolean {
    const isErevYomTovAfterCandleLight = jewishCalendar.isErevYomTov() && date?.getTime() >= candleLightTime.getTime();
    const isBeforeYomTovEnd = jewishCalendar.isYomTovAssurBemelacha() && date?.getTime() <= shabbosEndTime.getTime();

    return isErevYomTovAfterCandleLight || isBeforeYomTovEnd;
  }

  /**
   * Checks whether the current/selected date is Rosh Chodesh.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static roshChodesh({ jewishCalendar }: HolidayConfig): boolean {
    return jewishCalendar.isRoshChodesh();
  }

  /**
   * Checks whether the current/selected date is Tzom.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static tzom({ jewishCalendar }: HolidayConfig): boolean {
    return jewishCalendar.isTaanis() && !SchedulerHelper.isYomKippur(jewishCalendar);
  }

  /**
   * Checks whether the current/selected date is Erev Pesach.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static erevPesach({ jewishCalendar }: HolidayConfig): boolean {
    return jewishCalendar.getJewishMonth() === JewishCalendar.NISSAN && jewishCalendar.getJewishDayOfMonth() === 14;
  }

  /**
   * Checks whether the current/selected date is Erev Chag.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static erevChag({ jewishCalendar }: HolidayConfig): boolean {
    return jewishCalendar.isErevYomTov();
  }

  /**
   * Checks whether the current/selected date is Hanukkah.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static hanukkah({ jewishCalendar, hanukkahCandles }: HolidayConfig): boolean {
    if (!jewishCalendar.isChanukah()) return false;
    return !!hanukkahCandles ? hanukkahCandles[SchedulerHelper.getHanukkahDayIndex(jewishCalendar)] : false;
  }

  /**
   * Checks whether the current/selected date is Yom Chol.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static yomChol({ date, jewishCalendar, candleLightTime, shabbosEndTime }: HolidayConfig): boolean {
    const isErevShabbosAfterCandleLight = date?.getDay() === 5 && date?.getTime() >= candleLightTime?.getTime();
    const isBeforeShabbosEnd = date?.getDay() === 6 && date?.getTime() <= shabbosEndTime?.getTime();
    const isErevYomTovAfterCandleLight = jewishCalendar.isErevYomTov() && date?.getTime() >= candleLightTime?.getTime();
    const isBeforeYomTovEnd = jewishCalendar.isYomTovAssurBemelacha() && date?.getTime() <= shabbosEndTime?.getTime();

    return !isErevYomTovAfterCandleLight && !isBeforeYomTovEnd && !isErevShabbosAfterCandleLight && !isBeforeShabbosEnd;
  }

  /**
   * Checks whether the current/selected date is Shabbat.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static shabbat({ date, candleLightTime, shabbosEndTime }: HolidayConfig): boolean {
    const isErevShabbosAfterCandleLight = date.getDay() === 5 && date.getTime() >= candleLightTime.getTime();
    const isBeforeShabbosEnd = date.getDay() === 6 && date.getTime() <= shabbosEndTime.getTime();

    return isErevShabbosAfterCandleLight || isBeforeShabbosEnd;
  }

  /**
   * Checks whether the current/selected date is Erev Shabbat.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static erevShabbat({ date, dayLink, erevShabbosTime }: HolidayConfig): boolean {
    const currentDate = new Date(date);
    currentDate.setDate(date.getDate() + dayLink);
    return currentDate.getDay() === 5 && currentDate.getTime() >= erevShabbosTime.getTime();
  }

  /**
   * Checks whether the current/selected date is Yom Kippur Katan.
   * @param {HolidayConfig} - special day config options on current/selected date.
   * @private
   */
  private static yomKippurKatan({ date, jewishCalendar }: HolidayConfig): boolean {
    const day = date.getDay();
    const roshChodeshOnShabbos = jewishCalendar.getJewishDayOfMonth() === 28 && day === 4;
    const roshChodeshOnMonday = jewishCalendar.getJewishDayOfMonth() === 27 && day === 4;

    return jewishCalendar.isErevRoshChodesh() && ![5, 6].includes(day) ||
      roshChodeshOnShabbos || roshChodeshOnMonday;
  }

  /**
   * Checks whether the current/selected date is Yom Kippur.
   * @param {JewishCalendar} calendar – JewishCalendar object on current/selected date.
   * @private
   */
  private static isYomKippur(calendar: JewishCalendar): boolean {
    return calendar.getJewishMonth() === JewishCalendar.TISHREI && calendar.getJewishDayOfMonth() === 10;
  }

  /**
   * Returns the Hanukkah day number on current/selected date.
   * @param {JewishCalendar} jewishCalendar - – JewishCalendar object on current/selected date.
   * @private
   */
  private static getHanukkahDayIndex(jewishCalendar: JewishCalendar): number {
    const hanukkahDays = jewishCalendar.isKislevShort()
      ? [25, 26, 27, 28, 29, 1, 2, 3]
      : [25, 26, 27, 28, 29, 30, 1, 2];

    return hanukkahDays.indexOf(jewishCalendar.getJewishDayOfMonth());
  }
}
