<div #container *ngIf="!widget.content.table.alertLayout; else alert"
  [class.flex-column]="!widget.content.table.singleLine"
  [style]="{'--title-col-size': widget.content.table?.titleColumnWidth + '%'}" class="d-flex h-100">
  <div #single [hidden]="true" [class.single-line]="widget.content.table.singleLine" class="scroll-wrapper wrapper"
    [class.w-100]="!(widget.effect.type === effectTypes.Scroll && (widget.effect.scrollDirection === scrollDirections.Right || widget.effect.scrollDirection === scrollDirections.Left))"
    [ngClass]="widget.language === languages.Hebrew ? 'scroll-rtl' : 'scroll-ltr'"
    [style.--scroll-duration]="widget.effect.type === effectTypes.Scroll ? speed + 's' : 0 + 's' ">
    <div *ngFor="let record of widget.content.table.records
                      | refresh:refreshCount
                      | filterTableRecords:date:dayLink:shabbosEnd:user.inEretzIsrael
                      | async">
      <div *ngIf="record.enabled"
        [style.color]="record.highlighted ? (widget.content.table.highlightColor || DEFAULT_HIGHLIGHT_COLOUR) : 'inherit'"
        class="event-item">
        <div [class.left]="horizontalAlignLeft" [class.right]="horizontalAlignRight" [class]="widget.font.horizontalAlign !== align.Center ?
                      (widget.language === languages.Hebrew ? widthClassName.Right : widthClassName.Left) : ''"
          [class.mx-3]="widget.content.table.singleLine"
          [style.fontSize]="widget.content.table?.titleColumnFontSize ? widget.content.table?.titleColumnFontSize / 2 + 'vh' : widget.font.size / 2 + 'vh'"
          class="title">{{ record | halachic:widget.language }}</div>

        <div *ngIf="!record.titleOnly" [class.left]="horizontalAlignLeft" [class.right]="horizontalAlignRight"
          [class]="widget.font.horizontalAlign !== align.Center ? (widget.language === languages.Hebrew ? widthClassName.Left : widthClassName.Right) : ''"
          class="time">
          {{
          record.isFixed
          ? (record.fixedTime |
          timeFormat:widgetState.timeFormatTableWidget:widget.content.table.isAmPm:widget.content.table.isUpper:widget.content.table.padZero:widgetState.displaySeconds)
          : (record | tableGetTime: widget: times: date: halachicTimesTwoDays:date: widget.changeTimeKey:
          widget.content.table.isAmPm: widget.content.table.isUpper: widget.content.table.padZero: dayLink:
          widgetState.displaySeconds)
          }}
        </div>
      </div>
    </div>
  </div>
  <div class="h-100"
    [class.w-100]="!(widget.effect.type === effectTypes.Scroll && (widget.effect.scrollDirection === scrollDirections.Right || widget.effect.scrollDirection === scrollDirections.Left))"
    [class.vertical-align-bottom]="verticalAlignBottom" [class.vertical-align-center]="verticalAlignCenter"
    [class.vertical-align-top]="verticalAlignTop">
    <div #wrapper>
      <div *ngFor="let _ of (widget.effect.type === effectTypes.None ? 1 : itemsPerView) | times"
        [class.single-line]="widget.content.table.singleLine" class="wrapper"
        [class.w-100]="!(widget.effect.type === effectTypes.Scroll && (widget.effect.scrollDirection === scrollDirections.Right || widget.effect.scrollDirection === scrollDirections.Left))"
        [ngClass]="{'scroll-rtl': widget.language === languages.Hebrew, 'scroll-ltr': widget.language !== languages.Hebrew, 'scroll-wrapper': speed > 0}"
        [style.--scroll-duration]="widget.effect.type === effectTypes.Scroll ? speed + 's' : 0 + 's' "
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(single.innerHTML)">
      </div>
    </div>
  </div>
</div>
<ng-template #alert>
  <app-alert-viewer [widget]="widget"></app-alert-viewer>
</ng-template>