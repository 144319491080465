import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  pure: false,
  name: 'jewishContent'
})
export class JewishContentPipe implements PipeTransform {

  /**
   * Filters Jewish keys by specified category.
   * @param {string[]} chosenContent - keys to filter.
   * @param {string[]} content - a category to filter by.
   */
  public transform(chosenContent: string[], content: string[]): string[] {
    return chosenContent.filter(key => content.includes(key));
  }

}
