<div
  class="container position-relative"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
>
  <mat-card mat-dialog-content [style.direction]="direction">
    <button
      [title]="'buttons.close' | translate"
      class="close-btn"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon>close</mat-icon>
    </button>
    <!-- <mat-card-header>
      עדכן פרטי הטקסט
    </mat-card-header> -->

    <form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
      <div class="control-pair">
        <div
          *ngIf="widget.content.text.isDateHhebre == true"
          style="display: flex; justify-content: center"
        >
          <div class="contoning-select-date-box">
            <!-- <h4 [style.text-align]="direction === 'rtl' ? 'right' : 'left'">בחירת חודש לועזי</h4> -->
            <mat-form-field appearance="fill">
              <!-- <mat-label>Favorite animal</mat-label> -->
              <mat-select
              [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                [formControl]="dateControlMonts"
                [placeholder]="dateMonthDif != '32' ? dateMonthDif : ''"
              >
                <mat-option>--</mat-option>
                <mat-option *ngFor="let mon of montsDate" [value]="mon">
                  {{ mon.name }}
                </mat-option>
              </mat-select>
              <mat-hint>{{ dateControlMonts.value?.num }}</mat-hint>
            </mat-form-field>
          </div>

          <div class="contoning-select-date-box">
            <mat-form-field appearance="fill">
              <!-- <mat-label>Favorite animal</mat-label> -->
              <mat-select
              [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                [formControl]="dateControlDays"
                [placeholder]="dateMonthDif != '32' ? dateMonthDif : ''"
              >
                <!-- <mat-option>--</mat-option> -->
                <mat-option *ngFor="let day of daysDate" [value]="day">
                  {{ day.name }}
                </mat-option>
              </mat-select>
              <mat-hint>{{ dateControlDays.value?.name }}</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div
          *ngIf="widget.content.text.isDateHhebre == false"
          style="display: flex; justify-content: center"
        >
          <div class="contoning-select-date-box">
            <h4 [style.text-align]="direction === 'rtl' ? 'right' : 'left'">בחירת חודש עברי</h4>
            <mat-form-field appearance="fill">
              <mat-select
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                [formControl]="controlMonts"
                [placeholder]="dateHebrewMonthDif.val"
              >
                <mat-option *ngFor="let mon of monts" [style.text-align]="direction === 'rtl' ? 'right' : 'left'" [value]="mon">
                  {{ mon.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="contoning-select-date-box">
            <h4 [style.text-align]="direction === 'rtl' ? 'right' : 'left'">בחירת יום עברי</h4>
            <mat-form-field appearance="fill">
              <mat-select
                [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
                [formControl]="animalControlDays"
                [placeholder]="dateHebrewDayDif.val"
              >
                <mat-option *ngFor="let day of days"  [style.text-align]="direction === 'rtl' ? 'right' : 'left'" [value]="day">
                  {{ day.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="w-100">
        <mat-form-field
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
          class="mat-form-field-contind-input"
        >
          <mat-label>{{
            "dashboard.widgetProperties.specific.textByDate.text" | translate
          }}</mat-label>
          <input matInput formControlName="text" type="text" />
        </mat-form-field>

        <mat-form-field
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
          class="mat-form-field-contind-input"
        >
          <mat-label>{{
            "dashboard.widgetProperties.specific.textByDate.text" | translate
          }}</mat-label>
          <input matInput formControlName="text1" type="text" />
        </mat-form-field>

        <mat-form-field
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
          class="mat-form-field-contind-input"
        >
          <mat-label>{{
            "dashboard.widgetProperties.specific.textByDate.text" | translate
          }}</mat-label>
          <input matInput formControlName="text2" type="text" />
        </mat-form-field>
        <mat-form-field
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
          class="mat-form-field-contind-input"
        >
          <mat-label>{{
            "dashboard.widgetProperties.specific.textByDate.text" | translate
          }}</mat-label>
          <input matInput formControlName="text3" type="text" />
        </mat-form-field>
        <mat-form-field
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
          class="mat-form-field-contind-input"
        >
          <mat-label>{{
            "dashboard.widgetProperties.specific.textByDate.text" | translate
          }}</mat-label>
          <input matInput formControlName="text4" type="text" />
        </mat-form-field>
        <mat-form-field
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
          class="mat-form-field-contind-input"
        >
          <mat-label>{{
            "dashboard.widgetProperties.specific.textByDate.text" | translate
          }}</mat-label>
          <input matInput formControlName="text5" type="text" />
        </mat-form-field>
        <mat-form-field
          [style.text-align]="direction === 'rtl' ? 'right' : 'left'"
          class="mat-form-field-contind-input"
        >
          <mat-label>{{
            "dashboard.widgetProperties.specific.textByDate.text" | translate
          }}</mat-label>
          <input matInput formControlName="text6" type="text" />
        </mat-form-field>
      </div>

    </form>
  </mat-card>
  <!-- <div style="padding: 41px 88px" mat-dialog-actions class="btns actions"> -->
  <div mat-dialog-actions class="actions" [ngClass]="direction === 'rtl' ? 'btns-he' : 'btns-hn'">
    <button
    color="accent"
    mat-raised-button
    [mat-dialog-close]="false"
    type="button"
    >
    {{ "buttons.cancel" | translate }}
  </button>
  <button
    color="primary"
    mat-raised-button
    [mat-dialog-close]="true"
    type="submit"
    (click)="onSubmit()"
  >
    {{ "buttons.update" | translate }}
  </button>
  </div>

</div>
