import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KosherZmanimOptionDto } from '../../../../_dto/kosher-zmanim-option-dto';
import { TimesDto } from '../../../../_dto/times-dto';
import { UserDto } from '../../../../_dto/user-dto';
import { Languages } from '../../../../_enums/languages';
import { Direction, TranslateHelper } from '../../../../_helpers/translate.helper';
import { AccountService } from '../../../../_services/account.service';
import { SnackbarService } from '../../../../_services/snackbar.service';

@Component({
  selector: 'app-jewish-settings',
  templateUrl: './jewish-settings.component.html',
  styleUrls: ['./jewish-settings.component.css']
})
export class JewishSettingsComponent implements OnInit, AfterViewInit {
  @Input() times: TimesDto[];

  public tzaisTimes: { option: string; en: string; he: string }[];
  public user: UserDto;
  public direction: Direction = 'ltr';
  public activeTabIndex = 0;

  public options = [
    'hebrewDate',
    'gregorianDate',
    'parasha',
    'dafYomi',
    'sefiratHaomer',
    'molad',
    'kiddushLevanahEnd',
    'dayOfWeek',
    'haftara',
    'dayDescription',
    'moridHatal'
  ];

  constructor(
    private accountService: AccountService,
    private translate: TranslateService,
    private translateHelper: TranslateHelper,
    private snackbar: SnackbarService
  ) { }

  public ngOnInit(): void {
    this.setTranslationConfiguration();
    this.accountService.getUser().subscribe((user: UserDto) => this.user = user);
  }

  public ngAfterViewInit(): void {
    this.tzaisTimes = this.times
      .find(time => time.key === 'tzais').options
      .map((timeDto: KosherZmanimOptionDto) => ({
        ...timeDto,
        en: timeDto.en.replace('Geonim', ''),
        he: timeDto.he.replace('גאונים', '')
      }))
      .filter((timeDto: KosherZmanimOptionDto) => timeDto.option.toLowerCase().includes('degrees'));
  }

  /**
   * Updates user profile & shows a snackbar.
   */
  public save(): void {
    this.accountService.updateProfile(this.user).subscribe(
      () => {
        console.log(this.user);
        this.snackbar.success(this.translate.instant('snackbar.profileUpdated'), this.translate.instant('buttons.ok'));
      },
      error => {
        console.log(error);
        this.snackbar.error(error.error.Message, this.translate.instant('buttons.ok'));
      }
    );
  }

  /**
   * Subscribes to language change and define the page direction.
   * @private
   */
  private setTranslationConfiguration(): void {
    this.translate.onLangChange.subscribe(({ lang }) => this.direction = lang === Languages.Hebrew ? 'rtl' : 'ltr');
    this.direction = this.translateHelper.direction;
  }
}
